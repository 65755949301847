import { useContext } from 'react';
import { Divider } from '@mui/material';

import { viewMemberContext } from 'shared/context/member';
import Modal from 'components/modal';

import BasicInfo from './basicinfo';
import Tabs from './tabs';
import YearFilter from './yearfilter';
import useStyles from './style';

const ViewModal = () => {
  const classes = useStyles();
  const { openModal, setOpenModal, selectedMember } =
    useContext(viewMemberContext);

  return (
    <Modal openModal={openModal} setOpenModal={setOpenModal}>
      <BasicInfo fieldList={selectedMember.basicInfo} />
      <Divider />
      <YearFilter />
      <Divider />
      <Tabs className={classes.verticaltabs} />
    </Modal>
  );
};
export default ViewModal;
