import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: '4px 12px',
    borderRadius: '8px',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: { width: '100%', textAlign: 'center' },
  userName: { flex: '15%' },
  email: { flex: '33%' },
  chartCount: { flex: '33%' },
  roleDropDownContainer: { width: '100%', flex: '15%', textAlign: 'center' },
  roleDropDown: {
    '& .MuiSelect-select': {
      border: 0,
      padding: '1px 8px !important',
    },
    '& fieldset': {
      border: 0,
    },
    '& svg': {
      display: 'none',
    },
    '& .Mui-error svg': { color: 'red' },
    maxWidth: 'auto',
    backgroundColor: '#B8C3C9',
    borderRadius: '10px',
    margin: 'auto',
  },
  dotsIcon: { padding: 0, minWidth: 'auto', width: '100%', flex: '4%' },
}));

export default useStyles;
