import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  navGuideAccordion: {
    borderRadius: '10px !important',
    backgroundColor: 'rgba(193, 209, 214, 0.30)',
    color: '#fff',
    fontSize: '14px',
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
      padding: '4px 20px',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-expandIcon': {
      transition: 'none !important',
    },
    '& .MuiCollapse-root': {
      transition: 'none !important',
    },
    '&::before': {
      content: '',
      height: '0 !important',
    },
  },
  navGuideAccordionSummary: {},
  navGuideAccordionDetails: {
    padding: '0px',
  },
  navGuideAccordionDetailItem: {
    padding: '2px 20px',
  },
  navGuideAccordionInner: {
    width: '100%',
    borderRadius: '5px',
    backgroundColor: 'rgba(193, 209, 214, 0)',
    color: '#fff',
    fontSize: '14px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(223, 229, 231, 0.3)',
    },
    '&.Mui-expanded': {
      backgroundColor: 'rgba(193, 209, 214, 0)',
      '&:hover': {
        backgroundColor: 'rgba(193, 209, 214, 0)',
      },
    },
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
      padding: '3px 2px',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-expandIcon': {
      transition: 'none !important',
    },
    '& .MuiCollapse-root': {
      transition: 'none !important',
    },
    '&::before': {
      content: '',
      height: '0 !important',
    },
  },
  textFieldBox: {
    padding: '0',
  },
  customExpandIconWrapper: {
    transform: 'none !important',
  },
  textField: {
    padding: '2px',
    margin: '5px',
    color: '#fff',
    '& .MuiInputBase-input': {
      padding: '2px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2196f3',
      },
    },
  },
  underline: {
    color: 'white',
    '&:before': {
      borderBottomColor: 'white',
    },
    '&:after': {
      borderBottomColor: 'white',
    },
  },
  textDetail: {
    borderBottom: '2px solid white',
    padding: '2px',
    margin: '5px',
    color: '#fff',
  },
}));

export default useStyles;
