export const tableFieldsMetaData = {
  fields: [
    { id: 'ClaimID', alignRight: false, label: 'Claim ID' },
    { id: 'Subscriber_ID', alignRight: false, label: 'Member ID' },
    { id: 'NPI', alignRight: false, label: 'NPI' },
    { id: 'DOS_Max', alignRight: false, label: 'DOS' },
    { id: 'Status', alignRight: false, label: 'Status' },
    { id: 'Health_Plan_No', alignRight: false, label: 'Health Plan#' },
    {
      id: 'IsHistorical',
      alignRight: false,
      label: 'Historical',
      collapse: true,
    },
    {
      id: 'File_ID',
      alignRight: false,
      label: 'File ID',
      collapse: true,
    },
    {
      id: 'CreatedAt',
      alignRight: false,
      label: 'Created At',
      collapse: true,
    },
    {
      id: 'CreatedBy',
      alignRight: false,
      label: 'Created By',
      collapse: true,
    },
  ],
  keyid: '_id',
  title: 'Search results',
  enableCollapse: true,
  enableViewIcon: true,
  enableEditIcon: false,
  enableDeleteIcon: false,
  enableCheckBox: false,
};
