import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: { paddingTop: '12px', paddingBottom: '4px', minWidth: '500px' },
  container: {},
  coderContainer: {
    margin: '12px 0',
    backgroundColor: theme.palette.secondary.main,
    padding: '12px 6px',
    borderRadius: theme.radius,
    boxShadow: '0px 0px 12px 0px #ddd',
  },
  coderHeading: { marginBottom: '12px', fontSize: '16px' },
  buttonGroup: { gap: '12px', flexWrap: 'wrap' },
  button: {
    padding: 0,
    border: 'none',
    borderRadius: '999px !important',
    backgroundColor: theme.palette.primary.grey,
    transition: 'all 200ms linear',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      '& .MuiChip-root': { color: theme.palette.secondary.main },
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
      },
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiChip-root': { color: theme.palette.secondary.main },
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.primary.hover,
        color: theme.palette.primary.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
        '& .MuiChip-root': { color: theme.palette.secondary.main },
        '& .MuiAvatar-root': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.white,
        },
      },
    },
  },
  chips: { border: 0 },
  previewContainer: {
    boxShadow: '0px 0px 12px 0px #eee',
    borderRadius: theme.radius,
    overflowY: 'auto',
    maxHeight: '200px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  previewHeadingContainer: {
    padding: '12px',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.primary.white,
  },
  previewHeading: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '16px',
    padding: '12px 8px',
    color: theme.palette.primary.white,

    borderRadius: theme.radius,
  },
  previewContentContainer: {
    padding: '12px',
    paddingTop: 0,
    backgroundColor: theme.palette.primary.white,
  },
  listContainer: {},
  listRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    minWidth: '500px',
    margin: '8px 10px 8px 0px',
    borderRadius: theme.radius,
    '&:nth-child(even)': { backgroundColor: theme.palette.primary.grey },
    '&:nth-child(odd)': { backgroundColor: theme.palette.secondary.grey },
  },
  listCell: {
    flex: 1,
    '& h6': { color: theme.palette.primary.main, fontSize: '12px' },
    '& p': { margin: 0 },
  },
  infoIcon: {
    margin: 0,
    borderRadius: '999px',
    lineHeight: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(40%, -25%)',
    backgroundColor: theme.palette.secondary.main,
    padding: '4px',
    transition: 'all 150ms linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.hover,
      transform: 'rotate(-10deg) scale(1.2) translate(30%, -20%)',
    },
    '& svg': {
      filter: 'invert(1)',
      maxWidth: '14px',
      maxHeight: '14px',
      opacity: 0.5,
      transform: 'rotate(10deg)',
    },
  },
  popupContainer: {
    padding: '8px',
    '& p': { marginBottom: '6px', fontSize: '14px' },
  },
  assignBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': { backgroundColor: theme.palette.primary.hover },
  },
  cancelBtn: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': { backgroundColor: theme.palette.secondary.hover },
  },
}));

export default useStyles;
