import { createTheme } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const COLORS_COMPONENT_DISABLE_BACKGROUND = '#F4F5F7';

const theme = createTheme({
  palette: {
    primary: {
      main: '#02375F',
      hover: '#001a3e',
      white: '#fff',
      grey: '#F6F6F6',
      black: '#000',
    },
    secondary: {
      main: '#F6FDFC',
      grey: '#DFDFDF',
      background: 'rgba(42, 160, 23, 0.07)',
      hover: '#e6f5f3',
    },
  },
  boxShadow: {
    button: '0px 6px 4px 0px rgba(0, 0, 0, 0.25)',
    dataRow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
  },
  radius: '8px',

  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: '#f2f6f7',
        fontWeight: 'bold',
      },
    },
    MuiTooltip: {
      tooltip: {
        //fontSize: '1em',
        // backgroundColor: '#f7f7df',
        backgroundColor: '#02375F',
        color: '#fff',
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronRightIcon,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'COLORS_COMPONENT_DISABLE_BACKGROUND',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: COLORS_COMPONENT_DISABLE_BACKGROUND,
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        // variants will help you define the props given by Mui and the styles you want to apply for it
        {
          props: { variant: 'standard', disabled: true },
          style: {
            backgroundColor: COLORS_COMPONENT_DISABLE_BACKGROUND,
          },
        },
      ],
    },
  },
});
export default theme;
