import { useContext } from 'react';
import { Grid, Box, Button } from 'components/controls';
import { viewClaimContext } from 'shared/context/claim';

import { StyleBox } from './style';

const Actions = ({}) => {
  const { setOpenModal } = useContext(viewClaimContext);

  const closeClick = () => {
    setOpenModal(false);
  };
  return (
    <Box sx={StyleBox}>
      <Grid
        container
        spacing={1}
        //display ="flex"
        // alignItems="center"
        // justify="center"
        sx={{
          //backgroundColor:'blue',
          margin: 0,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs="auto">
          <Button
            disabled
            label="Submit"
            //className={classes.actionButton}
            // onClick={searchClick}
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            label="Close"
            //className={classes.actionButton}
            onClick={closeClick}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Actions;
