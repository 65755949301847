import { getQueueUsers } from 'api/queue';
import { useEffect, useState } from 'react';
import useStyles from './style';
import { InfoIcon } from 'icons/customIcons';

const {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Avatar,
  Typography,
  Button,
  Popover,
} = require('@mui/material');

const BulkAssignDialog = ({
  queueId = '',
  openBulkAssignDialog = false,
  chartList = [],
  setOpenBulkAssignDialog = () => {},
}) => {
  const [queueUsers, setQueueUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [popupAnchor, setPopupAnchor] = useState(
    chartList?.reduce((acc, curr) => {
      acc[curr?.record?._id] = null;
      return acc;
    }, {})
  );

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const res = await getQueueUsers(queueId);
        setQueueUsers(res?.data);
      } catch (error) {
        console.error({ error });
      }
    })();
  }, [queueId]);

  const handleClose = () => {
    setOpenBulkAssignDialog(false);
    setSelectedUser('');
  };

  const handleSelectedUserChange = (e, newVal) => {
    setSelectedUser(newVal);
  };

  const handleInfoOpen = (e, id) => {
    setPopupAnchor((prev) => ({ ...prev, [id]: e?.currentTarget }));
  };
  const handleInfoClose = (id) => {
    setPopupAnchor((prev) => ({ ...prev, [id]: null }));
  };

  const handleAssign = () => {};

  return (
    <Dialog open={openBulkAssignDialog} onClose={handleClose}>
      <DialogTitle className={classes.title}>Bulk Assignment</DialogTitle>
      <DialogContent>
        <Box>
          <Box className={classes.coderContainer}>
            <Typography variant="h6" className={classes.coderHeading}>
              Select a coder
            </Typography>
            <ToggleButtonGroup
              value={selectedUser}
              onChange={handleSelectedUserChange}
              exclusive
              className={classes.buttonGroup}
            >
              {queueUsers
                ?.filter((user) => user?.role?.toLowerCase() === 'analyst')
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((user) => (
                  <ToggleButton
                    key={user?._id}
                    value={user?._id}
                    className={classes.button}
                  >
                    <Chip
                      size="small"
                      avatar={
                        <Avatar>
                          {Array.from(user?.name)[0]?.toUpperCase()}
                        </Avatar>
                      }
                      label={user?.name}
                      variant="outlined"
                      className={classes.chips}
                    />
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </Box>
          <Box className={classes.previewContainer}>
            <Box className={classes.previewHeadingContainer}>
              <Typography variant="h6" className={classes.previewHeading}>
                {
                  chartList?.filter(
                    (chart) =>
                      chart?.record?.chartStatus?.trim()?.toLowerCase() ===
                      'processed'
                  )?.length
                }{' '}
                charts selected
              </Typography>
            </Box>
            <Box className={classes.previewContentContainer}>
              <Box className={classes.listContainer}>
                {chartList
                  ?.filter(
                    (chart) =>
                      chart?.record?.chartStatus?.trim()?.toLowerCase() ===
                      'processed'
                  )
                  ?.map((chart) => (
                    <Box className={classes.listRow} key={chart?.record?._id}>
                      <Box className={classes.listCell}>
                        <Typography variant="h6">ID</Typography>
                        <Typography paragraph>
                          {chart?.record?.memberID}
                        </Typography>
                      </Box>
                      <Box className={classes.listCell}>
                        <Typography variant="h6">First Name</Typography>
                        <Typography paragraph>
                          {chart?.record?.memberFirstName}
                        </Typography>
                      </Box>
                      <Box className={classes.listCell}>
                        <Typography variant="h6">Last Name</Typography>
                        <Typography paragraph>
                          {chart?.record?.memberLastName}
                        </Typography>
                      </Box>
                      <Typography
                        onMouseEnter={(e) => {
                          handleInfoOpen(e, chart?.record?._id);
                        }}
                        onMouseLeave={() => {
                          handleInfoClose(chart?.record?._id);
                        }}
                        className={classes.infoIcon}
                        aria-owns={
                          Boolean(popupAnchor[chart?.record?._id])
                            ? chart?.record?._id
                            : undefined
                        }
                        aria-haspopup="true"
                      >
                        <InfoIcon />
                      </Typography>
                      <Popover
                        id={chart?.record?._id}
                        sx={{
                          pointerEvents: 'none',
                          top: '-10px',
                        }}
                        PaperProps={{
                          sx: { boxShadow: '0px 0px 12px 0px #00000044' },
                        }}
                        open={Boolean(popupAnchor[chart?.record?._id])}
                        anchorEl={popupAnchor[chart?.record?._id]}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        onClose={() => {
                          handleInfoClose(chart?.record?._id);
                        }}
                        disableRestoreFocus
                      >
                        <Box className={classes.popupContainer}>
                          <Typography paragraph>
                            <strong>Gender: </strong>
                            <span>{chart?.record?.memberGender}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>DOB: </strong>
                            <span>{chart?.record?.memberDOB}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>Project Name: </strong>
                            <span>{chart?.record?.projectName}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>Pages: </strong>
                            <span>{chart?.record?.numberOfPages}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>Total DX Suggested: </strong>
                            <span>{chart?.record?.totalDxSuggested || 0}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>User Added DX: </strong>
                            <span>{chart?.record?.userAddedDx || 0}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>Total DX Accepted: </strong>
                            <span>{chart?.record?.totalDxAccepted || 0}</span>
                          </Typography>
                          <Typography paragraph>
                            <strong>Update Date: </strong>
                            <span>
                              {chart?.record?.lastUpdatedBy?.updatedDate
                                ? new Date(
                                    chart?.record?.lastUpdatedBy?.updatedDate.$date
                                  ).toLocaleDateString()
                                : 'N/A'}
                            </span>
                          </Typography>
                        </Box>
                      </Popover>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAssign} className={classes.assignBtn}>
          Assign
        </Button>
        <Button onClick={handleClose} className={classes.cancelBtn}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkAssignDialog;
