import { useEffect, useState } from 'react';

import ProjectListBar from './projectListBar';
import ProjectView from './projectView';
import AssignUser from './assignUser';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import { adminProjectAPI } from 'api/admin';
import PdfView from './pdfView';

const { Box, CircularProgress } = require('@mui/material');

const Projects = () => {
  const rowsPerPage = 20;
  const [pageState, setPageState] = useState({
    pageNo: 1,
    totalRecords: null,
    totalPages: 1,
  });
  const [selectedProject, setSelectedProject] = useState(0);
  const [currentScreen, setCurrentScreen] = useState({
    screen: 'view',
    id: '',
    lockStatus: {},
  });
  const [chartProjects, setChartProjets] = useState([]);
  const [rerenderSwitch, setRerenderSwitch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userRole = useSelector(UserSelector.getUserRoles);
  const userDetails = useSelector(UserSelector.getUserDetail);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const projectList = await adminProjectAPI.getChartsProjects({
        pageno: pageState.pageNo,
        rowsPerPage,
      });
      setPageState((currState) => {
        return {
          ...currState,
          totalRecords: projectList.recordCount,
          totalPages: Math.ceil(pageState.totalRecords / rowsPerPage),
        };
      });
      setChartProjets(projectList?.records);
      setIsLoading(false);
    })();
  }, [pageState.pageNo, rerenderSwitch]);

  switch (currentScreen?.screen) {
    case 'view':
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            padding: '12px',
            height: 'auto',
          }}
        >
          <ProjectListBar
            list={chartProjects}
            selectedItem={selectedProject}
            onChange={setSelectedProject}
            role={userRole}
            onPageChange={setPageState}
            pageState={pageState}
            loading={isLoading}
            empty={!Boolean(chartProjects?.length)}
            setRerenderSwitch={setRerenderSwitch}
          />
          {!isLoading ? (
            <ProjectView
              selectedProject={selectedProject}
              setCurrentScreen={setCurrentScreen}
              role={userRole}
              projectData={chartProjects}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '5vh auto',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      );
    case 'assign':
      return (
        (userRole === 'SuperAdmin' || userRole === 'ClientAdmin') && (
          <AssignUser
            setCurrentScreen={setCurrentScreen}
            selectedProject={selectedProject}
            list={chartProjects}
          />
        )
      );
    case 'chart':
      return (
        <PdfView
          setCurrentScreen={setCurrentScreen}
          chartId={currentScreen?.id}
          projectId={chartProjects[selectedProject]?.projectID}
          hccVersion={chartProjects[selectedProject]?.hccVersion}
          yearOfService={chartProjects[selectedProject]?.yearOfService}
          readonly={
            userRole?.trim() === 'ClientUser'
              ? chartProjects[selectedProject]?.projectUsers?.some(
                  (user) =>
                    user?.userid === userDetails?.userid &&
                    user?.role === 'supervisor'
                )
                ? false
                : userDetails?.userid ===
                  currentScreen?.lockStatus?.lockedTo?.id
                ? false
                : true
              : false
          }
          operator={currentScreen?.lockStatus?.lockedTo?.name}
          projectTitle={chartProjects?.[selectedProject]?.projectName
            ?.toUpperCase()
            ?.trim()}
          submissionStatus={
            currentScreen?.submitted
              ? currentScreen?.reviewed
                ? 'supervisor'
                : 'reviewer'
              : 'coder'
          }
        />
      );
  }
};

export default Projects;
