import { roles } from 'config/constants';
import useStyles from './style';

const { Box, Typography, Select, MenuItem, Button } = require('@mui/material');

const UserItem = ({ userName, email, role, chartsCount, id, onRoleChange }) => {
  const classes = useStyles();

  const DotsIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="35"
      viewBox="0 0 31 35"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4802 17.5003C10.4802 15.8962 9.37118 14.5837 8.01575 14.5837C6.66032 14.5837 5.55133 15.8962 5.55133 17.5003C5.55133 19.1045 6.66032 20.417 8.01575 20.417C9.37118 20.417 10.4802 19.1045 10.4802 17.5003ZM12.9446 17.5003C12.9446 19.1045 14.0536 20.417 15.409 20.417C16.7644 20.417 17.8734 19.1045 17.8734 17.5003C17.8734 15.8962 16.7644 14.5837 15.409 14.5837C14.0536 14.5837 12.9446 15.8962 12.9446 17.5003ZM20.3378 17.5003C20.3378 19.1045 21.4468 20.417 22.8023 20.417C24.1577 20.417 25.2667 19.1045 25.2667 17.5003C25.2667 15.8962 24.1577 14.5837 22.8023 14.5837C21.4468 14.5837 20.3378 15.8962 20.3378 17.5003Z"
        fill="#020202"
      />
    </svg>
  );

  const handleChange = (e) => {
    onRoleChange((currUsers) =>
      currUsers.map((user) =>
        user._id === id ? { ...user, roles: e.target.value } : user
      )
    );
  };

  return (
    <Box className={classes.container}>
      <Typography
        className={`${classes.label} ${classes.userName}`}
        variant="h6"
      >
        {userName.charAt(0).toUpperCase() + userName.slice(1)}
      </Typography>
      <Typography className={`${classes.label} ${classes.email}`} variant="h6">
        {email}
      </Typography>
      <Box className={classes.roleDropDownContainer}>
        <Select
          value={role}
          onChange={handleChange}
          className={classes.roleDropDown}
          sx={
            role !== 'unassigned' && {
              backgroundColor: '#02375F !important',
              color: '#fff',
            }
          }
        >
          {roles.map((role, index) => (
            <MenuItem key={role.value + index} value={role.value}>
              {role.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Typography
        className={`${classes.label} ${classes.chartCount}`}
        variant="h6"
      >
        {`${chartsCount} charts assigned`}
      </Typography>
      <Button type="button" className={classes.dotsIcon}>
        <DotsIcon />
      </Button>
    </Box>
  );
};

export default UserItem;
