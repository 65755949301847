import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useStyles from './style';

const BasicTable = ({
  headFields,
  rows,
  tableStyles = {},
  width = 200,
  height = 200,
  headCellFontSize = 10,
  TableCellFontSize = 9,
}) => {
  const classes = useStyles();

  return (
    <TableContainer
      style={{ height: { height }, width: { width } }}
      component={Paper}
    >
      <Table
        className={classes.table}
        sx={tableStyles}
        aria-label="Basic table"
      >
        <TableHead>
          <TableRow>
            {headFields.map((item, index) => (
              <TableCell key={index} style={{ fontSize: { headCellFontSize } }}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {Object.values(row).map((fieldValue, colIndex) => (
                <TableCell
                  key={`Col-${index}-${colIndex}`}
                  style={{ fontSize: { TableCellFontSize } }}
                  component="th"
                >
                  {fieldValue}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;

/**
 * Example:
 *  <BasicTable
            headFields={['Field1', 'Field2']}
            rows={[{'Field1':'A1','Field2':'A2},{'Field1':'B1','Field2':'B2}]}
          />
 */
