import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { en } from 'config/metadata/en';
import useControlEvent from 'utils/controlevent';
import DiaLogBox from 'components/dialogbox';
import { TextBox, Button, Box } from 'components/controls';
import CopyRights from 'components/copyrights';
import { userAuthenticate } from './service';
import { UserAction, UserSelector } from 'state/user';
import { AppAction } from 'state/app';
import { VERSION } from 'version';
import useStyles from './style';
import validator from './validator';
import { IconButton, InputAdornment } from '@mui/material';
import { setMaskedEmail } from 'shared/context/mfa/localstoragedata';

const Login = () => {
  const token = useSelector(UserSelector.getUserToken);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    controlVal,
    controlErrorVal,
    setControlErrorVal,
    onFieldValueChange,
  } = useControlEvent({ username: '', password: '' });

  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errorVal = validator(controlVal);
    setControlErrorVal(errorVal);
    if (Object.keys(errorVal).length) {
      setLoading(false);
      return;
    }

    try {
      const { setchangepassword, setmfa, setpasswordexpired, data, user } =
        await userAuthenticate({
          username: controlVal.username,
          password: controlVal.password,
        });
      // const setchangepassword = false;
      if (setpasswordexpired) {
        history.push('/updatepassw');
      } else if (setchangepassword) {
        history.push(
          `/confirmpassw?qusername=${controlVal.username}&qtype=FORCE_PASSWORD_CHANGE`
        );
      } else if (setmfa === true) {
        setMaskedEmail(data.email);
        if (data.enableMfaAuth === false) {
          history.replace('/mfa-setup');
        } else {
          history.replace('/mfa-login');
        }
      } else {
        dispatch(
          AppAction.AppAll({
            version: VERSION.version,
          })
        );
        dispatch(UserAction.UserAll(user));
        history.push('/');
      }
    } catch (e) {
      dispatch(UserAction.UserAll({}));
      setIsLoginFailed(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      history.push('/');
    }
  }, [token]);

  return (
    <Box className={classes.container} component="main">
      <Box className={classes.bgShape}>
        {/* <img src="/assets/shapes/shape1.png" alt="" />
        <img src="/assets/shapes/shape2.png" alt="" /> */}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.paper}>
          <Box>{/* <img src="/assets/images/login-image.svg" /> */}</Box>
          <span className={classes.hr} />
          <Box className={classes.form}>
            {/* <Avatar className={classes.avatar}>{IconList.User}</Avatar> */}
            <img src="/logo2.png" alt="logo" className={classes.logo} />
            {/* <Typography component="h1" variant="h5">
              {en.login.signin}
            </Typography> */}
            <form autoComplete="off" noValidate onSubmit={signIn}>
              <TextBox
                name="username"
                label={en.login.username}
                value={controlVal.username}
                onChange={onFieldValueChange}
                error={controlErrorVal.username}
                autoFocus
                required
                fullWidth
              />

              <TextBox
                name="password"
                label={en.login.password}
                value={controlVal.password}
                onChange={onFieldValueChange}
                error={controlErrorVal.password}
                autoFocus
                required
                fullWidth
                type={`${showPassword ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => !state);
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                label={en.login.signin}
                // startIcon={<LockOpenIcon />}
                className={classes.submit}
                disabled={loading}
              />
              <Link
                href="/forgotpassw"
                variant="body2"
                className={classes.link}
              >
                Forgot password?
              </Link>
            </form>
            <Box>
              <CopyRights />
            </Box>
          </Box>
        </Box>
      </Box>
      <DiaLogBox
        openDialog={isLoginFailed}
        setOpenDialog={setIsLoginFailed}
        title={en.login.failure.dialogTitle}
        message={en.login.failure.dialogMessage}
      />
    </Box>
  );
};
Login.propTypes = {};
export default Login;
