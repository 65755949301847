import * as ActionType from './actiontypes';

// action creators
export const UserAll = (user) => {
  return { type: ActionType.USER_ALL, value: user };
};

export const UserToken = (token) => {
  return { type: ActionType.USER_Token, value: token };
};

export const UserRefreshToken = (token) => {
  return { type: ActionType.USER_RefreshToken, value: token };
};

export const UserCurrentClient = (currentClient) => {
  return { type: ActionType.USER_CurentClient, value: currentClient };
};

export const UserCurrentBusType = (currentBusType) => {
  return { type: ActionType.USER_CurentBusType, value: currentBusType };
};

export const UserCurrentBusSubType = (currentBusSubType) => {
  return { type: ActionType.USER_CurentBusSubType, value: currentBusSubType };
};

export const UserLogout = () => {
  return { type: ActionType.USER_Logout, value: null };
};

export const changeYearOfService = (year) => {
  return {
    type: ActionType.USER_YearOfService,
    value: year,
  };
};

export const changeHealthPlanId = (planId) => {
  return {
    type: ActionType.USER_HealthPlanId,
    value: planId,
  };
};
