import { useTheme } from '@mui/styles';
import useStyles from './style';
import { Box, CircularProgress } from '@mui/material';
import { useState } from 'react';

const Chat = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  return (
    <Box className={classes.container} position="relative">
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="rgba(255, 255, 255, 0.8)"
          zIndex={1}
        >
          <CircularProgress />
        </Box>
      )}
      <iframe
        src="https://azure-fastapi-cli.azurewebsites.net/index.html"
        width="100%"
        height="100%"
        title="Example Website"
        style={{ border: 'none', display: loading ? 'none' : 'block' }}
        onLoad={() => setLoading(false)}
        id="inlineFrameExample"
      />
    </Box>
  );
};
export default Chat;
