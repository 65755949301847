import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    '& .MuiDialog-container': {
      width: '1000px',
      position: 'relative',
      margin: '0 auto',
      '& .MuiPaper-root.MuiDialog-paper': {
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        padding: theme.spacing(2),
        scrollbarWidth: 'none',
        margin: '10px 14px',
        backgroundColor: '#f9fafb',
        borderRadius: '8px',
        maxWidth: '100%',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    },
    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      marginTop: '4px',
    },
  },
  dialogContent: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '900px',
    // height: '550px',
  },
  tableContainer: {
    borderRadius: '8px',
    border: '2px dashed black',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    height: '550px',
  },
  table: {
    minWidth: '600px',
  },
  tableHeader: {
    height: '50px',
    backgroundColor: theme.overrides.MuiTableCell.head.backgroundColor,
    '& th': {
      color: '#000',
      fontWeight: 'bold',
      fontSize: '14px',
    },
  },
  tableCell: {
    padding: '5px 15px',
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '50px',
  },
  filterText: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.text.secondary,
  },
  dialogActions: {
    padding: '15px 30px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  actionbtn: {
    backgroundColor: theme.palette.primary.main,
    padding: '8px 20px',
    minWidth: '140px',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tooltip: {
    fontSize: '12px',
    backgroundColor: theme.overrides.MuiTooltip.tooltip.backgroundColor,
    color: theme.overrides.MuiTooltip.tooltip.color,
  },
}));

export default useStyles;
