import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  reportsCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '50px',
    padding: '10px',
    marginTop: '2%',
  },
  buttonContainer: {
    boxShadow: '0px 0px 15px 0px #e8e8e8',
    marginRight: '10px',
    border: 'none',
    background: 'none',
    textTransform: 'none',
    color: 'inherit',
    borderRadius: '15px !important',
    '&.Mui-selected': {
      backgroundColor: '#DCDCDC',
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
    },
    '&:not(.Mui-selected):hover': {
      backgroundColor: '#F0F0F0',
      boxShadow: '0px 0px 10px 0px#F0F0F0',
      color: 'black',
    },
  },
}));
export default useStyles;
