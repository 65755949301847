const { useState, useEffect, useCallback } = require('react');

const { Box, Typography } = require('@mui/material');
import DataRow from 'components/controls/dataRow';
import CreateProject from './createProject';

import { getChartsProjects } from 'api/admin/project';

import useStyles from './style';

const PAGE_SIZE = 20;

const Projects = ({ onProjectOpen = () => {} }) => {
  const [projects, setProjects] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [tableHeight, setTableHeight] = useState(
    window.innerHeight >= 1080 ? 600 : 590
  );

  // variable screen height
  useEffect(() => {
    const updateHeight = () => {
      setTableHeight(window.innerHeight >= 1080 ? 800 : 590);
    };

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const classes = useStyles();

  // Fetch data from the API
  const fetchData = useCallback(async (pageNumber) => {
    if (loading) return;

    setLoading(true);
    try {
      const response = await getChartsProjects({
        pageno: pageNumber,
        rowsPerPage: PAGE_SIZE,
      });
      setProjects((prevData) => [
        ...prevData,
        ...response?.records?.map((record) => ({
          id: record?._id,
          fields: [
            { field: 'Project Name', value: record?.projectName, space: 40 },
            {
              field: 'Line of Business',
              value: record?.lineOfBusiness,
              space: 20,
            },
            {
              field: 'Year of Service',
              value: record?.yearOfService,
              space: 4,
            },
            {
              field: 'Total Charts',
              value: record?.projectCharts?.length || 0,
              space: 4,
            },
            {
              field: 'Date Created',
              value: new Date(
                record?.createdBy?.createdDate?.$date
              ).toLocaleDateString('en-US'),
              space: 10,
            },
            {
              field: 'Project Date',
              value: `${new Date(record?.projectStartDate).toLocaleDateString(
                'en-US'
              )} - ${new Date(record?.projectEndDate).toLocaleDateString(
                'en-US'
              )}`,
              space: 21,
            },
            { field: 'Priority', value: record?.priority, space: 1 },
          ],
          expansionContent: (
            <Box className={classes.description}>
              <Typography variant="h6">Description:</Typography>
              <Typography paragraph>{record?.projectDescription}</Typography>
            </Box>
          ),
          disableEdit: !isNaN(record?.projectCharts?.length),
          record,
        })),
      ]);
      setTotalRows(response?.recordCount); // Total rows in the database
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Load data on initial render and when the page changes
  useEffect(() => {
    fetchData(page);
  }, [page, fetchData]);

  const handleTopInView = () => {
    console.log('TOP IN VIEW::');
  };
  const handleBottomInView = () => {
    console.log('BOTTOM IN VIEW::');
  };
  const handleFolderIconClick = (event, record) => {
    onProjectOpen(event, record);
  };

  return (
    <Box className={classes.container}>
      <DataRow
        width="100%"
        totalRows={totalRows}
        loading={loading}
        placeholderCount={3}
        // tableHeight={340}
        tableHeight={tableHeight}
        emptyDataMessage="No projects available yet"
        data={projects}
        onLoadMore={() => setPage((prevPage) => prevPage + 1)}
        onCreateClick={() => {
          setProjectId(null);
          setOpenAddDialog(true);
        }}
        onOpen={handleFolderIconClick}
        onTopInView={handleTopInView}
        onBottomInView={handleBottomInView}
        onEditClick={(e, rec) => {
          setProjectId(rec?._id);
          setOpenAddDialog(true);
        }}
        allowRowExpansion
        defaultExpanded
        virtualized
        allowEdit
      />
      <CreateProject
        open={openAddDialog}
        onClose={setOpenAddDialog}
        reloadState={setProjects}
        projectid={projectId}
      />
    </Box>
  );
};

export default Projects;
