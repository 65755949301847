import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  navGuideContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    borderRadius: '75px 0px 0px 75px',
    width: '100%',
    flex: '23%',
    maxHeight: '84.5vh',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  navGuideContainerSm: {
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    borderRadius: '75px 0px 0px 75px',
    width: '100%',
    flex: '18%',
    maxHeight: '84.5vh',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  logo: {
    width: '100px',
    height: 'auto',
    mixBlendMode: 'color-dodge',
    marginLeft: '48px',
    visibility: 'hidden',
  },
  top: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& button': {
      color: '#fff',
      gap: '2px',
      alignItems: 'center',
      '& svg': { width: '24px' },
    },
  },
  navTitle: { color: '#fff', fontWeight: '600' },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px',
  },
  searchBar: {
    marginBottom: '12px',
    borderRadius: '999px',
    paddingRight: '20px',
    '& fieldset': {
      border: '2px solid white',
    },
    '&:hover fieldset': {
      border: '2px solid white !important',
    },
    '&.Mui-focused fieldset': {
      border: '2px solid white !important',
    },
    '& .MuiInputBase-input': {
      padding: '8px 0 8px 16px',
    },
    '& button': {
      maxWidth: '40px',
      maxHeight: '40px',
    },
    '& input': {
      color: '#fff',
      '&::placeholder': {
        color: '#fff',
        opacity: 1,
      },
    },
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '& .Mui-expanded': {
      margin: '0',
    },
  },
  submittedCodesButton: {
    boxShadow: 'none',
    borderRadius: '10px',
    backgroundColor: 'rgba(193, 209, 214, 0.30)',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    '&:hover': {
      backgroundColor: 'rgba(193, 209, 214, 0.40)',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'rgba(193, 209, 214, 0.40)',
      boxShadow: 'none',
    },
  },
  skeletonAccordion: {
    borderRadius: '10px',
    backgroundColor: 'rgba(193, 209, 214, 0.3)',
    width: '100%',
    height: '37px',
    marginBottom: '2px',
    paddingLeft: '16px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3))',
    },
  },
}));

export default useStyles;
