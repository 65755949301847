import { Box } from 'components/controls';
import useStyles from 'views/mfa-setup/style';
import { Button } from '@mui/material';
import { useContext } from 'react';
import { setupMfaContext } from 'shared/context/mfa';

const FirstComponent = () => {
  const { setstage, maskedEmail } = useContext(setupMfaContext);
  const classes = useStyles();
  const callSecond = () => {
    setstage(2);
  };
  return (
    <Box className={classes.stageonediv}>
      <Button onClick={callSecond} className={classes.btn}>
        + Add
      </Button>
      <Box className={classes.emailonediv}>
        Mail : <Box className={classes.emailone}>{maskedEmail}</Box>{' '}
      </Box>
    </Box>
  );
};

export default FirstComponent;
