import { adminAPI } from 'config/api-routlink';
import request from 'shared/service/request';

/**
 * Request: POST
 * Details: Create user by admin
 * @param email
 * @param name
 * @param clients
 * @param roles
 * @param enabled
 */
export const create = async ({
  email,
  name,
  clients,
  roles,
  enabled,
  healthPlanId,
}) => {
  const rdata = await request.post(adminAPI.USER_CREATE, {
    email,
    name,
    clients,
    roles,
    enabled,
    healthPlanId,
  });

  return rdata.data?.data;
};

/**
 * Request: POST
 * Details: Update user details by admin
 * @param username
 * @param email
 * @param name
 * @param clients
 * @param roles
 * @param enabled
 */
export const updateByAdmin = async ({
  username,
  email,
  name,
  clients,
  roles,
  enabled,
  healthPlanId,
}) => {
  const rdata = await request.post(adminAPI.USER_UPDATE_BY_ADMIN, {
    username,
    email,
    name,
    clients,
    roles,
    enabled,
    healthPlanId,
  });

  return rdata.data?.data;
};

/**
 * Request: POST
 * Details: Update user details by admin
 * @param projectID
 * @param projectUser
 */
export const assignUserToProject = async ({ projectID, projectUser }) => {
  const rdata = await request.post(adminAPI.USER_ASSIGN_TO_PROJECT, {
    projectID,
    projectUser,
  });

  return rdata.data?.data;
};

/**
 * Request: GET
 * Details: Get users with filter
 * @param rowsPerPage
 * @param filter
 * @param paginationToken
 * @param sort
 * @param selectFields
 */
export const find = async ({
  pageno,
  rowsPerPage,
  filter,
  paginationToken,
  sort,
  selectFields,
}) => {
  const rdata = await request.get(adminAPI.USER_FIND, {
    params: {
      filter,
      pageno,
      rowsPerPage,
      paginationToken,
      sort,
      selectFields,
    },
  });

  return rdata.data?.data;
};

export const getUserDetailById = async (username) => {
  const rdata = await request.get(
    adminAPI.USER_DETAIL_BY_ID.replace(':username', username),
    {}
  );

  return rdata.data?.data;
};
