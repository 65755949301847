import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    display: 'flex',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #000',
  },
  checkbox: {},
  detailCard: {
    width: '100%',
    padding: '8px 16px',
    borderBottom: '1px solid #000',
    borderLeft: '1px solid #000',
  },
  detailCardUpper: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  detailCardLower: {},
  hcc: {
    fontWeight: 700,
    fontSize: '17px',
  },
  category: {
    fontSize: '16px',
  },
  icd10: {
    fontSize: '14px',
  },
}));

export default useStyles;
