import { useStyles } from './style';
import { Box } from '@mui/material';
import { CaretIcon } from 'icons/customIcons';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
const ReportsSection = ({ props, expand }) => {
  const classes = useStyles();
  const tableKeys = [
    'Total Opportunity',
    'Top 5 Suggested',
    'Top 5 Accepted',
    'Top 5 Rejected',
    'Top 5 Added',
    'Top 5 Deleted',
    'Top 5 Claim Confirmed',
  ];
  return (
    <Box
      className={expand ? classes.outerContainerExpand : classes.outerContainer}
    >
      <Box className={classes.innerContainer}>
        {Object.entries(props)
          .filter(
            ([key, value]) =>
              key === 'Chart Details' || key === 'Member Details'
          )
          .map(([key, value], index) => (
            <SubSection
              key={key}
              heading={key}
              detailsList={value}
              index={index}
            />
          ))}
        <TableSection tableKeys={tableKeys} data={props} />
      </Box>
    </Box>
  );
};

export default ReportsSection;

const SubSection = ({ heading, detailsList, index }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      className={index === 0 ? classes.subContainerFirst : classes.subContainer}
    >
      <Box className={classes.headingContainer}>
        <CaretIcon
          style={{
            filter: 'invert(1)',
            maxWidth: '18px',
            maxHeight: '18px',
          }}
        />
        {heading}
      </Box>
      <Box className={classes.detailsContainer}>
        {Object.entries(detailsList).map(([key, value]) =>
          Array.isArray(value) ? (
            <Box className={classes.details} key={key}>
              {key}:
              {value.length !== 0
                ? value.map((item, index) => (
                    <span key={index}>
                      {typeof item === 'object' && item !== null ? (
                        <Tooltip
                          placement="top"
                          title={item?.description}
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: theme.palette.primary.main,
                                color: 'white',
                              },
                            },
                            arrow: {
                              sx: {
                                color: theme.palette.primary.main,
                              },
                            },
                          }}
                        >
                          <Link href="#" className={classes.linkItem}>
                            {item?.code}
                          </Link>
                        </Tooltip>
                      ) : (
                        item
                      )}
                      {index < value.length - 1 && ', '}
                    </span>
                  ))
                : 'N/A'}
            </Box>
          ) : typeof value === 'number' ? (
            <Box className={classes.details} key={key}>
              {key}: {value.toLocaleString('en-US')}
            </Box>
          ) : (
            <Box className={classes.details} key={key}>
              {key}: {value}
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

const TableSection = ({ tableKeys, data }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{}</TableCell>
            <TableCell>Dx Details</TableCell>
            <TableCell>HCC Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableKeys.map((item, outerIndex) => (
            <TableRow key={outerIndex}>
              <TableCell>{item}</TableCell>
              <TableCell>{FormatValue(data?.['Dx Details']?.[item])}</TableCell>
              <TableCell>
                {FormatValue(data?.['HCC Details']?.[item])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FormatValue = (value) => {
  const classes = useStyles();
  const theme = useTheme();
  return Array.isArray(value)
    ? value.length !== 0
      ? value.map((item, index) => (
          <span key={index}>
            {typeof item === 'object' && item !== null ? (
              <Tooltip
                placement="top"
                title={item?.description}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: theme.palette.primary.main,
                      color: 'white',
                    },
                  },
                  arrow: {
                    sx: {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
              >
                <Link href="#" className={classes.linkItem}>
                  {item?.code}
                </Link>
              </Tooltip>
            ) : (
              item
            )}
            {index < value.length - 1 && ', '}
          </span>
        ))
      : 'N/A'
    : typeof value === 'number'
    ? value.toLocaleString('en-US')
    : value;
};
