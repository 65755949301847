import { login } from 'api/auth';

export const userAuthenticate = async ({ username, password }) => {
  const options = {
    username,
    password,
  };

  const { setchangepassword, setmfa, setpasswordexpired, data } = await login(
    options
  );

  if (setmfa === true) {
    return { setmfa, data };
  }
  if (setchangepassword) {
    return { setchangepassword, data: null };
  }

  if (setpasswordexpired) {
    return { setpasswordexpired, data: null };
  }

  const user = {
    detail: {
      userid: data.user.userid,
      name: data.user.name,
      email: data.user.email,
    },
    username: data.user.name,
    token: data.id_token,
    refreshToken: data.refresh_token,
    roles: data.user.roles,
    healthPlanId: data.user.healthPlanId,
  };

  if (Object.keys(data.user.clients).length > 0) {
    user.clients = data.user.clients;
    const currentClient = user.clients[Object.keys(user.clients)[0]];
    user.currentClient = {
      clientName: currentClient.clientName,
      clientID: currentClient.clientID,
      clientKey: currentClient.clientKey,
      busTypes: currentClient.busTypes,
    };
    if (Object.keys(currentClient.busTypes).length > 0) {
      const _busType = Object.keys(currentClient.busTypes)[0];
      user.currentBusType = {
        clientID: currentClient.clientID,
        busType: _busType,
        busSubTypes: currentClient.busTypes[_busType],
      };
      if (user.currentBusType.busSubTypes.length) {
        user.currentBusSubType = {
          clientID: currentClient.clientID,
          busType: user.currentBusType.busType,
          busSubType: user.currentBusType.busSubTypes[0],
        };
      }
    }
  }
  return { setchangepassword, user };
};
