const {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
} = require('@mui/material');

const { default: DiagnoseCard } = require('../diagnoseCard');

import { useState } from 'react';
import useStyles from './style';
import { FilterIcon, PlusIcon } from 'icons/customIcons';

const DiagnoseList = ({
  coordinates,
  onDiagnoseClick,
  setSelectedDiagnose,
  diagnoseFilter,
  setDiagnoseFilter,
  readonly,
  setAlert,
}) => {
  const [openFilterMenu, setOpenFilterMenu] = useState(null);

  const open = Boolean(openFilterMenu);

  const classes = useStyles();

  const AcceptedIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="24 / Tick">
          <path
            id="Shape"
            d="M9.54961 17.9996L3.84961 12.2996L5.27461 10.8746L9.54961 15.1496L18.7246 5.97461L20.1496 7.39961L9.54961 17.9996Z"
            fill="#3D9F04"
          />
        </g>
      </svg>
    );
  };

  const RejectedIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="20 / Cross">
          <path
            id="Union"
            d="M7.2744 18L6 16.7256L10.7256 12L6 7.2744L7.2744 6L12 10.7256L16.7256 6L18 7.2744L13.2744 12L18 16.7256L16.7256 18L12 13.2744L7.2744 18Z"
            fill="#E70A0E"
          />
        </g>
      </svg>
    );
  };

  const handlePlusButtonClick = () => {
    if (readonly) setAlert(true);
    else setSelectedDiagnose('add');
  };

  const handleFilterButtonClick = (e) => {
    setOpenFilterMenu(e.currentTarget);
  };

  const handleDiagnoseFilter = (e) => {
    setDiagnoseFilter({
      accepted: false,
      rejected: false,
      inferred: false,
      all: false,
      [e.target.id]: true,
    });
    setOpenFilterMenu(null);
  };

  const handleClose = () => {
    setOpenFilterMenu(null);
  };

  return (
    <Box className={classes.inferredContainer}>
      <Typography variant="h6" className={classes.title}>
        <span>Chart Diagnosis</span>
        <span>
          <Box sx={{ position: 'relative' }}>
            <IconButton
              id="filterButton"
              aria-controls={open ? 'filterMenu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              type="button"
              className={classes.filterButton}
              onClick={handleFilterButtonClick}
            >
              <FilterIcon />
            </IconButton>
            <Menu
              id="filterMenu"
              aria-labelledby="filterButton"
              anchorEl={openFilterMenu}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              className={classes.filterMenu}
            >
              <MenuItem
                onClick={handleDiagnoseFilter}
                className={classes.filterMenuItem}
                disabled={diagnoseFilter?.accepted}
                id="accepted"
              >
                Accepted <AcceptedIcon />
              </MenuItem>
              <MenuItem
                onClick={handleDiagnoseFilter}
                className={classes.filterMenuItem}
                disabled={diagnoseFilter?.rejected}
                id="rejected"
              >
                Rejected <RejectedIcon />
              </MenuItem>
              <MenuItem
                onClick={handleDiagnoseFilter}
                className={classes.filterMenuItem}
                disabled={diagnoseFilter?.inferred}
                id="inferred"
              >
                Inferred
              </MenuItem>
              <MenuItem
                onClick={handleDiagnoseFilter}
                className={classes.filterMenuItem}
                disabled={diagnoseFilter?.all}
                id="all"
              >
                All
              </MenuItem>
            </Menu>
          </Box>
          <IconButton
            type="button"
            className={classes.addButton}
            onClick={handlePlusButtonClick}
          >
            <PlusIcon />
          </IconButton>
        </span>
      </Typography>
      <Box className={classes.inferredDiagnosis}>
        {coordinates?.length ? (
          coordinates.map((word, index) => (
            <DiagnoseCard
              key={word?.id + index}
              id={word?.id + 'diagnos'}
              tabIndex={index + 1}
              onClick={(e) => {
                onDiagnoseClick(e, word);
              }}
              code={word?.data?.dxCodeCaptured}
              description={word?.data?.dxDescription}
              status={word?.data?.Status ?? null}
              encounterDate={word?.data?.encounterDate ?? null}
              hcc={
                word?.data?.hccCaptured === null ||
                word?.data?.hccCaptured?.trim() === ''
                  ? 'N/A'
                  : word?.data?.hccCaptured
              }
            />
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default DiagnoseList;
