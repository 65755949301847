import { Card, CardContent } from '@mui/material';
import { Grid, Label } from 'components/controls';

import useStyles from './style';

const BasicInfo = ({ fieldList, className }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent sx={{ paddingLeft: 0, paddingTop: 0 }}>
        <Grid container direction="row" className={className}>
          {fieldList.map((item, index) => {
            return (
              <Grid sx={{ paddingLeft: 1 }} item spacing={0} xs={4} key={index}>
                <Label
                  label={`${item.Label}: `}
                  display="inline"
                  color="white"
                  variant="subtitle1"
                />
                <Label
                  label={item.Value}
                  display="inline"
                  color="#C0C0C0"
                  variant="caption"
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BasicInfo;
