import forge from 'node-forge';
function base64Decode(base64String) {
  const binaryString = Uint8Array.from(atobPolyfill(base64String), (char) =>
    char.charCodeAt(0)
  );
  return new TextDecoder().decode(binaryString);
}
function atobPolyfill(base64) {
  if (typeof window !== 'undefined' && window.atob) {
    return window.atob(base64);
  }
  return Buffer.from(base64, 'base64').toString('binary');
}
export const encryptPasswordWithPublicKey = (password) => {
  const publicKeyBase64 = process.env.REACT_APP_ENCRYPTION_PUBLIC_KEY;
  const decodedPublicKey = base64Decode(publicKeyBase64);
  const publicKey = forge.pki.publicKeyFromPem(decodedPublicKey);
  const encrypted = publicKey.encrypt(password, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
  });

  return forge.util.encode64(encrypted);
};
