import { useState } from 'react';

import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Menu, Typography } from '@mui/material';
import DiagnosisListDialog from './disgnosisListDialog';

import useStyles from './style';

const ContextMenu = ({
  open = false,
  anchorPosition,
  onClose,
  diagnosisList,
  selectionData,
  setSelectedDiagnose,
  rerender,
  chartId,
}) => {
  const [listOpen, setListOpen] = useState(false);
  const classes = useStyles();

  const handleLinkAttribute = () => {
    setListOpen(true);
  };

  const handleAddAttribute = () => {
    setSelectedDiagnose((prev) => {
      if (
        prev?.type === 'add' ||
        prev === 'add' ||
        prev?.type === 'addFromChart' ||
        prev === 'addFromChart'
      )
        return {
          type: 'addFromChart',
          data: {
            attributes: [
              ...(prev?.data?.attributes || []),
              {
                text: selectionData?.selectedText,
                boundingBox: selectionData?.boundingBox,
                id: `customAttribute_${Date.now()}`,
                isVerified: true,
              },
            ],
          },
        };
      else
        return {
          type: 'add',
          data: {
            attributes: [
              {
                text: selectionData?.selectedText,
                boundingBox: selectionData?.boundingBox,
                id: `customAttribute_${Date.now()}`,
                isVerified: true,
              },
            ],
          },
        };
    });
    onClose();
  };

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        anchorPosition
          ? { top: anchorPosition.mouseY, left: anchorPosition.mouseX }
          : null
      }
      className={classes.container}
    >
      <MenuList>
        <MenuItem
          className={classes.menuOptionText}
          onClick={handleAddAttribute}
          disabled={!Boolean(selectionData?.selectedText?.length)}
        >
          {/* <ListItemIcon></ListItemIcon> */}
          <ListItemText>Add as code</ListItemText>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          ></Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          className={classes.menuOptionText}
          onClick={handleLinkAttribute}
          disabled={!Boolean(selectionData?.selectedText?.length)}
        >
          {/* <ListItemIcon></ListItemIcon> */}
          <ListItemText>Link to an existing code</ListItemText>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          ></Typography>
        </MenuItem>
      </MenuList>
      <DiagnosisListDialog
        open={listOpen}
        setOpen={setListOpen}
        list={diagnosisList}
        selection={selectionData}
        rerender={rerender}
        chartId={chartId}
      />
    </Menu>
  );
};

export default ContextMenu;
