import { adminAPI } from 'config/api-routlink';
import request from 'shared/service/request';

// GET request - List clients
export const find = async ({ includeInActive }) => {
  const rdata = await request.get(adminAPI.CLIENT_FIND, {
    params: {
      includeInActive,
    },
  });

  return rdata.data?.data;
};
