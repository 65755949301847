import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    padding: '8px 12px',
  },
  headContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titlesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    '& h6': {
      fontSize: '1.1rem',
    },
  },
  detailsTogglerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    borderRadius: '999px',
    border: `2px solid ${theme.palette.primary.main}`,
    '& button': {
      backgroundColor: theme.palette.primary.main,
      transform: 'scale(1.1)',
      transition: 'all 150ms linear',
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
      '& svg': {
        maxWidth: '20px',
        maxHeight: '20px',
      },
      '&.expanded': {
        transform: 'rotate(180deg) scale(1.1)',
      },
    },
    '& h6': {
      fontSize: '1rem',
      fontWeight: 'normal',
      padding: '2px 8px 2px 24px',
      textTransform: 'capitalize',
    },
  },
  detailsContentWrapper: {
    maxHeight: 0,
    transition: 'all 500ms ease-out',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.grey,
    borderRadius: '8px',
    padding: 0,
    '&.expanded': {
      padding: '16px',
      maxHeight: '500px',
    },
  },
  detailsContent: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: '4px',
    display: 'flex',
    backgroundColor: theme.palette.primary.white,
  },
  sectionLeft: {
    flex: '35%',
    padding: '16px',
    borderRight: `2px solid ${theme.palette.secondary.grey}`,
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 'bolder',
      marginBottom: '6px',
    },
    '& p': {
      fontSize: '14px',
      margin: 0,
      marginLeft: '6px',
      opacity: 0.6,
    },
  },
  sectionCenter: {
    flex: '25%',
    padding: '16px',
    borderRight: `2px solid ${theme.palette.secondary.grey}`,
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 'bolder',
      marginBottom: '6px',
    },
    '& p': {
      fontSize: '14px',
      margin: 0,
      marginLeft: '6px',
      opacity: 0.6,
    },
  },
  sectionRight: {
    flex: '65%',
    padding: '16px',
    '& h6': {
      textAlign: 'center',
      fontWeight: 'bolder',
      fontSize: '1rem',
      marginBottom: '8px',
    },
  },

  memberDetails: { marginBottom: '10px' },
  retrospectiveReview: { marginBottom: '10px' },
  totalRiskScore: { marginBottom: '10px' },
  codesTable: {
    display: 'flex',
    border: `1.5px solid ${theme.palette.secondary.grey}`,
  },
  codesTableColumn: {
    flex: 1,
    textAlign: 'center',
  },
  codesTableHead: {
    border: `2px solid ${theme.palette.secondary.grey}`,
    fontSize: '14px !important',
    marginBottom: '0 !important',
    padding: '4px 0',
  },
  codesTableCellSubmittedCode: {
    border: `2px solid ${theme.palette.secondary.grey}`,
    margin: 0,
    padding: 0,
    '&:before': {
      content: 'none',
    },
  },
  codesTableCellContainer: {
    border: `2px solid ${theme.palette.secondary.grey}`,
    fontSize: '1rem',
    maxHeight: '200px',
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  codesTableSummary: {
    fontSize: '14px',
    margin: 0,
    padding: '5px 12px',
    marginBottom: '-8px',
    paddingBottom: '0px',
    textAlign: 'left',
  },
  codesTableCell: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '12px',
    marginTop: '0px',
    '&::before': {
      content: '"•"',
      marginLeft: '20px',
      fontWeight: '900',
      display: 'inline-block',
      lineHeight: '1',
    },
  },
  detailsText: {
    marginLeft: '15px',
    marginBottom: '2px',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontWeight: 'normal',
  },
  defaultHcc: {
    border: `2px solid ${theme.palette.secondary.grey}`,
  },
  acceptedHcc: {
    background: '#E7F4F3',
    color: '#088C86',
    border: `2px solid ${theme.palette.secondary.grey}`,
  },
  submittedHcc: {
    background: '#FDF1F2',
    color: '#E8727B',
    border: `2px solid ${theme.palette.secondary.grey}`,
  },
  reviewerHcc: {},
  accordionDetailsExpanded: {},
  detailsContentWrapper: {
    maxHeight: 0,
    transition: 'all 500ms ease-out',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.grey,
    borderRadius: '8px',
    padding: 0,
    '&.expanded': {
      padding: '16px',
      maxHeight: '500px',
    },
  },
}));

export default useStyles;
