import { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Tab, Tabs } from '@mui/material';

import TabPanel from './tabpanel';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '25%',
    height: '90%',
  },
  tab: {
    color: 'gray',
    '&.Mui-selected': {
      color: 'black',
      fontWeight: 'bold',
    },
  },
  tabpanel: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}));

const VerticalTabs = ({ tabList, className }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        indicatorColor="secondary"
        textColor="secondary"
        //style= {{height: "90%"}}
      >
        {tabList.map((item, index) => {
          return (
            <Tab
              key={index}
              className={classes.tab}
              label={item.label}
              {...a11yProps({ index })}
            />
          );
        })}
      </Tabs>

      {
        // Tab panel html mapping
        tabList.map((item, index) => {
          return (
            <TabPanel
              key={index}
              className={classes.tabpanel}
              value={value}
              index={index}
            >
              {item.content}
            </TabPanel>
          );
        })
      }
    </div>
  );
};

export default VerticalTabs;
/************
 * export const tabList = [
  {
    label: 'Tab-1',
    content: <div>Table 1 </div>,
  },
  {
    label: 'Tab-2',
    content: <div>Table 2 </div>,
  }
]
  <VerticalTabs tabList={tabList} />
 */
