import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

import { HeaderActions } from './actions';
import useStyles from './style';

const EnhancedTableHead = (props) => {
  const defaultClasses = useStyles();
  const {
    metadata,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      variant="head"
      key="TableHead"
      className={defaultClasses.tableHeader}
      sx={{
        '& .MuiAccordionSummary-content': {
          my: '10px',
          '&.Mui-expanded': { my: '10px' },
        },
        '&:hover': { '& .MuiCheckbox-root': { visibility: 'visible' } },
        flexDirection: 'row-reverse',
        backgroundColor: '#F6F6F6 !important',
        borderRadius: '10px !important',
        minHeight: 'auto !important',
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20) !important',
        fontSize: '16px !important',
        fontStyle: 'normal !important',
        fontWeight: '700 !important',
      }}
    >
      <TableRow key="HeadRow" className={classes.tableRow}>
        <HeaderActions
          metadata={metadata}
          anySelected={numSelected > 0 && numSelected < rowCount}
          allSelected={rowCount > 0 && numSelected === rowCount}
          onSelectAllClick={onSelectAllClick}
        />
        {metadata.fields
          .filter((x) => !x.collapse)
          .map((headCell, index) => (
            <TableCell
              variant="head"
              style={{
                backgroundColor: headCell.backgroundColor,
                fontWeight: 'bold',
              }}
              key={index}
              align={headCell.alignRight ? 'right' : 'left'}
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                //key={`SortLabel-${headCell.id}`}
                key={index}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span
                    className={classes.visuallyHidden}
                    key={`Span-${headCell.id}`}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  metadata: PropTypes.object,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
