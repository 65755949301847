import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  outerContainerExpand: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'strech',
    backgroundColor: theme.palette.primary.grey,
    padding: '8px 12px',
    opacity: 1,
    height: 'auto',
    maxHeight: '500px',
    transition: 'all 500ms ease-out',
    overflow: 'hidden',
  },
  outerContainer: {
    maxHeight: 0,
    padding: 0,
    overflow: 'hidden',
    transition: 'all 500ms ease-in',
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'strech',
    padding: '10px',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e")`,
    backgroundColor: theme.palette.primary.white,
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    fontSize: '14px',
    minHeight: '170px',
    paddingLeft: '30px',
    backgroundColor: theme.palette.primary.white,
    borderRight: `2px solid ${theme.palette.secondary.grey}`,
    paddingRight: '15px',
    maxHeight: '210px',
  },
  detailsContainer: {
    overflowY: 'scroll',
    maxHeight: '200px',
    scrollbarWidth: 'none',
  },
  subContainerFirst: {
    minHeight: '170px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    fontSize: '14px',
    backgroundColor: theme.palette.primary.white,
    borderRight: `2px solid ${theme.palette.secondary.grey}`,
    paddingRight: '15px',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 'bolder',
  },
  linkItem: {
    textDecoration: 'none',
  },
  details: {
    paddingBottom: '6.914px',
  },
  tableContainer: {
    maxHeight: '210px',
    border: `2px solid ${theme.palette.secondary.grey}`,
    scrollbarWidth: 'none',
    maxWidth: '60%',
    fontSize: '14px',
    border: `2px solid ${theme.palette.secondary.grey}`,
    borderCollapse: 'collapse',
    paddingBottom: '0px',
    '& th, & td, & tr': {
      border: `2px solid ${theme.palette.secondary.grey}`,
      padding: '4.914px 16.741px',
    },
  },
}));
