import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  bgShape: {
    height: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.up('md')]: {
      backgroundImage:
        'url(./assets/shapes/shape1.png), url(./assets/shapes/shape2.png)',
      backgroundPosition: 'left, right',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: 'contain',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '100%',
      paddingLeft: theme.spacing(16),
    },
  },
  paper: {
    position: 'relative',
    padding: '45px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '35px 0 0 35px',
    '-webkit-box-shadow': 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    '-moz-box-shadow': 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    boxShadow: 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(5px)',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    '& > div': { flexGrow: 1, flexBasis: 0 },
    '& > div:first-child': {
      padding: theme.spacing(2),
      '& img': { opacity: 0.8 },
    },
  },
  hr: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '5px',
    height: '100%',
    filter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2E0A70',
      borderRadius: '5px',
      borderWidth: '2px',
    },

    '& .MuiFormControl-root': {
      margin: 0,
      marginBottom: theme.spacing(4),
    },

    '& .MuiFormControl-fullWidth': {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '75%',
      },
    },

    '& .MuiFormLabel-root': { color: '#000' },

    '& .MuiFormLabel-asterisk': { color: 'transparent' },
  },
  logo: {
    maxWidth: '90px',
    height: 'auto',
    marginTop: '0px',
  },
  submit: {
    backgroundColor: '#8971B5',
    width: '75%',
    color: '#000',
    marginBottom: theme.spacing(4),
    fontSize: '20px',
    lineHeight: 'normal',
    fontFamily: 'Merriweather',
    fontWeight: 400,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: 'white',
      backgroundColor: '#8971B5',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
  },
  link: {
    color: 'rgba(0, 0, 0, 0.80)',
    fontFamily: 'Merriweather',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    textDecoration: 'none',
    marginBottom: theme.spacing(3),
  },
  mainLogo: {
    width: '104.694px',
    height: '104.694px',
    flexShrink: '0',
    color: '#175F88',
  },
  heading: {
    color: 'rgba(0;0;0;0.80)',
    fontSize: '20px',
    fontFamily: 'Inter',
    fontWeight: '500',
    wordWrap: 'break-word',
    marginTop: '10px',
  },
  btn: {
    backgroundColor: '#8971B5',
    color: 'black',
    fontSize: '24.80',
    fontFamily: 'Merriweather',
    fontWeight: '400',
    wordWrap: 'break-word',
    transition: 'all 0.3s ease-in-out',
    ':disabled': {
      backgroundColor: 'whitesmoke',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#8971B5',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
    '& img': {
      width: '20px',
      height: 'auto',
      transition: 'all 0.3s ease-in-out',
    },
    ':disabled': {
      backgroundColor: 'whitesmoke',
    },
    '&:disabled img': {
      opacity: '0.3',
    },
    '&:hover:not(:disabled) img': {
      filter: 'invert(100%)',
    },
  },
  btnimg: {
    ':disabled': {
      color: 'whitesmoke',
    },
  },
  btntwo: {
    backgroundColor: '#8971B5',
    color: 'black',
    fontSize: '24.80',
    fontFamily: 'Merriweather',
    fontWeight: '400',
    transition: 'all 0.3s ease-in-out',
    marginLeft: '40px',
    '& img': {
      transition: 'all 0.3s ease-in-out',
    },
    ':disabled': {
      backgroundColor: 'whitesmoke',
    },
    '&:disabled img': {
      opacity: '0.3',
    },
    '&:hover:not(:disabled) img': {
      filter: 'invert(100%)',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#8971B5',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
    minWidth: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  email: {
    // flexShrink: '0',
    color: '#000',
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginLeft: '30px',
    width: '300px',
    maxWidth: '300px',
    lineHeight: 'normal',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
  },
  emailone: {
    flexShrink: '0',
    color: '#000',
    fontFamily: 'Manrope',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    maxWidth: '350px',
    lineHeight: 'normal',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  emailonediv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: '0',
    color: '#000',
    fontFamily: 'Manrope',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    maxWidth: '450px',
    lineHeight: 'normal',
  },
  maindiv: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '350px',
    maxHeight: '400px',
    alignItems: 'center',
  },
  imagediv: {
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  stageonediv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingLeft: '100px',
    minHeight: '90px',
    width: '400px',
    gap: '10%',
  },
  stagetwodiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    paddingLeft: '50px',
  },
  stagethreediv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CopyRights: {
    position: 'absolute',
    bottom: '0',
  },
  resendbtn: {
    marginLeft: '68.5%',
    color: 'black',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      color: '#303030',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  textfield: {
    marginTop: '7%',
    width: '400px',
    height: '50px',
  },
  formdiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  textdiv: {
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '5px',
  },
  btndiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '100px',
    marginBottom: '30px',
  },
  btnback: {
    backgroundColor: '#8C9DA3',
    color: 'black',
    fontSize: '24.80',
    fontFamily: 'Merriweather',
    fontWeight: '400',
    wordWrap: 'break-word',
    transition: 'all 0.3s ease-in-out',

    '&:hover': {
      color: 'white',
      backgroundColor: '#8C9DA3',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
  },
  btntwoback: {
    backgroundColor: '#8C9DA3',
    color: 'black',
    fontSize: '24.80',
    fontFamily: 'Merriweather',
    fontWeight: '400',
    wordWrap: 'break-word',
    transition: 'all 0.3s ease-in-out',
    marginLeft: '-50px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#8C9DA3',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
  },
  formtwodiv: {
    margin: '0 0 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px',
    width: '400px',
    maxWidth: '400px',
  },
  emailthree: {
    display: 'inline',
    fontWeight: 'bold',
  },
}));
export default useStyles;
