import { useState } from 'react';
import { TableSortLabel } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  Popover,
  InputLabel,
  Checkbox,
  ListItemText,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import TablePagination from '@mui/material/TablePagination';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import { useTheme } from '@mui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import useStyles from './style';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
const DownloadingModal = ({ open }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '10px',
          backgroundColor: 'white',
          color: '#02375F',
          boxShadow: 24,
          p: 4,
        }}
      >
        <FileDownloadIcon sx={{ width: '100px', height: 'auto' }} />
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </Box>
    </Modal>
  );
};

const LoadingPlaceholder = ({ qty }) => {
  const theme = useTheme();
  const classes = useStyles();
  return [...Array(qty)]?.map((_, idx) => (
    <Skeleton
      animation="wave"
      key={idx}
      variant="rounded"
      height={45}
      className={classes.LoadingPlaceholderContainer}
      sx={{ width: '95vw', marginTop: '3px', display: 'block' }}
    />
  ));
};

const TableRows = ({ data, headersList }) => {
  const classes = useStyles();
  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow
          key={rowIndex}
          className={classes.RowContainer}
          sx={{
            padding: '5px !important',
            fontSize: '14px !important',
            background: '#FFF !important',
            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20) !important',
            borderRadius: '10px !important',
            marginTop: '5px !important',
            height: '45px',
          }}
        >
          {headersList
            .filter((column) => column.display)
            .map((column, colIndex) => {
              const field = row.fields.find((f) => f.field === column.label);
              return (
                <TableCell
                  key={colIndex}
                  sx={{
                    padding: '8px',
                    whiteSpace: 'nowrap',
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: column?.align === 'center' ? 'center' : 'left',
                  }}
                >
                  {field?.value}
                </TableCell>
              );
            })}
        </TableRow>
      ))}
    </>
  );
};

const OptionsTab = ({
  tableName,
  headersData,
  setHeadersData,
  filters,
  setFilters,
  exportFunction,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const classes = useStyles();

  const numFilters = ['=', '>', '>=', '<=', '<', '!=', 'empty', 'not empty'];
  const stringFilters = [
    'equals',
    'contains',
    'does not contain',
    'is empty',
    'is not empty',
  ];
  const dateFilters = ['=', '>', '>=', '<=', '<', '!=', 'empty', 'not empty'];

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleFilterClick = (event) => setAnchorElFilter(event.currentTarget);
  const handleFilterClose = () => setAnchorElFilter(null);

  const handleChangeColumns = (value) => {
    setHeadersData((prevHeaders) =>
      prevHeaders.map((header) =>
        header.value === value
          ? { ...header, display: !header.display }
          : header
      )
    );
  };

  const handleApplyFilter = () => {
    if (!selectedColumn || !selectedFilter) return;

    let formattedValue = filterValue;

    if (getSelectedColumnType() === 'date' && filterValue) {
      const date = new Date(filterValue);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      formattedValue = `${month}-${day}-${year}`;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedColumn]: {
        ...prevFilters[selectedColumn],
        [selectedFilter]: formattedValue,
      },
    }));

    setSelectedColumn('');
    setSelectedFilter('');
    setFilterValue('');

    handleFilterClose();

    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  const handleRemoveFilter = (column, filterType) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[column][filterType];

      if (Object.keys(newFilters[column]).length === 0) {
        delete newFilters[column];
      }

      return newFilters;
    });
  };

  const getFilterOptions = () => {
    const column = headersData.find((h) => h.value === selectedColumn);
    if (!column) return [];
    if (column.type === 'number') return numFilters;
    if (column.type === 'date') return dateFilters;
    return stringFilters;
  };

  const getSelectedColumnType = () => {
    const column = headersData.find((h) => h.value === selectedColumn);
    return column ? column.type : '';
  };

  return (
    <Box>
      <Box className={classes.newOptionsContainer}>
        <Typography className={classes.optionsNameContainer}>
          {tableName}
        </Typography>

        <Box className={classes.optionsButtonContainer}>
          <Tooltip title="Export Data">
            <Button onClick={exportFunction}>
              <FileDownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Apply Filters">
            <Button onClick={handleFilterClick}>
              <FilterListIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Edit Columns">
            <Button onClick={handleClick}>
              <MoreVertIcon />
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Box className={classes.filtersContainer}>
        {Object.entries(filters).map(([column, filterData]) =>
          Object.entries(filterData).map(([filterType, value]) => {
            const columnLabel =
              headersData.find((header) => header.value === column)?.label ||
              column;

            return (
              <Chip
                key={`${column}-${filterType}`}
                label={`${columnLabel} ${filterType.replace(
                  /_/g,
                  ' '
                )} ${value}`}
                onDelete={() => handleRemoveFilter(column, filterType)}
                color="primary"
                variant="outlined"
                className={classes.filterChip}
              />
            );
          })
        )}
      </Box>

      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={handleFilterClose}
      >
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            minWidth: 300,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleApplyFilter();
            }
          }}
        >
          <FormControl
            fullWidth
            className={classes.selectDropdown}
            variant="outlined"
          >
            <InputLabel>Column</InputLabel>
            <Select
              value={selectedColumn}
              onChange={(e) => setSelectedColumn(e.target.value)}
            >
              {headersData.map((header) => (
                <MenuItem key={header.value} value={header.value}>
                  {header.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            className={classes.selectDropdown}
            variant="outlined"
          >
            <InputLabel>Filter Type</InputLabel>
            <Select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              {getFilterOptions().map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedFilter &&
            !['empty', 'not empty', 'is empty', 'is not empty'].includes(
              selectedFilter
            ) && (
              <FormControl
                fullWidth
                className={classes.selectDropdown}
                variant="outlined"
              >
                <InputLabel shrink={false} style={{ display: 'none' }}>
                  Value
                </InputLabel>

                {getSelectedColumnType() === 'date' ? (
                  <input
                    type="date"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                    }}
                  />
                )}
              </FormControl>
            )}

          <Button variant="contained" onClick={handleApplyFilter}>
            Apply Filter
          </Button>
        </Box>
      </Popover>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Box className={classes.customScrollbar}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}></Box>
          {headersData.map((header) => (
            <MenuItem
              key={header.value}
              onClick={() => handleChangeColumns(header.value)}
              sx={{
                margin: '0px',
                fontWeight: 'bold',
                fontSize: '12px',
                boxShadow: '0 0 0 0.1px black',
                padding: '0px 5px',
              }}
            >
              <ListItemText primary={header.label} />
              <Checkbox checked={header.display} />
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

const CustomTable = ({
  totalRows = 0,
  data = [],
  loading = false,
  headersList = [],
  setHeadersList = () => {},
  tableName = '',
  exportFunction = () => {},
  page = 0,
  setPage = () => {},
  rowsPerPage = 10,
  setRowsPerPage = () => {},
  sort = {},
  setSort = () => {},
  filters = {},
  setFilters = () => {},
  open = false,
  setOpen = () => {},
}) => {
  const theme = useTheme();
  const role = useSelector(UserSelector?.getUserRoles);
  const classes = useStyles();
  const handleSort = (column) => {
    setSort((prevSort) => {
      const newSortOrder = prevSort[column] === 'asc' ? 'desc' : 'asc';
      return { ...prevSort, [column]: newSortOrder };
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box className={classes.optionsMainContainer}>
        <OptionsTab
          filters={filters}
          setFilters={setFilters}
          tableName={tableName}
          headersData={headersList}
          setHeadersData={setHeadersList}
          exportFunction={exportFunction}
        />
      </Box>

      <TableContainer
        sx={{
          width: '95vw',
          maxWidth: '100%',
          overflowX: 'auto',
          display: 'block',
          '&::-webkit-scrollbar': {
            height: '5px',
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: theme.palette.secondary.main,
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.primary.hover,
          },
        }}
      >
        <Table
          sx={{
            minWidth: '100%',
            tableLayout: 'auto',
            whiteSpace: 'nowrap',
            borderSpacing: '0 10px',
            borderCollapse: 'separate',
          }}
        >
          <TableHead className={classes.headContainer}>
            <TableRow
              sx={{
                backgroundColor: '#F6F6F6 !important',
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
                borderRadius: '20px',
              }}
            >
              {headersList
                .filter((item) => item.display)
                .map((item, index) => (
                  <TableCell
                    key={item.value}
                    sx={{
                      minWidth: index === 0 ? '200px' : '150px',
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      textAlign: item?.align === 'center' ? 'center' : 'left',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <TableSortLabel
                      active={!!sort[item.value]}
                      direction={sort[item.value] || 'asc'}
                      onClick={() => handleSort(item.value)}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent:
                          item?.align === 'center' ? 'center' : 'flex-start',
                        width: '100%',
                        marginLeft: item?.align === 'left' ? '0px' : '10px',
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headersList?.length}>
                  <LoadingPlaceholder qty={rowsPerPage} />
                </TableCell>
              </TableRow>
            ) : (
              <TableRows data={data} headersList={headersList} />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{ display: 'flex', justifyContent: 'center', width: '95vw', mt: 2 }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRows ?? data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#F6F6F6 !important',
            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20) !important',
            borderRadius: '10px !important',
          }}
        />
      </Box>
      <DownloadingModal open={open} />
    </Box>
  );
};
export default CustomTable;
