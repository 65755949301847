import { createContext, useState } from 'react';

export const claimContext = createContext();
claimContext.displayName = 'Claim';

export const viewClaimContext = createContext();
viewClaimContext.displayName = 'ViewClaim';

const ClaimProvider = (props) => {
  // Filter
  const [filters, setFilters] = useState([]);
  const [filterMetadata, setFilterMetadata] = useState([]);

  // Table
  const [tableMetadata, setTableMetadata] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [options, setOptions] = useState({
    pageno: 1,
    rowsPerPage: 5,
    filter: [],
    sort: {},
  });
  const [records, setRecords] = useState([]);

  // Modal - Claim View
  const initalSelectedClaim = {
    type: 'view',
    claimUID: 'claimUID',
    status: 'Status',
    claimID: 'ClaimID',
    basicInfo: [],
  };
  const [openModal, setOpenModal] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(initalSelectedClaim);
  const [currentTab, setCurrentTab] = useState({});

  return (
    <claimContext.Provider
      value={{
        filters,
        setFilters,
        filterMetadata,
        setFilterMetadata,
        tableMetadata,
        setTableMetadata,
        recordCount,
        setRecordCount,
        options,
        setOptions,
        records,
        setRecords,
      }}
    >
      <viewClaimContext.Provider
        value={{
          openModal,
          setOpenModal,
          selectedClaim,
          setSelectedClaim,
          currentTab,
          setCurrentTab,
        }}
      >
        {props.children}
      </viewClaimContext.Provider>
    </claimContext.Provider>
  );
};

export default ClaimProvider;
