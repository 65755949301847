import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    flex: '27%',
    backgroundColor: theme.palette.primary.grey,
    // padding: '4px 6px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    marginTop: '5px',
    backgroundColor: theme.palette.secondary.grey,
    fontSize: '18px',
    padding: '2px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  },
  diagnoseList: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '4px',
    borderRadius: '0 0 10px 10px',
    minHeight: '30px',
    maxHeight: '30vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.secondary.grey,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.hover,
    },
  },
}));

export default useStyles;
