import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '30px',
    paddingBottom: 0,
  },
  menu: {
    marginBottom: '30px',
  },
  queueMenu: {
    marginBottom: '30px',
    maxWidth: '40px',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
