import { useEffect, useState, useContext } from 'react';

import { AdvanceTable } from 'components/table';

import { tableFieldsMetaData } from './metadata';
// import { getClaimById } from './service';
import { viewMemberContext } from 'shared/context/member';
import { getClaims } from './service';

const Claims = ({}) => {
  const { selectedMember, selectedYear } = useContext(viewMemberContext);

  const [tableMetadata, setTableMetadata] = useState(tableFieldsMetaData);
  const [recordCount, setRecordCount] = useState(25);
  const [options, setOptions] = useState({
    pageno: 1,
    rowsPerPage: 5,
    filter: {},
    sort: {},
  });
  const [records, setRecords] = useState([]);

  // Enable if onTableClick function is implemented
  // const [selectedClaim, setSelectedClaim] = useState({});

  useEffect(() => {
    const asyncEffect = async () => {
      await getClaims({
        selectedMember,
        options,
        setRecords,
        recordCount,
        setRecordCount,
      });
    };
    asyncEffect();
  }, [selectedMember.Subscriber_ID, selectedYear]);

  // Params: type, id
  const onTableClick = async () => {
    // ToDO: Implement modal open while clicking the chart
    /*
    const claim = await getClaimById(id);
    const newSelectedClaim = {
      type: type,
      claimUID: id,
      status: claim.Overall.status,
      claimID: claim.Overall.claimID,
      basicInfo: claim.basicInfo,
      ...claim,
    };
    setSelectedClaim(newSelectedClaim);
    */
  };

  useEffect(() => {
    // if any dropdown dynamic, include useEffect options and api call
    setTableMetadata(tableFieldsMetaData);
  }, []);

  return (
    <div>
      <AdvanceTable
        metadata={tableMetadata}
        data={records}
        recordCount={recordCount}
        options={options}
        setOptions={setOptions}
        onClick={onTableClick}
      />
    </div>
  );
};

export default Claims;
