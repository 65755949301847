const { TextField, Box, Button } = require('@mui/material');
const { default: useStyles } = require('./style');

const FilterIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        d="M6.60001 12.0002H17.4M4.20001 7.2002H19.8M10.2 16.8002H13.8"
        stroke="#404040"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SearchIcon = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        d="M16.9269 17.0396L20.4 20.3996M19.28 11.4396C19.28 15.7695 15.7699 19.2796 11.44 19.2796C7.11009 19.2796 3.60001 15.7695 3.60001 11.4396C3.60001 7.1097 7.11009 3.59961 11.44 3.59961C15.7699 3.59961 19.28 7.1097 19.28 11.4396Z"
        stroke="black"
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const SearchBar = () => {
  const classes = useStyles();
  return (
    <Box className={classes.searchBarContainer}>
      <TextField
        variant="standard"
        type="text"
        placeholder="Search"
        InputProps={{
          disableUnderline: true,
        }}
        className={classes.searchBarTextField}
      />
      <Box className={classes.searchBarIconContainer}>
        <Button type="button" className={classes.searchBarIconButton}>
          <FilterIcon />
        </Button>
        <Button type="button" className={classes.searchBarIconButton}>
          <SearchIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default SearchBar;
