import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {},
  pill: {
    display: 'inline-block',
    border: `1px solid ${theme.palette.primary.black}`,
    marginRight: '20px',
    padding: '2px 16px',
    borderRadius: '8px',
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '14px',
  },
  tableContainer: {
    borderRadius: '8px',
    overflow: 'hidden',
    outline: '1px solid #00000033',
    marginTop: '10px',
  },
  filterTable: {
    padding: 0,
    '& .MuiTableCell-root': {
      border: '2px solid #00000033',
    },
  },
  filterHead: {
    backgroundColor: theme.palette.primary.grey,
  },
  filteRows: {
    backgroundColor: theme.palette.primary.white,
  },
}));

export default useStyles;
