import { Box, Typography } from '@mui/material';

import DiagnoseCard from '../diagnoseCard';

const { default: useStyles } = require('./style');

const DeletedDiagnose = ({ submittedCodes, submissionStatus, jsonData }) => {
  const classes = useStyles();

  const filteredData = (submittedCodes) => {
    return (submittedCodes?.diagnosisPreviouslySubmitted ?? []).filter((item) =>
      (submittedCodes?.diagnosisPreviouslySubmitted ?? []).some(
        (obj) => obj?.hccCode === item?.hccCode && obj.status === 'CLAIM_DELETE'
      )
    );
  };

  return (
    <>
      <Box
        className={`${classes.container} ${classes.diagnosisBox} ${classes.addScreen}`}
      >
        <Typography variant="h6" className={classes.title}>
          <span>Deleted Diagnosis</span>
        </Typography>
        {submissionStatus === 'coder' ? (
          <></>
        ) : (
          <Box className={classes.diagnoseList}>
            {filteredData(submittedCodes).map((item) => (
              <DiagnoseCard
                key={item?.dxCode}
                code={item?.dxCode}
                description={item?.dxDescription}
                status="INFERRED"
                hcc={item?.hccCode}
                deleted
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default DeletedDiagnose;
