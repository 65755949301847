import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import useStyles from './style';
import { UpsertOfficeVisitById } from 'api/charts';
import { pdfSubject } from 'shared/rxjs/subjects';
import { useTheme } from '@emotion/react';

const AccordionOfficeVisits = ({
  label,
  visits,
  expandIcon,
  chartId,
  readonly,
  setAlert,
}) => {
  const classes = useStyles();
  const [editableVisitId, setEditableVisitId] = useState(null);
  const [expandedVisitId, setExpandedVisitId] = useState(null);
  const [visitData, setVisitData] = useState(visits);
  const [initialVisitData, setInitialVisitData] = useState({});
  const [errors, setErrors] = useState({});

  const theme = useTheme();

  useEffect(() => {
    const visitMap = visits?.reduce((map, visit) => {
      map[visit.visitId] = { ...visit };
      return map;
    }, {});
    setInitialVisitData(visitMap);
  }, [visits]);

  const handleClickVisit = (visitId, index, boundingBox) => {
    index = index + 1;
    const pageNumber = boundingBox[0]?.pageNumber;
    pdfSubject.next({ visitId, index, pageNumber });
  };

  const handleEditClick = (visitId, index) => {
    if (readonly) {
      setAlert(true);
      return;
    }
    setEditableVisitId(visitId);
    setExpandedVisitId(visitId);
    const { boundingBox } = visits[index];
    const pageNumber = boundingBox[0]?.pageNumber;
    pdfSubject.next({ visitId, index, pageNumber });
    setErrors({});
  };

  const handleSaveClick = async () => {
    const visitToUpdate = visitData.find(
      (visit) => visit.visitId === editableVisitId
    );
    if (!visitToUpdate) return;

    const { dos, provider, placeOfService } = visitToUpdate;
    const newErrors = {};

    if (!dos) newErrors.dos = 'Date of Service is required';
    if (dos.length != 10 || (errors.dos != undefined && errors.dos != '')) {
      newErrors.dos = 'INVALID DATE';
    }
    if (!provider) newErrors.provider = 'Provider is required';
    if (!placeOfService)
      newErrors.placeOfService = 'Place of Service is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const initialVisit = initialVisitData[editableVisitId];
    const hasChanges =
      JSON.stringify(initialVisit) !== JSON.stringify(visitToUpdate);

    if (!hasChanges) {
      setEditableVisitId(null);
      return;
    }

    const sendData = {
      chartId: chartId,
      visitDetail: visitToUpdate,
    };
    try {
      await UpsertOfficeVisitById(sendData);
      setInitialVisitData((prev) => ({
        ...prev,
        [editableVisitId]: { ...visitToUpdate },
      }));
    } catch (error) {
      console.error('Save Office Visit failed:', error);
    } finally {
      setEditableVisitId(null);
    }
  };

  const handleDiscardClick = (visitId) => {
    const initialVisit = initialVisitData[visitId];
    setVisitData((prev) =>
      prev?.map((visit) =>
        visit?.visitId === visitId ? { ...initialVisit } : visit
      )
    );
    setEditableVisitId(null);
  };

  const handleChange = (e, visitId, field) => {
    const updatedValue = e.target.value;
    if (field === 'dos') {
      let dateOfService = updatedValue;
      let len = dateOfService.length;
      if (len > 10) return;
      let isValid = true;
      const dateRegex =
        /^(0[1-9]|1[0-2])?(-)?(0[1-9]|[12][0-9]|3[01])?(-)?(\d{1,4})?$/;

      if (dateRegex.test(dateOfService)) {
        isValid = true;
      } else {
        isValid = false;
      }

      const updatedVisits = visitData?.map((visit) =>
        visit?.visitId === visitId
          ? { ...visit, [field]: dateOfService }
          : visit
      );
      setVisitData(updatedVisits);
      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'INVALID DATE',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: '',
        }));
      }
    } else {
      const updatedVisits = visitData?.map((visit) =>
        visit?.visitId === visitId ? { ...visit, [field]: updatedValue } : visit
      );
      setVisitData(updatedVisits);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  };

  const handleAccordionChange = (visitId) => (_, isExpanded) => {
    setExpandedVisitId(isExpanded ? visitId : null);
  };

  return (
    <Accordion className={classes.navGuideAccordion}>
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls="office-visit-content"
        id="office-visit-header"
      >
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {visitData?.map((visit, index) => (
          <Accordion
            key={visit?.visitId}
            className={classes.navGuideAccordionInner}
            expanded={expandedVisitId === visit?.visitId}
            onChange={handleAccordionChange(visit?.visitId)}
          >
            <AccordionSummary
              expandIcon={
                <>
                  {editableVisitId === visit?.visitId && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDiscardClick(visit?.visitId);
                      }}
                    >
                      <CloseIcon
                        style={{ color: theme.palette.primary.black }}
                      />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      editableVisitId === visit?.visitId
                        ? handleSaveClick()
                        : handleEditClick(visit?.visitId, index);
                    }}
                  >
                    {editableVisitId === visit?.visitId ? (
                      <CheckIcon
                        style={{ color: theme.palette.primary.black }}
                      />
                    ) : (
                      <EditIcon
                        style={{ color: theme.palette.primary.black }}
                      />
                    )}
                  </IconButton>
                </>
              }
              aria-controls={`panel${visit?.visitId}-content`}
              id={visit?.visitId}
              classes={{
                expandIconWrapper: classes.customExpandIconWrapper,
              }}
            >
              {editableVisitId === visit?.visitId ? (
                <>
                  <TextField
                    value={visit.dos}
                    onChange={(e) => handleChange(e, visit?.visitId, 'dos')}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                      },
                    }}
                    error={!!errors?.dos}
                    helperText={errors?.dos}
                    onClick={(e) => e.stopPropagation()}
                  />
                </>
              ) : (
                <Typography
                  onClick={() =>
                    handleClickVisit(visit?.visitId, index, visit?.boundingBox)
                  }
                >
                  {visit?.dos}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.textFieldBox}>
              <Box>
                {editableVisitId === visit?.visitId ? (
                  <>
                    <TextField
                      value={visit?.provider}
                      onChange={(e) =>
                        handleChange(e, visit?.visitId, 'provider')
                      }
                      className={classes.textField}
                      fullWidth
                      margin="normal"
                      variant="standard"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                        },
                      }}
                      error={!!errors?.provider}
                      helperText={errors?.provider}
                    />
                    <TextField
                      variant="standard"
                      value={visit?.placeOfService}
                      onChange={(e) =>
                        handleChange(e, visit?.visitId, 'placeOfService')
                      }
                      className={classes.textField}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        classes: {
                          underline: classes.underline,
                        },
                      }}
                      error={!!errors?.placeOfService}
                      helperText={errors?.placeOfService}
                    />
                  </>
                ) : (
                  <>
                    <Box className={classes.textDetail}>
                      <Typography>{visit?.provider}</Typography>
                    </Box>
                    <Box className={classes.textDetail}>
                      <Typography>{visit?.placeOfService}</Typography>
                    </Box>
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionOfficeVisits;
