import { Box, Button } from 'components/controls';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { loginMfaContext } from 'shared/context/mfa';
import useStyles from '../style';
import { userSendcode } from '../service';
import { removeMaskedEmail } from 'shared/context/mfa/localstoragedata';

const SecondComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    setStage,
    setIsSuccess,
    setOpenNotification,
    setNotificationMsg,
    loading,
    setLoading,
    setSendDisabled,
    maskedEmail,
  } = useContext(loginMfaContext);

  const callThird = async () => {
    setLoading(true);
    setSendDisabled(true);
    const { success, message } = await userSendcode();
    setLoading(false);
    if (success) {
      setNotificationMsg(message);
      setIsSuccess(true);
      setOpenNotification(true);
      setStage(3);
    } else {
      if (
        message === 'User not found' ||
        message === 'User already has MFA enabled for email'
      ) {
        removeMaskedEmail();
        history.replace('/login');
      }
      setNotificationMsg(message);
      setIsSuccess(false);
      setOpenNotification(true);
      if (message === 'Session expired') {
        setTimeout(() => {
          removeMaskedEmail();
          history.replace('/login');
        }, 3000);
      }
    }
    setTimeout(() => {
      setSendDisabled(false);
    }, 120000);
  };

  return (
    <Box className={classes.stagetwodiv}>
      <Box className={classes.textdiv}>
        Send the verification code to{' '}
        <Box className={classes.email}>{maskedEmail}</Box>?
      </Box>
      <Button
        className={classes.stagetwobtn}
        onClick={callThird}
        label="Send Code"
        disabled={loading}
      />
    </Box>
  );
};

export default SecondComponent;
