import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: '0 4px 4px',
    maxHeight: '65vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': { width: '4px', height: '6px' },
    '&::-webkit-scrollbar-track:horizontal': {
      background: '#c0c0c0',
      border: 'none',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: '#666666',
      borderRadius: '10px',
    },
  },
  loadingContainer: {
    margin: '2em auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDataContainer: {
    width: '80%',
    margin: '0 auto',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      width: '50%',
      height: 'auto',
    },
    '& p': {
      fontWeight: 700,
    },
  },
  toggleGroup: {
    flexWrap: 'wrap',
    gap: '12px',
    '& .Mui-selected': {
      border: '1px solid #3E9E8E !important',
      background: '#F6FDFC',
      color: '#3E9E8E',
    },
  },
  toggleGroupButton: {
    padding: '8px 4px',
    borderRadius: '8px !important',
    border: '1px solid #555 !important',
    background: '#ddd',
    color: '#555',
    minHeight: 'auto',
    lineHeight: 1,
  },
  usersSelectionContainer: {
    border: '2px solid #eee',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '12px',
    '& h6': {
      lineHeight: 1,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  bulkAssignContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
  bulkActionButton: {
    position: 'relative',
    background: '#3E9E8E',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      background: '#3E9E8E',
      color: '#fff',
    },
    '&:disabled': {
      background: '#eee',
      color: '#ddd',
    },
  },
  bulkAssignCoderProgress: {
    color: '#3E9E8E',
    position: 'absolute',
    maxWidth: '24px',
    maxHeight: '24px',
  },
}));

export default useStyles;
