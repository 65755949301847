import { useEffect, useState } from 'react';

import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

import CustomZoomPlugin from './customZoomPlugin';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import useStyles from './style';
import ContextMenu from './contextMenu';
import { pdfSubject, titleSubject } from 'shared/rxjs/subjects';

const PdfViewer = ({
  workerUrl,
  pdfFile,
  coordinates,
  visithighlights,
  titlehighlights,
  onHighlightClick,
  attributes,
  onAttributeClick,
  focus,
  setSelectedDiagnose,
  rerender,
  readonly,
  setAlert,
  chartId,
  fullDiagnoseList = [],
}) => {
  const [pdfState, setPdfState] = useState({ currentPage: 0, totalPages: 0 });
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [boundingBox, setBoundingBox] = useState(null);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const goToFirstPage = (pageNumber) => {
    jumpToPage(pageNumber);
  };

  useEffect(() => {
    const subscription = pdfSubject.subscribe((data) => {
      goToFirstPage(data?.pageNumber - 1);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = titleSubject.subscribe((data) => {
      goToFirstPage(data?.pageNumber - 1);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const handleTextSelection = (e) => {
      const selection = window.getSelection();
      const text = selection.toString();
      const pdfViewer = document.querySelector('.rpv-core__viewer');

      if (!pdfViewer.contains(e.target)) {
        return;
      }

      if (text) {
        const ranges = [];
        for (let i = 0; i < selection.rangeCount; i++) {
          ranges.push(selection.getRangeAt(i));
        }

        const boundingBoxes = ranges.flatMap((range) => {
          const rects = Array.from(range.getClientRects());
          return rects
            .map((rect) => {
              const pageElement = range.startContainer.parentElement.closest(
                '.rpv-core__page-layer'
              );
              if (!pageElement) return null;

              const pageRect = pageElement.getBoundingClientRect();
              const pageNumber = parseInt(
                pageElement.getAttribute('data-virtual-index'),
                10
              );

              const relativeX = rect.left - pageRect.left;
              const relativeY = rect.top - pageRect.top;

              return {
                pageNumber: pageNumber + 1,
                x0: relativeX,
                y0: relativeY,
                x1: relativeX + rect.width,
                y1: relativeY + rect.height,
                width: pageRect.width,
                height: pageRect.height,
              };
            })
            .filter(Boolean);
        });

        setSelectedText(text);
        setBoundingBox(boundingBoxes);
      } else {
        setSelectedText('');
      }
    };

    document.addEventListener('mouseup', handleTextSelection);

    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
    };
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    if (readonly) setAlert(true);
    else
      setAnchorPosition({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
  };

  const handleClose = () => {
    setAnchorPosition(null);
  };

  const classes = useStyles();

  const renderHighlights = (props) =>
    coordinates?.length ? (
      <Box>
        {coordinates?.map((coordinate) => {
          let tab = 0;
          return coordinate?.bounds
            ?.filter((bound) => bound?.pageIndex === props?.pageIndex)
            ?.map((bound, i) => {
              tab += 1;
              return (
                <Box
                  onClick={(e) => {
                    onHighlightClick(e, coordinate);
                  }}
                  key={`${coordinate.id}${bound.top}${bound.left}`}
                  className={`${classes.highlights} ${classes.coordinate} ${
                    focus[coordinate.id] && classes.colored
                  }`}
                  style={Object.assign(
                    {},
                    props.getCssProperties(bound, props.rotation)
                  )}
                  id={coordinate.id + 'highlight' + (i + 1)}
                  tabIndex={tab}
                />
              );
            });
        })}

        {attributes?.length &&
          attributes?.map(
            (attribute) =>
              attribute?.bounds &&
              attribute?.bounds
                .filter((bound) => bound?.pageIndex === props?.pageIndex)
                .map((bound, i) => (
                  <Box
                    onClick={onAttributeClick && onAttributeClick}
                    key={`${attribute?.id}${bound.top}${bound?.left}`}
                    id={attribute?.id}
                    className={`${classes.highlights} ${classes.attribute} ${
                      focus[attribute?.id?.replace(/Attribute\d*$/, '')] &&
                      classes.coloredAttribute
                    }`}
                    style={Object.assign(
                      {},
                      props?.getCssProperties(bound, props?.rotation)
                    )}
                    tabIndex={i + 100}
                  />
                ))
          )}

        {visithighlights?.length > 0 &&
          visithighlights?.map((coordinate) => {
            let tab = 0;
            return coordinate?.bounds
              ?.filter((bound) => bound?.pageIndex === props?.pageIndex)
              ?.map((bound, i) => {
                tab += 1;
                return (
                  <Box
                    key={`${coordinate?.id}${bound?.top}${bound?.left}`}
                    className={`${classes.visithighlight} ${
                      classes.visitcoordinate
                    } ${focus[coordinate.id] && classes.visitcolored}`}
                    style={Object.assign(
                      {},
                      props.getCssProperties(bound, props.rotation)
                    )}
                    id={coordinate?.id + 'visithighlight' + 1}
                    tabIndex={tab}
                  />
                );
              });
          })}

        {titlehighlights?.length > 0 &&
          titlehighlights?.map((coordinate) => {
            let tab = 0;
            return coordinate?.bounds
              ?.filter((bound) => bound?.pageIndex === props?.pageIndex)
              ?.map((bound, i) => {
                tab += 1;
                return (
                  <Box
                    key={`${coordinate?.id}${bound?.top}${bound?.left}`}
                    className={`${classes.titlehighlights} ${
                      classes.titlecoordinate
                    } ${focus[coordinate.id] && classes.titlecolored}`}
                    style={Object.assign(
                      {},
                      props.getCssProperties(bound, props?.rotation)
                    )}
                    id={coordinate?.id + 'titlehighlight' + (i + 1)}
                    tabIndex={tab}
                  />
                );
              });
          })}
      </Box>
    ) : (
      <></>
    );

  const handleDocumentLoad = (e) => {
    setPdfState((prevState) => ({
      ...prevState,
      totalPages: e?.doc?._pdfInfo?.numPages,
    }));
  };

  const handlePageChange = (e) => {
    setPdfState((prevState) => ({
      ...prevState,
      currentPage: e?.currentPage,
    }));
  };

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;

  const CustomZoomPluginInstance = CustomZoomPlugin();
  const { zoomTo } = CustomZoomPluginInstance;

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  return (
    <Box className={classes.pdfViewerContainer} onContextMenu={handleClick}>
      <Box className={classes.pdfControls}>
        <Typography variant="h6" className={classes.pageTracker}>{`(${
          pdfState.currentPage + 1
        }/${pdfState.totalPages})`}</Typography>
        <Box className={classes.zoomControls}>
          <ZoomOut>
            {({ onClick }) => (
              <Button type="button" onClick={onClick}>
                -
              </Button>
            )}
          </ZoomOut>
          <Tooltip title="Reset Zoom Level" placement="top">
            <Button
              className={classes.zoomReset}
              onClick={() => {
                zoomTo(SpecialZoomLevel.PageWidth);
              }}
              tooltip="Reset zoom"
            >
              Zoom
            </Button>
          </Tooltip>
          <ZoomIn>
            {({ onClick }) => (
              <Button type="button" onClick={onClick}>
                +
              </Button>
            )}
          </ZoomIn>
        </Box>
      </Box>
      <Box className={classes.pdfContainer}>
        <Worker workerUrl={workerUrl} className={classes.worker}>
          <Viewer
            fileUrl={pdfFile}
            plugins={[
              highlightPluginInstance,
              zoomPluginInstance,
              CustomZoomPluginInstance,
              pageNavigationPluginInstance,
            ]}
            defaultScale={SpecialZoomLevel.PageWidth}
            onPageChange={handlePageChange}
            onDocumentLoad={handleDocumentLoad}
          />
        </Worker>
      </Box>
      <ContextMenu
        open={Boolean(anchorPosition)}
        anchorPosition={anchorPosition}
        onClose={handleClose}
        diagnosisList={fullDiagnoseList}
        selectionData={{ selectedText, boundingBox }}
        setSelectedDiagnose={setSelectedDiagnose}
        rerender={rerender}
        chartId={chartId}
      />
    </Box>
  );
};

export default PdfViewer;
