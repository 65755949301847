import { CancelIcon } from 'icons/customIcons';
import useStyles from './style';
import { useState } from 'react';

const { Box, IconButton, Typography, Skeleton } = require('@mui/material');

const ReportCard = ({
  icon = <></>,
  count = 0,
  label = '',
  list = [],
  loading = false,
  wide = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleCardExpand = () => {
    setExpanded((prev) => !prev);
  };

  return loading ? (
    <Skeleton
      height={150}
      width={250}
      variant="rounded"
      className={classes.container}
    />
  ) : (
    <Box
      className={`${classes.container} ${expanded ? 'expanded' : ''}`}
      sx={wide ? { '&.expanded': { maxWidth: '420px !important' } } : {}}
    >
      <Box>
        <IconButton className={classes.iicon} onClick={handleCardExpand}>
          {expanded ? <CancelIcon /> : 'i'}
        </IconButton>
        <Box className={classes.icon}>{icon}</Box>
        <Typography variant="h6" className={classes.count}>
          {count}
        </Typography>
        <Typography paragraph className={classes.label}>
          {label}
        </Typography>
      </Box>
      <Box className={`${classes.infoContainer} ${expanded ? 'expanded' : ''}`}>
        {list?.map((listItem) => (
          <Box
            className={classes.info}
            key={`${listItem?.label}-${listItem?.value}`}
          >
            <span>{listItem?.label}</span>
            <span className={wide ? 'wide' : ''}>{listItem?.value}</span>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ReportCard;
