import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import useStyles from './style';
import { titleSubject } from 'shared/rxjs/subjects';

const AccordionTitles = ({ label, titles, expandIcon }) => {
  const classes = useStyles();

  const handleClick = (boundingBox, elementId) => {
    const pageNumber = boundingBox?.pageNumber;
    titleSubject.next({ boundingBox, elementId, pageNumber });
  };

  return (
    <Accordion className={classes.navGuideAccordion}>
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls="titles-content"
        id="titles-header"
      >
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.scrollableContent}>
          <Box className={classes.textFieldBox}>
            {titles.map((title, index) => (
              <Box key={index}>
                <Typography
                  onClick={() =>
                    handleClick(title.boundingBox, title.elementId)
                  }
                  className={classes.titleText}
                >
                  {title.text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionTitles;
