import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '95%',
    maxWidth: '95%',
    width: '95%',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'strech',
    justifyContent: 'strech',
  },
  mainContainer: {
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nameContainer: {
    marginLeft: '20px',
    fontSize: '20px',
    fontWeight: '500',
  },
  tableContainer: {},
}));
export default useStyles;
