import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  searchBarContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    border: '2px solid #999',
    borderRadius: '4px',
  },
  searchBarTextField: {
    width: '90%',
    '& input': {
      padding: 0,
      border: 0,
      borderRadius: '5px',
      padding: '4px 8px',
      '&::placeholder': { color: '#000', opacity: 1 },
    },
  },
  searchBarIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    marginRight: '10px',
  },
  searchBarIconButton: { padding: 0, minWidth: 'auto' },
}));

export default useStyles;
