import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, Typography } from '@mui/material';
import useStyles from './style';
import DiagnoseItem from './diagnoseItem';
import { addAttribute } from 'api/charts';
import Notification from 'components/notification';

const DiagnosisListDialog = ({
  open,
  setOpen,
  list,
  selection,
  rerender,
  chartId,
}) => {
  const [checkedDiagnosis, setCheckedDiagnosis] = useState(
    list.map((diagnose) => ({ id: diagnose?.Id, checked: false }))
  );
  const [isLoading, setIsLoading] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  const classes = useStyles();

  const handleSave = async () => {
    setIsLoading(true);

    const id = checkedDiagnosis
      .filter((item) => item.checked === true)
      .map((item) => item.id);

    if (id.length) {
      try {
        await addAttribute({
          chartId: chartId,
          diagnoseId: id,
          boundingBox: selection.boundingBox,
          text: selection.selectedText,
        });

        rerender((prev) =>
          prev?.map((obj) =>
            id.indexOf(obj?.Id) > -1
              ? {
                  ...obj,
                  attributes: [
                    ...obj?.attributes,
                    {
                      text: selection.selectedText,
                      boundingBox: selection.boundingBox,
                      id: `customAttribute${Date.now()}`,
                      isVerified: true,
                    },
                  ],
                }
              : obj
          )
        );

        setNotificationMsg(
          'New supporting document successfully registered to the selected codes.'
        );
        setNotificationType('success');
        setOpenNotification(true);

        setCheckedDiagnosis(
          list.map((diagnose) => ({ id: diagnose?.Id, checked: false }))
        );
        setIsLoading(false);
        setOpen(false);
      } catch (e) {
        setNotificationMsg('An unexpected error occured');
        setNotificationType('error');
        setOpenNotification(true);

        setIsLoading(false);
      }
    } else {
      return;
    }
  };

  const handleCheckChange = (id, checked) => {
    setCheckedDiagnosis((prev) =>
      prev.map((item) => (item.id === id ? { ...item, checked } : item))
    );
  };

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick' && isLoading) return;
    else setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.container}>
      <Notification
        type={notificationType}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
      <DialogTitle id="diagnosis-list-title" className={classes.title}>
        Codes List
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <DialogContentText
          id="diagnosis-list-description"
          className={classes.description}
        >
          These codes have been added but are not yet linked to any diagnosis.
        </DialogContentText>
        <Box className={classes.selecteionIndicatorContainer}>
          <Typography variant="h6">Selected Text</Typography>
          <Typography paragraph>{selection?.selectedText}</Typography>
        </Box>
        <Box className={classes.listContainer}>
          {list.map((diagnose) => (
            <DiagnoseItem
              key={diagnose.Id}
              id={diagnose?.Id}
              hcc={`HCC: ${
                diagnose?.hccCaptured?.trim() === '' ||
                diagnose?.hccCaptured === null
                  ? 'N/A'
                  : diagnose?.hccCaptured
              }`}
              category={diagnose?.Category}
              icd10={`${diagnose?.Code} - ${diagnose?.Description}`}
              onCheckChange={handleCheckChange}
              disabled={isLoading}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button
          onClick={handleSave}
          autoFocus
          className={classes.buttons + ' ' + classes.saveButton}
          disabled={
            isLoading ||
            Boolean(
              !checkedDiagnosis.filter((item) => item.checked === true).length
            )
          }
        >
          Save
          {isLoading && <CircularProgress className={classes.progress} />}
        </Button>
        <Button
          onClick={handleClose}
          className={classes.buttons}
          disabled={isLoading}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiagnosisListDialog;
