import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      maxHeight: 'calc(100% - 32px)',
    },
    '& .MuiDialog-paper': {
      maxWidth: '1000px',
    },
  },
  dialogTitle: {
    paddingBottom: '4px',
    '& p': {
      fontSize: '12px',
      marginBottom: '4px',
    },
  },
  dialogContent: {
    overflow: 'initial',
    paddingBottom: '4px',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchBarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    '& svg': {
      maxWidth: '20px',
    },
    '& button': {
      padding: '2px',
      minHeight: 'auto',
      lineHeight: 1,
    },
  },
  icdSearchBar: {
    borderBottom: '1px solid #000',
    '& input': {
      border: 0,
      outline: 'none',
      minWidth: '200px',
    },
  },
  hccCodeFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    paddingTop: '7px',
    marginLeft: 'auto',
  },

  hccCodeText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '6px',
    border: '2px solid #3E9E8E',
    padding: '3px 8px',
    backgroundColor: '#F6FDFC',
    color: '#3E9E8E',
    cursor: 'pointer',
  },
  hccOnlytext: {
    fontSize: '0.7rem',
  },
  hccVersiontext: {
    paddingTop: '6px',
    fontSize: '0.9rem',
  },
  refreshButtonContainer: {
    '& button': {
      borderRadius: '6px',
      border: '1px solid #3E9E8E',
      backgroundColor: '#F6FDFC',
      color: '#3E9E8E',
      minHeight: 0,
      lineHeight: 1,
    },
  },
  tableContainer: {
    borderRadius: '10px',
    border: '2px solid grey',
    marginTop: '16px',
    overflow: 'auto',
    maxHeight: '340px',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  muiTable: {
    '& .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'rgb(199 239 234)',
      '& th': {
        fontWeight: 700,
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        transition: 'background 200ms linear',
        userSelect: 'none',
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
        '&:last-child': { border: 'none' },
        '&:hover': {
          backgroundColor: 'rgba(62, 158, 142, 0.10)',
        },
        '&:focus': {
          backgroundColor: 'rgba(62, 158, 142, 0.10)',
        },
      },
    },
    '& .MuiTableRow-root': {
      borderBottom: '2px solid grey',
    },
    '& .MuiTableCell-root': {
      padding: '4px 8px',
      '&:first-child': {
        textAlign: 'center',
      },
    },
  },
  dialogActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginRight: theme.spacing(5),
  },
  actionButtons: {
    borderRadius: '6px',
    border: '1px solid #3E9E8E',
    backgroundColor: '#F6FDFC',
    color: '#3E9E8E',
    minHeight: 0,
    lineHeight: 1,
    '&:disabled': {
      backgroundColor: '#ccc',
      border: '1px solid #aaa',
    },
  },
  initialPlaceholder: {
    textAlign: 'center',
    '& img': {
      maxWidth: '300px',
      height: 'auto',
    },
    '& h6': {
      maxWidth: '70%',
      fontSize: '16px',
      color: '#aaa',
      fontWeight: 700,
      margin: '0 auto',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  loadingImage: {
    '& img': {
      maxWidth: '100px',
      height: 'auto',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  code: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '& div': {
      minWidth: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  refreshButton: {
    transition: 'all 300ms ease-out',
    '&:hover': {
      transform: 'rotate(470deg) scale(1.1)',
    },
  },
}));

export default useStyles;
