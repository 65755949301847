import {
  Box,
  Button,
  Dialog,
  Typography,
  IconButton,
  Input,
} from '@mui/material';
import useStyles from './styles';
import { useState } from 'react';
import { uploadFile } from 'api/charts';
import CloseIcon from '@mui/icons-material/Close';
import SuccessDialog from '../successdialog';
import Notification from 'components/notification';

const ZipFileUpload = ({
  open,
  onClose,
  projectID,
  projectName,
  handleFileUpload,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonLabel, setButtonLabel] = useState('Browse');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [dialogType, setDialogType] = useState('success');
  const [isDragging, setIsDragging] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('info');
  const [isUploading, setIsUploading] = useState(false);

  const handleClose = () => {
    setSelectedFile(null);
    setButtonLabel('Browse');

    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }

    onClose(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setButtonLabel('Upload');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    const allowedExtensions = ['zip'];

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFile(file);
      setButtonLabel('Upload');
    } else {
      setSnackbarMessage(
        'Unsupported file type. Please upload only .zip files.'
      );
      setSnackbarType('error');
      setSnackbarOpen(true);
    }
  };

  const handleButtonClick = async () => {
    if (buttonLabel === 'Upload' && selectedFile) {
      setIsUploading(true);
      try {
        const result = await uploadFile(selectedFile, projectID);

        if (result.data.success) {
          setDialogType('success');
        } else {
          setDialogType('failure');
        }

        setShowSuccessDialog(true);
      } catch (error) {
        setDialogType('failure');
        console.error('File upload failed:', error);
        setShowSuccessDialog(true);
      } finally {
        setIsUploading(false);
        setSelectedFile(null);
        setButtonLabel('Browse');
      }

      handleClose();
    } else {
      document.getElementById('fileInput').click();
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setButtonLabel('Browse');
    // document.getElementById('fileInput').value = '';

    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false);
  };

  const reopenUploadFileDialog = () => {
    setShowSuccessDialog(false);
    setTimeout(() => {
      onClose(true);
    }, 300);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.dialog}>
        <Typography className={classes.BoldText}>
          &lt; Step 2 of 2 &gt;
        </Typography>
        <Typography style={{ margin: '0px 5px 4px 5px', color: '#1A1919' }}>
          Please upload a Zip file containing the medical charts into{' '}
          {projectName}
        </Typography>

        {!selectedFile && (
          <Box
            className={`${classes.uploadContainer} ${
              isDragging ? classes.Dragging : ''
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <img
              className={classes.ImgContainer}
              src="/folder.png"
              alt="folder logo"
            />
            <Typography style={{ textAlign: 'center' }}>
              {' '}
              <Typography style={{ color: '#105166', display: 'inline' }}>
                Drag and drop
              </Typography>{' '}
              your Zip file here to Upload{' '}
            </Typography>
            <Input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              inputProps={{ accept: '.zip' }}
            />
          </Box>
        )}

        {selectedFile && (
          <Box className={classes.uploadContainer}>
            <Box className={classes.FileButton}>
              <Typography variant="h6">Selected File:</Typography>
              <IconButton onClick={handleFileRemove} style={{ marginLeft: 8 }}>
                <CloseIcon style={{ color: 'red' }} />
              </IconButton>
            </Box>
            <Typography>{selectedFile.name}</Typography>
          </Box>
        )}
        <Box className={classes.buttonContainer}>
          <Button
            type="submit"
            className={`${classes.button} ${classes.saveButton}`}
            onClick={handleButtonClick}
            disabled={isUploading}
          >
            {buttonLabel}
          </Button>
          <Button
            type="button"
            className={classes.button}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
        <Notification
          type={snackbarType}
          message={snackbarMessage}
          openNotification={snackbarOpen}
          setOpenNotification={setSnackbarOpen}
        />
      </Dialog>
      {showSuccessDialog && (
        <SuccessDialog
          open={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          type={dialogType}
          reopenUploadDialog={reopenUploadFileDialog}
          projectName={projectName}
          lastStep
          handleFileUpload={handleFileUpload}
        />
      )}
    </>
  );
};

export default ZipFileUpload;
