import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  pdfViewerContainer: {
    backgroundColor: 'red',
    width: '100%',
    flex: '50%',
    backgroundColor: '#EAEBED',
    borderRadius: '10px',
  },
  pdfViewerContainerSm: {
    backgroundColor: 'red',
    width: '100%',
    flex: '33%',
    backgroundColor: '#EAEBED',
    borderRadius: '0',
  },
  pdfContainer: {
    maxWidth: '90%',
    height: '76vh',
    margin: '0 auto',
    borderRadius: '5px',
    overflow: 'hidden',
    '& .rpv-core__inner-pages': {
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      scrollBehavior: 'smooth',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .rpv-core__inner-page': {
      backgroundColor: '#EAEBED',
    },
    '& .rpv-core__page-layer': {
      '&:after': {
        boxShadow: 'none',
      },
    },
  },
  pdfContainerSm: {
    maxWidth: '98%',
    height: '76vh',
    margin: '0 auto',
    borderRadius: '5px',
    overflow: 'hidden',
    '& .rpv-core__inner-pages': {
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      scrollBehavior: 'smooth',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .rpv-core__inner-page': {
      backgroundColor: '#EAEBED',
    },
    '& .rpv-core__page-layer': {
      '&:after': {
        boxShadow: 'none',
      },
    },
  },
  pdfControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px 75px 5px',
  },
  pageTracker: { fontSize: '16px' },
  zoomControls: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    fontSize: '14px',
    '& button': {
      minWidth: 'auto',
      height: '20px',
      width: '20px',
      padding: '4px',
      color: '#000',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  zoomReset: {
    width: 'auto !important',
  },
  chartLabel: {
    fontSize: '20px',
    fontWeight: 700,
  },
  highlights: {
    transition: 'background-color 200ms linear',
  },
  colored: {
    background: '#166C8744',
  },
  coloredAttribute: {
    backgroundColor: '#CBCB0944',
  },
  coordinate: {
    zIndex: 2,
    borderBottom: '2px solid #166C87',
    '&:hover': { background: '#166C8744' },
    '&:focus': { background: '#166C8744' },
  },
  attribute: {
    zIndex: 1,
    borderBottom: '2px solid #CBCB09',
    '&:hover': { background: '#CBCB0944' },
    '&:focus': { background: '#CBCB0944' },
  },
  visitcoordinate: {
    zIndex: 2,
    background: '#3fa8c520',
  },
}));

export default useStyles;
