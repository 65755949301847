export const llmOptions = [
  { label: 'GPT-4o-mini', value: 'gpt-4o-mini' },
  { label: 'GPT-4o', value: 'gpt-4o' },
  { label: 'Mistral (7B)', value: 'mistral' },
  { label: 'LLaMA 2 (7B)', value: 'llama2:7b' },
  { label: 'LLaMA 3.1 (8B)', value: 'llama3.1:8b-instruct-q4_0' },
  { label: 'Meditron (7B)', value: 'meditron:7b' },
  { label: 'LLaMA 2 (13B)', value: 'llama2:13b' },
  { label: 'Meditron (70B)', value: 'meditron:70b' },
];
