import { queueAPI } from 'config/api-routlink';
import request from 'shared/service/request';

// GET request - Get all matching provider name based on search string
export const getProvidersBySearch = async (searchText, projectIdList) => {
  const params = new URLSearchParams();
  params.append('searchText', searchText);

  projectIdList.forEach((id) => {
    params.append('projectIdList', id);
  });
  const rdata = await request.get(queueAPI.GET_PROVIDER_SEARCH, { params });
  return { data: rdata.data };
};

// GET request - Get all matching member based on search string
export const getMembersBySearch = async (searchText, projectIdList) => {
  const params = new URLSearchParams();
  params.append('searchText', searchText);

  projectIdList.forEach((id) => {
    params.append('projectIdList', id);
  });
  const rdata = await request.get(queueAPI.GET_MEMBER_SEARCH, { params });
  return { data: rdata.data?.data };
};

// GET request - Get all matching disease categories on search string
export const getDiseaseCategoriesBySearch = async (searchText) => {
  const rdata = await request.get(queueAPI.GET_DISEASE_CATEGORIES_SEARCH, {
    params: { searchText },
  });
  return { data: rdata.data?.data };
};

// GET request - Get charts by filter
export const getChartsByFilters = async (
  projectIdList,
  selectedFilters,
  pagenum,
  chartsFilter
) => {
  const filter = {
    projectIdList: projectIdList,
    ...selectedFilters,
    pagenum: pagenum,
    chartsFilter,
  };

  const rdata = await request.get(queueAPI.GET_CHARTS_BY_FILTER, {
    params: { filter: JSON.stringify(filter) },
  });
  return { data: rdata.data?.data };
};

// POST request - create queue
export const createQueue = async ({
  queueName,
  selectedChartIds,
  transformedFilters,
  selectedProjects,
  chartsFilter,
}) => {
  const requestData = {
    queueName,
    chartsFilter,
    filter: {
      projectIdList: selectedProjects,
      includeChartIds: selectedChartIds,
      ignoreChartIds: [],
      ...transformedFilters,
    },
  };

  const rdata = await request.post(`${queueAPI.CREATE_QUEUE}`, requestData);
  return {
    data: rdata.data?.data,
  };
};

// GET request - get queues list
export const getQueuesList = async ({ rowsPerPage, pageno }) => {
  const rData = await request.get(`${queueAPI.GET_QUEUES}`, {
    params: {
      rowsPerPage,
      pageno,
    },
  });
  return { data: rData?.data?.data };
};

// GET request - queue name validation
export const queueNameValidation = async ({ queueName }) => {
  const rdata = await request.get(queueAPI.QUEUE_NAME_VALIDATION, {
    params: { queueName },
  });
  return { data: rdata.data };
};

// GET request - Get project list
export const getChartsProjects = async ({
  pageno,
  rowsPerPage,
  filter,
  sort,
}) => {
  const rdata = await request.get(queueAPI.GET_PROJECT_WITH_CHART_COUNT, {
    params: {
      pageno,
      rowsPerPage,
      filter,
      sort,
    },
  });

  return rdata?.data;
};

// GET request - Get charts by queueId
export const getChartsByQueueId = async ({
  queueID,
  pagenum,
  rowsPerPage,
  filter,
}) => {
  const rdata = await request.get(queueAPI.GET_CHARTS_BY_QUEUEID, {
    params: {
      queueID,
      pagenum,
      rowsPerPage,
      filter,
    },
  });

  return rdata?.data;
};

// GET request - Get list of queue name
export const getListQueueName = async ({ rowsPerPage, pageno }) => {
  const rdata = await request.get(queueAPI.GET_LIST_QUEUE_NAME, {
    params: {
      rowsPerPage,
      pageno,
    },
  });

  return rdata?.data;
};

// GET request - Get list of Client User
export const getListClientUser = async ({ queueID, rowsPerPage, pageno }) => {
  const rdata = await request.get(queueAPI.GET_LIST_CLIENT_USERS, {
    params: {
      queueID,
      rowsPerPage,
      pageno,
    },
  });

  return rdata?.data;
};

// POST request - assign user to queue
export const assignUserToQueue = async ({ queueID, queueUsers }) => {
  const rdata = await request.post(queueAPI.ASSIGN_CLIENT_USER, {
    queueID,
    queueUsers,
  });

  return rdata.data?.data;
};

// GET request - Get assigned queue users
export const getQueueUsers = async (queueID) => {
  const rdata = await request.get(queueAPI.GET_QUEUE_USERS + `/${queueID}`);

  return rdata.data?.data;
};

export const getReportByQueueId = async (queueId) => {
  const rdata = await request.get(
    `${queueAPI.GET_REPORT_BY_QUEUE_ID}/${queueId}`
  );
  return { data: rdata.data?.data?.details ?? {} };
};

// GET request - Get total count of member provider disease category
export const getProviderMemberCategories = async (projectIdList) => {
  const params = new URLSearchParams();

  projectIdList.forEach((id) => {
    params.append('projectIdList', id);
  });
  const rdata = await request.get(queueAPI.GET_PROVIDER_MEMBER_CATEGORIES, {
    params,
  });
  return { data: rdata.data?.data };
};
