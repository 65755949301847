import React from 'react';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PageviewIcon from '@mui/icons-material/Pageview';
import MuiReportIcon from '@mui/icons-material/Report';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import CommentIcon from '@mui/icons-material/Comment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GetAppIcon from '@mui/icons-material/GetApp';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import EmailIcon from '@mui/icons-material/Email';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  AdminIcon,
  ChartsIcon,
  DashboardIcon,
  EncountersIcon,
  ExpandIcon,
  FileManagerIcon,
  FilterIcon,
  FolderIcon,
  LogoutIcon,
  MagnifyingGlassPlusIcon,
  MemberIcon,
  PenIcon,
  PlusIcon,
  ReportsIcon,
  SubmissionIcon,
  UploadIcon,
} from './customIcons';

export const IconList = {
  Default: <MuiReportIcon />,
  Inbox: <InboxIcon />,
  Mail: <MailIcon />,
  Dashboard: <DashboardIcon />,
  Search: <PageviewIcon />,
  Admin: <AdminIcon />,
  ProductAdmin: <ManageAccountsIcon />,
  FileUpload: <FileManagerIcon />,
  FileInsert: <InsertDriveFileIcon />,
  FileAttach: <AttachFileIcon />,
  Document: <ChartsIcon />,
  Report: <ReportsIcon />,
  Exit: <LogoutIcon />,
  Member: <MemberIcon />,
  Add: <AddIcon />,
  Edit: <EditIcon />,
  Delete: <DeleteIcon />,
  View: <VisibilityOutlinedIcon />,
  Close: <CloseIcon />,
  Cancel: <CancelIcon />,
  Filter: <FilterListIcon />,
  DownArrow: <KeyboardArrowDownIcon />,
  UpArrow: <KeyboardArrowUpIcon />,
  ForwardArrow: <ArrowForwardIosSharpIcon />,
  ForwardArrow_Small: <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />,
  Send: <SendIcon />,
  Comment: <CommentIcon />,
  Assessment: <AssessmentIcon />,
  Check: <CheckIcon />,
  CheckCircle: <CheckCircleIcon />,
  CheckList: <PlaylistAddCheckIcon />,
  ImportExport: <ImportExportIcon />,
  Download: <GetAppIcon />,
  HandTouch: <TouchAppIcon />,
  Email: <EmailIcon />,
  Hospital: <LocalHospitalIcon />,
  Info: <InfoIcon />,
  User: <AccountCircleIcon />,
  Menu: <MenuIcon />,
  Notification: <NotificationsIcon />,
  More: <MoreIcon />,
  Receipt: <ReceiptIcon />,
  Swap: <SwapHorizIcon />,
  Submission: <SubmissionIcon />,
  ExpandMore: <ExpandMoreIcon />,
  Encounters: <EncountersIcon />,
  Expand: <ExpandIcon />,
  Plus: <PlusIcon />,
  Folder: <FolderIcon />,
  Upload: <UploadIcon />,
  Filter: <FilterIcon />,
  Pen: <PenIcon />,
  MagnifyingGlassPlus: <MagnifyingGlassPlusIcon />,
  Cancel: <CancelIcon />,
};
