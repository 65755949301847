import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    boxShadow: '0px 0px 15px 0px #e8e8e8',
    borderRadius: '15px',
    padding: '12px',
    maxWidth: '250px',
    minHeight: '100px',
    transition: 'all 150ms ease',
    '&.expanded': {
      maxWidth: '350px',
    },
    '& > div:first-child': {
      padding: '4px 16px',
    },
  },
  icon: {
    '& svg': {
      width: '22px',
      height: '22px',
    },
  },
  iicon: {
    position: 'absolute',
    top: '10px',
    right: '12px',
    backgroundColor: theme.palette.secondary.grey,
    color: theme.palette.primary.black,
    fontWeight: 'bolder',
    height: '22px',
    width: '22px',
    fontSize: '16px',
    transition: 'all 200ms linear',
    padding: '2px',
    fontStyle: 'italic',
    '&:hover': {
      backgroundColor: theme.palette.secondary.grey,
      transform: 'scale(1.3)',
    },
  },
  count: { fontWeight: 'bold' },
  label: { marginBottom: '8px' },
  infoContainer: {
    maxHeight: '0px',
    padding: '0px 12px',
    overflow: 'hidden',
    transition: 'all 200ms ease',
    '&.expanded': {
      maxHeight: '1000px',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    fontSize: '14px',
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '4px',
    padding: '2px 6px',
    borderRadius: '4px',
    '& span:first-child': {
      fontWeight: 'bold',
      opacity: 0.6,
    },
    '& span': {
      '&.wide': {
        fontSize: '10px',
      },
    },
  },
  loader: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
