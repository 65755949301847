import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import DiagnoseList from './diagnoseList';
import DiagnoseForm from './diagnoseForm';
import { useEffect, useState } from 'react';
import { chartsAPI } from 'api';
import Notification from 'components/notification';

const { default: useStyles } = require('./style');

const DiagnoseBox = ({
  coordinates,
  setCoordinates,
  onDiagnoseClick,
  selectedDiagnose,
  setSelectedDiagnose,
  diagnoseFilter,
  setDiagnoseFilter,
  chartId,
  projectId,
  readonly,
  setAlert,
  submissionStatus,
  hccVersion,
  yearOfService,
  setCurrentScreen,
  rerender,
  fullDiagnoseList = [],
}) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  useEffect(() => {
    setIsSubmitEnabled(
      fullDiagnoseList?.every((word) =>
        word?.Status?.trim()?.toLowerCase() === 'accepted' ||
        word?.Status?.trim()?.toLowerCase() === 'rejected'
          ? true
          : false
      ) && Boolean(fullDiagnoseList?.length)
    );
  }, [coordinates]);

  const classes = useStyles();

  const handleSubmitConfirm = () => {
    setOpenConfirmDialog(true);
  };

  const handleChartSubmit = async () => {
    setOpenConfirmDialog(false);
    try {
      setIsLoading(true);

      await chartsAPI.submitChart({
        chartId,
        projectId,
        status: submissionStatus,
        hccVersion: String(hccVersion),
        yearOfService: String(yearOfService),
      });

      setNotificationMsg('Chart submitted successfully.');
      setNotificationType('success');
      setOpenNotification(true);
      setCurrentScreen({ screen: 'view', props: '' });
      setIsLoading(false);
    } catch (e) {
      setNotificationMsg('Unexpected error, Failed to submit chart.');
      setNotificationType('error');
      setOpenNotification(true);

      setIsLoading(false);
    }
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Box
      className={`${classes.container} ${classes.diagnosisBox} ${classes.addScreen}`}
    >
      <Notification
        type={notificationType}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
      <Box>
        <Box className={classes.diagnoseContainer}>
          {!selectedDiagnose ? (
            <DiagnoseList
              coordinates={coordinates}
              onDiagnoseClick={onDiagnoseClick}
              setSelectedDiagnose={setSelectedDiagnose}
              readonly={readonly}
              setAlert={setAlert}
              diagnoseFilter={diagnoseFilter}
              setDiagnoseFilter={setDiagnoseFilter}
            />
          ) : selectedDiagnose === 'add' ||
            selectedDiagnose?.type === 'add' ||
            selectedDiagnose?.type === 'addFromChart' ? (
            <DiagnoseForm
              chartId={chartId}
              setSelectedDiagnose={setSelectedDiagnose}
              selectedDiagnose={selectedDiagnose}
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              hccVersion={hccVersion}
              yearOfService={yearOfService}
              rerender={rerender}
              addDiagnose
            />
          ) : (
            <DiagnoseForm
              selectedDiagnose={selectedDiagnose}
              setSelectedDiagnose={setSelectedDiagnose}
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              chartId={chartId}
              hccVersion={hccVersion}
              yearOfService={yearOfService}
              rerender={rerender}
            />
          )}
        </Box>
      </Box>
      {!selectedDiagnose && (
        <Button
          className={classes.submitButton}
          disabled={
            !isSubmitEnabled ||
            isloading ||
            readonly ||
            (submissionStatus !== 'coder' && submissionStatus !== 'reviewer')
          }
          onClick={handleSubmitConfirm}
        >
          {isloading && (
            <Box className={classes.submitProgressContainer}>
              <CircularProgress className={classes.submitProgress} />
            </Box>
          )}
          Submit
        </Button>
      )}
      <Dialog open={openConfirmDialog}>
        <DialogTitle>Submit this chart?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {submissionStatus === 'coder'
              ? 'Are you sure you want to submit the chart? The chart will move to "coded" phase, if you choose proceed.'
              : 'Are you sure you want to complete the review for this chart? The chart will move to the "validated" phase, if you choose proceed.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleChartSubmit}>
            Yes
          </Button>
          <Button
            className={classes.buttonSecondary}
            onClick={handleConfirmClose}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DiagnoseBox;
