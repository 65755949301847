import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  navGuideAccordion: {
    borderRadius: '10px !important',
    backgroundColor: 'rgba(193, 209, 214, 0.30)',
    color: '#fff',
    fontSize: '14px',
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
      padding: '4px 20px',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-expandIcon': {
      transition: 'none !important',
    },
    '& .MuiCollapse-root': {
      transition: 'none !important',
    },
    '&::before': {
      content: '',
      height: '0 !important',
    },
  },
  textFieldBox: {
    padding: '0',
  },
  scrollableContent: {
    fontSize: '1px',
    maxHeight: '200px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  titleText: {
    fontSize: '14px',
    marginBottom: '7px',
    cursor: 'pointer',
    borderRadius: '2px',
    padding: '2px',
    '&:hover': {
      backgroundColor: 'rgba(223, 229, 231, 0.3)',
    },
  },
}));

export default useStyles;
