import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const FilteredList = ({ filters, setFilters }) => {
  const onDelete = (fieldId) => {
    setFilters((filters) =>
      filters.filter((fitem) => fitem.fieldId !== fieldId)
    );
  };

  return (
    <Stack direction="row" spacing={1}>
      {filters.map((item) => (
        <Chip
          sx={{ mb: '10px' }}
          key={item.fieldId}
          label={
            <Box>
              <Box sx={{ fontWeight: 'bold', display: 'inline', padding: 0.5 }}>
                {item.fieldLabel}
              </Box>
              {item.selectedOperator} {item.valueLabel}
            </Box>
          }
          onDelete={() => onDelete(item.fieldId)}
        />
      ))}
    </Stack>
  );
};

export default FilteredList;
