import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  Skeleton,
} from '@mui/material';

import useStyles from './style';
import AccordionOfficeVisits from './AccordionOfficVisits';
import AccordionTitles from './AccordionTitles';
import { ExpandIcon, MagnifyingGlassIcon } from 'icons/customIcons';

const NavGuide = ({
  chartId,
  officeVisits,
  titles,
  isLoading,
  readonly,
  setAlert,
  queueRole,
}) => {
  const classes = useStyles();

  const renderSkeletons = (count) => {
    return [...Array(count)].map((_, idx) => (
      <Skeleton
        key={idx}
        className={classes.skeletonAccordion}
        variant="rectangular"
      />
    ));
  };

  return (
    <Box className={classes.navGuideContainer}>
      {!readonly && (
        <Box className={classes.titleBar}>
          <Box className={classes.bottom}>
            <Typography variant="h6" className={classes.navTitle}>
              Queue Role:{' '}
              {queueRole?.toLowerCase() === 'superadmin'
                ? 'Super Admin'
                : queueRole?.toLowerCase() === 'clientadmin'
                ? 'Client Admin'
                : queueRole?.toLowerCase()?.charAt(0)?.toUpperCase() +
                  queueRole?.toLowerCase()?.slice(1)}
            </Typography>
          </Box>
        </Box>
      )}
      <OutlinedInput
        type="text"
        placeholder="Search"
        className={classes.searchBar}
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <IconButton aria-label="search" edge="end">
              <MagnifyingGlassIcon style={{ filter: 'brightness(0%)' }} />
            </IconButton>
          </InputAdornment>
        }
      />
      <Box className={classes.accordionContainer}>
        {isLoading ? (
          <Box className={classes.loadingContainer}>{renderSkeletons(5)}</Box>
        ) : (
          <>
            <AccordionOfficeVisits
              expandIcon={
                <ExpandIcon
                  style={{
                    maxWidth: '20px',
                    maxHeight: '20px',
                    transform: 'rotate(-90deg)',
                  }}
                />
              }
              key={'OfficeVisits'}
              label="Visits"
              visits={officeVisits}
              chartId={chartId}
              readonly={readonly}
              setAlert={setAlert}
            />
            <AccordionTitles
              expandIcon={
                <ExpandIcon
                  style={{
                    maxWidth: '20px',
                    maxHeight: '20px',
                    transform: 'rotate(-90deg)',
                  }}
                />
              }
              key={'Titles'}
              label={'Section Navigation'}
              titles={titles}
              chartId={chartId}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default NavGuide;
