import { FormControlLabel, Switch } from '@mui/material';

const SwitchBox = (props) => {
  const { name, label, checked = false, onChange, ...rest } = props;
  return (
    <FormControlLabel
      control={
        <Switch
          id={name}
          name={name}
          checked={checked}
          color="primary"
          onChange={onChange}
          {...rest}
        />
      }
      label={label}
    />
  );
};

export default SwitchBox;

/*******************************
 * Example for import
 * <SwitchBox 
       name= {field.id}
       label= {field.displayName}
       checked= {controlVal[field.id]}
       onChange= {onFieldValueChange}    
  />
 */
