import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px',
    '& .MuiDialog-container': {
      width: '900px',
      position: 'relative',
      margin: '0 auto',
      '& .MuiPaper-root.MuiDialog-paper': {
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        padding: theme.spacing(1),
        scrollbarWidth: 'none',
        margin: '10px 14px',
        backgroundColor: '#F2F3F4',
        borderRadius: '4px',
        maxWidth: '100%',
      },
    },

    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      marginTop: '4px',
    },
  },
  buttonContainer: {
    padding: '1rem 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '10px',
  },
  button: {
    '&:hover': { backgroundColor: theme.palette.primary.main },
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3.447px 3.447px 0px rgba(0, 0, 0, 0.25)',
    padding: '6px 30px',
    '&:disabled': { backgroundColor: 'gray', color: 'whitesmoke' },
  },
  saveButton: {
    position: 'relative',
  },
  uploadContainer: {
    background: '#ffffff',
    border: '1px dashed black',
    borderRadius: '20px',
    padding: '0 20px',
    margin: '0 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: `${theme.spacing(3)} ${theme.spacing(6)}`,
    height: '220px',
    width: '600px',
  },
  BoldText: {
    fontWeight: '500',
    padding: '0',
    margin: 'auto',
  },
  ImgContainer: {
    height: '70px',
  },
  Dragging: {
    border: '2px dashed #20668C',
    background: '#f0f0f0',
  },
  FileButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
