import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import useStyles from './style';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonIcon } from 'components/controls';
import { menuList } from 'config/page-menu.config';
import { UserAction, UserSelector } from 'state/user';
import ChangePassword from '../appbar/changepassw';
import { Box, FormControl, InputLabel, Select } from '@mui/material';
import Button from '@mui/material/Button';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Typography } from '@mui/material';
import { yearOfServiceHccVersion } from 'config/constants/yearOfService&hccVersion.constant';

const AppBarLayout = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [openPasswModal, setOpenPasswModal] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [timeLeft, setTimeLeft] = useState(300);
  const [inactiveTime, setInactiveTime] = useState(0);
  const userRoles = useSelector(UserSelector.getUserRoles);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [yearOfService, setYearOfService] = useState('');
  const [healthPlanId, setHealthPlanId] = useState('');

  const [healthPlanValue, setHealthPlanValue] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem('rstate'))?.user;
    const clientId = currentUser.currentClient.clientID;

    if (currentUser) {
      const userHealthPlans = currentUser?.clients[clientId].healthPlanIds.map(
        (id, index) => ({
          value: id,
          id: index + 1,
        })
      );
      if (userHealthPlans) {
        setHealthPlanValue(userHealthPlans);
        setLoading(false);
      }
    }
  }, []);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleDropdownHPid = (event) => {
    const selectedValue = event.target.value;
    setHealthPlanId(selectedValue);
    dispatch(UserAction.changeHealthPlanId(selectedValue));
    window.location.reload(true);
  };

  const handleDropdownYOS = (event) => {
    const selectedValue = event.target.value;
    setYearOfService(selectedValue);
    dispatch(UserAction.changeYearOfService(selectedValue));
    window.location.reload(true);
  };

  const userLogout = () => {
    sessionStorage.removeItem('timeLeft');
    dispatch(UserAction.UserLogout());
    history.push('/login');
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  // not allow multiple tab logic
  useEffect(() => {
    const bc = new BroadcastChannel('single_tab');

    const messageListener = (e) => {
      const { data } = e;
      if (data === 'ping') {
        bc.postMessage('pong');
      }

      if (data === 'pong') {
        alert('Another tab is already active. You cannot open multiple tabs.');
        window.opener = null;
        window.open('about:blank', '_self');
        window.close();
      }
    };

    bc.postMessage('ping');
    bc.addEventListener('message', messageListener);

    return () => {
      bc.removeEventListener('message', messageListener);
      bc.close();
    };
  }, []);

  useEffect(() => {
    const resetInactivityTimer = () => {
      setInactiveTime(0);
      setTimeLeft(300);
    };

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    const inactivityInterval = setInterval(() => {
      setInactiveTime((prev) => prev + 1);

      if (inactiveTime >= 600) {
        const time = timeLeft - 1;
        setTimeLeft(time);
      }

      if (timeLeft <= 0) {
        userLogout();
      }
    }, 1000);

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearInterval(inactivityInterval);
    };
  }, [inactiveTime, timeLeft]);

  useEffect(() => {
    let path = location.pathname.substring(1);
    if (path === 'claim') path = 'encounters';
    if (path === '') path = 'dashboard';
    if (path === 'filemanager') path = 'fileManager';
    setSelectedMenu(path.charAt(0).toUpperCase() + path.slice(1));
  }, [location.pathname]);

  useEffect(() => {
    const storedYear = localStorage.getItem('yearOfService');
    const validYear =
      yearOfServiceHccVersion.find((item) => item.label === storedYear)
        ?.label || yearOfServiceHccVersion[0].label;

    setYearOfService(validYear);
    dispatch(UserAction.changeYearOfService(validYear));

    if (!loading) {
      const storedHealthPlan = localStorage.getItem('selectedHealthPlanId');
      const validHealthPlan =
        healthPlanValue.find((item) => item?.value === storedHealthPlan)
          ?.value || healthPlanValue[0]?.value;

      setHealthPlanId(validHealthPlan);
      dispatch(UserAction.changeHealthPlanId(validHealthPlan));
    }
  }, [loading]);

  return (
    <Box position="static" className={classes.appBarContainer}>
      <Box className={classes.imageContainer}>
        <img
          src="/HDMLogoUpdated.png"
          className={classes.logo}
          alt="Health Data Max Logo"
        />
      </Box>
      {inactiveTime >= 600 ? (
        <Box className={classes.timerContainer}>
          <Typography variant="body2">{`You will be logged out in: ${formatTime(
            timeLeft
          )}`}</Typography>
        </Box>
      ) : (
        ''
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <List
          sx={{
            '& .MuiListItemIcon-root': {
              justifyContent: 'center',
              paddingRight: theme.spacing(2),
              [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(4),
              },
            },
            '& svg': { maxWidth: '20px', height: 'auto' },
          }}
          className={classes.ListContainer}
        >
          {menuList
            .filter((x) => x.Roles.some((y) => userRoles.includes(y)))
            .map((item) => (
              <ListItem
                className={clsx(classes.listItem, {
                  [classes.selectedMenu]: selectedMenu === item.Name,
                })}
                key={item.Name}
                component={Link}
                to={item.Path}
              >
                <ListItemText
                  className={classes.listItemText}
                  primary={item.Name}
                />
              </ListItem>
            ))}
        </List>
      </Box>
      <Box className={classes.globaFilter}>
        <FormControl fullWidth className={classes.lobSelect}>
          <InputLabel
            id="healthPlanId"
            className={`${classes.dropdownLabel} ${
              healthPlanId ? classes.hiddenLabel : ''
            }`}
          >
            {loading ? 'Loading...' : 'Health Plan ID'}
          </InputLabel>
          <Select
            labelId="healthPlanId-select-label"
            id="healthPlanId"
            name="healthPlanId"
            value={healthPlanId}
            label={loading ? 'loading...' : 'Health Plan ID'}
            onChange={handleDropdownHPid}
            className={classes.dropdown}
            disablle={loading}
          >
            {healthPlanValue.map((lab) => (
              <MenuItem value={lab.value} key={lab.id}>
                {lab.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            width: '9rem',
          }}
        >
          <FormControl fullWidth className={classes.lobSelect}>
            <InputLabel
              id="yearOfService"
              className={`${classes.dropdownLabel} ${
                yearOfService ? classes.hiddenLabel : ''
              }`}
            >
              YOS
            </InputLabel>
            <Select
              labelId="yearOfService-select-label"
              id="yearOfService"
              name="yearOfService"
              value={yearOfService}
              label="Line Of Business"
              onChange={handleDropdownYOS}
              className={classes.dropdown}
            >
              {yearOfServiceHccVersion.map((lob) => (
                <MenuItem value={lob.label} key={lob.label}>
                  {lob.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.iconsContainer}>
        <Button className={classes.icon}>
          <NotificationsNoneIcon />
        </Button>
        {/* <Button className={classes.icon}>
          <SupportAgentIcon />
        </Button> */}
        <ButtonIcon
          iconName="User"
          tooltipText="User"
          edge="end"
          aria-label="account of current user"
          aria-controls={'primary-search-account-menu'}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          className={classes.icon}
        />
        <Menu
          anchorEl={anchorEl}
          id={'primary-search-account-menu'}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {}}>Support</MenuItem>
          <MenuItem onClick={() => setOpenPasswModal(true)}>
            Change Password
          </MenuItem>
          <MenuItem onClick={userLogout}>Logout</MenuItem>
        </Menu>
        <ChangePassword
          openPasswModal={openPasswModal}
          setOpenPasswModal={setOpenPasswModal}
        />
      </Box>
    </Box>
  );
};

export default AppBarLayout;
