import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    padding: '14px 10px',
    paddingLeft: 0,
    minWidth: '330px',
    height: '203px',
    overflow: 'hidden',
    transition: 'height 500ms linear',

    '&::-webkit-scrollbar': {
      width: '5px',
      scrollbarGutter: '100px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#3E9E8E',
      borderRadius: '10px',
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: '#2c8576',
    },
  },
  chevronIconButton: {
    display: 'block',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
    borderRadius: '9999px',
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: '16px',
    marginTop: '4px',
  },
  innerContainer: { margin: '18px 0', width: '100%' },
  listButton: {
    backgroundColor: '#3E9E8E',
    color: '#fff',
    fontSize: '14px',
    padding: '8px 42px',
    borderRadius: '16px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.40))',
    '&:hover': { backgroundColor: '#3e9e8e' },
  },
  addButton: {
    '&:hover': { backgroundColor: '#3e9e8e' },
    backgroundColor: '#3E9E8E',
    color: '#fff',
    padding: '4px',
    borderRadius: '9999px',
    minWidth: 'auto',
    height: '40px',
    width: '40px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // projectListHL: {
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  //   width: '5px',
  //   height: '100%',
  //   backgroundColor: 'rgba(18, 44, 51, 0.12)',
  //   filter: 'blur(1px)',
  // },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  LoadMoreContainer: {
    display: 'flex',
    margin: '10px auto',
    width: '95%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  loadMoreButton: {
    backgroundColor: '#3E9E8E',
    color: '#fff',
    fontSize: '14px',
    padding: '3px 14px',
    '&:hover': { backgroundColor: '#3e9e8e' },
    '&:disabled': { backgroundColor: 'gray', color: '#bbb' },
  },
}));

export default useStyles;
