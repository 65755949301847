const { Box, CircularProgress, Button, Typography } = require('@mui/material');
import { useEffect, useState } from 'react';

import '@react-pdf-viewer/core/lib/styles/index.css';

import '@react-pdf-viewer/highlight/lib/styles/index.css';

import useStyles from './style';
import PdfViewer from './pdfViewer';

import NavGuide from './navGuide';
import DiagnoseBox from './diagnoseBox';
import { getChartById, getRiskScores } from 'api/charts';
import { CHARTS_URL } from 'config/api-endpoints';
import { loadState } from 'state/storagestate';
import {
  getMemberDetails,
  getOfficeVisits,
  getSubmittedCodes,
  getTitles,
} from 'api/charts';
import { getRetrospectiveReview } from 'api/admin/project';
import ReadonlyAlert from './readonlyAlert';
import DetailSection from './detailsSection';

const ChartView = ({
  setCurrentScreen,
  chartId,
  projectId,
  hccVersion,
  yearOfService,
  submissionStatus,
  queueId = '',
  projectTitle = '',
  queueTitle = '',
  queueRole = '',
  readonly = false,
  currentView,
}) => {
  const [jsonData, setJsonData] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [focus, setFocus] = useState({});
  const [selectedDiagnose, setSelectedDiagnose] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(true);
  const [readonlyAlert, setReadonlyAlert] = useState(false);
  const [changeSubmitCodes, setChangeSubmitCodes] = useState(false);
  const [loadingSubmitCodes, setLoadingSubmitCodes] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const [retrospectiveReview, setRetrospectiveReview] = useState([]);
  const [officeVisits, setOfficeVisits] = useState([]);
  const [submittedCodes, setSubmittedCodes] = useState([]);
  const [visitCoordinates, setVisitCoordinates] = useState([]);
  const [titles, setTitles] = useState([]);
  const [titleCoordinates, setTitleCoordinates] = useState([]);
  const [diagnoseFilter, setDiagnoseFilter] = useState({
    accepted: false,
    rejected: false,
    inferred: false,
    all: true,
  });
  const [riskScores, setRiskScores] = useState({
    acceptedScore: 0,
    outstandingScore: 0,
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;
  }

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await getChartById(chartId);
        await setJsonData(res?.data);
      } catch (e) {
        setIsFound(false);
      }
    })();
  }, []);

  useEffect(() => {
    setCoordinates([]);
    setAttributes([]);
    handleHighlightGeneration();
  }, [jsonData, diagnoseFilter]);

  useEffect(() => {
    handleVisitHighlightGeneration();
    handleTitleHighlightGeneration();
  }, [officeVisits, titles]);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        setIsLoading(true);
        const res = await getMemberDetails(chartId, projectId);

        const contentArray = Object.keys(res?.data)?.map((key) => ({
          key: key,
          text: key === 'DOB' ? formatDate(res?.data[key]) : res?.data[key],
        }));

        setMemberDetails(contentArray);
      } catch (error) {
        console.error('Error fetching member details:', error);
      }
    };

    fetchMemberDetails();
  }, [chartId]);

  useEffect(() => {
    const fetchRetrospectiveReview = async () => {
      try {
        setIsLoading(true);
        const res = await getRetrospectiveReview(chartId, projectId);

        const contentArray = Object.keys(res?.data)?.map((key) => ({
          key: key,
          text:
            key.includes('Date') && !key.includes('CMS')
              ? formatDate(res?.data[key])
              : res?.data[key],
        }));

        await setRetrospectiveReview(contentArray);
      } catch (error) {
        console.error('Error fetching Retrospective review:', error);
      }
    };

    fetchRetrospectiveReview();
  }, [chartId]);

  useEffect(() => {
    const fetchSubmittedCodes = async () => {
      try {
        // setIsLoading(true);
        setLoadingSubmitCodes(true);
        const res = await getSubmittedCodes({
          chartId,
          projectId,
          queueId: queueId?.length ? queueId : null,
        });
        setSubmittedCodes(res?.data);
        setLoadingSubmitCodes(false);
      } catch (error) {
        setLoadingSubmitCodes(false);
        console.error('Error fetching office visits:', error);
      }
    };

    fetchSubmittedCodes();
  }, [chartId, changeSubmitCodes]);

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        setIsLoading(true);
        const res = await getTitles(chartId);
        setTitles(res?.data?.titles);
      } catch (error) {}
    };
    fetchTitles();
  }, [chartId]);

  useEffect(() => {
    const fetchOfficeVisits = async () => {
      try {
        setIsLoading(true);
        const res = await getOfficeVisits(chartId);

        await setOfficeVisits(res?.data[0]?.visitDetail);
      } catch (error) {
        console.error('Error fetching office visits:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfficeVisits();
  }, [chartId]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getRiskScores({ chartId, projectId });
        setRiskScores({
          acceptedScore: res?.data[0]?.riskScoreCaptured,
          outstandingScore: res?.data[0]?.riskScoreSuggested,
        });
      } catch (error) {
        console.error({ error });
      }
    })();
  }, [chartId]);

  const userData = loadState();

  const handleVisitHighlightGeneration = () => {
    if (officeVisits) {
      officeVisits?.forEach((word) => {
        const _word = {
          id: word?.visitId,
          visit: word?.visit,
          bounds: [],
        };

        _word.bounds = word?.boundingBox?.map((bounds) => {
          const width = ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width;
          const height = ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height;

          if (!isNaN(width) && !isNaN(height)) {
            return {
              pageIndex: bounds?.pageNumber - 1,
              top: (bounds?.y0 * 100) / bounds?.height,
              left: (bounds?.x0 * 100) / bounds?.width,
              width: width,
              height: height,
            };
          }

          return null;
        });

        const validBounds = _word?.bounds?.filter(Boolean);
        if (validBounds?.length > 0) {
          _word.bounds = validBounds;
          setVisitCoordinates((prev) => [...prev, _word]);
        }
      });
    }
  };

  const handleTitleHighlightGeneration = (filter = '') => {
    if (titles) {
      titles?.forEach((word) => {
        const _word = {
          id: word?.elementId,
          text: word?.text,
          bounds: [],
        };

        const bounds = word?.boundingBox;
        if (bounds) {
          _word.bounds = [
            {
              pageIndex: bounds.pageNumber - 1,
              top: (bounds?.y0 * 100) / bounds?.height,
              left: (bounds?.x0 * 100) / bounds?.width,
              width: ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width,
              height: ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height,
            },
          ];
        }

        setTitleCoordinates((prev) => [...prev, _word]);
      });
    }
  };

  const handleHighlightGeneration = (filter = '') => {
    if (jsonData) {
      const filteredData = diagnoseFilter?.all
        ? jsonData
        : jsonData?.filter(
            (item) => diagnoseFilter[item?.Status.toLowerCase().trim()]
          );
      filteredData?.forEach((word) => {
        if (filter === '' || word?.text === filter) {
          const _word = {
            id: word?.dxCodeCaptured,
            data: word,
            bounds: [],
            diagnoseId: word?.Id,
          };
          setFocus((i) => ({ [word?.dxCodeCaptured]: false, ...i }));

          _word.bounds = word?.boundingBox?.map((bounds) => ({
            pageIndex: bounds?.pageNumber - 1,
            top: (bounds?.y0 * 100) / bounds?.height,
            left: (bounds?.x0 * 100) / bounds?.width,
            width: ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width,
            height: ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height,
          }));

          setCoordinates((curr) => [...curr, _word]);

          word?.attributes?.forEach((attribute, index) => {
            const _attribute = {
              id: `${word?.dxCodeCaptured}Attribute${index}`,
              data: attribute,
              bounds: [],
            };

            _attribute.bounds = attribute?.boundingBox?.map((bounds) => ({
              pageIndex: bounds?.pageNumber - 1,
              top: (bounds?.y0 * 100) / bounds?.height,
              left: (bounds?.x0 * 100) / bounds?.width,
              width: ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width,
              height: ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height,
            }));

            setAttributes((curr) => [...curr, _attribute]);
          });
        }
      });
    }
  };

  const handleDiagnoseClick = (e, word) => {
    handleFocusSet(e?.currentTarget?.id?.replace('diagnos', ''));

    document
      .getElementById(e?.currentTarget?.id?.replace('diagnos', 'highlight1'))
      ?.focus();
    document.getElementById(e?.currentTarget?.id)?.focus();

    if (readonly) setReadonlyAlert(true);
    else setSelectedDiagnose(word);
  };

  const handleHighlightClick = (e, highlight) => {
    handleFocusSet(e?.target?.id?.replace(/highlight\d*$/, ''));

    document
      .getElementById(e?.target?.id?.replace(/highlight\d*$/, 'diagnos'))
      ?.focus();

    if (selectedDiagnose) setSelectedDiagnose(highlight);
  };

  const handleFocusSet = (elementId) => {
    const ids = coordinates
      ?.filter((coordinate) => coordinate?.id === elementId && coordinate)
      ?.map((coordinate) => coordinate?.id);

    setFocus((prevFocus) => ({
      ...Object.keys(prevFocus)?.reduce(
        (acc, key) => ({ ...acc, [key]: key === ids[0] }),
        {}
      ),
    }));
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <DetailSection
          queueName={queueTitle}
          projectName={projectTitle}
          memberDetails={memberDetails}
          retrospectiveReview={retrospectiveReview}
          setCurrentScreen={setCurrentScreen}
          jsonData={jsonData}
          submittedCodes={submittedCodes}
          riskScores={riskScores}
          currentView={currentView}
          submissionStatus={submissionStatus}
          loadingSubmitCodes={loadingSubmitCodes}
        />
      )}
      <Box className={classes.container}>
        {isLoading ? (
          <Box className={classes?.loadingSpinnerContainer}>
            <CircularProgress />
          </Box>
        ) : isFound ? (
          <>
            <NavGuide
              chartId={chartId}
              projectTitle={projectTitle}
              officeVisits={officeVisits}
              titles={titles}
              isLoading={isLoading}
              readonly={readonly}
              setAlert={setReadonlyAlert}
              queueRole={queueRole}
            />

            <PdfViewer
              pdfFile={`${CHARTS_URL}/pdf/${chartId}/${projectId}`}
              workerUrl={
                'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js'
              }
              coordinates={coordinates}
              visithighlights={visitCoordinates}
              titlehighlights={titleCoordinates}
              attributes={attributes}
              onHighlightClick={handleHighlightClick}
              focus={focus}
              setSelectedDiagnose={setSelectedDiagnose}
              rerender={setJsonData}
              fullDiagnoseList={jsonData}
              readonly={readonly}
              setAlert={setReadonlyAlert}
              chartId={chartId}
            />
            <DiagnoseBox
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              onDiagnoseClick={handleDiagnoseClick}
              selectedDiagnose={selectedDiagnose}
              setSelectedDiagnose={setSelectedDiagnose}
              chartId={chartId}
              queueId={queueId}
              userName={userData?.user?.username}
              companyName={userData?.user?.currentClient?.clientName}
              readonly={readonly}
              setAlert={setReadonlyAlert}
              projectId={projectId}
              submissionStatus={submissionStatus}
              hccVersion={hccVersion}
              yearOfService={yearOfService}
              setCurrentScreen={setCurrentScreen}
              rerender={setJsonData}
              diagnoseFilter={diagnoseFilter}
              setDiagnoseFilter={setDiagnoseFilter}
              fullDiagnoseList={jsonData}
              setRiskScores={setRiskScores}
              currentView={currentView}
              submittedCodes={submittedCodes}
              setChangeSubmitCodes={setChangeSubmitCodes}
            />
          </>
        ) : (
          <Box className={classes.notFoundContainer}>
            <img src="./assets/images/not-found.svg" alt="file not found" />
            <Typography variant="h6">
              {`Something unexpected has happened, it seems we can't find the file
            you're looking for.`}
            </Typography>
            <Button
              type="button"
              onClick={() => {
                setCurrentScreen((prev) => ({
                  ...prev,
                  screen: 'landing',
                  props: '',
                }));
              }}
            >
              Return to projects
            </Button>
          </Box>
        )}
        <ReadonlyAlert open={readonlyAlert} setOpen={setReadonlyAlert} />
      </Box>
    </>
  );
};

export default ChartView;
