import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  diagnos: {
    borderRadius: '10px',
    padding: '4px',
    lineHeight: '1',
    '&:hover': {
      backgroundColor: '#BFDFDA',
    },
    '&:focus': {
      backgroundColor: '#BFDFDA',
      outline: 'none',
    },
  },
  active: {
    backgroundColor: '#BFDFDA',
  },
  code: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
  },
  status: {
    width: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10%',
  },
  codeName: {
    fontSize: '12px',
  },
  codeDescription: {
    margin: 0,
    fontSize: '9px',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
