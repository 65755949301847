export const IconGallery = {
  ExpandIcon: ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={style}
    >
      <path
        d="M22.5 18.25L20.75 20L15 14.25L9.25 20L7.5 18.25L15 10.75L22.5 18.25Z"
        fill="white"
      />
    </svg>
  ),
  ReturnArrowIcon: ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      style={style}
    >
      <path
        d="M12.3353 20.7145C12.9004 20.7145 13.3076 20.299 13.3076 19.7423V16.568H13.5485C16.7727 16.568 18.8002 17.3824 20.2544 20.0996C20.5453 20.6314 20.9275 20.7145 21.2765 20.7145C21.7169 20.7145 22.1324 20.3157 22.1324 19.601C22.1324 13.4602 19.5315 9.62944 13.5485 9.62944H13.3076V6.48839C13.3076 5.93164 12.9004 5.47461 12.3187 5.47461C11.9115 5.47461 11.6373 5.64911 11.1969 6.0646L4.64059 12.1971C4.31651 12.5046 4.2168 12.812 4.2168 13.0946C4.2168 13.3688 4.32482 13.6846 4.64059 13.9837L11.1969 20.1744C11.5958 20.5483 11.9282 20.7145 12.3353 20.7145ZM11.8617 18.9279C11.8118 18.9279 11.762 18.903 11.7204 18.8532L5.79563 13.2524C5.72915 13.186 5.70423 13.1444 5.70423 13.0946C5.70423 13.0447 5.72915 12.9949 5.79563 12.9367L11.7121 7.26119C11.7537 7.22795 11.8035 7.19471 11.8534 7.19471C11.9282 7.19471 11.9697 7.24457 11.9697 7.31104V10.61C11.9697 10.8011 12.0611 10.8842 12.2522 10.8842H13.3657C19.0662 10.8842 20.7863 14.8396 20.9524 18.7618C20.9524 18.8282 20.9275 18.8532 20.886 18.8532C20.8527 18.8532 20.8361 18.8282 20.8112 18.7701C19.8306 16.6843 17.3128 15.3132 13.3657 15.3132H12.2522C12.0611 15.3132 11.9697 15.3963 11.9697 15.5875V18.8033C11.9697 18.8781 11.9282 18.9279 11.8617 18.9279Z"
        fill="white"
      />
    </svg>
  ),
  ProfileIcon: ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      style={style}
    >
      <circle cx="21" cy="21" r="21" fill="white" />
      <circle cx="21" cy="12.5996" r="6" fill="#02375F" />
      <ellipse cx="20.9998" cy="28.5" rx="13.2" ry="7.5" fill="#02375F" />
    </svg>
  ),
  InwardArrowIcon: ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      style={style}
    >
      <g opacity="0.3">
        <path
          d="M6.85059 17.0003L15.6438 25.7935L17.647 23.7904L10.8569 17.0003L17.647 10.2102L15.6438 8.20703L6.85059 17.0003ZM14.8548 17.0003L23.648 25.7935L25.6512 23.7904L18.8611 17.0003L25.6512 10.2102L23.648 8.20703L14.8548 17.0003Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  FilterIcon: ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      style={style}
    >
      <path
        d="M8.62724 15.4727H22.7438M5.49023 9.2832H25.8808M13.3328 21.6621H18.0383"
        stroke="white"
        strokeWidth="2.0153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  MagnifyingGlassIcon: ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      style={style}
    >
      <path
        d="M22.1254 21.9731L26.6651 26.3057M25.2012 14.7521C25.2012 20.3354 20.6132 24.8615 14.9536 24.8615C9.29404 24.8615 4.70605 20.3354 4.70605 14.7521C4.70605 9.16874 9.29404 4.64258 14.9536 4.64258C20.6132 4.64258 25.2012 9.16874 25.2012 14.7521Z"
        stroke="white"
        strokeWidth="2.0153"
        strokeLinecap="round"
      />
    </svg>
  ),
};
