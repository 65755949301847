import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    flex: '15%',
    display: 'block',
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    paddingBottom: '10px',
  },
  title: {
    backgroundColor: 'rgba(179, 179, 179, 0.41)',
    fontSize: '18px',
    marginBottom: '6px',
    padding: '2px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  },
  headButton: {
    minWidth: 'auto',
    lineHeight: 1,
    padding: 0,
    '&:disabled': { opacity: 0.3 },
    '& svg': { transform: 'scale(0.8)' },
  },
  innerContainer: { padding: '0 6px' },
  progress: {
    color: '#3E9E8E',
    maxWidth: '20px',
    maxHeight: '20px',
    marginLeft: '4px',
  },
}));

export default useStyles;
