export const tableFieldsMetaData = {
  fields: [
    { id: 'MedicalRecordNo', alignRight: false, label: 'MRN' },
    { id: 'Subscriber_ID', alignRight: false, label: 'Member ID' },
    { id: 'Chart_Name', alignRight: false, label: 'Name' },
    { id: 'Status', alignRight: false, label: 'Status' },

    {
      id: 'NPI',
      alignRight: false,
      label: 'NPI',
      collapse: true,
    },
    {
      id: 'CreatedAt',
      alignRight: false,
      label: 'Created At',
      collapse: true,
    },
  ],
  keyid: '_id',
  title: 'Medical Charts',
  enableCollapse: true,
  enableViewIcon: true,
  enableEditIcon: false,
  enableDeleteIcon: false,
  enableCheckBox: false,
};
