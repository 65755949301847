import { useState } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import TreeView from './treeview';
import MainContent from './maincontent';

import { StyleTreeView, StyleMainContent } from './style';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const TabPanel = ({ section }) => {
  const [selectedSectionId, setSelectedSectionId] = useState('0');

  /*
  useEffect(() => {
    const newCurrentTab = { tabId };
    newCurrentTab.sectionTree = selectedClaim[tabId]?.sectionTree;
    newCurrentTab.sectionFields = selectedClaim[tabId]?.sectionFields;
    setCurrentTab(newCurrentTab);
  }, [selectedClaim]);
*/
  return (
    <div>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <Item sx={StyleTreeView}>
          <TreeView
            sectionTree={section.sectionTree}
            setSelectedSectionId={setSelectedSectionId}
          />
        </Item>
        <Item sx={StyleMainContent}>
          <MainContent
            sectionFields={section.sectionFields}
            selectedSectionId={selectedSectionId}
          />
        </Item>
      </Stack>
    </div>
  );
};

export default TabPanel;
