import makeStyles from '@mui/styles/makeStyles';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBarContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 5,
    boxSizing: 'border-box',
    width: '100%',
    background: '#02375F',
    color: 'white',
    WebkitTextStroke: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: { padding: '30px 20px' },
    [theme.breakpoints.up(1500)]: { padding: '20px 10px' },
    [theme.breakpoints.up(1300)]: { padding: '0px 0px' },
    '& .MuiPaper-root': { maxWidth: '100%' },
    boxShadow: '0px 0px 1px #02375F',
    height: '60px',
  },
  imageContainer: {
    flexShrink: 0,
    width: '5%',
    marginRight: '4%',
    padding: '0.5rem 0.8rem',
  },
  ListContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '10px',
  },
  listItem: {
    '& .MuiTypography-root': {
      fontSize: '15px',
    },
    color: 'white',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#D3D3D3',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      bottom: '5px',
      left: '17px',
      width: '30%',
      height: '2px',
      backgroundColor: '#D3D3D3',
      transition: 'width 0.3s ease',
    },
  },
  selectedMenu: {
    backgroundColor: 'transparent',
    color: '#D3D3D3',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiListItemText-primary': {
      textDecoration: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '5px',
      left: '17px',
      width: '60%',
      height: '2px',
      backgroundColor: '#D3D3D3',
      transition: 'width 0.3s ease',
    },
  },
  iconsContainer: {
    marginLeft: 'auto',

    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,

    justifyContent: 'flex-end',
    gap: theme.spacing(0.25),
    marginRight: theme.spacing(2),
  },
  icon: {
    backgroundColor: 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#D3D3D3',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  timerContainer: {
    display: 'flex',
    position: 'absolute',
    top: '63px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '300px',
    left: '50%',
    transform: 'translateX(-50%)',
    minHeight: '50px',
    color: '#E56E76',
    textAlign: 'center',
    boxShadow: '0px 0px 1px black',
    borderRadius: '30px',
    border: '3px solid #E56E76',
    background: '#F3E6E6',
    boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  logo: {
    maxWidth: '90px',
    height: 'auto',
    display: 'block',
  },
  [theme.breakpoints.down('sm')]: {
    ListContainer: {
      width: 'auto',
      margin: '0 10px',
    },
    iconsContainer: {
      marginLeft: 'auto',
    },
  },
  hhp: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '14px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      gap: '1.5rem',
    },
  },
  sectionContainer: {
    display: 'flex',
    '& button': {
      padding: 0,
      '& svg': { color: '#fff' },
    },
  },
  sectionDesktop: {
    '& svg': { width: '45px', height: 'auto' },
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  sectionMobile: {
    '& svg': { width: '30px', height: 'auto' },
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
  menu: {
    zIndex: 6,
    boxSizing: 'border-box',
    position: 'absolute',
    overflow: 'auto',
    right: 0,
    top: '50px',
    '& .MuiPaper-root': {
      borderRadius: '12px',
      '& .MuiList-root': {
        '& .MuiMenuItem-root:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
        },
      },
    },
  },
  menuMobile: {
    zIndex: 5,
    boxSizing: 'border-box',
    overflow: 'auto',
    right: 0,
    top: '30px',
    '& .MuiPaper-root': {
      borderRadius: '12px',
      '& .MuiList-root': {
        '& .MuiMenuItem-root:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
        },
      },
    },
  },
  lobSelect: {
    '& .MuiSelect-select': {
      border: 0,
      padding: '10px',
    },
    '& fieldset': {
      border: 0,
    },
    '& svg': {
      // color: theme.palette.primary.main,
    },
    '& .Mui-error svg': { color: 'red' },
  },
  dropdownLabel: {
    '&.Mui-focused': { color: '#2E0A70' },
    // color: theme.palette.primary.main,
    fontSize: '14px',
    top: '-7px',
  },
  hiddenLabel: {
    display: 'none',
  },
  dropdown: {
    backgroundColor: '#F9FDFF',
    // color: theme.palette.primary.main,
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    height: '30px',
    padding: '2px',
    fontSize: '15px',
  },
  globaFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '230px',
    gap: '5px',
  },
  [theme.breakpoints.down(1500)]: {
    appBarContainer: {
      padding: theme.spacing(1),
      height: '55px',
    },
    hhp: {
      fontSize: '18px',
    },
    logo: {
      maxWidth: '80px',
    },
    ListContainer: {
      gap: '0px',
    },
    listItem: {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    iconsContainer: {
      marginRight: theme.spacing(1),
    },
    timerContainer: {
      minWidth: '250px',
      minHeight: '45px',
    },
    profile: {
      gap: '1rem',
    },
    dropdownLabel: {
      fontSize: '12px',
      top: '-7px',
    },
    dropdown: {
      borderRadius: '10px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      height: '28px',
    },
    globaFilter: {
      width: '205px',
      gap: '5px',
    },
  },
}));
export default useStyles;
