import axios from 'axios';
//import Auth from './auth';
// import { API_KEY } from 'config/api-endpoints';
import { authAPI } from 'config/api-routlink';

import { UserAction } from 'state/user';
import configureStore from 'state/configurestore';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

const request = axios.create(defaultOptions);

const getNewTokenFromRefreshToken = async ({ username, refreshToken }) => {
  const rdata = await request.post(authAPI.REFRESH_TOKEN, {
    username,
    refresh_token: refreshToken,
  });

  const token = rdata.data.data.id_token;
  const newRefreshToken = rdata.data.data.refresh_token;
  const store = configureStore();
  const { dispatch } = store;
  dispatch(UserAction.UserToken(token));
  dispatch(UserAction.UserRefreshToken(newRefreshToken));
  return token;
};

const userLogout = () => {
  const { dispatch } = store;
  dispatch(UserAction.UserLogout());
  sessionStorage.clear();
  // document.cookie = 'rstate=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;';
};

// API Request
request.interceptors.request.use(async (config) => {
  // config.headers['X-Api-Key'] = API_KEY;
  const serviceUrl = new URL(config.url);
  if (serviceUrl.pathname?.includes('/auth/')) {
    return config;
  }

  // No addition Header for s3 url operations
  if (
    serviceUrl.hostname?.includes('amazonaws.com') &&
    serviceUrl.hostname?.includes('.s3.')
  ) {
    return config;
  }

  const store = configureStore();

  const { dispatch } = store;

  const state = store.getState();

  if (!state.user?.token) {
    dispatch(UserAction.UserLogout());
  }

  config.headers['Authorization'] = `Bearer ${state.user?.token}`;
  config.headers['ClientID'] = `${state.user?.currentBusSubType?.clientID}`;
  config.headers['BusType'] = `${state.user?.currentBusSubType?.busType}`;
  config.headers['BusSubType'] = `${state.user?.currentBusSubType?.busSubType}`;

  if (
    !(
      serviceUrl.pathname.includes('auth') ||
      serviceUrl.pathname.includes('mfa')
    )
  ) {
    config.headers['yearOfService'] = `${state.user?.yearOfService}`;
    config.headers['healthPlanId'] = `${state.user?.selectedHealthPlanId}`;
  }

  return config;
});

// API Response
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      if (!error?.response) {
        userLogout();
      }

      const store = configureStore();
      const serviceUrl = new URL(error?.config?.url);

      //ToDo: Remove after testing

      // console.log(`URL =${serviceUrl.pathname}`);
      // console.log(`API error status=${error?.response?.status}`);
      // console.log('error.request', error?.request);
      // console.log('error.response', error?.response);
      // console.log('error.config', error?.config);

      // If refresh token fails, logout | clear session to force login again
      if (
        serviceUrl.pathname === '/auth/token/refreshtoken' &&
        error?.response?.status !== 200
      ) {
        userLogout();
      }

      // If Token expired , get new token from refresh token and request again
      if (
        !serviceUrl.pathname?.startsWith('/auth/') &&
        error?.response?.status === 401 &&
        !error.config?._retry
      ) {
        const state = store.getState();
        error.config._retry = true;
        const tokenParams = {
          username: state.user.username,
          refreshToken: state.user.refreshToken,
        };
        getNewTokenFromRefreshToken(tokenParams).then((token) => {
          error.config.headers.Authorization = `Bearer ${token}`;
          return request(error.config);
        });
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      return Promise.reject(error);
    }
  }
);
export default request;
