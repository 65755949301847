import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    flex: '27%',
    backgroundColor: theme.palette.primary.grey,
    padding: '4px 6px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    display: 'block',
    margin: '1em auto',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
    '&:disabled': {
      backgroundColor: '#aaa',
    },
  },
  submitProgress: {
    position: 'absolute',
    maxWidth: '20px',
    maxHeight: '20px',
    color: theme.palette.primary.main,
  },
  submitProgressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  profile: {
    flex: '5%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    marginBottom: '4px',
    '& svg': { width: '30px', height: 'auto' },
  },
  profileText: {
    '& h5': { fontSize: '12px', fontWeight: 600, lineHeight: 1, color: '#000' },
    '& h6': { fontSize: '10px', fontWeight: 400, lineHeight: 1, color: '#000' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '4px',
    textTransform: 'capitalize',
  },
  diagnoseContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  diagnos: {
    borderRadius: '10px',
    padding: '4px',
    lineHeight: '1',
    '&:hover': {
      backgroundColor: '#BFDFDA',
    },
    '&:focus': {
      backgroundColor: '#BFDFDA',
      outline: 'none',
    },
  },
  code: {
    fontSize: '10px',
    display: 'flex',
    '& span': {
      width: '100%',
      flex: '50%',
    },
  },
  codeName: {
    fontSize: '12px',
  },
  codeDescription: {
    margin: 0,
    fontSize: '9px',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  buttonSecondary: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
