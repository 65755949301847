import { Grid, Box, Label } from 'components/controls';

const BasicInfo = ({ fieldList }) => {
  return (
    <Box m={1}>
      <Grid container spacing={0} direction="row">
        {fieldList.map((item, index) => {
          return (
            <Grid item xs={4} key={index}>
              <Label
                label={`${item.Label}: `}
                display="inline"
                variant="subtitle1"
              />
              <Label label={item.Value} display="inline" variant="caption" />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default BasicInfo;
