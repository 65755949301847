export const initialState = ({ projectData }) => {
  if (!projectData) {
    return {
      projectName: { value: '', hasError: false, errorMsg: '' },
      projectDescription: { value: '', hasError: false, errorMsg: '' },
      projectStartDate: { value: '', hasError: false, errorMsg: '' },
      projectEndDate: { value: '', hasError: false, errorMsg: '' },
      QAPercentage: { value: '', hasError: false, errorMsg: '' },
      lineOfBusiness: { value: '', hasError: false, errorMsg: '' },
      yearOfService: { value: '', hasError: false, errorMsg: '' },
      hccVersion: { value: '', hasError: false, errorMsg: '' },
      llm: { value: '', hasError: false, errorMsg: '' },
      priority: { value: '', hasError: false, errorMsg: '' },
    };
  } else {
    return {
      projectName: {
        value: projectData?.projectName,
        hasError: false,
        errorMsg: '',
      },
      projectDescription: {
        value: projectData?.projectDescription,
        hasError: false,
        errorMsg: '',
      },
      projectStartDate: {
        value: projectData?.projectStartDate.split('T')[0],
        hasError: false,
        errorMsg: '',
      },
      projectEndDate: {
        value: projectData?.projectEndDate.split('T')[0],
        hasError: false,
        errorMsg: '',
      },
      QAPercentage: {
        value: projectData?.QAPercentage,
        hasError: false,
        errorMsg: '',
      },
      lineOfBusiness: {
        value: projectData?.lineOfBusiness,
        hasError: false,
        errorMsg: '',
      },
      yearOfService: {
        value: projectData?.yearOfService,
        hasError: false,
        errorMsg: '',
      },
      hccVersion: {
        value: projectData?.hccVersion,
        hasError: false,
        errorMsg: '',
      },
      llm: {
        value: projectData?.llm,
        hasError: false,
        errorMsg: '',
      },
      priority: {
        value: projectData?.priority,
        hasError: false,
        errorMsg: '',
      },
    };
  }
};
