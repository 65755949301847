import { updatepassw } from 'api/auth';

export const updatepassword = async ({ oldpassword, newpassword }) => {
  const options = {
    oldpassword,
    newpassword,
  };

  const data = await updatepassw(options);
  return data;
};
