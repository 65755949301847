import { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { ButtonIcon } from 'components/controls';
import CollapseRow from './collapse-row';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = ({
  row,
  rowIndex,
  metadata,
  fields,
  collapseFields,
  collapseFieldID,
  open,
  setOpen,
}) => {
  const classes = useRowStyles();

  const rowIconHandleClick = () => {
    const newOpen = { ...open };
    newOpen[rowIndex] = !newOpen[rowIndex];
    setOpen(newOpen);
  };
  return (
    <Fragment key={rowIndex}>
      <TableRow className={classes.root}>
        <TableCell>
          <ButtonIcon
            iconName={open[rowIndex] ? 'UpArrow' : 'DownArrow'}
            onClick={rowIconHandleClick}
            tooltipText={open[rowIndex] ? 'Collapse' : 'Expand'}
          />
        </TableCell>
        {fields.map((col, index) => (
          <TableCell key={index} align={col.alignRight ? 'right' : 'left'}>
            {row[col.id]}
          </TableCell>
        ))}
      </TableRow>
      <CollapseRow
        open={open[rowIndex]}
        rowIndex={rowIndex}
        row={row}
        collapseFields={collapseFields}
        collapseFieldID={collapseFieldID}
        metadata={metadata}
      />
    </Fragment>
  );
};

export default Row;
