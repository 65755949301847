import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rowItemCellContainer: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    borderBottom: '1px solid #ddd',
    width: 'auto',
  },
  LoadingPlaceholderContainer: {
    borderRadius: theme.radius,
    mb: '8px',
  },
  newOptionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '95vw',
    backgroundColor: '#F6F6F6',
    padding: '10px 20px',
    marginBottom: '10px',
  },
  optionsMainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '95vw',
    backgroundColor: '#F6F6F6',
    marginBottom: '10px',
  },
  headContainer: {
    backgroundColor: 'None',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    fontStyle: 'normal !important',

    border: 'None',
    marginBottom: '50px',
  },
  RowContainer: {
    padding: '5px',
    borderRadius: '10px',
    background: '#FFF',
    '& .MuiTableRow-root': {
      fontSize: '24px !important',
      backgroundColor: '#f5f5f5 !important',
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
    },
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '95vw',
  },

  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  optionsNameContainer: {
    fontSize: '20px',
    fontWeight: '500',
  },
  optionsButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    padding: '10px 20px',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
  },
  filterChip: {
    fontSize: '14px',
    fontWeight: '500',
    border: `1px solid #02375F !important`,
    color: '#02375F !important',
  },
  selectDropdown: {
    '& .MuiInputLabel-root': {
      top: '50%',
      transform: 'translate(14px, -50%)',
      transition: 'all 0.2s ease-in-out',
    },
    '& .MuiInputLabel-shrink': {
      top: 0,
      transform: 'translate(14px, -8px) scale(0.75)',
    },
    '& .MuiSelect-select': {
      paddingTop: '16px',
      paddingBottom: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        top: 0,
      },
    },
  },
  customScrollbar: {
    minWidth: 250,
    maxHeight: 300,
    overflowY: 'auto',
    p: 2,

    '&::-webkit-scrollbar': {
      width: '8px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#02375F',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
  },
}));
export default useStyles;
