import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  navGuideAccordion: {
    borderRadius: '10px !important',
    backgroundColor: theme.palette.secondary.grey,
    border: `1px solid ${theme.palette.primary.black}`,
    color: theme.palette.primary.black,
    fontSize: '14px',
    boxShadow: 'none',
    marginBottom: '8px',
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
      padding: '4px 20px',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-expandIcon': {
      transition: 'none !important',
    },
    '& .MuiCollapse-root': {
      transition: 'none !important',
    },
    '&::before': {
      content: '',
      height: '0 !important',
    },
  },
  navGuideAccordionSummary: {},
  navGuideAccordionDetails: {
    padding: '0px',
  },
  navGuideAccordionDetailItem: {
    padding: '2px 20px',
  },
  navGuideAccordionInner: {
    width: '100%',
    borderRadius: '5px',
    backgroundColor: theme.palette.secondary.grey,
    color: theme.palette.primary.black,
    fontSize: '14px',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.black}`,
    '&:not(:last-child)': { marginBottom: '8px' },
    '&:hover': {
      backgroundColor: theme.palette.secondary.grey,
    },
    '&.Mui-expanded': {
      backgroundColor: theme.palette.secondary.grey,
      '&:hover': {
        backgroundColor: theme.palette.secondary.grey,
      },
    },
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
      padding: '3px 2px',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '& .MuiAccordionSummary-expandIcon': {
      transition: 'none !important',
    },
    '& .MuiCollapse-root': {
      transition: 'none !important',
    },
    '&::before': {
      content: '',
      height: '0 !important',
    },
  },
  textFieldBox: {
    padding: '0',
  },
  customExpandIconWrapper: {
    transform: 'none !important',
  },
  textField: {
    padding: '2px',
    margin: '5px',
    color: theme.palette.primary.black,
    '& .MuiInputBase-input': {
      padding: '2px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.black,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2196f3',
      },
    },
  },
  underline: {
    color: theme.palette.primary.black,
    '&:before': {
      borderBottomColor: theme.palette.primary.black,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.black,
    },
  },
  textDetail: {
    borderBottom: `2px solid ${theme.palette.primary.black}`,
    padding: '2px',
    margin: '5px',
    color: theme.palette.primary.black,
  },
}));

export default useStyles;
