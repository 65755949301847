import { Select, MenuItem, FormControl } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { CaretIcon } from 'icons/customIcons';

const DropDown = ({
  autoWidth = false,
  disabled = false,
  options = [],
  className = '',
  itemClassName = '',
  name = '',
  value = '',
  onChange = () => {},
  ...props
}) => {
  const theme = useTheme();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={className}
      disabled={disabled}
      sx={{
        margin: '8px 0',
        minWidth: '260px',
      }}
    >
      <Select
        label={props.defaultLabel}
        name={name}
        id={name}
        value={value || ''}
        onChange={(e) => onChange(e)}
        autoWidth={autoWidth}
        displayEmpty
        IconComponent={(props) => (
          <CaretIcon
            {...props}
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              right: '8px',
            }}
          />
        )}
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.white,
          borderRadius: '8px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.dark,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.white,
            backgroundColor: theme.palette.primary.main,
          },
          '& .MuiSelect-select': {
            padding: '10px 40px 10px 12px',
          },
          '& .MuiSelect-icon': {
            right: '12px',
          },
        }}
      >
        <MenuItem value="" disabled>
          {props.defaultLabel}
        </MenuItem>
        {options.length > 0 ? (
          options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              className={itemClassName}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default DropDown;
