import { useContext, useMemo } from 'react';

import HorizontalTabs from 'components/horizontaltabs';

import TabPanel from './tab-panel';
import { Label } from 'components/controls';
import { viewClaimContext } from 'shared/context/claim';

const Tabs = ({ className }) => {
  const { selectedClaim, setSelectedClaim } = useContext(viewClaimContext);

  const getTabList = useMemo(() => {
    const newSelectedClaim = selectedClaim;
    const categories = [
      'Claim',
      'Member',
      'Provider',
      'Diagnosis',
      'ServiceLine',
    ];
    const initialTabList = [];
    for (const category of categories) {
      const section = {
        sectionTree: selectedClaim[category]?.sectionTree || [],
        sectionFields: selectedClaim[category]?.sectionFields || [],
      };

      // Clear SelectedClaim data once category loded for memory leakage
      delete newSelectedClaim[category];

      initialTabList.push({
        label: (
          <Label
            label={
              <>
                <Label label={category} variant="subtitle2" display="inline" />
                {selectedClaim.Overall?.errors[category] > 0 && (
                  <Label
                    label="*"
                    color="red"
                    variant="subtitle2"
                    display="inline"
                  />
                )}
              </>
            }
            variant="subtitle2"
            display="inline"
          />
        ),

        content: <TabPanel tabId={category} section={section} />,
      });
    }

    setSelectedClaim(newSelectedClaim);

    return initialTabList;
  }, [selectedClaim.claimUID]);

  //
  /*
  useEffect(() => {

    
    const initialTabList = useMemo(getTabList(), [selectedClaim.claimUID]);
    setTabList(initialTabList);
  }, [selectedClaim]);
*/
  return <HorizontalTabs tabList={getTabList} className={className} />;
};

export default Tabs;
