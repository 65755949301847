import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/styles';
import { useState } from 'react';

const Tabs = ({
  className,
  buttonClassName,
  tabItems = [],
  onTabSwitch = () => {},
  defaultSelection = null,
  ...props
}) => {
  const [tab, setTab] = useState(
    defaultSelection ? defaultSelection : tabItems[0]?.value
  );

  const theme = useTheme();

  const handleTabSwitch = (event, newValue) => {
    if (newValue !== null) {
      setTab(newValue);
      onTabSwitch(event, newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={tab}
      exclusive
      onChange={handleTabSwitch}
      className={className}
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        gap: '12px',
        mb: '12px',
      }}
    >
      {tabItems.map((item) => (
        <ToggleButton
          key={item?.value}
          value={item?.value}
          className={buttonClassName}
          sx={{
            border: `1px solid ${theme.palette.primary.main} !important`,
            borderRadius: theme.radius + ' !important',
            minWidth: '250px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            fontSize: '22px',
            p: '4px',
            transition: 'all 300ms linear',
            '&:hover': {
              boxShadow: theme.shadows.button,
              backgroundColor: theme.palette.secondary.main,
            },
            '&.Mui-selected': {
              backgroundColor: `${theme.palette.primary.main} !important`,
              color: theme.palette.primary.white,
              border: `1px solid ${theme.palette.primary.white} !important`,
              boxShadow: theme.shadows.button,
            },
          }}
        >
          {item?.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default Tabs;
