import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    background: '#dee0e3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    '& .MuiDialog-container': {
      width: '900px',
      position: 'relative',
      margin: '0 auto',
      '& .MuiPaper-root.MuiDialog-paper': {
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        padding: theme.spacing(1),
        scrollbarWidth: 'none',
        margin: '10px 14px',
        backgroundColor: '#DEE0E3',
        borderRadius: '4px',
        maxWidth: '100%',
      },
    },

    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      marginTop: '4px',
    },
  },
  closeButton: {
    '&:hover': { backgroundColor: theme.palette.primary.main },
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '20px',
    borderRadius: '8px',
    marginTop: '14px',
    boxShadow: '0px 3.447px 3.447px 0px rgba(0, 0, 0, 0.25)',
    padding: '6px 30px',
    '&:disabled': { backgroundColor: '#fff' },
  },
  headBox: {
    padding: '0',
  },
  ListContainer: {
    overflowY: 'auto',
    height: '290px',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#c0c0c0',
      border: 'none',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#666666',
      borderRadius: '10px',
    },
  },
  ListTableContainer: {
    background: '#fff',
    padding: '14px 24px 14px 24px',
    marginBottom: '1px',
    borderRadius: '5px',
  },
}));

export default useStyles;
