import {
  ADMIN_URL,
  AUTH_URL,
  CLAIM_URL,
  CHARTS_URL,
  MFA_URL,
  QUEUE_URL,
  REPORTS_URL,
} from './api-endpoints';

export const adminAPI = {
  S3_PRESIGNED_URL: ADMIN_URL + '/s3/presignedurl',
  USER_FIND: ADMIN_URL + '/user/find',
  USER_CREATE: ADMIN_URL + '/user/create',
  USER_UPDATE_BY_ADMIN: ADMIN_URL + '/user/update-by-admin',
  USER_DETAIL_BY_ID: ADMIN_URL + '/user/:username',
  USER_ASSIGN_TO_PROJECT: ADMIN_URL + '/user/assign',
  CLIENT_FIND: ADMIN_URL + '/client/find',
  PROJECT_GET_CHART_PROJECTS: ADMIN_URL + '/project/chart-projects-list',
  PROJECT_GET_CHART_PROJECT_BY_ID: ADMIN_URL + '/project/:projectid',
  PROJECT_UPSERT_CHART_PROJECT: ADMIN_URL + '/project/upsert-chart-project',
  FILES_CREATE: ADMIN_URL + '/files/create',
  FILES_FIND: ADMIN_URL + '/files/find',
  FILES_DISTINCT_VALUES: ADMIN_URL + '/files/distinctvalues',
  RETROSPECTIVE_REVIEW: ADMIN_URL + '/project/retrospectiveReview',
  GET_PROJECT_USERS: ADMIN_URL + '/getUsersByProjectId',
  GET_REPORT_BY_PROJECT_ID: ADMIN_URL + '/project-reports',
};
export const authAPI = {
  LOGIN: AUTH_URL + '/login',
  CHANGE_PASSW: AUTH_URL + '/changepassw',
  FORGOT_PASSW: AUTH_URL + '/forgotpassw',
  CONFIRM_PASSW: AUTH_URL + '/confirmpassw',
  UPDATE_PASSW: AUTH_URL + '/updatepassw',
  TOKEN_VERIFY: AUTH_URL + '/token/verify',
  REFRESH_TOKEN: AUTH_URL + '/token/refreshtoken',
  HEALTH_CHECK: AUTH_URL + '/healthcheck',
};

// export const memberAPI = {
//   search: MEMBER_URL + '/search',
// };

export const claimAPI = {
  FIND: CLAIM_URL + '/find',
  DISTINCT_VALUES: CLAIM_URL + '/distinctvalues',
  VIEW: CLAIM_URL + '/view',
  DASHBOARD_YEARLIST: CLAIM_URL + '/dashboard/yearlist',
  DASHBOARD_SUMMARY: CLAIM_URL + '/dashboard/summary',
  DASHBOARD_CLAIMCOUNT_BYSTATUS: CLAIM_URL + '/dashboard/claimcount-by-status',
  DASHBOARD_TOPERRORS_CLAIMCOUNT: CLAIM_URL + '/dashboard/top-erros-claimcount',
  MEMBER_FIND: CLAIM_URL + '/member/find',
  MEMBER_DISTINCT_VALUES: CLAIM_URL + '/member/distinctvalues',
  VIEW_MEMBER: CLAIM_URL + '/viewmember',
  SUBMISSION_REQUEST: CLAIM_URL + '/submission/request',
};

export const chartsAPI = {
  CHARTLIST_FIND: CHARTS_URL + '/getChartsByProjectId',
  AGGREGATE_DISTINCT: CHARTS_URL + '/aggregate/distinct',
  VIEW: CHARTS_URL + '/getChartById',
  EDIT: CHARTS_URL + '/edit',
  CREATE: CHARTS_URL + '/create',
  UPLOAD_FILE: CHARTS_URL + '/upload-file',
  ADD_ATTRIBUTE: CHARTS_URL + '/addAttribute',
  ACCEPT_DIAGNOSE: CHARTS_URL + '/acceptDiagnoseById',
  REJECT_DIAGNOSE: CHARTS_URL + '/rejectDiagnoseById',
  INSERT_DIAGNOSE: CHARTS_URL + '/insertDiagnoseById',
  MEMBER_DETAILS: CHARTS_URL + '/getMemberDetails',
  OFFICE_VISITS: CHARTS_URL + '/getOfficeVisits',
  UPSERT_OFFICE_VISITS: CHARTS_URL + '/upsertVisitDetailById',
  GET_SUBMITTED_CODES: CHARTS_URL + '/getSubmittedCodes',
  GET_ICD_BY_SEARCH: CHARTS_URL + '/getIcdCodes',
  GET_TITLES: CHARTS_URL + '/getTitles',
  ASSIGN_USER_SINGLE_CHART: CHARTS_URL + '/assignChart',
  SUBMIT_CHART: CHARTS_URL + '/submitChart',
  BULK_ASSIGN_CHARTS: CHARTS_URL + '/bulkAssignCharts',
  RISK_SCORES: CHARTS_URL + '/risk-score',
};

export const mfaAPI = {
  MFA_SETUP_SEND_CODE: MFA_URL + '/email/setup/generate-code',
  MFA_SETUP_VERIFY_CODE: MFA_URL + '/email/setup/validate-code',
  MFA_LOGIN_SEND_CODE: MFA_URL + '/email/login/generate-code',
  MFA_LOGIN_VERIFY_CODE: MFA_URL + '/email/login/validate-code',
  MFA_SETUP_VERIFY_COOKIE: MFA_URL + '/email/setup/verify-cookie',
  MFA_LOGIN_VERIFY_COOKIE: MFA_URL + '/email/login/verify-cookie',
};

export const queueAPI = {
  GET_MEMEBER_SEARCH: QUEUE_URL + '/getMembersBySearch',
  GET_DIESEASE_CATEGORIES_SEARCH: QUEUE_URL + '/getDiseaseCategoriesBySearch',
  GET_QUEUES: QUEUE_URL + '/list-queues',
  GET_MEMBER_SEARCH: QUEUE_URL + '/search-members',
  GET_PROVIDER_SEARCH: QUEUE_URL + '/search-providers',
  GET_DISEASE_CATEGORIES_SEARCH: QUEUE_URL + '/search-disease-categories',
  GET_CHARTS_BY_FILTER: QUEUE_URL + '/filter-charts',
  CREATE_QUEUE: QUEUE_URL + '/create-queue',
  QUEUE_NAME_VALIDATION: QUEUE_URL + '/validate-queue-name',
  GET_PROJECT_WITH_CHART_COUNT: QUEUE_URL + '/list-projects',
  GET_CHARTS_BY_QUEUEID: QUEUE_URL + '/charts-by-queueId',
  GET_LIST_QUEUE_NAME: QUEUE_URL + '/list-queue-names',
  GET_LIST_CLIENT_USERS: QUEUE_URL + '/list-client-users',
  ASSIGN_CLIENT_USER: QUEUE_URL + '/assign-client-users',
  GET_REPORT_BY_QUEUE_ID: QUEUE_URL + '/queue-reports',
  GET_QUEUE_USERS: QUEUE_URL + '/list-assigned-users',
  GET_PROVIDER_MEMBER_CATEGORIES:
    QUEUE_URL + '/statistics/providers-members-categories',
};

export const reportsAPI = {
  GET_REPORTS_TOTAL_DETAILS: REPORTS_URL + '/total-details',
  GET_REPORTS_CHART_STATUS: REPORTS_URL + '/charts/status',
  GET_REPORTS_PROJECT_MEMBER_RISKSCORE: REPORTS_URL + '/project/member',
  GET_REPORTS_TOP_HCC: REPORTS_URL + '/top/hcc',
  GET_MEMBER_REPORT: REPORTS_URL + '/member-report',
  GET_CLAIM_REPORT: REPORTS_URL + '/claim-report',
  GET_ENCOUNTER_REPORT: REPORTS_URL + '/encounter-report',
  GET_MEMBER_CHART_REPORT: REPORTS_URL + '/member-chart-report',
  GET_EXPORT_MEMBER_REPORT: REPORTS_URL + '/export-member-report',
  GET_EXPORT_CLAIM_REPORT: REPORTS_URL + '/export-claim-report',
  GET_EXPORT_ENCOUNTER_REPORT: REPORTS_URL + '/export-encounter-report',
  GET_EXPORT_MEMBER_CHART_REPORT: REPORTS_URL + '/export-member-chart-report',
};
