import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  diagnos: {
    borderRadius: '10px',
    padding: '4px',
    lineHeight: '1',
    border: '1px solid transparent',
    transition: 'all 200ms linear',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      outline: 'none',
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  deleted: {
    '&:hover': {
      backgroundColor: '#FDF1F2',
      border: `1px solid #E8727B`,
    },
  },
  code: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
  },
  status: {
    width: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10%',
  },
  codeName: {
    fontSize: '12px',
  },
  codeDescription: {
    margin: 0,
    fontSize: '9px',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
