export const baseMetadata = {
  fields: [
    { id: 'Year', alignRight: false, label: 'Year' },
    { id: 'RAF_Type', alignRight: false, label: 'RAF Type' },
    { id: 'Medicaid_Status', alignRight: false, label: 'Medicaid Status' },
    {
      id: 'Medicaid_Dual_Status',
      alignRight: false,
      label: 'Medicaid Dual Status',
    },
    { id: 'Orec', alignRight: false, label: 'Orec' },
    { id: 'Last_Payment_Date', alignRight: false, label: 'Last Payment Date' },
  ],
};

export const displayFields = [
  // {
  //     label: 'Demographics Score',
  //     value: 1.2
  // }
];
