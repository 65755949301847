export const yearOfServiceHccVersion = [
  { label: '2025', value: ['HCC_v22', 'HCC_v24', 'HCC_v28'] },
  { label: '2024', value: ['HCC_v22', 'HCC_v24', 'HCC_v28'] },
  { label: '2023', value: ['HCC_v22', 'HCC_v24'] },
  { label: '2022', value: ['HCC_v22', 'HCC_v24'] },
  { label: '2021', value: ['HCC_v22', 'HCC_v24'] },
  { label: '2020', value: ['HCC_v22', 'HCC_v24'] },
  { label: '2019', value: ['HCC_v22', 'HCC_v23'] },
  { label: '2018', value: ['HCC_v22'] },
  { label: '2017', value: ['HCC_v22'] },
];
