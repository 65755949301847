const { Box, Typography, Button, Menu, MenuItem } = require('@mui/material');

const { default: DiagnoseCard } = require('../diagnoseCard');

import { useState } from 'react';
import useStyles from './style';

const DiagnoseList = ({
  coordinates,
  onDiagnoseClick,
  setSelectedDiagnose,
  diagnoseFilter,
  setDiagnoseFilter,
  readonly,
  setAlert,
}) => {
  const [openFilterMenu, setOpenFilterMenu] = useState(null);

  const open = Boolean(openFilterMenu);

  const classes = useStyles();

  const PlusIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 22 22"
      fill="none"
      style={{ borderRadius: '999px', ...style }}
    >
      <rect width="25" height="25" fill="#138471" />
      <path
        d="M10.147 17.8424V11.8556H4.16016V10.145H10.147V4.1582H11.8575V10.145H17.8444V11.8556H11.8575V17.8424H10.147Z"
        fill="#F5F5F5"
      />
    </svg>
  );

  const FilterIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      style={style}
    >
      <path
        d="M8.62724 15.4727H22.7438M5.49023 9.2832H25.8808M13.3328 21.6621H18.0383"
        stroke="black"
        strokeWidth="2.0153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const AcceptedIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="24 / Tick">
          <path
            id="Shape"
            d="M9.54961 17.9996L3.84961 12.2996L5.27461 10.8746L9.54961 15.1496L18.7246 5.97461L20.1496 7.39961L9.54961 17.9996Z"
            fill="#3D9F04"
          />
        </g>
      </svg>
    );
  };

  const RejectedIcon = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="20 / Cross">
          <path
            id="Union"
            d="M7.2744 18L6 16.7256L10.7256 12L6 7.2744L7.2744 6L12 10.7256L16.7256 6L18 7.2744L13.2744 12L18 16.7256L16.7256 18L12 13.2744L7.2744 18Z"
            fill="#E70A0E"
          />
        </g>
      </svg>
    );
  };

  const handlePlusButtonClick = () => {
    if (readonly) setAlert(true);
    else setSelectedDiagnose('add');
  };

  const handleFilterButtonClick = (e) => {
    setOpenFilterMenu(e.currentTarget);
  };

  const handleDiagnoseFilter = (e) => {
    setDiagnoseFilter({
      accepted: false,
      rejected: false,
      inferred: false,
      all: false,
      [e.target.id]: true,
    });
    setOpenFilterMenu(null);
  };

  const handleClose = () => {
    setOpenFilterMenu(null);
  };

  return (
    <>
      <Box className={classes.inferredContainer}>
        <Typography variant="h6" className={classes.title}>
          <span>Chart Diagnosis</span>
          <span>
            <Box sx={{ position: 'relative' }}>
              <Button
                id="filterButton"
                aria-controls={open ? 'filterMenu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                type="button"
                className={classes.headButton}
                onClick={handleFilterButtonClick}
              >
                <FilterIcon />
              </Button>
              <Menu
                id="filterMenu"
                aria-labelledby="filterButton"
                anchorEl={openFilterMenu}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                className={classes.filterMenu}
              >
                <MenuItem
                  onClick={handleDiagnoseFilter}
                  className={classes.filterMenuItem}
                  disabled={diagnoseFilter?.accepted}
                  id="accepted"
                >
                  Accepted <AcceptedIcon />
                </MenuItem>
                <MenuItem
                  onClick={handleDiagnoseFilter}
                  className={classes.filterMenuItem}
                  disabled={diagnoseFilter?.rejected}
                  id="rejected"
                >
                  Rejected <RejectedIcon />
                </MenuItem>
                <MenuItem
                  onClick={handleDiagnoseFilter}
                  className={classes.filterMenuItem}
                  disabled={diagnoseFilter?.inferred}
                  id="inferred"
                >
                  Inferred
                </MenuItem>
                <MenuItem
                  onClick={handleDiagnoseFilter}
                  className={classes.filterMenuItem}
                  disabled={diagnoseFilter?.all}
                  id="all"
                >
                  All
                </MenuItem>
              </Menu>
            </Box>
            <Button
              type="button"
              className={classes.headButton}
              onClick={handlePlusButtonClick}
            >
              <PlusIcon />
            </Button>
          </span>
        </Typography>
        <Box className={classes.inferredDiagnosis}>
          {coordinates?.length ? (
            coordinates.map((word, index) => (
              <DiagnoseCard
                key={word?.id + index}
                id={word?.id + 'diagnos'}
                tabIndex={index + 1}
                onClick={(e) => {
                  onDiagnoseClick(e, word);
                }}
                code={word?.data?.dxCodeCaptured}
                description={word?.data?.dxDescription}
                status={word?.data?.Status ?? null}
                encounterDate={word?.data?.encounterDate ?? null}
                hcc={
                  word?.data?.hccCaptured === null ||
                  word?.data?.hccCaptured?.trim() === ''
                    ? 'N/A'
                    : word?.data?.hccCaptured
                }
              />
            ))
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box className={classes.capturedContainer}>
        <Typography variant="h4">Captured Diagnosis</Typography>
        <Box className={classes.capturedDiagnosis}></Box>
      </Box>
    </>
  );
};

export default DiagnoseList;
