import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBarLayout from './appbar';
import { useTheme } from '@mui/material';
const MainLayout = (props) => {
  //const classes = useStyles();
  const { children } = props;
  const [handleOpen, setHandleOpen] = React.useState(false);
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', maxWidth: '100%' }}>
      {/* <CssBaseline /> */}
      <Box sx={{ width: '100%' }}>
        <AppBarLayout handle={[handleOpen, setHandleOpen]} />
        <Box>
          {/* <DrawerHeader /> */}
          {children}
        </Box>
      </Box>
    </Box>
  );
};
/*
 <SideNavBar open={handleOpen} setOpen={setHandleOpen}/>    
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <DrawerHeader />
    {children}
    </Box>
*/

// const MainLayout = (props) => {
//   const classes = useStyles();
//   const { children } = props;
//   const [handleOpen, setHandleOpen] = React.useState(false);

//   return (
//     <div className={classes.root}>
//       <CssBaseline />
//       <AppBarLayout handle={[handleOpen, setHandleOpen]} />
//       <SideNavBar handle={[handleOpen, setHandleOpen]} />
//       <main
//         className={clsx(classes.content, {
//           [classes.contentShift]: handleOpen,
//         })}
//       >
//         {children}
//         <Footer />
//       </main>
//     </div>
//   );
// };
MainLayout.prototype = {
  children: PropTypes.node.isRequired,
};
export default MainLayout;
