import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  Dashboard: {
    padding: '10px 20px 20px 20px',
    height: 'calc(100vh - 100px)',
    width: '100%',
  },
  greeting: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '10px',
    margin: '0px 10px 10px 10px',
  },
  box: {
    backgroundColor: '#F6F6F6',
    padding: '10px 20px',
    borderRadius: '10px',
  },
  gridContainer: {
    display: 'grid',
    '@media (min-width: 1300px)': {
      gridTemplateRows: 'repeat(3, 200px)',
    },
    '@media (max-width: 1300px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '2rem',
    },
  },
  column: {
    display: 'grid',
    '@media (min-width: 1300px)': {
      // gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateColumns: '1fr 0.9fr 0.9fr 1.2fr',
    },
    '@media (max-width: 1300px)': {
      gridTemplateRows: 'repeat(4, 200px)',
      gap: '4rem',
    },
  },
  item: {
    flex: 1,
    minHeight: '150px',
    minWidth: '150px',
    maxWidth: '25vw',
    padding: '0 0 0 10px',
    // margin: '10px 0px',
    '@media (max-width: 1300px)': {
      minHeight: '300px',
    },
  },
}));

export default useStyles;
