import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#DEE0E3',
    padding: '18px 15px',
    minHeight: '90vh',
  },
  backButton: {
    color: '#000',
    gap: '4px',
    fontSize: '18px',
    padding: 0,
    marginBottom: '30px',
    '& svg': { width: '30px' },
  },
  headerBar: {
    width: '100%',
    padding: '12px 36px 12px 18px',
    borderRadius: '8px',
    backgroundColor: '#F6F6F6',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '28px',
  },
  headerLabel: {
    fontSize: '18px',
  },
  totalUsers: {
    backgroundColor: '#D9D9D9',
    padding: '4px 8px',
    fontWeight: '600',
    borderRadius: '8px',
  },
  saveButtonContainer: { position: 'relative' },
  saveSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    translate: '-50% -50%',
    maxHeight: '20px',
    maxWidth: '20px',
    color: '#fff',
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '16px',
    padding: '4px 12px',
    '&:hover': { backgroundColor: theme.palette.primary.main },
    '&:disabled': { backgroundColor: 'gray' },
  },
  usersContainer: {
    marginTop: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
    // maxHeight: '300px',
    // overflowY: 'scroll',
    // '&::-webkit-scrollbar': {
    //   width: '5px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   boxShadow: 'inset 0 0 5px grey',
    //   borderRadius: '10px',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   background: theme.palette.primary.main,
    //   borderRadius: '10px',
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   background: theme.palette.primary.main,
    // },
  },
  loadingSpinner: {
    width: '100%',
    height: '20vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noDataContainer: {
    '& img': { width: '100%', height: 'auto' },
    '& h6': {
      color: '#fff',
      fontWeight: 700,
      textStroke: '1px ' + theme.palette.primary.main,
    },
  },
  loadMoreButtonContainer: {
    margin: '20px auto',
    width: '100%',
    display: 'flex',
    alighnItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    '& button': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '&:hover': { backgroundColor: theme.palette.primary.main },
      '&:disabled': { backgroundColor: 'gray' },
    },
  },
  searchBarContainer: {
    minWidth: '25vw',
  },
  projectName: {
    '& span:last-child': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '20px',
    },
  },
}));
export default useStyles;
