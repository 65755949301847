import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '30px',
    paddingBottom: 0,
  },
  dataRowContainer: {
    display: 'flex',
    justifyContent: 'stretch',
    overflowX: 'auto',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '5px',
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: theme.palette.secondary.main,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.hover,
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  projects: {
    display: 'flex',
    alignItems: 'center',
  },
  topButtons: {
    backgroundColor: theme.palette.primary.main,
    padding: '12px',
    marginLeft: '16px',
    '& svg': { width: '16px', height: '16px' },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.grey,
    },
  },
  tableContainer: {},
}));

export default useStyles;
