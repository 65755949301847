import { Box, Typography } from '@mui/material';

import MemberProvider from 'shared/context/member';

// import Filter from './components/filter';
import Table from './components/table';

import WithHOC from './hoc';
// import Actions from './actions';
import FilterAction from './components/filter-action';
import ViewMemberModal from 'shared/components/viewmember-modal';

const MainComponent = (props) => {
  return (
    <Box sx={{ padding: '25px', marginTop: '10px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        <Typography color="primary" variant="h5">
          {/* Member */}
        </Typography>
        <FilterAction {...props} />
      </Box>
      <Table {...props} />
      <ViewMemberModal {...props} />
      {/* <Grid container>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid> */}
    </Box>
  );
};

const MainComponentWithHOC = WithHOC(MainComponent);

const Member = () => {
  return (
    <MemberProvider>
      <MainComponentWithHOC />
    </MemberProvider>
  );
};

export default Member;
