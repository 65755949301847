import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(() => ({
  table: {
    minWidth: 400,
    maxWidth: '98%',
  },
  '.MuiTableCell-head': {
    fontWeight: 'bold',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 10,
    width: 1,
  },
  collapseTableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      '& .MuiIconButton-root': {
        color: '#000',
      },
    },
  },
  tableHeader: {
    backgroundColor: 'rgba(67, 110, 113, 0.50)',
  },
  muiPaper: {
    boxShadow: 'none',
    '& .MuiToolbar-root': {
      '&:first-child': {
        backgroundColor: '#eee9f7',
        minHeight: 'auto',
        marginBottom: '20px',
        '& .MuiTypography-root': {
          fontWeight: 'bold',
        },
      },
      '&:last-child': {
        marginTop: '20px',
        minHeight: 'auto',
        background: 'rgba(62, 124, 158, 0.2)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
      },
    },
  },
}));
export default useStyles;
