import MuiModal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';

import useStyles from './style';

const Modal = ({ openModal, setOpenModal, classNamePaper, children }) => {
  const classes = useStyles();
  const closeModalClick = () => setOpenModal(false);

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={closeModalClick}
      closeAfterTransition
      //disableBackdropClick
      // BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <div className={classNamePaper || classes.modalPaper}>
          <div className={classes.modalContent}>{children}</div>
        </div>
      </Fade>
    </MuiModal>
  );
};

Modal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  children: PropTypes.any,
};
export default Modal;
/*****************
// Close icon on right corner
  <div className={classes.closeIcon}>
        <ButtonIcon 
        iconName="Close"
        onClick={closeModalClick}
        tooltipText="Close"
    />

   
            </div>
*/
