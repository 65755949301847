import { useContext } from 'react';
import { claimContext, viewClaimContext } from 'shared/context/claim';

const WithHOC = (WrapperComponent) => {
  const HOC = (props) => {
    const context = useContext(claimContext);
    const viewContext = useContext(viewClaimContext);
    return (
      <WrapperComponent
        {...props}
        hocContext={context}
        hocContextViewClaim={viewContext}
      />
    );
  };

  return HOC;
};

export default WithHOC;
