import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  Skeleton,
} from '@mui/material';

import { IconGallery } from '../iconGallery';
import useStyles from './style';
import GuideAccordion from './guideAccordion';
import AccordionOfficeVisits from './AccordionOfficVisits';
import AccordionTitles from './AccordionTitles';
import { useState } from 'react';
import SubmittedCodesDialogue from './submittedCodesDialogue';

const NavGuide = ({
  setCurrentScreen,
  chartId,
  projectTitle = 'NavigationNavigation Guide',
  memberDetails,
  retrospectiveReview,
  officeVisits,
  submittedCode,
  titles,
  isLoading,
  readonly,
  setAlert,
}) => {
  const classes = useStyles();
  const [isPrevSubmittedDialogOpen, setIsPrevSubmittedDialogOpen] =
    useState(false);

  const handleBackButtonClick = () => {
    setCurrentScreen({ screen: 'view', props: '' });
  };

  const handleDialogOpen = () => {
    setIsPrevSubmittedDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsPrevSubmittedDialogOpen(false);
  };

  const renderSkeletons = (count) => {
    return [...Array(count)].map((_, idx) => (
      <Skeleton
        key={idx}
        className={classes.skeletonAccordion}
        variant="rectangular"
      />
    ));
  };

  return (
    <Box className={classes.navGuideContainer}>
      <Box className={classes.titleBar}>
        <Box className={classes.top}>
          <img src="/logo.png" alt="hdm-logo" className={classes.logo} />
          <IconButton
            type="button"
            onClick={handleBackButtonClick}
            sx={{
              '&:hover': { transform: 'scale(1.4)' },
              transform: 'scale(1.2)',
              transition: 'all 200ms ease-in-out',
              height: '38px',
              width: '38px',
              padding: 0,
              mr: '14px',
            }}
          >
            <IconGallery.ReturnArrowIcon />
          </IconButton>
        </Box>
        <Box className={classes.bottom}>
          <Typography variant="h6" className={classes.navTitle}>
            {projectTitle}
          </Typography>
        </Box>
      </Box>
      <OutlinedInput
        type="text"
        placeholder="Search"
        className={classes.searchBar}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="search" edge="end">
              <IconGallery.MagnifyingGlassIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Box className={classes.accordionContainer}>
        {isLoading ? (
          <Box className={classes.loadingContainer}>{renderSkeletons(5)}</Box>
        ) : (
          <>
            <Button
              type="button"
              variant="contained"
              className={classes.submittedCodesButton}
              onClick={handleDialogOpen}
            >
              Previously Submitted Codes
            </Button>
            <GuideAccordion
              expandIcon={<IconGallery.ExpandIcon />}
              key={'MemberDetails'}
              label={'Member Details'}
              content={memberDetails}
            />
            <GuideAccordion
              expandIcon={<IconGallery.ExpandIcon />}
              key={'RetrospectiveReview'}
              label={'Retrospective Review'}
              content={retrospectiveReview}
            />
            <AccordionOfficeVisits
              expandIcon={<IconGallery.ExpandIcon />}
              key={'OfficeVisits'}
              label={'Office Visits'}
              visits={officeVisits}
              chartId={chartId}
              readonly={readonly}
              setAlert={setAlert}
            />
            <AccordionTitles
              expandIcon={<IconGallery.ExpandIcon />}
              key={'Titles'}
              label={'Section Navigation'}
              titles={titles}
              chartId={chartId}
            />
          </>
        )}
        <SubmittedCodesDialogue
          open={isPrevSubmittedDialogOpen}
          onClose={handleDialogClose}
          submittedCode={submittedCode}
        />
      </Box>
    </Box>
  );
};

export default NavGuide;
