import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiDialog-paper': {
      borderRadius: '10px',
      background: '#DEE0E3',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
    },
  },
  title: { textAlign: 'center', fontWeight: 700, paddingBottom: '4px' },
  description: {},
  contentContainer: {
    paddingBottom: '8px',
    overflow: 'initial',
  },
  selecteionIndicatorContainer: {
    border: '1px solid ' + theme.palette.primary.main,
    margin: '6px 0',
    borderRadius: '8px',
    backgroundColor: '#f2f2f2',
    overflow: 'hidden',
    '& h6': {
      textAlign: 'center',
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      fontSize: '14px',
    },
    '& p': {
      padding: '2px 8px',
      margin: 0,
      fontSize: '14px',
    },
  },
  listContainer: {
    borderRadius: '4px',
    maxHeight: '200px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '#879EA066',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#879EA0',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#587173',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  buttons: {
    color: '#fff',
    background: theme.palette.primary.main,
    borderRadius: '6px',
    boxShadow: '0px 2.897px 2.897px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: '#fff',
      background: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: '#aaa',
    },
  },
  saveButton: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    maxHeight: '24px',
    maxWidth: '24px',
  },
}));

export default useStyles;
