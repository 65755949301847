import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Box, Typography } from '@mui/material';

const HorizontalBar = ({ data, title }) => {
  const svgRef = useRef();

  const BAR_HEIGHT = 25;
  const CHART_WIDTH = 350;
  const MARGIN = { top: 20, right: 30, bottom: 30, left: 60 };

  const getChartHeight = () => {
    const numBars = data?.length;
    return numBars * BAR_HEIGHT + MARGIN.top + MARGIN.bottom;
  };

  useEffect(() => {
    const MARGIN = { top: 20, right: 30, bottom: 20, left: 60 };
    const width = CHART_WIDTH - MARGIN.left - MARGIN.right - 20;

    // X-axis logic
    const x = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([0, width]);

    const xAxis = d3.axisBottom(x);
  }, [data]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const MARGIN = { top: 20, right: 30, bottom: 20, left: 130 };
    const width = CHART_WIDTH - MARGIN.left - MARGIN.right - 10;
    const height = getChartHeight() - MARGIN.top - MARGIN.bottom;

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([0, width]);

    const y = d3
      .scaleBand()
      .domain(data.map((d) => d.name))
      .range([0, height])
      .padding(0.2);

    svg.selectAll('*').remove();

    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${MARGIN.left},${MARGIN.top})`);

    chartGroup
      .selectAll('.bar1')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar1')
      .attr('x', 0)
      .attr('y', (d) => y(d.name))
      .attr('width', (d) => x(d.value))
      .attr('height', y.bandwidth())
      .style('fill', '#5696EA');

    chartGroup
      .selectAll('.label1')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'label1')
      .attr('x', (d) => x(d.value) + 5)
      .attr('y', (d) => y(d.name) + y.bandwidth() / 2)
      .attr('dy', '0.35em')
      .text((d) => d.value)
      .style('font-size', '14px')
      .style('fill', 'black')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    const yAxis = d3.axisLeft(y);
    chartGroup.append('g').call(yAxis);

    chartGroup.selectAll('.tick text').style('opacity', 0);

    chartGroup
      .selectAll('.tick')
      .append('foreignObject')
      .attr('x', -MARGIN.left + 10)
      .attr('y', -10)
      .attr('width', MARGIN.left - 20)
      .attr('height', 20)
      .append('xhtml:div')
      .style('font-size', '13px')
      .style('overflow', 'hidden')
      .style('white-space', 'nowrap')
      .style('text-overflow', 'ellipsis')
      .style('width', '100%')
      .text((d) => d);

    chartGroup.select('.domain').style('display', 'none');

    const xAxis = d3.axisBottom(x);

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'rgba(0, 0, 0, 0.7)')
      .style('color', 'white')
      .style('padding', '5px')
      .style('border-radius', '5px')
      .style('font-size', '14px');

    chartGroup
      .selectAll('.bar1, .bar2')
      .on('mouseover', function (event, d) {
        const barClass = d3.select(this).attr('class');
        const labelClass = barClass === 'bar1' ? '.label1' : '.label2';

        d3.select(this).style('opacity', 0.8).style('cursor', 'pointer');

        tooltip
          .style('visibility', 'visible')
          .html(
            `Project: ${d.name}<br/>value: ${
              data.find((item) => item.name === d.name)?.value
            }`
          )
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 28}px`);
      })
      .on('mouseout', function () {
        d3.select(this).style('opacity', 1);
        chartGroup.selectAll('.label1, .label2').style('opacity', 0);

        tooltip.style('visibility', 'hidden');
      });
  }, [data]);

  return (
    <Box
      sx={{
        // width: '100%',
        width: `${CHART_WIDTH}px`,
        height: '170px',
        // textAlign: 'start !important',
        overflow: 'visible',
        display: 'flex-inline',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <svg
        ref={svgRef}
        // width={320}
        width={CHART_WIDTH}
        height={getChartHeight()}
      ></svg>{' '}
    </Box>
  );
};

export default HorizontalBar;
