import { memberAPI } from 'api';
import { dateFormat } from 'utils/date';

import { filterFieldsMetadata } from './metadata';

const getDistinctValues = async (field) => {
  const options = {
    field: field,
  };
  const result = await memberAPI.distinctValues(options);
  return result.data;
};

export const updateDynamicFilterFields = async (setFilterMetadata) => {
  const updatedMetadata = [];
  for await (const fitem of filterFieldsMetadata) {
    if (fitem.isDynamic) {
      const values = await getDistinctValues(fitem.fieldId);
      fitem.items = values.map((x) => ({ label: x, value: x }));
    }
    updatedMetadata.push(fitem);
  }

  setFilterMetadata(updatedMetadata);
};

const customFilterFieldsTrans = (key, value) => {
  // Add Any custom filter transform
  return {
    key,
    value,
  };
};

const filterTransform = (filter = []) => {
  const _filter = {};
  for (const f of filter) {
    if (f.value !== undefined && f.value !== null) {
      const cf = customFilterFieldsTrans(f.fieldId, f.value);
      _filter[cf.key] = cf.value;
    }
  }
  return _filter;
};

const responseDataTransform = (records = []) => {
  records = records.map((item, index) => {
    item.CreatedAt = dateFormat(item.CreatedAt);
    item.UpdatedAt = dateFormat(item.UpdatedAt);
    item.IsHistorical = item.IsHistorical ? 'Yes' : '';
    if (!item.id) {
      item.id = index;
    }
    return item;
  });
  return records;
};

export const getFilterData = async ({
  options,
  setRecords,
  recordCount,
  setRecordCount,
}) => {
  options.filter = filterTransform(options.filter);

  let rdata = {};
  rdata = await memberAPI.find(options);
  if (options.pageno === 1 && rdata.count !== recordCount) {
    setRecordCount(parseInt(rdata.count));
  }

  if (rdata.records) {
    const records = responseDataTransform(rdata.records);
    setRecords(records);
  } else {
    setRecords([]);
  }
  return rdata;
};
