import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

import { ButtonIcon } from 'components/controls';

export const RowActions = ({
  metadata,
  row,
  index,
  isItemSelected,
  handleClick,
  onClick,
  onCollapseClick,
  openCollapse,
}) => {
  return (
    <>
      {metadata.enableCollapse && (
        <TableCell key={`collapase-${index}`}>
          <ButtonIcon
            iconName={openCollapse === index ? 'UpArrow' : 'DownArrow'}
            onClick={() => onCollapseClick(index, openCollapse)}
            tooltipText={openCollapse === index ? 'Collapse' : 'Expand'}
          />
        </TableCell>
      )}
      {metadata.enableCheckBox && (
        <TableCell padding="checkbox" key={`Checkbox-${index}`}>
          <Checkbox
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row[metadata.keyid])}
            inputProps={{ 'aria-labelledby': `Checkout-${index}` }}
          />
        </TableCell>
      )}
      {metadata.enableViewIcon && (
        <TableCell padding="checkbox" key={`viewicon-${index}`}>
          <ButtonIcon
            iconName="View"
            onClick={() => onClick('view', row[metadata.keyid])}
            tooltipText="View"
          />
        </TableCell>
      )}
      {metadata.enableEditIcon && (
        <TableCell padding="checkbox">
          <ButtonIcon
            iconName="Edit"
            onClick={() => onClick('edit', row[metadata.keyid])}
            tooltipText="Edit"
          />
        </TableCell>
      )}
      {metadata.enableDeleteIcon && (
        <TableCell padding="checkbox" key={`DeleteIcon-${index}`}>
          <ButtonIcon
            iconName="Delete"
            onClick={() => onClick('delete', row[metadata.keyid])}
            tooltipText="Delete"
          />
        </TableCell>
      )}
      {metadata.enableSendIcon && (
        <TableCell padding="checkbox" key={`sendIcon-${index}`}>
          <ButtonIcon
            iconName="Email"
            onClick={() => onClick('send', row[metadata.keyid])}
            tooltipText="Send"
          />
        </TableCell>
      )}
    </>
  );
};

export const HeaderActions = ({
  metadata,
  anySelected,
  allSelected,
  onSelectAllClick,
}) => {
  return (
    <>
      {metadata.enableCollapse && (
        <TableCell padding="checkbox" key="Collapse" />
      )}
      {metadata.enableCheckBox && (
        <TableCell padding="checkbox" key="CheckBoxCell">
          <Checkbox
            key="CheckBox"
            checked={allSelected}
            indeterminate={anySelected}
            onChange={onSelectAllClick}
          />
        </TableCell>
      )}
      {metadata.enableViewIcon && (
        <TableCell padding="checkbox" key="ViewIcon" />
      )}
      {metadata.enableEditIcon && (
        <TableCell padding="checkbox" key="EditIcon" />
      )}
      {metadata.enableDeleteIcon && (
        <TableCell padding="checkbox" key="DeleteIcon" />
      )}
      {metadata.enableSendIcon && (
        <TableCell padding="checkbox" key="SendIcon" />
      )}
    </>
  );
};

/*****************
Examples:
    <RowActions
    metadata={metadata}
    row={row}
    index={index}
    isItemSelected={isItemSelected}
    handleClick={handleClick}
    onClick={onClick}
    onCollapseClick={onCollapseClick}
    openCollapse={openCollapse}
    />

    <HeaderActions
    metadata={metadata}
    checked={checked}
    onSelectAllClick={onSelectAllClick}
    />

*/
