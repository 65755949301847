import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  label: {
    color: '#044D64',
    lineHeight: 1,
    marginTop: '8px',
    fontSize: '12px',
    marginBottom: '4px',
  },
  formControl: {
    minWidth: '150px',
  },
  formField: {
    '& input': {
      padding: 0,
      fontSize: '11px',
      Width: '100%',
      paddingBottom: '2px',
    },
  },
  helperText: {},
  icdContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icdselect: {
    minHeight: 'auto',
    lineHeight: 1,
    padding: 0,
    margin: '8px 0 4px',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
