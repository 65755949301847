import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-container': {
      width: '900px',
      position: 'relative',
      margin: '0 auto',
      '& .MuiPaper-root.MuiDialog-paper': {
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        padding: theme.spacing(1),
        scrollbarWidth: 'none',
        margin: '10px 14px',
        backgroundColor: '#FCFEFE',
        borderRadius: '14px',
        maxWidth: '100%',
      },
    },

    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      marginTop: '4px',
    },
  },
  successDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '210px',
    width: '360px',
  },
  messageText: {
    color: '#1A1919',
    padding: '10px 2px 10px 2px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  buttonStyle: {
    color: '#105166',
    background: '#FCFEFE',
    border: 'none',
    borderBottom: '2px solid #105166',
    padding: '5px 10px',
    fontSize: '1rem',
    cursor: 'pointer',
    '&:active': {
      color: '#105166',
    },
    '&::after': {
      borderBottom: '2px solid #127D1C',
    },
    '&:active::after': {
      borderBottomColor: '#127D1C',
    },
  },
}));

export default useStyles;
