import { forwardRef } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Message notification in the app
 * @param {info|success|warning|error} [type="info"]
 * @param {string} message
 * @param {Boolean} openNotification
 * @param {Function} openNotification
 * @returns
 */
const Notification = ({
  type = 'info',
  message,
  openNotification,
  setOpenNotification,
  className,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={openNotification}
        autoHideDuration={5000}
        onClose={handleClose}
        className={className}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Notification;
