import {
  Box,
  Button,
  Dialog,
  Typography,
  IconButton,
  Input,
} from '@mui/material';
import useStyles from './styles';
import { useState } from 'react';
import { uploadFile } from 'api/charts';
import SuccessDialog from './successdialog';
import CloseIcon from '@mui/icons-material/Close';
import Notification from 'components/notification';

const UploadFile = ({
  open,
  onClose,
  projectID,
  projectName,
  handleFileUpload,
}) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonLabel, setButtonLabel] = useState('Browse');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [dialogType, setDialogType] = useState('success');
  const [isDragging, setIsDragging] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('info');
  const [isUploading, setIsUploading] = useState(false);

  const detailsList = [
    'Chart UID',
    'File Name',
    'File Type',
    'Member ID',
    'Gender',
    'Member Name',
    'Member DOB',
    'Number Of Pages',
    'Provider NPI',
    'Provider Address',
    'Chart Load Date',
    'Comments',
    'Diagnosis',
  ];

  const handleClose = () => {
    setSelectedFile(null);
    setButtonLabel('Browse');

    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }

    onClose(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setButtonLabel('Upload');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    const allowedExtensions = ['xlsx', 'csv'];

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      setSelectedFile(file);
      setButtonLabel('Upload');
    } else {
      // alert('Unsupported file type. Please upload only .xlsx or .csv files.');
      setSnackbarMessage(
        'Unsupported file type. Please upload only .xlsx or .csv files.'
      );
      setSnackbarType('error');
      setSnackbarOpen(true);
    }
  };

  const handleButtonClick = async () => {
    if (buttonLabel === 'Upload' && selectedFile) {
      setIsUploading(true);
      try {
        const result = await uploadFile(selectedFile, projectID);

        if (result.data.success) {
          setDialogType('success');
        } else {
          setDialogType('failure');
        }

        setShowSuccessDialog(true);
      } catch (error) {
        setDialogType('failure');
        console.error('File upload failed:', error);
        setShowSuccessDialog(true);
      } finally {
        setIsUploading(false);
        setSelectedFile(null);
        setButtonLabel('Browse');
      }

      handleClose();
    } else {
      document.getElementById('fileInput').click();
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setButtonLabel('Browse');

    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const reopenUploadFileDialog = () => {
    setShowSuccessDialog(false);
    setTimeout(() => {
      onClose(true);
    }, 300);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <Typography className={classes.BoldText}>
          &lt; Step 1 of 2 &gt;
        </Typography>
        <Typography style={{ margin: '0px 5px 4px 5px', color: '#1A1919' }}>
          Please upload your Member data chase file in either .xlsx or .csv
          format with the following fields into {projectName}.
        </Typography>
        <Box className={classes.titleBar}>
          <Typography sx={{ fontSize: '1.1rem' }}>
            Chase file Details
          </Typography>
          <Typography sx={{ fontSize: '1.1rem' }}>
            Project Name: {projectName}
          </Typography>
        </Box>
        <Box className={classes.uploadListContainer}>
          {!selectedFile && (
            <Box
              className={`${classes.uploadContainer} ${
                isDragging ? classes.Dragging : ''
              }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <svg
                width="90"
                height="90"
                viewBox="0 0 150 150"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M68.75 100V49.0625L52.5 65.3125L43.75 56.25L75 25L106.25 56.25L97.5 65.3125L81.25 49.0625V100H68.75ZM37.5 125C34.0625 125 31.1208 123.777 28.675 121.331C26.2292 118.885 25.0042 115.942 25 112.5V93.75H37.5V112.5H112.5V93.75H125V112.5C125 115.937 123.777 118.881 121.331 121.331C118.885 123.781 115.942 125.004 112.5 125H37.5Z"
                  fill="#20668C"
                />
              </svg>
              <Typography style={{ textAlign: 'center' }}>
                {' '}
                <Typography style={{ color: '#105166', display: 'inline' }}>
                  Drag and drop
                </Typography>{' '}
                your file here to
                <br />
                Upload{' '}
              </Typography>
              <Input
                id="fileInput"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                inputProps={{ accept: '.xlsx,.csv' }}
              />
            </Box>
          )}

          {selectedFile && (
            <Box className={classes.uploadContainer}>
              <Box className={classes.FileButton}>
                <Typography variant="h6">Selected File:</Typography>
                <IconButton
                  onClick={handleFileRemove}
                  style={{ marginLeft: 8 }}
                >
                  <CloseIcon style={{ color: 'red' }} />
                </IconButton>
              </Box>
              <Typography>{selectedFile.name}</Typography>
            </Box>
          )}

          <Box className={classes.ListContainer}>
            {detailsList.map((detail, index) => (
              <Box key={index} className={classes.ListTableContainer}>
                <Typography>{detail}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            type="submit"
            className={`${classes.button} ${classes.saveButton}`}
            onClick={handleButtonClick}
            disabled={isUploading}
          >
            {buttonLabel}
          </Button>
          <Button
            type="button"
            className={`${classes.button} ${classes.closeButton}`}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
        <Notification
          type={snackbarType}
          message={snackbarMessage}
          openNotification={snackbarOpen}
          setOpenNotification={setSnackbarOpen}
        />
      </Dialog>
      <SuccessDialog
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        type={dialogType}
        reopenUploadDialog={reopenUploadFileDialog}
        lastStep={false}
        projectID={projectID}
        projectName={projectName}
        handleFileUpload={handleFileUpload}
      />
    </>
  );
};

export default UploadFile;
