import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { IconList } from 'icons';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const FilterSearch = ({ open, setOpen, setCurrentFilter, filterMetadata }) => {
  const onFilterSelect = (
    event,
    value
    //reason,
    // details
  ) => {
    setCurrentFilter(value);
    setOpen(false);
  };
  const onCancelSearch = () => {
    setOpen(false);
  };
  return (
    <Autocomplete
      id="grouped-demo"
      open={open}
      openOnFocus
      onChange={onFilterSelect}
      // clearOnEscape
      options={filterMetadata}
      groupBy={(option) => option.groupName}
      getOptionLabel={(option) => option.fieldLabel}
      sx={{ width: 300, mb: '20px' }}
      renderInput={(params) => (
        <Stack direction="row">
          <TextField
            variant="standard"
            fullWidth
            display="inline"
            {...params}
            label="Search"
          />

          <IconButton {...params} onClick={onCancelSearch}>
            {IconList.Cancel}
          </IconButton>
        </Stack>
      )}
      renderGroup={(params) => (
        <li>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

export default FilterSearch;
