import { Box, Button, CircularProgress, Typography } from '@mui/material';
import useStyles from './style';
import DiagnoseCard from '../diagnoseCard';
import Form from './form';
import { chartsAPI } from 'config/api-routlink';
import Notification from 'components/notification';
import { useEffect, useState } from 'react';
import request from 'shared/service/request';
import { reasonsList } from 'config/constants/reasons';

const DiagnoseForm = ({
  selectedDiagnose,
  setSelectedDiagnose,
  addDiagnose = false,
  rerender,
  chartId,
  hccVersion,
  yearOfService,
}) => {
  const [notificationMsg, setNotificationMsg] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [checkboxes, setCheckboxes] = useState(
    selectedDiagnose?.data?.reason ?? reasonsList
  );
  const [ischecked, setIsChecked] = useState(false);
  const [comments, setComments] = useState(
    selectedDiagnose?.data?.comments ?? ''
  );
  const [supportdoc, setSupportDoc] = useState(
    selectedDiagnose?.data?.attributes
  );
  const [formelements, setFormElements] = useState({
    Encounter_Date: selectedDiagnose?.data?.encounterDate,
    ICD_10: selectedDiagnose?.data?.dxCodeCaptured
      ? `${selectedDiagnose?.data?.dxCodeCaptured} - ${selectedDiagnose?.data?.dxDescription}`
      : '',
    HCC_Code: selectedDiagnose?.data?.hccMapCaptured?.code ?? '',
    HCC_description: selectedDiagnose?.data?.hccMapCaptured?.description ?? '',
    Provider: selectedDiagnose?.data?.provider,
    Note_Type: selectedDiagnose?.data?.noteType,
    Place_of_Service: selectedDiagnose?.data?.placeOfService,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsChecked(Object.values(checkboxes).some((value) => value === true));
  }, [checkboxes]);
  useEffect(() => {
    const resetForm = selectedDiagnose?.type === 'add';
    setSupportDoc(selectedDiagnose?.data?.attributes);
    if (resetForm) {
      setFormElements({
        Encounter_Date: '',
        ICD_10: '',
        HCC_Code: '',
        HCC_description: '',
        Provider: '',
        Note_Type: '',
        Place_of_Service: '',
      });
    }
  }, [selectedDiagnose]);

  const classes = useStyles();

  const handleSupportDocChange = (e) => {
    const { id, checked } = e.target;
    const updatedCheckboxes = supportdoc.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, isVerified: checked } : checkbox
    );
    setSupportDoc(updatedCheckboxes);
  };

  const handleDiagnoseAccept = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (
        Boolean(formelements?.Encounter_Date && formelements?.ICD_10?.length)
      ) {
        const postData = {
          chartId: chartId,
          diagnoseId: selectedDiagnose.diagnoseId,
          Status: selectedDiagnose?.data?.Status,
          Attributes: supportdoc,
          encounterDate: formelements.Encounter_Date,
          Dx_code: formelements.ICD_10.split('-')[0].trim(),
          Dx_Description: formelements.ICD_10.split('-')
            .slice(1)
            .join('-')
            .trim(),
          hcc_code: formelements?.HCC_Code
            ? String(formelements.HCC_Code)
            : null,
          hcc_description: formelements?.HCC_description
            ? formelements?.HCC_description
            : null,
          provider: formelements?.Provider,
          noteType: formelements?.Note_Type,
          placeOfService: formelements?.Place_of_Service,
          comments: comments,
        };
        await request.post(chartsAPI.ACCEPT_DIAGNOSE, postData);

        const id = selectedDiagnose.diagnoseId;
        rerender((prev) =>
          prev.map((obj) =>
            obj?.Id === id
              ? {
                  ...obj,
                  attributes: postData?.Attributes,
                  dxDescription: postData?.Dx_Description,
                  dxCodeCaptured: postData?.Dx_code,
                  comments: postData?.comments,
                  encounterDate: postData?.encounterDate,
                  noteType: postData?.noteType,
                  placeOfService: postData?.placeOfService,
                  provider: postData?.provider,
                  hccCaptured: postData.hcc_code,
                  Status: 'accepted',
                }
              : obj
          )
        );
        setSelectedDiagnose(null);
      } else {
        setNotificationMsg('Fields with (*) are required');
        setIsSuccess(false);
        setOpenNotification(true);
      }
    } catch (error) {
      setNotificationMsg(error.response.data.message);
      setIsSuccess(false);
      setOpenNotification(true);
    }

    setIsLoading(false);
  };

  const handleDiagnoseCancel = () => {
    setSelectedDiagnose(null);
  };
  const handleDiagnoseReject = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const postData = {
        chartId: chartId,
        Status: selectedDiagnose?.data?.Status,
        diagnoseId: selectedDiagnose?.diagnoseId,
        reason: checkboxes,
        comments: comments,
      };
      await request.post(chartsAPI.REJECT_DIAGNOSE, postData);
      const id = selectedDiagnose?.diagnoseId;
      rerender((prev) =>
        prev.map((obj) =>
          obj?.Id === id
            ? {
                ...obj,
                reason: postData?.reason,
                comments: postData?.comments,
                Status: 'rejected',
              }
            : obj
        )
      );
      setSelectedDiagnose(null);
    } catch (error) {
      setNotificationMsg('Something went wrong');
      setIsSuccess(false);
      setOpenNotification(true);
    }

    setIsLoading(false);
  };

  const handleDiagnoseSave = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (
        Boolean(
          formelements?.Encounter_Date &&
            formelements?.ICD_10?.trim()?.length &&
            formelements?.ICD_10?.trim()?.split('-')[0]?.trim()?.length &&
            formelements?.ICD_10?.trim()?.split('-')[1]?.trim()?.length
        )
      ) {
        const postData = {
          chartId: chartId,
          encounterDate: formelements?.Encounter_Date,
          code: formelements?.ICD_10.split('-')[0].trim(),
          description: formelements?.ICD_10.split('-')
            .slice(1)
            .join('-')
            .trim(),
          hcc_code: formelements?.HCC_Code
            ? String(formelements.HCC_Code)
            : null,
          hcc_description: formelements?.HCC_description
            ? formelements?.HCC_description
            : null,
          provider: formelements?.Provider,
          noteType: formelements?.Note_Type,
          placeOfService: formelements?.Place_of_Service,
          attributes: supportdoc,
        };
        const rdata = await request.post(chartsAPI.INSERT_DIAGNOSE, postData);
        const newDiagnoseId = rdata?.data?.id;

        rerender((data) => {
          return [
            ...(data || []),
            {
              ChartID: newDiagnoseId,
              Id: newDiagnoseId,
              Description: postData?.description,
              Code: postData?.code,
              Status: 'accepted',
              attributes: postData?.attributes ?? [],
              boundingBox: [],
              dxDescription: postData?.description,
              dxCodeCaptured: postData?.code,
              comments: postData?.comments ?? '',
              encounterDate: postData?.encounterDate,
              noteType: postData?.noteType,
              placeOfService: postData?.placeOfService,
              provider: postData?.provider,
              hccCaptured: postData?.hcc_code,
              hccCodeSuggested: null,
              hccMapCaptured: {
                code: postData.hcc_code,
                description: postData.hcc_description,
              },
              hccSuggested: '',
              reason: {
                inconsistentDocumentation: false,
                changedToSpecificCode: false,
                timingOfDiagnosis: false,
                providerType: false,
                duplicateCode: false,
                otherReasons: false,
              },
            },
          ];
        });

        setSelectedDiagnose(null);
        setIsLoading(false);
      } else {
        setNotificationMsg(
          'One or more required fields are either empty or invalid!'
        );
        setIsSuccess(false);
        setOpenNotification(true);
        setIsLoading(false);
      }
    } catch (error) {
      setNotificationMsg(error.response.data.message);
      setIsSuccess(false);
      setOpenNotification(true);
      setIsLoading(false);
    }
  };

  const PlusIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 22 22"
      fill="none"
      style={{ borderRadius: '999px', ...style }}
    >
      <rect width="25" height="25" fill="#138471" />
      <path
        d="M10.147 17.8424V11.8556H4.16016V10.145H10.147V4.1582H11.8575V10.145H17.8444V11.8556H11.8575V17.8424H10.147Z"
        fill="#F5F5F5"
      />
    </svg>
  );

  const FilterIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      style={style}
    >
      <path
        d="M8.62724 15.4727H22.7438M5.49023 9.2832H25.8808M13.3328 21.6621H18.0383"
        stroke="black"
        strokeWidth="2.0153"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <Box className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        <span>
          Chart Diagnosis
          {isLoading && <CircularProgress className={classes.progress} />}
        </span>
        <span>
          <Button type="button" className={classes.headButton} disabled>
            <FilterIcon />
          </Button>
          <Button type="button" className={classes.headButton} disabled>
            <PlusIcon />
          </Button>
        </span>
      </Typography>
      <Box className={classes.innerContainer}>
        {!addDiagnose && (
          <DiagnoseCard
            code={selectedDiagnose?.data?.dxCodeCaptured}
            description={selectedDiagnose?.data?.dxDescription}
            id={selectedDiagnose?.data?.Code}
            onClick={handleDiagnoseCancel}
            tabIndex={1}
            status={selectedDiagnose?.data?.Status ?? null}
            active
            encounterDate={selectedDiagnose?.data?.encounterDate}
            hcc={
              selectedDiagnose?.data?.hccCaptured?.trim() === '' ||
              selectedDiagnose?.data?.hccCaptured?.trim().toLowerCase() ===
                'not specified'
                ? 'N/A'
                : selectedDiagnose?.data?.hccCaptured?.trim()
            }
          />
        )}
        <Form
          onAccept={handleDiagnoseAccept}
          onReject={handleDiagnoseReject}
          onCancel={handleDiagnoseCancel}
          onSave={handleDiagnoseSave}
          selectedDiagnose={selectedDiagnose}
          addDiagnose={addDiagnose}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          ischecked={ischecked}
          comments={comments}
          setComments={setComments}
          supportdoc={supportdoc}
          focusId={selectedDiagnose?.id}
          setSupportDoc={setSupportDoc}
          handleSupportDocChange={handleSupportDocChange}
          formelements={formelements}
          setFormElements={setFormElements}
          disabled={isLoading}
          hccVersion={hccVersion}
          yearOfService={yearOfService}
        />
      </Box>
      <Notification
        type={isSuccess ? 'success' : 'error'}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
    </Box>
  );
};

export default DiagnoseForm;
