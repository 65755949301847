import { find } from 'api/claim';
import { dateFormat } from 'utils/date';
import { getClaimById as _getClaimById } from 'api/claim';

const customFilterFieldsTrans = (key, value) => {
  if (key === 'DOS_Min') {
    key = `range|${key}`;
    value = { gte: value.replace(/\-/gi, '') };
    //value = value.replace(/\-/gi, '');
  } else if (key === 'DOS_Max') {
    key = `range|${key}`;
    value = { lte: value.replace(/\-/gi, '') };
    //value = value.replace(/\-/gi, '');
  } else if (
    [
      'Subscriber_FirstName',
      'Subscriber_LastName',
      'BillProv_FirstName',
      'BillProv_LastName',
      'File_Name',
    ].includes(key)
  ) {
    key = `text|${key}`;
  }
  return {
    key,
    value,
  };
};
const filterTransform = (filter) => {
  const _filter = {};
  for (const [key, value] of Object.entries(filter)) {
    if (value) {
      const cf = customFilterFieldsTrans(key, value);
      _filter[cf.key] = cf.value;
    }
  }
  return _filter;
};

const responseDataTransform = (records = []) => {
  records = records.map((item, index) => {
    item.DOS_Max = dateFormat(item.DOS_Max);
    item.CreatedAt = dateFormat(item.CreatedAt);
    item.UpdatedAt = dateFormat(item.UpdatedAt);
    item.IsHistorical = item.IsHistorical ? 'Yes' : '';
    if (!item.id) {
      item.id = index;
    }
    return item;
  });
  return records;
};

export const getClaims = async ({
  selectedMember,
  options,
  setRecords,
  recordCount,
  setRecordCount,
}) => {
  options.filter.Subscriber_ID = selectedMember.Subscriber_ID;
  options.filter.Health_Plan_No = selectedMember.Health_Plan_No;
  options.filter = filterTransform(options.filter);
  //{ id: 'Subscriber_ID', alignRight: false, label: 'Member ID' },
  //{ id: 'Health_Plan_No', alignRight: false, label: 'Health Plan#' },

  let rdata = {};
  rdata = await find(options);
  if (options.pageno === 1 && rdata.count !== recordCount) {
    setRecordCount(parseInt(rdata.count));
  }

  if (rdata.records) {
    const records = responseDataTransform(rdata.records);
    setRecords(records);
  } else {
    setRecords([]);
  }
  return rdata;
};

export const getClaimById = async (claimUID) => {
  const result = (await _getClaimById(claimUID)).data;
  return result;
};
