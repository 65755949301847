import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import useStyles from './styles';
import { useEffect, useState } from 'react';
import { initialState } from './initialState';
import { adminProjectAPI } from 'api/admin';
import Notification from 'components/notification';
import { lineOfBusiness } from 'config/constants';
import { yearOfServiceHccVersion } from 'config/constants/yearOfService&hccVersion.constant';
import { llmOptions } from 'config/constants/llm.constant';

const CreateProject = ({
  open = false,
  onClose = () => {},
  projectid = null,
  reloadState = () => {},
}) => {
  const classes = useStyles();

  const [formState, setFormState] = useState(initialState({}));
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationType, setNotificationType] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [initialFormState, setInitialFormState] = useState(initialState({}));
  const [isFormModified, setIsFormModified] = useState(false);

  const today = new Date().toISOString().split('T')[0];

  formState.yearOfService.value = localStorage.getItem('yearOfService');

  useEffect(() => {
    if (projectid)
      (async () => {
        setIsEditLoading(true);
        const projectData = await adminProjectAPI.getChartProjectsById(
          projectid
        );
        const initialFormData = initialState({ projectData });
        setFormState(initialFormData);
        setInitialFormState(initialFormData);
        setIsEditLoading(false);
      })();
    else {
      setFormState(initialState({}));
      setInitialFormState(initialState({}));
    }
  }, [projectid]);

  useEffect(() => {
    setIsFormModified(
      JSON.stringify(formState) !== JSON.stringify(initialFormState)
    );
  }, [formState]);

  const handleClose = () => onClose(false);

  const handleFormFieldChange = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.name]: {
        ...prevState[e.target.name],
        value: e.target.value,
      },
    }));
  };

  // const handleYearOfServiceSelect = (yearOfService) => {
  //   const selectedYear = yearOfServiceHccVersion.find(
  //     (item) => item.label === yearOfService
  //   );
  //   setFormState((prevState) => {
  //     // Check if the current hccVersion is valid for the selected year
  //     const isHccVersionValid = selectedYear?.value.includes(
  //       prevState.hccVersion.value
  //     );
  //     return {
  //       ...prevState,
  //       yearOfService: {
  //         ...prevState.yearOfService,
  //         value: Number(yearOfService),
  //       },
  //       hccVersion: {
  //         ...prevState.hccVersion,
  //         value: isHccVersionValid ? prevState.hccVersion.value : null,
  //       },
  //     };
  //   });
  // };

  const handleInputValidation = ({ value, control, error = '' }) => {
    if (!Boolean(value) || value === '') {
      setFormState((prevState) => ({
        ...prevState,
        [control]: {
          ...prevState[control],
          hasError: true,
          errorMsg: error,
        },
      }));
      return true;
    } else if (control === 'QAPercentage' && (value < 0 || value > 100)) {
      setFormState((prevState) => ({
        ...prevState,
        [control]: {
          ...prevState[control],
          hasError: true,
          errorMsg: 'QA percentge must be between 0 - 100',
        },
      }));
      return true;
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [control]: {
          ...prevState[control],
          hasError: false,
          errorMsg: '',
        },
      }));
      return false;
    }
  };

  const handleFormValidation = () => {
    let isInvalid = false;
    if (
      handleInputValidation({
        value: formState.projectName.value,
        control: 'projectName',
        error: 'project name is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.projectDescription.value,
        control: 'projectDescription',
        error: 'project description is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.projectStartDate.value,
        control: 'projectStartDate',
        error: 'project start date is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.projectEndDate.value,
        control: 'projectEndDate',
        error: 'project end date is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.lineOfBusiness.value,
        control: 'lineOfBusiness',
        error: 'line of business is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.yearOfService.value,
        control: 'yearOfService',
        error: 'year of service is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.hccVersion.value,
        control: 'hccVersion',
        error: 'HCC version is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.llm.value,
        control: 'llm',
        error: 'LLM is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.priority.value,
        control: 'priority',
        error: 'priority is required',
      })
    )
      isInvalid = true;
    if (
      handleInputValidation({
        value: formState.QAPercentage.value,
        control: 'QAPercentage',
        error: 'QA percentage is required',
      })
    )
      isInvalid = true;

    return isInvalid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!handleFormValidation()) {
      setIsLoading(true);
      const formdata = {
        projectName: formState.projectName.value,
        projectDescription: formState.projectDescription.value,
        priority: formState.priority.value,
        projectStartDate: formState.projectStartDate.value,
        projectEndDate: formState.projectEndDate.value,
        lineOfBusiness: formState.lineOfBusiness.value,
        hccVersion: formState.hccVersion.value,
        // yearOfService: formState.yearOfService.value,
        llm: formState.llm.value,
        QAPercentage: formState.QAPercentage.value,
      };
      if (projectid) formdata.projectID = projectid;
      await adminProjectAPI.upsertChartsProject(formdata);
      setFormState(initialState);
      setIsLoading(false);
      setNotificationMsg(
        projectid
          ? 'Project update successfully!'
          : 'Project created successfully'
      );
      setNotificationType('success');
      setOpenNotification(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      sx={
        isEditLoading && {
          '& .MuiDialog-container': {
            '& .MuiPaper-root.MuiDialog-paper': { overflowY: 'hidden' },
          },
        }
      }
    >
      {isEditLoading && (
        <Box className={classes.editLoadingContainer}>
          <CircularProgress className={classes.editLoader} />
        </Box>
      )}
      <Box className={classes.titleBar}>
        <Typography variant="h5">
          Project UID :{' '}
          {projectid
            ? formState.projectName.value.trim() === ''
              ? 'Edit Project'
              : formState.projectName.value.trim()
            : formState.projectName.value.trim() === ''
            ? 'New Project'
            : formState.projectName.value.trim()}
        </Typography>
      </Box>
      <form onSubmit={handleFormSubmit}>
        <Box className={classes.formContainer}>
          <TextField
            className={classes.inputField}
            variant="standard"
            label="Project Name"
            type="text"
            name="projectName"
            id="projectName"
            value={formState.projectName.value}
            onChange={handleFormFieldChange}
            error={formState.projectName.hasError}
            helperText={formState.projectName.errorMsg}
            disabled={isLoading}
          />
          <TextField
            className={classes.inputField}
            variant="standard"
            label="Project Description"
            type="text"
            name="projectDescription"
            id="projectDescription"
            value={formState.projectDescription.value}
            onChange={handleFormFieldChange}
            error={formState.projectDescription.hasError}
            helperText={formState.projectDescription.errorMsg}
            disabled={isLoading}
          />
          <TextField
            className={classes.inputField}
            variant="standard"
            label="Project Start Date"
            type="date"
            name="projectStartDate"
            id="projectStartDate"
            value={formState.projectStartDate.value}
            onChange={handleFormFieldChange}
            error={formState.projectStartDate.hasError}
            helperText={formState.projectStartDate.errorMsg}
            disabled={isLoading}
            inputProps={{
              max: today,
            }}
            sx={
              formState.projectStartDate.value.length
                ? { '& input': { color: '#000' } }
                : {
                    '& input': { color: 'transparent' },
                  }
            }
          />
          <TextField
            className={classes.inputField}
            variant="standard"
            label="Project End Date"
            type="date"
            name="projectEndDate"
            id="projectEndDate"
            value={formState.projectEndDate.value}
            onChange={handleFormFieldChange}
            error={formState.projectEndDate.hasError}
            helperText={formState.projectEndDate.errorMsg}
            disabled={isLoading}
            inputProps={{
              min: formState.projectStartDate.value || today,
            }}
            sx={
              formState.projectEndDate.value.length
                ? { '& input': { color: '#000' } }
                : {
                    '& input': { color: 'transparent' },
                  }
            }
          />
          <FormControl
            fullWidth
            className={classes.lobSelect}
            error={formState.lineOfBusiness.hasError}
          >
            <InputLabel
              id="lineofbusiness-label"
              className={classes.dropdownLabel}
            >
              Line Of Business
            </InputLabel>
            <Select
              labelId="lineofbusiness-select-label"
              id="lineOfBusiness"
              name="lineOfBusiness"
              value={formState.lineOfBusiness.value}
              label="Line Of Business"
              onChange={handleFormFieldChange}
              className={classes.dropdown}
              disabled={isLoading}
            >
              {lineOfBusiness.map((lob) => (
                <MenuItem value={lob.value} key={lob.value}>
                  {lob.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formState.lineOfBusiness.errorMsg}</FormHelperText>
          </FormControl>

          <Box className={classes.priorityContainer}>
            {/* <FormControl
              fullWidth
              className={classes.lobSelect}
              error={formState.yearOfService.hasError}
            >
              <InputLabel id="yearOfService" className={classes.dropdownLabel}>
                Year of Service
              </InputLabel>
              <Select
                labelId="yearOfService-select-label"
                id="yearOfService"
                name="yearOfService"
                value={formState.yearOfService.value}
                label="Line Of Business"
                onChange={(e) => handleYearOfServiceSelect(e.target.value)}
                className={classes.dropdown}
                disabled={isLoading}
              >
                {yearOfServiceHccVersion.map((lob) => (
                  <MenuItem value={lob.label} key={lob.label}>
                    {lob.label}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText>
                {formState.yearOfService.errorMsg}
              </FormHelperText>
            </FormControl> */}

            <FormControl
              fullWidth
              className={classes.lobSelect}
              error={formState.hccVersion.hasError}
              sx={{
                width: '88%',
              }}
            >
              <InputLabel
                id="hccVersion-label"
                className={classes.dropdownLabel}
              >
                HCC version
              </InputLabel>
              <Select
                labelId="hccVersion-select-label"
                id="hccVersion"
                name="hccVersion"
                value={formState.hccVersion.value}
                label="HCC version"
                onChange={handleFormFieldChange}
                className={classes.dropdown}
                disabled={isLoading}
              >
                {yearOfServiceHccVersion
                  .find((item) => item.label == formState.yearOfService.value)
                  ?.value.map((version) => (
                    <MenuItem key={version} value={version}>
                      {version}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{formState.hccVersion.errorMsg}</FormHelperText>
            </FormControl>
          </Box>

          <Box className={classes.priorityContainer}>
            <FormControl
              fullWidth
              error={formState.llm.hasError}
              className={`${classes.muiSelect} ${classes.customWidth}`}
            >
              <InputLabel id="LLM-label" className={classes.dropdownLabel}>
                LLM
              </InputLabel>
              <Select
                labelId="llm-select-label"
                id="llm"
                name="llm"
                value={formState.llm.value}
                label="llm"
                onChange={handleFormFieldChange}
                className={classes.dropdown}
                disabled={isLoading}
              >
                {llmOptions.map((lob) => (
                  <MenuItem value={lob.value} key={lob.value}>
                    {lob.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formState.llm.errorMsg}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              className={classes.muiSelect}
              error={formState.priority.hasError}
            >
              <InputLabel id="priority-label" className={classes.dropdownLabel}>
                Priority
              </InputLabel>
              <Select
                labelId="priority-select-label"
                id="priority"
                name="priority"
                value={formState.priority.value}
                label="Priority"
                onChange={handleFormFieldChange}
                className={classes.dropdown}
                disabled={isLoading}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
              <FormHelperText>{formState.priority.errorMsg}</FormHelperText>
            </FormControl>
          </Box>
          <Box className={classes.QAContainer}>
            <TextField
              className={classes.inputField + ' ' + classes.qa}
              variant="standard"
              label="QA"
              type="number"
              min="0"
              max="100"
              name="QAPercentage"
              id="QAPercentage"
              value={formState.QAPercentage.value}
              onChange={handleFormFieldChange}
              error={formState.QAPercentage.hasError}
              helperText={formState.QAPercentage.errorMsg}
              disabled={isLoading}
            />
            <Typography variant="h6">%</Typography>
          </Box>
          <Box />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            type="submit"
            className={`${classes.button} ${classes.saveButton}`}
            disabled={!isFormModified || isLoading}
          >
            Save
            {isLoading && <CircularProgress className={classes.spinner} />}
          </Button>
          <Button
            type="button"
            className={classes.button}
            onClick={handleClose}
            disabled={isLoading}
          >
            Close
          </Button>
        </Box>
      </form>
      <Notification
        type={notificationType}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
    </Dialog>
  );
};

export default CreateProject;
