import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import useStyles from '../style';
import CustomTable from 'components/customTable';
import { getEncountersReport, getExportEncountersReport } from 'api/report';
import Notification from 'components/notification';
import FileSaver from 'file-saver';
const EncounterTab = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({});
  const [notificationType, setNotificationType] = useState('');
  const [notificationMssg, setNotificationMssg] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState([
    {
      label: 'HealthPlan ID',
      value: 'Health_Plan_No',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Member ID',
      value: 'Member.Subscriber_ID',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'First Name',
      value: 'Member.Subscriber_FirstName',
      display: true,
      type: 'string',
      align: 'left',
    },
    {
      label: 'Last Name',
      value: 'Member.Subscriber_LastName',
      display: true,
      type: 'string',
      align: 'left',
    },
    {
      label: 'Claim ID',
      value: 'Claim.ClaimID',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Claim Total Amount',
      value: 'Claim.TotalAmt',
      display: true,
      type: 'number',
      align: 'center',
    },
    {
      label: 'Is Supplemental',
      value: 'IsSupplemental',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Dx Count',
      value: 'DxCount',
      display: false,
      type: 'number',
      align: 'center',
    },
    {
      label: '999 Status',
      value: 'Status_999',
      display: false,
      type: 'string',
      align: 'center',
    },
    {
      label: '277 Status',
      value: 'Status_277',
      display: false,
      type: 'string',
      align: 'center',
    },
    {
      label: 'MAO-002 Status',
      value: 'Status_mao002',
      display: false,
      type: 'string',
      align: 'center',
    },
  ]);

  const handleDownload = async () => {
    try {
      if (Object.keys(dataFilter).length === 0) {
        setNotificationType('error');
        setNotificationMssg('Please select atleast one filter');
        setNotificationOpen(true);
        return;
      }
      setOpen(true);
      const response = await getExportEncountersReport({ filters: filters });
      FileSaver.saveAs(response.data, 'report.xlsx');
      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(() => {
    const tabChanged = async () => {
      setLoading(true);
      try {
        const result = await getEncountersReport({
          pageno: page + 1,
          rowsPerPage: rowsPerPage,
          filters: filters,
          sort: sort,
        });
        if (result?.data === undefined) {
          setLoading(false);
          return;
        }

        const tableData = result?.data?.data.map((item) => {
          return {
            id: String(item?._id),
            fields: [
              { field: 'Health PlanId', value: item?.Health_Plan_No },
              { field: 'MemberID', value: item?.Member?.Subscriber_ID },
              {
                field: 'First Name',
                value: item?.Member?.Subscriber_FirstName,
              },
              { field: 'Last Name', value: item?.Member?.Subscriber_LastName },
              { field: 'Claim ID', value: item?.Claim?.ClaimID },
              { field: 'Claim Total Amount', value: item?.Claim?.TotalAmt },
              {
                field: 'Is Supplemental',
                value: item?.IsSupplemental ? 'True' : 'False',
              },
              { field: 'Dx Count', value: item?.DxCount },
              { field: '999 Status', value: item?.Status_999 },
              { field: '277 Status', value: item?.Status_277 },
              { field: 'MAO-002 Status', value: item?.Status_mao002 },
            ],
          };
        });
        setData([...tableData]);
        setTotalRows(result?.data?.count);
      } catch (e) {
        setData([]);
        setLoading(false);
      }
      setLoading(false);
    };
    tabChanged();
  }, [page, rowsPerPage, filters, sort]);

  return (
    <Box className={classes.mainContainer}>
      <Box
        sx={{
          maxWidth: '95%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <Box className={classes.tableContainer}>
          <CustomTable
            totalRows={totalRows}
            loading={loading}
            data={data}
            headersList={headers}
            setHeadersList={setHeaders}
            exportFunction={handleDownload}
            tableName="Total Encounters Report"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sort={sort}
            setSort={setSort}
            filters={filters}
            setFilters={setFilters}
            open={open}
          />
        </Box>
      </Box>
      <Notification
        type={notificationType}
        message={notificationMssg}
        openNotification={notificationOpen}
        setOpenNotification={setNotificationOpen}
      />
    </Box>
  );
};

export default EncounterTab;
