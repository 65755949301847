import React, { useEffect, useState, useCallback } from 'react';
import {
  InputAdornment,
  IconButton,
  Typography,
  Box,
  Button,
  OutlinedInput,
} from '@mui/material';
import useStyles from './style';
import {
  DoubleArrowDown,
  DoubleArrowUp,
  ReturnArrowIcon,
  MagnifyingGlassIcon,
} from 'icons/customIcons';
import QueueFilter from './queue-filter';
import { queueAPI } from 'api';
import DataRow from 'components/controls/dataRow';
import LabelBtn from 'components/labelBtn';

let pageReset = true;

const CreateQueue = ({
  queueName,
  selectedProjects,
  projectData,
  handleBackBtn,
  handleQueueCreated,
  onChartOpen = () => {},
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [chartsList, setChartsList] = useState([]);
  const classes = useStyles();
  const [selectedFilters, setSelectedFilters] = useState({
    selectedHCCCount: [],
    selectedComplexHCCs: [],
    selectedDiseaseCategories: [],
    pageCountRange: { min: 0, max: 5000 },
    selectedAgeRange: [],
    selectedGender: [],
    selectedChartStatus: [],
    selectedProviderSelect: [],
    selectedMemberName: [],
  });
  const [totalCharts, setTotalCharts] = useState(0);
  const [selectedChartIds, setSelectedChartIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingQueue, setIsCreatingQueue] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [ignoredCharts, setIgnoredCharts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalFilterSelected, setTotalFilterSelected] = useState(1);
  const [search, setSearch] = useState('');
  const [chartsFilter, setChartsFilter] = useState('');

  useEffect(() => {
    const total_filter = Object.keys(selectedFilters).reduce((total, key) => {
      if (Array.isArray(selectedFilters[key])) {
        return total + selectedFilters[key].length;
      }
      return total;
    }, 0);

    setTotalFilterSelected(total_filter + 1);
  }, [selectedFilters]);

  const handleItemCheck = (checkedRows) => {
    if (checkedRows == true) {
      setSelectAll(true);
      setSelectedChartIds([]);
    } else {
      const selectedIds = checkedRows.map((row) => row.id);
      setSelectedChartIds(selectedIds);
      setSelectAll(false);
    }
  };

  const handleChartOpen = (e, record) => {
    onChartOpen(e, record);
  };

  // i tried this logic for selectAll & deselectedCharts it is not working.
  // const handleItemCheck = (checkedRows) => {
  //   if (checkedRows === true) {
  //     setSelectAll(true);
  //     setIgnoredCharts([]);
  //     setSelectedChartIds([]);
  //     console.log('Selected all charts');
  //   } else {
  //     const selectedIds = checkedRows.map((row) => row.id);

  //     if (selectAll) {
  //       const deselectedCharts = chartsList
  //         .filter((chart) => !selectedIds.includes(chart.id))
  //         .map((chart) => chart.id);

  //       setIgnoredCharts((prevIgnored) => {
  //         const updatedIgnored = [...prevIgnored, ...deselectedCharts].filter(
  //           (id, index, self) => self.indexOf(id) === index
  //         );
  //         return updatedIgnored;
  //       });

  //       console.log('Deselected Charts:', deselectedCharts);
  //     } else {
  //       setIgnoredCharts((prevIgnored) => {
  //         const newlyIgnored = chartsList
  //           .filter((chart) => !selectedIds.includes(chart.id))
  //           .map((chart) => chart.id);

  //         const updatedIgnored = [...prevIgnored, ...newlyIgnored].filter(
  //           (id, index, self) => self.indexOf(id) === index
  //         );
  //         return updatedIgnored;
  //       });
  //     }

  //     setSelectedChartIds(selectedIds);
  //     setSelectAll(false);
  //     console.log('Selected Chart IDs:', selectedIds);
  //   }
  // };

  // console.group('selected value');
  // console.log('selectAll field, ', selectAll);
  // console.log('Ignored Charts:', ignoredCharts);
  // console.log('selected charts:', selectedChartIds);
  // console.groupEnd();

  const fieldMapping = {
    selectedHCCCount: 'hccCount',
    selectedComplexHCCs: 'complexHccs',
    selectedDiseaseCategories: 'diseaseCategories',
    pageCountRange: 'pageCount',
    selectedAgeRange: 'ageRange',
    selectedGender: 'gender',
    selectedChartStatus: 'chartStatus',
    selectedProviderSelect: 'providerName',
    selectedMemberName: 'memberName',
  };

  const transformFilters = (filters) => {
    const transformed = {};

    for (const [key, value] of Object.entries(filters)) {
      if (fieldMapping[key]) {
        transformed[fieldMapping[key]] = value;
      }
    }

    transformed.projectIdList = selectedProjects;
    return transformed;
  };

  const handleFilterUpdate = (updatedFilters) => {
    setSelectedFilters(updatedFilters);
  };

  const handleCreateQueue = async () => {
    setIsCreatingQueue(true);
    try {
      const transformedFilters = transformFilters(selectedFilters);
      const response = await queueAPI.createQueue({
        queueName,
        selectedChartIds,
        transformedFilters,
        selectedProjects,
        chartsFilter,
      });
      const newQueueId = response.data.queueId;
      setIsCreatingQueue(false);
      handleQueueCreated(newQueueId);
    } catch (error) {
      console.error('Error creating queue:', error);
      setIsCreatingQueue(false);
    }
  };

  const fetchCharts = useCallback(
    async (pageNumber) => {
      try {
        setIsLoading(true);
        const transformedFilters = transformFilters(selectedFilters);
        const pagenum = pageNumber;
        const res = await queueAPI.getChartsByFilters(
          selectedProjects,
          transformedFilters,
          pagenum,
          chartsFilter
        );
        setTotalCharts(res?.data[0]['totalCount']);
        setChartsList((prevData) => [
          ...prevData,
          ...res?.data[0]?.docs.map((record) => ({
            id: record?._id,
            fields: [
              { field: 'ID', value: record?.memberID, space: 12 },
              {
                field: 'First Name',
                value: record?.memberFirstName,
                space: 20,
              },
              { field: 'Last Name', value: record?.memberLastName, space: 20 },
              { field: 'Gender', value: record?.memberGender, space: 1 },
              { field: 'DOB', value: record?.memberDOB, space: 10 },
              { field: 'Project Name', value: record?.projectName, space: 20 },
              { field: 'Pages', value: record?.numberOfPages, space: 4 },
              {
                field: 'Total Dx Suggested',
                value: record?.totalDxSuggested || 0,
                space: 3,
              },
              {
                field: 'Last Updated Date',
                value: record?.lastUpdatedDate
                  ? new Date(record?.lastUpdatedDate).toLocaleDateString()
                  : 'N/A',
                space: 10,
              },
            ],
            expansionContent: (
              <Box className={classes.description}>
                <Typography variant="h6">Description:</Typography>
                <Typography paragraph>{record?.projectName}</Typography>
              </Box>
            ),
            record,
          })),
        ]);
      } catch (error) {
        console.error('Error fetching charts:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedFilters, chartsFilter]
  );

  useEffect(() => {
    if (!pageReset) {
      fetchCharts(page);
    }
    pageReset = false;
  }, [page, fetchCharts]);

  useEffect(() => {
    setChartsList([]);
    setPage(0);
    pageReset = true;
    fetchCharts(0);
  }, [selectedFilters, fetchCharts]);

  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const selectedProjectNames = selectedProjects.map((projectId) => {
    const project = projectData.find(
      (project) => project.projectID === projectId
    );
    return project ? project.projectName : '';
  });

  const isCreateQueueDisabled = () => {
    const filterVal = Object.values(selectedFilters).every((filter) => {
      if (Array.isArray(filter)) {
        return filter.length === 0;
      }
      if (
        filter &&
        typeof filter === 'object' &&
        filter.hasOwnProperty('min') &&
        filter.hasOwnProperty('max')
      ) {
        return filter.min === 0 && filter.max === 5000;
      }
      return !filter;
    });

    if (!filterVal && (selectedChartIds.length > 0 || selectAll)) {
      return false;
    }

    return true;
  };

  const handleTopInView = () => {
    console.log('TOP IN VIEW::');
  };
  const handleBottomInView = () => {
    console.log('BOTTOM IN VIEW::');
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setChartsFilter(search);
    }
  };

  return (
    <Box className={classes.CreateQueue}>
      <Box className={classes.queueHeading}>
        <Button className={classes.queueBackBtn} onClick={handleBackBtn}>
          <ReturnArrowIcon />
        </Button>
        <Box className={classes.queueHeadingDetail}>
          <Typography variant="h6">Queue Name: {queueName}</Typography>
          <Typography variant="h6">
            Selected Projects: {selectedProjectNames.join(', ')}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h7">
        Select the required filters and then select the charts create a Queue
      </Typography>
      <Box className={classes.BarContainer}>
        <Box className={classes.BarContainerLeft}>
          {' '}
          {selectAll == true
            ? totalCharts
            : selectedChartIds.length || 0} / {totalCharts || 0} Charts Selected
        </Box>
        <Box className={classes.BarContainerRight}>
          <OutlinedInput
            type="text"
            placeholder="Search"
            className={classes.searchBar}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="search" edge="end">
                  <MagnifyingGlassIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <LabelBtn
            label={`Select filter (${totalFilterSelected})`}
            showFilter={showFilter}
            toggleFilter={toggleFilter}
            iconUp={DoubleArrowUp}
            iconDown={DoubleArrowDown}
          />
          <Button
            className={classes.createBtn}
            disabled={isCreateQueueDisabled() || isCreatingQueue}
            onClick={handleCreateQueue}
          >
            {isCreatingQueue ? 'Creating...' : 'Create Queue'}
          </Button>
        </Box>
      </Box>
      {showFilter && (
        <Box className={classes.filterContainer}>
          <QueueFilter
            projectIdList={selectedProjects}
            defaultFilters={selectedFilters}
            onFilterChange={handleFilterUpdate}
          />
        </Box>
      )}
      <Box className={classes.chartTable}>
        <DataRow
          totalRows={totalCharts}
          loading={isLoading}
          placeholderCount={3}
          emptyDataMessage="No Charts available yet"
          data={chartsList}
          allowChecks
          allowRowExpansion
          onOpen={handleChartOpen}
          onItemCheck={handleItemCheck}
          defaultExpanded
          onLoadMore={() => {
            pageReset = false;
            setPage((prevPage) => prevPage + 1);
          }}
          virtualized
          onTopInView={handleTopInView}
          onBottomInView={handleBottomInView}
          hidePlusBtn
        />
      </Box>
    </Box>
  );
};

export default CreateQueue;
