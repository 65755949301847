import { Box } from 'components/controls';
import CopyRights from 'components/copyrights';
import useStyles from './style';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Notification from 'components/notification';
import { useContext } from 'react';
import { loginMfaContext } from 'shared/context/mfa';
import { LoginMfaProvider } from 'shared/context/mfa';
import SecondComponent from './second-component';
import ThirdComponent from './third-component';
import { getMaskedEmail } from 'shared/context/mfa/localstoragedata';
import Loading from 'components/loading';
import { useEffect } from 'react';

const MainComponent = () => {
  const classes = useStyles();
  const {
    stage,
    setStage,
    method,
    setMethod,
    select,
    setSelect,
    isSuccess,
    openNotification,
    setOpenNotification,
    notificationMsg,
    maskedEmail,
    setMaskedEmail,
  } = useContext(loginMfaContext);

  useEffect(() => {
    const storedData = getMaskedEmail();
    setMaskedEmail(storedData);
  }, []);

  const methodChange = async (e) => {
    setMethod(e.target.value);
    setSelect(true);
    setStage(2);
  };

  return (
    <Box className={classes.container} component="main">
      <Box className={classes.bgShape}></Box>
      <Box className={classes.content}>
        <Box className={classes.paper}>
          <Box></Box>
          <span className={classes.hr} />
          <Box className={classes.maindiv}>
            <Box className={classes.topdiv}>
              <img src="/logo2.png" alt="logo" className={classes.logo} />
              <Box className={classes.mainheading}>Verify your Identity</Box>
              {stage === 1 ? (
                <Box className={classes.subheading}>
                  Choose your Authentication method
                </Box>
              ) : (
                <Box className={classes.subheading}></Box>
              )}
            </Box>

            <Box className={classes.formdiv}>
              <FormControl fullWidth>
                <InputLabel id="authentication-method">
                  Authentication method
                </InputLabel>
                <Select
                  labelId="authentication-method"
                  id="authentication"
                  value={method}
                  label="Authentication method"
                  onChange={methodChange}
                  disabled={select}
                >
                  <MenuItem value="Mail">Mail</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {maskedEmail === null ? (
              <Loading />
            ) : (
              <>
                {stage === 1 ? (
                  <></>
                ) : stage === 2 ? (
                  <SecondComponent />
                ) : (
                  <ThirdComponent />
                )}
              </>
            )}
            <Notification
              type={isSuccess ? 'success' : 'error'}
              message={notificationMsg}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
            />
            <Box className={classes.CopyRights}>
              <CopyRights />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Mfalogin = () => {
  return (
    <LoginMfaProvider>
      <MainComponent />
    </LoginMfaProvider>
  );
};

export default Mfalogin;
