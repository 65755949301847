import { useContext } from 'react';
import { Card, CardContent, Divider } from '@mui/material';

import { Label, Grid } from 'components/controls';
import { viewClaimContext } from 'shared/context/claim';
import Modal from 'components/modal';
//import Tabs from './tabs';
import BasicInfo from './basicinfo';
import Tabs from './tabs';
import Actions from './actions';

import useStyles from './style';

const ViewClaimModal = () => {
  const classes = useStyles();
  const { openModal, setOpenModal, selectedClaim } =
    useContext(viewClaimContext);

  return (
    <Modal openModal={openModal} setOpenModal={setOpenModal}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container sx={{ padding: 0, marginTop: -2 }}>
            <Grid item xs={6}>
              <Label
                //gutterBottom
                variant="h6"
                color="white"
                label={`ClaimID: ${selectedClaim.claimID}`}
              />
            </Grid>
            <Grid item xs={6}>
              <Label
                // gutterBottom
                variant="h6"
                align="right"
                color="white"
                label={selectedClaim.status}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <BasicInfo fieldList={selectedClaim.basicInfo} />
      <Divider />
      <Tabs />
      <Divider />
      <Actions />
    </Modal>
  );
};
export default ViewClaimModal;
