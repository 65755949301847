import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '9.357px',
    border: `1px solid ${theme.palette.secondary.grey}`,
    margin: '4px auto 0',
    padding: '6px',
    maxWidth: '95%',
    overflowY: 'auto',
    maxHeight: '75vh',
    backgroundColor: theme.palette.primary.white,
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      '& > div:first-child': {
        marginBottom: '24px',
      },
    },

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.secondary.grey,
      border: `1px solid ${theme.palette.secondary.grey}`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.hover,
    },
  },
  descriptionLabel: {
    fontSize: '12px',
    fontWeight: 700,
    borderBottom: '1px solid #00000055',
  },
  actionButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    justifyContent: 'space-evenly',
    margin: '16px auto',
  },
  actionButton: {
    padding: '0 4px',
    minHeight: 'auto',
    borderRadius: 0,
    borderBottom: '1px solid #aaa',
    color: '#000',
    fontWeight: 600,
    backgroundSize: '200%',
    backgroundPosition: 'right',
    transition: 'all 150ms linear',
    '&:hover': {
      backgroundPosition: 'left',
      borderRadius: '5px',
    },
  },
  cancelButton: {
    backgroundImage: 'linear-gradient(to left, transparent 50%, #C3C3C3 50%)',
  },
  acceptButton: {
    backgroundImage: 'linear-gradient(to left, transparent 50%, #CDE4C4 50%)',
  },
  rejectButton: {
    backgroundImage: 'linear-gradient(to left, transparent 50%, #E4CEC4 50%)',
  },
  textarea: {
    borderRadius: '8px',
    border: '1px solid #B8B3B3',
    background: '#F2ECEC',
    width: '100%',
    resize: 'none',
    padding: '5px 8px',
    outline: 'none',
  },
  textareaLabel: {
    fontSize: '13px',
    fontWeight: 200,
  },
  addNewActionsContainer: {
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  selectOptions: {},
  checkbox: {
    display: 'inline-flex',
    fontSize: '25',
    color: 'black',
    fontFamily: 'Inter',
    fontWeight: '500',
    wordWrap: 'break-word',
    '&CheckBox': {
      fontSize: '10',
    },
  },
  checkboxdiv: {
    marginBottom: '-5px',
    marginTop: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '& .MuiSvgIcon-root': {
      fontSize: '11px',
      fontWeight: 'bold',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '11px',
      fontWeight: 'bold',
    },
  },
  actionButtonsBottom: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
    margin: '16px auto',
  },
  selectOptions: {
    marginBottom: '5px',
    marginTop: '-7px',
  },
}));

export default useStyles;
