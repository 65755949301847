import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  assignmentContainer: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
  },
  assigneeDropDown: {
    maxWidth: '70%',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.radius,
    '& .MuiSelect-select': {
      padding: '2px 8px !important',
      fontSize: '14px',
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
    '& .MuiSelect-select.Mui-selected': {
      fontSize: '14px',
    },
    '& svg': { display: 'none' },
    '&:hover': {
      backgroundColor: theme.palette.secondary.hover,
      '&::before': { borderBottom: '0px solid transparent !important' },
    },
    '&::before': { borderBottom: '0px solid transparent' },
  },
  assigneeDropDownDisabled: {
    cursor: 'not-allowed',
    maxWidth: '70%',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.radius,
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    '& .MuiSelect-select': { padding: '2px 8px !important' },
    '& svg': { display: 'none' },
    '&:hover': {
      '&::before': { borderBottom: '0px solid transparent !important' },
    },
    '&::before': { borderBottom: '0px solid transparent' },
  },
  singleAssignSpinner: {
    color: theme.palette.primary.main,
    maxWidth: '18px',
    maxHeight: '18px',
  },
  menuItemCell: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  assignButton: {
    pointerEvents: 'auto',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    padding: '4px',
    '& svg': {
      maxWidth: '14px',
      maxHeight: '14px',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.hover,
    },
  },
  tableContainer: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '5px',
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: theme.palette.secondary.main,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.hover,
    },
  },
  bulkAssignLogo: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '999px',
    '& svg': {
      color: theme.palette.primary.white,
      width: '27px',
      height: '27px',
    },
    '&:hover': { backgroundColor: theme.palette.primary.hover },
  },
  menuItemLabel: { fontSize: '12px' },
  menuItemSubLabel: {
    fontSize: '10px',
    color: theme.palette.primary.main,
    margin: 0,
  },
  queueContainer: {
    width: '100%',
    height: 'calc(100vh - 100px)',
    padding: '1rem 2rem 0 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  headerLeft: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  dropdownButton: {
    width: '250px',
    maxWidth: '260px',
    fontSize: '1.2rem',
    padding: '10px 14px',
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: '10px',
  },
  menu: {
    '& .MuiPaper-root': {
      maxHeight: '250px',
      overflowY: 'auto',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
      borderRadius: '8px',
      width: '250px',
      maxWidth: '260px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
    fontSize: '17px',
    color: theme.palette.primary.white,
  },
  queueBackBtn: {
    width: '10px',
    borderRadius: '50%',
  },
  selectFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '99px',
    maxWidth: '260px',
    width: '170px',
    height: '33px',
    backgroundColor: theme.palette.primary.white,
  },
  selectFilterLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  reportLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '44px',
    height: '44px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  tooltip: {
    fontSize: '12px',
    backgroundColor: '#02375F',
    color: theme.overrides.MuiTooltip.tooltip.color,
  },
}));

export default useStyles;
