import { useState } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import {
  TextBox,
  DropDown,
  CheckBox,
  DatePicker,
  SwitchBox,
} from 'components/controls';
import useControlEvent from 'utils/controlevent';

import { IconList } from 'icons';

const Operators = ({
  currentFilter,
  selectedOperator,
  setSelectedOperator,
}) => {
  const onOperatorChange = (e) => {
    setSelectedOperator(e.target.value);
  };
  return currentFilter.operators.length === 1 ? (
    <Chip label={selectedOperator} variant="outlined" />
  ) : (
    <DropDown
      name="operator"
      value={selectedOperator}
      onChange={onOperatorChange}
      options={currentFilter?.operators.map((oi) => ({ label: oi, value: oi }))}
      autoWidth
      className={{ style: { marginLeft: 1 } }}
    />
  );
};

const FilterValue = ({ currentFilter, controlVal, onFieldValueChange }) => {
  return (() => {
    if (currentFilter.type === 'textbox') {
      return (
        <TextBox
          name={currentFilter.fieldId}
          //label={currentFilter.fieldLabel}
          variant="standard"
          value={controlVal[currentFilter.fieldId]}
          onChange={onFieldValueChange}
          placeholder="Enter the value"
        />
      );
    } else if (currentFilter.type === 'dropdown') {
      return (
        <DropDown
          name={currentFilter.fieldId}
          value={controlVal[currentFilter.fieldId]}
          onChange={onFieldValueChange}
          options={currentFilter.items}
          margin={0}
          //label={currentFilter.fieldLabel}
          // className={{ marginLeft: 1, width:150 }}
        />
      );
    } else if (currentFilter.type === 'checkout') {
      return (
        <CheckBox
          name={currentFilter.fieldId}
          value={controlVal[currentFilter.fieldId]}
          onChange={onFieldValueChange}
          options={currentFilter.items}
        />
      );
    } else if (currentFilter.type === 'date') {
      return (
        <DatePicker
          name={currentFilter.fieldId}
          value={controlVal[currentFilter.fieldId]}
          onChange={onFieldValueChange}
        />
      );
    } else if (currentFilter.type === 'switch') {
      return (
        <SwitchBox
          name={currentFilter.fieldId}
          checked={controlVal[currentFilter.fieldId]}
          onChange={onFieldValueChange}
        />
      );
    } else {
      return <></>;
    }
  })();
};
const Actions = ({
  currentFilter,
  setCurrentFilter,
  filters,
  setFilters,
  selectedOperator,
  controlVal,
}) => {
  const onAddFilter = () => {
    const newFilters = filters.filter(
      (f) => f.fieldId !== currentFilter.fieldId
    );
    const value = controlVal[currentFilter.fieldId];
    let valueLabel = value;
    if (currentFilter.type === 'dropdown') {
      const selectedItem = currentFilter.items.find((f) => f.value === value);
      valueLabel = selectedItem.label;
    }
    newFilters.push({
      ...currentFilter,
      selectedOperator,
      value,
      valueLabel,
    });
    setFilters(newFilters);
    setCurrentFilter({});
  };
  const onClearFilter = () => setCurrentFilter({});
  return (
    <>
      <IconButton sx={{ padding: 0, margin: 0 }} onClick={onAddFilter}>
        {IconList.CheckCircle}
      </IconButton>
      <IconButton sx={{ padding: 0, margin: 0 }} onClick={onClearFilter}>
        {IconList.Cancel}
      </IconButton>
    </>
  );
};
const CurrentFilter = ({
  currentFilter,
  setCurrentFilter,
  filters,
  setFilters,
}) => {
  const { controlVal, setControlVal, onFieldValueChange } = useControlEvent({});
  const [selectedOperator, setSelectedOperator] = useState(
    currentFilter?.operators[0]
  );

  return (
    <Stack
      sx={{
        mb: '10px',
        alignItems: 'center',
        gap: '8px',
      }}
      direction="row"
      spacing={1}
    >
      <Chip label={currentFilter.fieldLabel} />
      {/* <Typography variant="subtitle1">{currentFilter.fieldLabel}</Typography> */}
      {/* <Chip label={<Operators currentFilter={currentFilter} />} /> */}
      <Operators
        currentFilter={currentFilter}
        selectedOperator={selectedOperator}
        setSelectedOperator={setSelectedOperator}
      />
      <FilterValue
        currentFilter={currentFilter}
        controlVal={controlVal}
        setControlVal={setControlVal}
        onFieldValueChange={onFieldValueChange}
      />
      <Actions
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        filters={filters}
        setFilters={setFilters}
        selectedOperator={selectedOperator}
        controlVal={controlVal}
      />
    </Stack>
  );
};

export default CurrentFilter;
