import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import useStyles from '../style';
import { getClaimsReport, getExportClaimsReport } from 'api/report';
import Notification from 'components/notification';
import CustomTable from 'components/customTable';
import FileSaver from 'file-saver';

const ClaimTab = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({});
  const [notificationType, setNotificationType] = useState('');
  const [notificationMssg, setNotificationMssg] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState([
    {
      label: 'HealthPlan ID',
      value: 'healthPlanId',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Member ID',
      value: 'memberID',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Claim ID',
      value: 'claimID',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Claim Type Code',
      value: 'clmTypCode',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'Place of Service',
      value: 'pos',
      display: true,
      type: 'string',
      align: 'left',
    },
    {
      label: 'Facility',
      value: 'facility',
      display: true,
      type: 'string',
      align: 'left',
    },
    {
      label: 'Billed Amount',
      value: 'sumBilledAmount',
      display: true,
      type: 'number',
      align: 'center',
    },
    {
      label: 'DOS Min',
      value: 'dosMin',
      display: false,
      type: 'date',
      align: 'center',
    },
    {
      label: 'DOS Max',
      value: 'dosMax',
      display: false,
      type: 'date',
      align: 'center',
    },
    {
      label: 'Billing Provider ID ',
      value: 'billProviderId',
      display: false,
      type: 'string',
      align: 'center',
    },
  ]);

  const handleDownload = async () => {
    try {
      if (
        Object.keys(filters).length === 0 ||
        Object.values(filters).every(
          (filter) => Object.keys(filter).length === 0
        )
      ) {
        setNotificationType('error');
        setNotificationMssg('Please select at least one filter');
        setNotificationOpen(true);
        return;
      }
      setOpen(true);
      const response = await getExportClaimsReport({ filters: filters });
      FileSaver.saveAs(response.data, 'report.xlsx');
      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(() => {
    const tabChanged = async () => {
      setLoading(true);
      try {
        const result = await getClaimsReport({
          pageno: page + 1,
          rowsPerPage: rowsPerPage,
          filters: filters,
          sort: sort,
        });
        if (result?.data === undefined) {
          setLoading(false);
          return;
        }

        const tableData = result?.data?.data.map((item) => {
          return {
            id: String(item?._id),
            fields: [
              { field: 'HealthPlan ID', value: item?.healthPlanId },
              { field: 'Member ID', value: item?.memberID },
              { field: 'Claim ID', value: item?.claimID },
              { field: 'Claim Type Code', value: item?.clmTypCode },
              { field: 'Place of Service', value: item?.pos },
              { field: 'Facility', value: item?.facility },
              { field: 'Billed Amount', value: item?.sumBilledAmount },
              { field: 'DOS Min', value: '2024' + item?.dosMin?.slice(4) },
              { field: 'DOS Max', value: '2024' + item?.dosMax?.slice(4) },
              { field: 'Billing Provider ID ', value: item?.billProviderId },
            ],
          };
        });
        setData([...tableData]);
        setTotalRows(result?.data?.count);
      } catch (e) {
        setData([]);
        setLoading(false);
      }
      setLoading(false);
    };
    tabChanged();
  }, [page, rowsPerPage, filters, sort]);

  return (
    <Box className={classes.mainContainer}>
      <Box
        sx={{
          maxWidth: '95%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <Box className={classes.tableContainer}>
          <CustomTable
            totalRows={totalRows}
            loading={loading}
            data={data}
            headersList={headers}
            setHeadersList={setHeaders}
            exportFunction={handleDownload}
            tableName="Total Claims Report"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sort={sort}
            setSort={setSort}
            filters={filters}
            setFilters={setFilters}
            open={open}
          />
        </Box>
      </Box>
      <Notification
        type={notificationType}
        message={notificationMssg}
        openNotification={notificationOpen}
        setOpenNotification={setNotificationOpen}
      />
    </Box>
  );
};

export default ClaimTab;
