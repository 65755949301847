import { createContext, useState } from 'react';

export const setupMfaContext = createContext();

export const loginMfaContext = createContext();

export const SetupMfaProvider = ({ children }) => {
  // const [mfaAuth, setMfaAuth] = useState(false);
  // const [emailMfaAuth, setEmailMfaAuth] = useState(false);
  const [stage, setstage] = useState(1);
  const [otp, setOtp] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState(true);
  const [btndisabled, setBtnDisabled] = useState(true);
  const [senddisabled, setSendDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState(null);
  return (
    <setupMfaContext.Provider
      value={{
        stage,
        setstage,
        otp,
        setOtp,
        isSuccess,
        setIsSuccess,
        openNotification,
        setOpenNotification,
        notificationMsg,
        setNotificationMsg,
        btndisabled,
        setBtnDisabled,
        senddisabled,
        setSendDisabled,
        loading,
        setLoading,
        maskedEmail,
        setMaskedEmail,
      }}
    >
      {children}
    </setupMfaContext.Provider>
  );
};

export const LoginMfaProvider = ({ children }) => {
  // const [mfaAuth, setMfaAuth] = useState(false);
  // const [emailMfaAuth, setEmailMfaAuth] = useState(false);
  const [otp, setOtp] = useState('');
  const [stage, setStage] = useState(1);
  const [method, setMethod] = useState('');
  const [select, setSelect] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState(true);
  const [loading, setLoading] = useState(false);
  const [senddisabled, setSendDisabled] = useState(true);
  const [maskedEmail, setMaskedEmail] = useState(null);
  return (
    <loginMfaContext.Provider
      value={{
        otp,
        setOtp,
        stage,
        setStage,
        method,
        setMethod,
        select,
        setSelect,
        isSuccess,
        setIsSuccess,
        openNotification,
        setOpenNotification,
        notificationMsg,
        setNotificationMsg,
        loading,
        setLoading,
        senddisabled,
        setSendDisabled,
        maskedEmail,
        setMaskedEmail,
      }}
    >
      {children}
    </loginMfaContext.Provider>
  );
};
