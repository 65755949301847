import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  queueFilter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    marginBottom: '0.3rem',
    '& .MuiTabs-scrollButtons': {
      '&.Mui-disabled': {
        opacity: 0.3,
      },
    },
  },
  tab: {
    color: 'black',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1.1rem',
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  tabContent: {
    flex: 1,
    padding: '1rem',
    borderRadius: '4px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  applyButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  applyButton: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.white,
    '&:hover': {
      color: theme.palette.primary.white,
      boxShadow: theme.boxShadow.button,
    },
    height: '1.7rem',
    whiteSpace: 'nowrap',
    margin: '5px 15px',
  },
  hccCount: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  rangeButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  rangeButton: {
    width: '7rem',
    height: '1.7rem',
    whiteSpace: 'nowrap',
    padding: '5px 10px',
    margin: '3px 10px',
    textAlign: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.boxShadow.button,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  complexHccBtn: {
    height: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis !important',
    overflow: 'hidden !important',
    padding: '4px 10px',
    textAlign: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.boxShadow.button,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchBar: {
    borderRadius: '999px',
    maxWidth: '350px',
    height: '32px',
    '& fieldset': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.palette.primary.hover} !important`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.palette.primary.main} !important`,
    },
    '& .MuiInputBase-input': {
      padding: '8px 0 8px 16px',
    },
    '& button': {
      maxWidth: '37px',
      maxHeight: '37px',
    },
    '& input': {
      color: 'black',
      '&::placeholder': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  diseaseCategories: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  diseaseCategoryBtn: {
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.boxShadow.button,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  stickySearchContainer: {
    position: 'sticky',
    top: -16,
    backgroundColor: theme.palette.primary.grey,
    zIndex: 10,
    paddingTop: '2px',
  },
  select: {
    width: '200px',
    height: '30px',
    borderRadius: '8px',
    padding: '0 10px',
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  providerButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  providerButton: {
    height: '2rem',
    width: '350px !important',
    whiteSpace: 'nowrap !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    padding: '5px 10px',
    margin: '3px 10px',
    textAlign: 'center',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    transition: 'all 0.3s ease',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.boxShadow.button,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
  },
  clearIcon: {
    color: theme.palette.primary.white,
    marginLeft: 'auto',
    marginRight: '3px',
    padding: 0,
    display: 'flex',
  },
  pageCount: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    alignItems: 'start',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  capsule: {
    borderRadius: '20px',
    height: '32px',
    fontSize: '12px',
    padding: '0 12px',
  },
  selectMenu: {
    borderRadius: '12px',
  },
  tooltip: {
    fontSize: '12px',
    backgroundColor: theme.overrides.MuiTooltip.tooltip.backgroundColor,
    color: theme.overrides.MuiTooltip.tooltip.color,
  },
  stickySearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px',
  },
  count: {
    marginRight: 'auto',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
