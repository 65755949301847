import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import MainLayout from 'layout/main';
import { UserSelector } from 'state/user';
import { AppSelector } from 'state/app';
import { VERSION } from 'version';

const PrivateRoute = ({ component: Component, location, roles, ...rest }) => {
  const token = useSelector(UserSelector.getUserToken);
  const userRoles = useSelector(UserSelector.getUserRoles);
  const userAppVersion = useSelector(AppSelector.getAppVersion);

  return VERSION.version === userAppVersion &&
    token &&
    userRoles &&
    roles.some((x) => userRoles.includes(x)) ? (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export default PrivateRoute;
