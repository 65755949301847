import { Box, Typography } from '@mui/material';

import ClaimProvider from 'shared/context/claim';
import ViewClaimModal from 'shared/components/viewclaim-modal';

import Table from './components/table';
import WithHOC from './hoc';
import FilterAction from './components/filter-action';

const MainComponent = (props) => {
  return (
    <Box sx={{ padding: '12px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        <Typography color="primary" variant="h5">
          {/* Claim */}
        </Typography>
        <FilterAction {...props} />
      </Box>
      <Table {...props} />
      <ViewClaimModal {...props} />
    </Box>
    // <div>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12}>
    //       <Typography color="secondary" variant="h6">
    //         Claim
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <FilterAction {...props} />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Table {...props} />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <ViewClaimModal {...props} />
    //     </Grid>
    //   </Grid>
    // </div>
  );
};

const MainComponentWithHOC = WithHOC(MainComponent);

const Claim = () => {
  return (
    <ClaimProvider>
      <MainComponentWithHOC />
    </ClaimProvider>
  );
};

export default Claim;
