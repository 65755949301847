import { Box, Dialog, Typography, IconButton } from '@mui/material';
import useStyles from './style';
import { useEffect, useState } from 'react';

const SubmittedCodesDialogue = ({ open, onClose, submittedCode }) => {
  const classes = useStyles();
  const [codesList, setCodesList] = useState([]);

  useEffect(() => {
    if (submittedCode && submittedCode.length > 0) {
      setCodesList(submittedCode);
    }
  }, [submittedCode]);

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <Box className={classes.dialogContent}>
        <Typography sx={{ fontSize: '24px', marginBottom: '8px' }}>
          Codes List
        </Typography>
        <Box className={classes.headBox}>
          <Typography sx={{ fontSize: '19px' }}>
            These are the codes that are Identified for this member previously
          </Typography>
          <Typography
            sx={{ fontSize: '19px', color: 'gray', marginBottom: '8px' }}
          >
            Right click on the ICD code to delete it
          </Typography>
        </Box>
        <Box className={classes.ListContainer}>
          {codesList.map((detail, index) => (
            <Box key={index} className={classes.ListTableContainer}>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: 'auto', fontWeight: 'bold' }}>
                  HCC 18
                </Typography>
                <Typography>{detail.category}</Typography>
              </Box>
              <Typography sx={{ fontSize: '15px' }}>
                {detail.dxCodeCaptured} - {detail.dxDescription}
              </Typography>{' '}
            </Box>
          ))}
        </Box>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          Close
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default SubmittedCodesDialogue;
