import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  bgShape: {
    height: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.up('md')]: {
      backgroundImage:
        'url(./assets/shapes/shape1.png), url(./assets/shapes/shape2.png)',
      backgroundPosition: 'left, right',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: 'contain',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '100%',
      paddingLeft: theme.spacing(16),
    },
  },
  paper: {
    position: 'relative',
    padding: '30px 45px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '35px 0 0 35px',
    '-webkit-box-shadow': 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    '-moz-box-shadow': 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    boxShadow: 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(5px)',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    '& > div': { flexGrow: 1, flexBasis: 0 },
    '& > div:first-child': {
      padding: theme.spacing(2),
      '& img': { opacity: 0.8 },
    },
  },
  hr: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '5px',
    height: '100%',
    filter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    '& h1': {
      fontFamily: 'Merriweather',
      opacity: 0.8,
      fontSize: '16px',
      marginBottom: theme.spacing(3),
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2E0A70',
      borderRadius: '5px',
      borderWidth: '2px',
    },

    '& .MuiFormControl-root': {
      margin: 0,
      marginBottom: theme.spacing(4),
    },

    '& .MuiFormControl-fullWidth': {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '75%',
      },
    },

    '& .MuiFormLabel-root': { color: '#000' },

    '& .MuiFormLabel-asterisk': { color: 'transparent' },
  },
  logo: {
    backgroundColor: theme.palette.primary.main,
    width: 'auto',
    height: 'auto',
    '& svg': {
      width: '45px',
      height: 'auto',
      fill: '#fff',
      stroke: theme.palette.primary.main,
    },
    marginBottom: theme.spacing(1),
  },
  submit: {
    backgroundColor: '#8971B5',
    // width: '75%',
    color: '#000',
    marginBottom: theme.spacing(4),
    fontSize: '20px',
    lineHeight: 'normal',
    fontFamily: 'Merriweather',
    fontWeight: 400,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: 'white',
      backgroundColor: '#8971B5',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
  },
  notificationContainer: {
    borderRadius: '30px',
    border: '3px solid #1093CD',
    background: '#DEEEF5',
    boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.25)',
  },
}));
export default useStyles;
