import { configureStore as _configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { loadState, saveState } from './storagestate';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const configureStore = () => {
  const store = _configureStore({
    reducer: rootReducer,
    preloadedState: loadState(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(routerMiddleware(history)),
  });

  store.subscribe(() => {
    saveState(store.getState());
  });

  return store;
};
export default configureStore;
