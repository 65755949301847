import { Box } from 'components/controls';
import useStyles from '../style';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { userVerify, userSendcode } from '../service';
import { useDispatch } from 'react-redux';
import { UserAction } from 'state/user';
import { AppAction } from 'state/app';
import { VERSION } from 'version';
import { useContext } from 'react';
import { setupMfaContext } from 'shared/context/mfa';
import { removeMaskedEmail } from 'shared/context/mfa/localstoragedata';

const ThirdComponent = () => {
  const {
    setstage,
    otp,
    setOtp,
    setNotificationMsg,
    setIsSuccess,
    setOpenNotification,
    loading,
    setLoading,
    btndisabled,
    senddisabled,
    setSendDisabled,
    maskedEmail,
  } = useContext(setupMfaContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const readOtp = async (e) => {
    e.preventDefault();
    setOtp(e.target.value);
  };

  const Sendotp = async () => {
    setLoading(true);
    setSendDisabled(true);
    const { success, message } = await userSendcode();
    setLoading(false);
    if (success) {
      setNotificationMsg(message);
      setIsSuccess(true);
      setOpenNotification(true);
    } else {
      if (
        message === 'User not found' ||
        message === 'User already has MFA enabled for email'
      ) {
        removeMaskedEmail();
        history.replace('/login');
      }
      setNotificationMsg(message);
      setIsSuccess(false);
      setOpenNotification(true);
      if (message === 'Session expired') {
        setTimeout(() => {
          removeMaskedEmail();
          history.replace('/login');
        }, 3000);
      }
    }
    setTimeout(() => {
      setSendDisabled(false);
    }, 120000);
  };

  const Verifyotp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { success, message, user } = await userVerify(otp);
      setLoading(false);
      if (success === false) {
        if (
          message === 'User not found' ||
          message === 'User already has MFA enabled for email'
        ) {
          removeMaskedEmail();
          history.replace('/login');
        }
        setNotificationMsg(message);
        setIsSuccess(false);
        setOpenNotification(true);
        if (
          message === 'Session expired' ||
          message === 'Your account is disabled, please contact administrator'
        ) {
          setTimeout(() => {
            removeMaskedEmail();
            history.replace('/login');
          }, 3000);
        }
      } else {
        // removeMaskedEmail();
        dispatch(
          AppAction.AppAll({
            version: VERSION.version,
          })
        );
        dispatch(UserAction.UserAll(user));
        removeMaskedEmail();
        history.replace('/');
      }
    } catch (e) {
      dispatch(UserAction.UserAll({}));
    }
  };
  const callSecond = async () => {
    setstage(2);
  };

  return (
    <Box className={classes.stagethreediv}>
      <Box className={classes.textdiv}>
        A verification code has been sent to your{' '}
        <Box className={classes.emailthree}>{maskedEmail}</Box>. Enter the code
        to enable your Multi-factor Authentication.
      </Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Box className={classes.formdiv}>
          <TextField
            placeholder="Enter the code"
            className={classes.textfield}
            value={otp}
            onChange={readOtp}
            disabled={loading}
          />
          <Button
            onClick={Sendotp}
            disabled={loading || senddisabled}
            className={classes.resendbtn}
          >
            {' '}
            Send code again{' '}
          </Button>
        </Box>
        <Box className={classes.btndiv}>
          <Button
            onClick={callSecond}
            className={classes.btnback}
            disabled={loading}
          >
            {' '}
            Back{' '}
          </Button>
          <Button
            onClick={Verifyotp}
            disabled={btndisabled || loading}
            className={classes.btn}
            type="submit"
          >
            <img
              src="/material-symbols_enable-rounded.png"
              alt="logo"
              className={classes.btnimg}
            />
            Enable
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ThirdComponent;
