import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import useStyles from './style';

const GuideAccordion = ({ label, content, expandIcon }) => {
  const classes = useStyles();
  return (
    <Accordion className={classes.navGuideAccordion}>
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls="panel1-content"
        id="panel1-header"
        className={classes.navGuideAccordionSummary}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails className={classes.navGuideAccordionDetails}>
        {content.map((item) => (
          <Box key={item.key} className={classes.navGuideAccordionDetailItem}>
            {/* {`${item.key.replace(/_/g, ' ')} : ${item.text}`} */}
            {`${
              !isNaN(new Date(item.text))
                ? item.key.replace(/_/g, ' ') + ' :'
                : ''
            } ${item.text}`}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default GuideAccordion;
