import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  hideScroll: {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export default useStyles;
