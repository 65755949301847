import { chartsAPI } from 'config/api-routlink';
import request from 'shared/service/request';

// GET request - Search charts with various filter
export const find = async ({
  pageno,
  rowsPerPage,
  sort,
  filter,
  projectId,
  userType,
}) => {
  const rdata = await request.get(chartsAPI.CHARTLIST_FIND, {
    params: {
      pageno: pageno,
      rowsPerPage: rowsPerPage,
      sort: sort,
      filter: filter,
      projectId,
      userType,
    },
  });

  return {
    count: rdata.data?.data?.recordCount,
    records: rdata.data?.data?.records,
  };
};

// Get Unique records
export const distinct = async ({ filter, field }) => {
  const rdata = await request.get(chartsAPI.AGGREGATE_DISTINCT, {
    params: {
      filter: filter,
      field: field,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

// GET request - Get Chart details by chart id
export const getChartById = async (chartId) => {
  const rdata = await request.get(chartsAPI.VIEW, { params: { chartId } });
  return {
    data: rdata.data?.data,
  };
};

// Put Request - Update chart by chartUID
export const updateChartById = async (chartUID, data) => {
  const rdata = await request.put(`${chartsAPI.EDIT}/update/${chartUID}`, {
    data: data,
  });
  return {
    data: rdata.data?.data,
  };
};

// Post Request - Create
export const create = async (data) => {
  const rdata = await request.post(`${chartsAPI.CREATE}`, {
    data: data,
  });
  return {
    data: rdata.data?.data,
  };
};

// Post Request - Upload File
export const uploadFile = async (file, projectID) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('projectID', projectID);

  const rdata = await request.post(chartsAPI.UPLOAD_FILE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {
    data: rdata.data,
  };
};

// Add new attribute
export const addAttribute = async ({
  diagnoseId,
  boundingBox,
  text,
  chartId,
}) => {
  const rdata = await request.post(chartsAPI.ADD_ATTRIBUTE, {
    chartId,
    diagnoseId,
    boundingBox,
    text,
    isVerified: true,
  });
};

// GET request - Get Member details by ChartId
export const getMemberDetails = async (chartId, projectId) => {
  const rdata = await request.get(chartsAPI.MEMBER_DETAILS, {
    params: { chartId, projectId },
  });
  return {
    data: rdata.data?.data,
  };
};

// GET request - Get office visits by ChartId
export const getOfficeVisits = async (chartId) => {
  const rdata = await request.get(chartsAPI.OFFICE_VISITS, {
    params: { chartId },
  });
  return {
    data: rdata.data?.data,
  };
};

// Post Request - Upsert Office visits by Id
export const UpsertOfficeVisitById = async ({ visitDetail, chartId }) => {
  const rdata = await request.post(`${chartsAPI.UPSERT_OFFICE_VISITS}`, {
    visitDetail,
    chartId,
  });
  return {
    data: rdata.data?.data,
  };
};

// GET request - Get submitted code diagnosis
export const getSubmittedCodes = async ({
  chartId,
  projectId,
  queueId = null,
}) => {
  const rdata = await request.get(chartsAPI.GET_SUBMITTED_CODES, {
    params: { chartId, projectId, queueId },
  });
  return {
    data: rdata.data?.data,
  };
};

// GET request - Get all matching ICD-10 codes based on a search string
export const getICDBySearchString = async (searchText, yearOfService) => {
  const rdata = await request.get(chartsAPI.GET_ICD_BY_SEARCH, {
    params: { searchText, yearOfService },
  });
  return { data: rdata.data?.data };
};

export const getTitles = async (chartId) => {
  const rdata = await request.get(`${chartsAPI.GET_TITLES}/${chartId}`);
  return { data: rdata.data?.data };
};

// POST request - Assign a single user to chart
export const assignUserByChartId = async ({
  chartId,
  userId,
  projectId,
  queueId,
  role,
}) => {
  const rdata = await request.post(chartsAPI.ASSIGN_USER_SINGLE_CHART, {
    chartId,
    userId,
    projectId,
    queueId,
    role,
  });
  return { data: rdata.data };
};

// POST request - Assign charts in bulk to a user
export const assignChartsInBulk = async ({
  projectId,
  userId,
  role,
  chartsList,
}) => {
  const rdata = await request.post(chartsAPI.BULK_ASSIGN_CHARTS, {
    chartsList,
    userId,
    projectId,
    role,
  });
  return { data: rdata.data };
};

// POST request - Mark a chart as submitted
export const submitChart = async ({
  projectId,
  chartId,
  queueId,
  status,
  hccVersion,
  yearOfService,
}) => {
  const rdata = await request.post(chartsAPI.SUBMIT_CHART, {
    chartId,
    projectId,
    queueId,
    status,
    hccVersion,
    yearOfService,
  });
  return { data: rdata.data };
};

export const getRiskScores = async ({ chartId, projectId }) => {
  const rdata = await request.get(chartsAPI.RISK_SCORES, {
    params: { chartId, projectId },
  });
  return { data: rdata.data.data };
};
