import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    '& .MuiDialog-container': {
      width: '900px',
      position: 'relative',
      margin: '0 auto',
      '& .MuiPaper-root.MuiDialog-paper': {
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        padding: theme.spacing(1),
        scrollbarWidth: 'none',
        margin: '10px 14px',
        backgroundColor: '#F2F3F4',
        borderRadius: '4px',
        maxWidth: '100%',
      },
    },

    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      marginTop: '4px',
    },
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#02375F',
    marginBottom: '10px',
    borderRadius: '4px',
    padding: '6px 14px',
    color: '#fff',
  },
  buttonContainer: {
    padding: '2.3rem 8px 1.8rem 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: '10px',
  },
  button: {
    '&:hover': { backgroundColor: theme.palette.primary.main },
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3.447px 3.447px 0px rgba(0, 0, 0, 0.25)',
    padding: '6px 30px',
    '&:disabled': { backgroundColor: 'gray', color: 'whitesmoke' },
  },
  saveButton: {
    position: 'absolute',
    left: '13%',
  },
  closeButton: {
    position: 'absolute',
    right: '28%',
  },
  uploadListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  uploadContainer: {
    background: '#ffffff',
    border: '1px dashed black',
    padding: '35px 10px',
    margin: '0 0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: `${theme.spacing(3)} ${theme.spacing(6)}`,
    height: '270px',
    width: '290px',
  },
  ListContainer: {
    flex: '1',
    overflowY: 'auto',
    height: '270px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#c0c0c0',
      border: 'none',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#666666',
      borderRadius: '10px',
    },
  },
  ListTableContainer: {
    background: '#fff',
    padding: '6px 8px 6px 20px',
    marginBottom: '5px',
    borderRadius: '5px',
  },
  BoldText: {
    fontWeight: '500',
    padding: '0',
    margin: 'auto',
  },
  Dragging: {
    border: '2px dashed #20668C',
    background: '#f0f0f0',
  },
  FileButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default useStyles;
