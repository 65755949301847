import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Logincheckcookie, Setupcheckcookie } from 'api/mfa';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
const ProtectedRoute = ({ component: Component, check, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const authCheckFunction = async () => {
    if (check === 'setup') {
      return await Setupcheckcookie();
    } else if (check === 'login') {
      return await Logincheckcookie();
    } else {
      return false;
    }
  };

  useEffect(() => {
    const performAuthCheck = async () => {
      const isSuccessful = await authCheckFunction();
      setIsAuthenticated(isSuccessful);
    };

    performAuthCheck();
  }, [check]);

  if (isAuthenticated === null) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;
