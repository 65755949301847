import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { IconList } from 'icons';
import { isEmptyJson } from 'utils/object';

import FilterSearch from './filter-search';
import CurrentFilter from './current-filter';
import FilteredList from './filtered-list';

/*
const options = [
  {
    groupName: 'Claim',
    fieldId: 'ClaimID',
    fieldLabel: 'Claim ID',
    type: 'textbox',
    operators: ['=', '!='],
  },
  {
    groupName: 'Claim',
    fieldId: 'Status',
    fieldLabel: 'Status',
    type: 'dropdown',
    items: [
      {
        label: "Submitted",
        value: "Submitted",
      },
      {
        label: "Rejected",
        value: "Rejected",
      },
      {
        label: "Queued",
        value: "Queued",
      }
    ],
    isItemListDynamic: true,
    operators: ['='],
  },
  {
    groupName: 'Claim',
    fieldId: 'DOS_Min',
    fieldLabel: 'From DOS',
    type: 'date',
    operators: ['>='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_ID',
    fieldLabel: 'Member ID',
    type: 'textbox',
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_FirstName',
    fieldLabel: 'First Name',
    type: 'textbox',
  },
  {
    groupName: 'Provider',
    fieldId: 'BillProv_FirstName',
    fieldLabel: 'First Name',
    type: 'textbox',
  },
];
*/

const AdvanceFilter = ({ filterMetadata, filters, setFilters }) => {
  const [searchOpen, setSeachOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  // const [filters, setFilters] = useState([]);
  // const [filterMetadata, setFilterMetadata] = useState(options)
  return (
    <Box sx={{ mr: '10px' }}>
      {/* <Grid container spacing={1}> */}
      <Grid item xs={12} sm={12} md={1.5} lg={1.5} xl={2}>
        <Grid container direction="column">
          <Grid item sx={{ ml: 'auto' }}>
            <Button
              variant="contained"
              startIcon={IconList.Add}
              sx={{
                color: '#02375F',
                textAlign: 'center',
                fontWeight: '700',
                borderRadius: '8px',
                border: '1px solid rgba(81, 133, 144, 0.00)',
                backgroundColor: '#F9FDFF',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.40)',
                minWidth: 'max-content',
                mb: '10px',
                '&:hover': { backgroundColor: '#F9FDFF' },
              }}
              onClick={() => setSeachOpen(!searchOpen)}
            >
              Add Filter
            </Button>
          </Grid>
          <Grid item>
            {searchOpen && (
              <FilterSearch
                open={searchOpen}
                setOpen={setSeachOpen}
                setCurrentFilter={setCurrentFilter}
                filterMetadata={filterMetadata}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={10.5} lg={10.5} xl={10}>
          <Stack
            direction="column"
            // divider={<Divider  flexItem />}
            spacing={1}
          > */}
      {!isEmptyJson(currentFilter) && (
        <CurrentFilter
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          filters={filters}
          setFilters={setFilters}
        />
      )}
      <FilteredList filters={filters} setFilters={setFilters} />
      {/* </Stack>
        </Grid> */}
      {/* </Grid> */}
    </Box>
  );
};

export default AdvanceFilter;
