const _ROLES_SUPER_ADMIN = 'SuperAdmin';
const _ROLES_CLIENT_ADMIN = 'ClientAdmin';
const _ROLES_CLIENT_USER = 'ClientUser';

export const ROLES = [
  _ROLES_SUPER_ADMIN,
  _ROLES_CLIENT_USER,
  _ROLES_CLIENT_ADMIN,
];

const _PRODUCT_MANAGE_ACCESS_ROLES = [_ROLES_SUPER_ADMIN, _ROLES_CLIENT_ADMIN];
const _CLIENT_ACCESS_ROLES = [_ROLES_CLIENT_ADMIN];
export const PRODUCT_MANAGE_ACCESS_ROLES = _PRODUCT_MANAGE_ACCESS_ROLES;
export const CLIENT_ACCESS_ROLES = _CLIENT_ACCESS_ROLES;
const _ACCESS_ROLES = {
  PRODUCT_MANAGE_ACCESS_ROLES: _PRODUCT_MANAGE_ACCESS_ROLES,
  CLIENT_ACCESS_ROLES: _CLIENT_ACCESS_ROLES,
};
export const ACCESS_ROLES = _ACCESS_ROLES;
export const ROLES_SUPER_ADMIN = _ROLES_SUPER_ADMIN;
export const ROLES_CLIENT_USER = _ROLES_CLIENT_USER;
export const ROLES_CLIENT_ADMIN = _ROLES_CLIENT_ADMIN;

export const getRoleAccessCategory = (userRoles) => {
  for (const [category, roles] of Object.entries(_ACCESS_ROLES)) {
    if (roles.find((r) => userRoles.includes(r))) {
      return category;
    }
  }
  return null;
};
