import { reportsAPI } from 'config/api-routlink';
import request from 'shared/service/request';

export const getReportsTotalDetails = async () => {
  const rdata = await request.get(reportsAPI.GET_REPORTS_TOTAL_DETAILS);

  return {
    data: rdata.data?.data,
  };
};

export const getReportsChartStatus = async () => {
  const rdata = await request.get(reportsAPI.GET_REPORTS_CHART_STATUS);

  return {
    data: rdata.data?.data,
  };
};

export const getReportsProjectMemberRiskscore = async () => {
  const rdata = await request.get(
    reportsAPI.GET_REPORTS_PROJECT_MEMBER_RISKSCORE
  );

  return {
    data: rdata.data?.data,
  };
};

export const getReportsTopHcc = async () => {
  const rdata = await request.get(reportsAPI.GET_REPORTS_TOP_HCC);

  return {
    data: rdata.data?.data,
  };
};

export const getMembersReport = async ({
  pageno,
  filters,
  rowsPerPage,
  sort,
}) => {
  const filter = JSON.stringify(filters);
  const rdata = await request.get(reportsAPI.GET_MEMBER_REPORT, {
    params: {
      pageno,
      filter,
      rowsPerPage,
      sort,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

export const getClaimsReport = async ({
  pageno,
  filters,
  rowsPerPage,
  sort,
}) => {
  const filter = JSON.stringify(filters);
  const rdata = await request.get(reportsAPI.GET_CLAIM_REPORT, {
    params: {
      pageno,
      filter,
      rowsPerPage,
      sort,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

export const getEncountersReport = async ({
  pageno,
  filters,
  rowsPerPage,
  sort,
}) => {
  const filter = JSON.stringify(filters);
  const rdata = await request.get(reportsAPI.GET_ENCOUNTER_REPORT, {
    params: {
      pageno,
      filter,
      rowsPerPage,
      sort,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

export const getMemberChartsReport = async ({
  pageno,
  filters,
  rowsPerPage,
  sort,
}) => {
  const filter = JSON.stringify(filters);
  const rdata = await request.get(reportsAPI.GET_MEMBER_CHART_REPORT, {
    params: {
      pageno,
      filter,
      rowsPerPage,
      sort,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

export const getExportMembersReport = async ({ filters }) => {
  const filter = JSON.stringify(filters);
  const response = await request.get(reportsAPI.GET_EXPORT_MEMBER_REPORT, {
    params: { filter },
    responseType: 'blob',
  });
  return response;
};

export const getExportClaimsReport = async ({ filters }) => {
  const filter = JSON.stringify(filters);
  const response = await request.get(reportsAPI.GET_EXPORT_CLAIM_REPORT, {
    params: { filter },
    responseType: 'blob',
  });
  return response;
};

export const getExportEncountersReport = async ({ filters }) => {
  const filter = JSON.stringify(filters);
  const response = await request.get(reportsAPI.GET_EXPORT_ENCOUNTER_REPORT, {
    params: { filter },
    responseType: 'blob',
  });
  return response;
};

export const getExportMemberChartsReport = async ({ filters }) => {
  const filter = JSON.stringify(filters);
  const response = await request.get(
    reportsAPI.GET_EXPORT_MEMBER_CHART_REPORT,
    {
      params: { filter },
      responseType: 'blob',
    }
  );
  return response;
};
