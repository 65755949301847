import { useContext } from 'react';
import { memberContext, viewMemberContext } from 'shared/context/member';

const WithHOC = (WrapperComponent) => {
  const HOC = (props) => {
    const context = useContext(memberContext);
    const viewContext = useContext(viewMemberContext);
    return (
      <WrapperComponent
        {...props}
        hocContext={context}
        hocContextView={viewContext}
      />
    );
  };

  return HOC;
};

export default WithHOC;
