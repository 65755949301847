import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    '& li': {
      maxWidth: '250px',
      textWrap: 'wrap',
    },
    '& .MuiMenu-paper': {
      boxShadow: '3px 3px 6px #eee',
      border: '1px solid #ccc',
      '& .MuiList-root': {
        padding: 0,
      },
      '& hr': {
        margin: 0,
      },
    },
  },
  menuOptionText: {
    '& span': {
      fontSize: '12px',
    },
  },
}));

export default useStyles;
