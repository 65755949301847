import { useState } from 'react';
import ProjectTable from './projectTable';
import TitleBar from './titleBar';

const { Box } = require('@mui/material');

const ProjectView = ({
  selectedProject,
  setCurrentScreen,
  role,
  projectData,
}) => {
  const [bulkAssignmentMode, setBulkAssignmentMode] = useState(false);
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);

  return (
    <Box sx={{ padding: '4px 12px ', width: '100%', height: 'auto' }}>
      <TitleBar
        selectedProject={selectedProject}
        setCurrentScreen={setCurrentScreen}
        role={role}
        projectData={projectData}
        bulkAssignmentMode={bulkAssignmentMode}
        setOpenAssignmentDialog={setOpenAssignmentDialog}
      />
      <ProjectTable
        selectedProject={selectedProject}
        setCurrentScreen={setCurrentScreen}
        projectData={projectData}
        bulkAssignmentMode={bulkAssignmentMode}
        setBulkAssignmentMode={setBulkAssignmentMode}
        openAssignmentDialog={openAssignmentDialog}
        setOpenAssignmentDialog={setOpenAssignmentDialog}
      />
    </Box>
  );
};

export default ProjectView;
