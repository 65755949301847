import {
  ChartsIcon,
  MagnifyingGlassPlusIcon,
  MemberIcon,
} from 'icons/customIcons';
import ReportCard from './reportCard';
import { useEffect, useState } from 'react';
import { getReportsTotalDetails } from 'api/report';
import useStyles from './style';

const { Box, Typography, Skeleton } = require('@mui/material');

const Reports = () => {
  const [report, setReport] = useState({
    charts: null,
    members: null,
    diagnosis: null,
  });
  const [stats, setStats] = useState({
    queues: 0,
    projects: 0,
    aro: 0,
    rafsc: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [reportChartStatus, setReportChartStatus] = useState([]);
  const [totalMember, setTotalMember] = useState([]);
  const [riskScore, setRiskScore] = useState([]);
  const [topHcc, setTopHcc] = useState([]);
  const classes = useStyles();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoadingChart(true);
  //       const result = await reportAPI.getReportsChartStatus();
  //       if (result?.data?.every((item) => item.value === 0)) {
  //         setReportChartStatus([]);
  //       } else {
  //         setReportChartStatus(result.data);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoadingChart(false);
  //     }
  //   };
  //
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   const fetchMemberRiskscore = async () => {
  //     try {
  //       setLoadingChart(true);
  //       const result = await reportAPI.getReportsProjectMemberRiskscore();
  //       setTotalMember(result?.data?.total_member);
  //       setRiskScore(result?.data?.risk_score);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoadingChart(false);
  //     }
  //   };
  //
  //   fetchMemberRiskscore();
  // }, []);

  // useEffect(() => {
  //   const fetchtopHcc = async () => {
  //     try {
  //       setLoadingChart(true);
  //       const result = await reportAPI.getReportsTopHcc();
  //       setTopHcc(result.data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoadingChart(false);
  //     }
  //   };
  //
  //   fetchtopHcc();
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getReportsTotalDetails();
        setStats({
          queues: res?.data?.queueCount,
          projects: res?.data?.projectCount,
          aro: res?.data?.avg_riskScoreOpportunity,
          rafsc: res?.data?.avgRafScoreCaptured,
        });
        setReport({
          charts: {
            count: res?.data?.totalCharts,
            title: 'Total Charts',
            details: [
              { label: 'Total pages', value: res?.data?.totalPages },
              { label: 'Total words', value: res?.data?.totalWordCount },
              { label: 'Total tokens', value: res?.data?.totalTokenCount },
              {
                label: 'Total coders assigned',
                value: res?.data?.totalCodeAssigned,
              },
              {
                label: 'Charts coded / hour',
                value: res?.data?.chartCodedPerHour,
              },
              {
                label: 'Codes reviewed / hour',
                value: res?.data?.codeReviewedPerHour,
              },
            ],
          },
          members: {
            count: res?.data?.totalMember,
            title: 'Total Members',
            details: [
              {
                label: 'Members with opportunity',
                value: res?.data?.memberWithOpportunity,
              },
              {
                label: 'Members with complex HCCs​',
                value: res?.data?.memberWithComplexHcc,
              },
              {
                label: 'Average HCCs/member',
                value: res?.data?.avgHCC_Member,
              },
              {
                label: 'Average pages/member',
                value: res?.data?.averagePage_Member,
              },
              {
                label: 'RAF Score captured / member',
                value: res?.data?.avgRafScoreCaptured,
              },
              {
                label: 'Average risk opportunity',
                value: res?.data?.avg_riskScoreOpportunity,
              },
            ],
          },
          diagnosis: {
            count: res?.data?.totalDiagnosisCode,
            title: 'Total Diagnosis',
            details: [
              {
                label: 'Total HCCs Opportunity',
                value: res?.data?.totalHCC_Opportunity,
              },
              {
                label: 'Top 5 Dxs suggested',
                value: res?.data?.top5DxCodeSuggested
                  ?.map((item) => item?.code)
                  .join(', '),
              },
              {
                label: 'Top 5 HCCs suggested',
                value: res?.data?.top5HccCodeSuggested
                  ?.map((item) => item?.code)
                  .join(', '),
              },
              {
                label: 'Top 5 Dxs captured',
                value: res?.data?.top5DxCodeCaptured
                  ?.map((item) => item?.code)
                  .join(', '),
              },
              {
                label: 'Top 5 HCCs captured',
                value: res?.data?.top5HccCodeCaptured
                  ?.map((item) => item?.code)
                  .join(', '),
              },
              {
                label: 'Top 5 Dxs accepted',
                value: res?.data?.top5DxAccepted
                  ?.map((item) => item?.code)
                  .join(', '),
              },
              {
                label: 'Top 5 DXs rejected',
                value: res?.data?.top5DxRejected
                  ?.map((item) => item?.code)
                  .join(', '),
              },
              {
                label: 'Top 5 DXs added',
                value: res?.data?.top5DxAdded
                  ?.map((item) => item?.code)
                  .join(', '),
              },
            ],
          },
        });
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const renderLoadingOrNoData = (data, loading) => {
    if (loading) {
      return <Skeleton height={250} width={250} variant="rounded" />;
    }

    if (!data || data.length === 0) {
      return <Typography>No data available</Typography>;
    }

    return null;
  };

  return (
    <>
      <Box className={classes.stats}>
        <Box>
          <Typography variant="h5">
            <span>Queues: </span>
            <span>{stats?.queues}</span>
          </Typography>
          <Typography variant="h5">
            <span>Projects: </span>
            <span>{stats?.projects}</span>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            <span>Average risk opportunity: </span>
            <span>{stats?.aro}</span>
          </Typography>
          <Typography variant="h6">
            <span>RAF Score captured / member: </span>
            <span>{stats?.rafsc}</span>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.container}>
        <ReportCard
          icon={<ChartsIcon />}
          count={report?.charts?.count}
          label={report?.charts?.title}
          list={report?.charts?.details}
          loading={loading}
        />
        <ReportCard
          icon={<MemberIcon />}
          count={report?.members?.count}
          label={report?.members?.title}
          list={report?.members?.details}
          loading={loading}
        />
        <ReportCard
          icon={<MagnifyingGlassPlusIcon />}
          count={report?.diagnosis?.count}
          label={report?.diagnosis?.title}
          list={report?.diagnosis?.details}
          loading={loading}
          wide
        />
      </Box>
      {/* <Box className={classes.ChartContainer}> */}
      {/*   <Box> */}
      {/*     {renderLoadingOrNoData(reportChartStatus, loadingChart) || ( */}
      {/*       <MyPieChart data={reportChartStatus} isPercentageVal radius={100} /> */}
      {/*     )} */}
      {/*   </Box> */}
      {/*   <Box */}
      {/*     style={{ */}
      {/*       flex: '1', */}
      {/*       display: 'flex', */}
      {/*       justifyContent: 'center', */}
      {/*       alignItems: 'center', */}
      {/*     }} */}
      {/*   > */}
      {/*     {renderLoadingOrNoData(totalMember, loadingChart) || ( */}
      {/*       <MyBarChart riskScore={riskScore} totalMember={totalMember} /> */}
      {/*     )} */}
      {/*   </Box> */}
      {/*   <Box> */}
      {/*     {renderLoadingOrNoData(topHcc, loadingChart) || ( */}
      {/*       <MyPieChart */}
      {/*         data={topHcc} */}
      {/*         title={'Top 5% HCCs'} */}
      {/*         isPercentageVal */}
      {/*         radius={100} */}
      {/*       /> */}
      {/*     )} */}
      {/*   </Box> */}
      {/* </Box> */}
    </>
  );
};

export default Reports;
