import { Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import PrivateRoute from './privateroute';
import ProtectedRoute from './protectedroute';
import { routePages } from 'config/page-menu.config';
import Loading from 'components/loading';

// <Route component={NotFound} />
const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          {routePages.map((page) => {
            if (page.isProtectedRoute) {
              return (
                <ProtectedRoute
                  key={page.path}
                  path={page.path}
                  component={page.component}
                  check={page.check}
                />
              );
            }
            switch (page.isPrivateRoute) {
              case true:
                return (
                  <PrivateRoute
                    path={page.path}
                    roles={page.roles}
                    component={page.component}
                    {...(page.exact && { exact: page.exact })}
                  />
                );

              default:
                return <Route path={page.path} component={page.component} />;
            }
          })}
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
