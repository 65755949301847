import { useState } from 'react';
import useStyles from './style';

const {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion,
  Checkbox,
  Box,
} = require('@mui/material');

const DropdownCheckList = ({
  title,
  list,
  handleSupportDocChange,
  focusId,
  disabled = false,
}) => {
  const [expanded, setExpanded] = useState();
  const classes = useStyles();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const AngleIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      style={style}
    >
      <path
        d="M9.62349 18.5129L8.25195 17.1483L12.7584 12.6647L8.25195 8.181L9.62349 6.81641L15.5015 12.6647L9.62349 18.5129Z"
        fill="#1C2129"
      />
    </svg>
  );

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      className={classes.accordion}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        className={classes.accordionSummary}
        expandIcon={<AngleIcon />}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {list ? (
          list.map((listItem, idx) => (
            <Box className={classes.checklistItem} key={listItem.id}>
              <Checkbox
                id={listItem.id}
                checked={listItem.isVerified}
                onChange={handleSupportDocChange}
                className={classes.check}
                disabled={disabled}
              />
              <Typography
                paragraph
                className={classes.attributeCheck}
                onClick={() => {
                  document.getElementById(`${focusId}Attribute${idx}`)?.focus();
                }}
              >
                {listItem.value}
              </Typography>
            </Box>
          ))
        ) : (
          <></>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DropdownCheckList;
