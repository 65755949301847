import { claimAPI } from 'config/api-routlink';
import request from 'shared/service/request';

export const find = async ({ pageno, rowsPerPage, sort, filter }) => {
  const rdata = await request.get(claimAPI.MEMBER_FIND, {
    params: {
      pageno: pageno,
      rowsPerPage: rowsPerPage,
      sort: sort,
      filter: filter,
    },
  });

  return {
    count: rdata.data?.data?.recordCount,
    records: rdata.data?.data?.records,
  };
};

export const getMemberByUid = async (memberUID) => {
  const rdata = await request.get(`${claimAPI.VIEW_MEMBER}/${memberUID}`, {});
  return {
    data: rdata.data?.data,
  };
};

// GET request - Summarize Member data with filter
export const distinctValues = async ({ filter, field }) => {
  const rdata = await request.get(claimAPI.MEMBER_DISTINCT_VALUES, {
    params: {
      filter: filter,
      field: field,
    },
  });

  return {
    data: rdata.data?.data,
  };
};
