import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { en } from 'config/metadata/en';
import useControlEvent from 'utils/controlevent';
import { TextBox, Button, Box, Typography } from 'components/controls';
import CopyRights from 'components/copyrights';

import { forgetpassw } from './service';
import { AppAction } from 'state/app';
import { VERSION } from 'version';
import { IconList } from 'icons';
import Notification from 'components/notification';
import { UserAction } from 'state/user';

import useStyles from './style';

const ForgetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { controlVal, controlErrorVal, onFieldValueChange } = useControlEvent({
    username: '',
  });

  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [loading, setLoading] = useState(false);

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await forgetpassw({
        username: controlVal.username,
      });
      setIsSuccess(true);
      setOpenNotification(true);

      dispatch(
        AppAction.AppAll({
          version: VERSION.version,
        })
      );
      dispatch(UserAction.UserAll({}));
      setTimeout(() => {
        history.push(
          `/confirmpassw?qusername=${controlVal.username}&qtype=FORGOT_PASSWORD`
        );
      }, 3000);
    } catch (e) {
      setIsSuccess(false);
      setOpenNotification(true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.container} component="main">
      <Box className={classes.bgShape}>
        {/* <img src="/assets/shapes/shape1.png" alt="" />
        <img src="/assets/shapes/shape2.png" alt="" /> */}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.paper}>
          <Box>{/* <img src="/assets/images/login-image.svg" /> */}</Box>
          <span className={classes.hr} />
          <Box className={classes.form}>
            <Avatar className={classes.logo}>{IconList.User}</Avatar>
            {/* <img src="/logo2.png" alt="logo" className={classes.logo} /> */}
            <Typography component="h1" variant="h5">
              {en.forgotpassw.title}
            </Typography>
            <form autoComplete="off" noValidate onSubmit={resetPassword}>
              <TextBox
                name="username"
                label={en.forgotpassw.username}
                value={controlVal.username}
                onChange={onFieldValueChange}
                error={controlErrorVal.username}
                autoFocus
                required
                fullWidth
              />

              <Button
                label={en.forgotpassw.submit}
                fullWidth
                startIcon={IconList.Email}
                className={classes.submit}
                disabled={loading}
              />
              <Notification
                type={isSuccess ? 'success' : 'error'}
                message={
                  isSuccess ? 'Code sent successfully' : 'Invalid username'
                }
                openNotification={openNotification}
                setOpenNotification={setOpenNotification}
              />
            </form>
            <Box>
              <CopyRights />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ForgetPassword.propTypes = {};
export default ForgetPassword;
