import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  table: {
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    width: 200,
    height: 200,
  },
});

export default useStyles;
