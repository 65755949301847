import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

import { Tab, Tabs } from '@mui/material';

import { Box } from 'components/controls';

import TabPanel from './tabpanel';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // display: 'flex',
    // width: '100%',
    '& .MuiTabs-root': {
      '& .MuiButtonBase-root': {
        backgroundColor: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderTop: 'none',
        borderBottom: '2px solid rgba(0, 0, 0, 0.25)',
        color: '#000',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        color: 'rgba(42, 160, 23, 0.75)',
      },
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '25%',
    height: '90%',
  },
  tab: {
    fontWeight: 'bold',
    backgroundColor: grey[100],
  },
  tabpanel: {
    // width: '100%',
    // height: '55vh',
    overflow: 'auto',
  },
}));

const HorizontalTabs = ({ tabList, classNameTabpanel }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      //className={clsx(classes.root, className)}
      className={classes.root}
    >
      <Box>
        <Tabs
          //  orientation="horizontal"
          //  variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Horizontal tabs"
          // className={classes.tabs}
          indicatorColor="secondary"
          textColor="secondary"
          //style= {{height: "90%"}}
        >
          {tabList.map((item, index) => {
            return (
              <Tab
                key={index}
                className={classes.tab}
                label={item.label}
                {...a11yProps({ index })}
              />
            );
          })}
        </Tabs>
      </Box>
      {
        // Tab panel html mapping
        tabList.map((item, index) => {
          return (
            <TabPanel
              key={index}
              className={classNameTabpanel || classes.tabpanel}
              value={value}
              index={index}
            >
              {item.content}
            </TabPanel>
          );
        })
      }
    </div>
  );
};

export default HorizontalTabs;
