import { mfaAPI } from 'config/api-routlink';
import request from 'shared/service/request';

export const Setupsendcode = async () => {
  try {
    const rdata = await request.get(mfaAPI.MFA_SETUP_SEND_CODE);
    const message = rdata.data.message;
    const success = rdata.data.success;
    return { success, message };
  } catch (error) {
    const success = error.response.data.success;
    const message = error.response.data.message;
    return { success, message };
  }
};

export const Loginsendcode = async () => {
  try {
    const rdata = await request.get(mfaAPI.MFA_LOGIN_SEND_CODE);
    const message = rdata.data.message;
    const success = rdata.data.success;
    return { success, message };
  } catch (error) {
    const success = error.response.data.success;
    const message = error.response.data.message;
    return { success, message };
  }
};

export const Setupverifycode = async ({ oneTimePassword }) => {
  try {
    const rdata = await request.post(mfaAPI.MFA_SETUP_VERIFY_CODE, {
      oneTimePassword,
    });
    const message = rdata.data.message;
    const success = rdata.data.success;
    const data = rdata.data.data;
    return { success, message, data };
  } catch (error) {
    const success = error.response.data.success;
    const message = error.response.data.message;
    const data = '';
    return { success, message, data };
  }
};

export const Loginverifycode = async ({ oneTimePassword }) => {
  try {
    const rdata = await request.post(mfaAPI.MFA_LOGIN_VERIFY_CODE, {
      oneTimePassword,
    });
    const message = rdata.data.message;
    const success = rdata.data.success;
    const data = rdata.data.data;
    return { success, message, data };
  } catch (error) {
    const success = error.response.data.success;
    const message = error.response.data.message;
    const data = '';
    return { success, message, data };
  }
};

export const Setupcheckcookie = async () => {
  try {
    const rdata = await request.get(mfaAPI.MFA_SETUP_VERIFY_COOKIE);
    const success = rdata.data.success;
    return success;
  } catch (error) {
    const success = error.response.data.success;
    return success;
  }
};

export const Logincheckcookie = async () => {
  try {
    const rdata = await request.get(mfaAPI.MFA_LOGIN_VERIFY_COOKIE);
    const success = rdata.data.success;
    return success;
  } catch (error) {
    const success = error.response.data.success;
    return success;
  }
};

//useful when auth app is also used
// export const Loginverifycode = async ({ oneTimePassword, authdone }) => {
//   const rdata = await request.post(mfaAPI.MFA_LOGIN_VERIFY_CODE, {
//     oneTimePassword,
//   },
// {
//   params: {
//     authdone : authdone
//   }
// });

//   return {
//     data: rdata.data.data,
//   };
// };

// export const Setupverifycode = async ({ oneTimePassword, authdone }) => {
//   const rdata = await request.post(mfaAPI.MFA_SETUP_VERIFY_CODE, {
//     oneTimePassword,
//   },
// {
//   params: {
//     authdone : authdone
//   }
// });

//   return {
//     data: rdata.data.data,
//   };
// };
