import { useMemo } from 'react';
import { createStore } from '@react-pdf-viewer/core';

const CustomZoomPlugin = () => {
  const store = useMemo(() => createStore({}), []);

  return {
    install: (pluginFunctions) => {
      store.update('zoom', pluginFunctions.zoom);
    },
    zoomTo: (scale) => {
      const zoom = store.get('zoom');

      if (zoom) {
        zoom(scale);
      }
    },
  };
};

export default CustomZoomPlugin;
