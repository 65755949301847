import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { IconButton, InputAdornment } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { TextBox } from 'components/controls';

const MainContent = ({ sectionFields, selectedSectionId }) => {
  return (
    <List
      //onScroll={onScroll}
      // onScrollCapture={onScrollCapture}
      // onSelect={onSelect}
      sx={{
        width: '100%',
        height: '100%',
        //maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        '& ul': { padding: 0 },
      }}
    >
      {sectionFields &&
        sectionFields.map((sectionItem) => (
          <>
            <ListItemButton
              component="a"
              divider
              disableGutters
              //href="#simple-list"
              key={`Section-${sectionItem.sectionId}`}
              {...(sectionItem.sectionId?.toString() ===
                selectedSectionId.toString() && {
                autoFocus: true,
              })}
            >
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                sx={{
                  backgroundColor: '#f5f4f2',
                  width: '100%',
                }}
              >
                {sectionItem.sectionPath.split('/').map((path) => (
                  <Link key={path} underline="hover" color="inherit" href="#">
                    {path}
                  </Link>
                ))}
              </Breadcrumbs>
            </ListItemButton>

            {sectionItem.fields.map((fitem) => (
              <ListItem key={fitem.title}>
                <TextBox
                  name={fitem.title}
                  label={fitem.title}
                  value={fitem.value}
                  variant="standard"
                  //disabled
                  {...(fitem.error && { error: fitem.errorDesc })}
                  FormHelperTextProps={{
                    fontSize: 9,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton size="large">
                          <EditIcon
                            color={fitem.fixed ? 'success' : 'primary'}
                          />
                        </IconButton>
                        {fitem.fixed && (
                          <IconButton size="large">
                            <InfoIcon color="success" />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
            ))}
          </>
        ))}
    </List>
  );
};

export default MainContent;
