import useStyles from './style';

const {
  FormControl,
  Input,
  FormHelperText,
  Typography,
  Box,
  Button,
} = require('@mui/material');

const FormInput = ({
  id,
  label,
  type,
  placeholder,
  value,
  error,
  errorMsg,
  required,
  fullWidth,
  onFormValueChange,
  name,
  disabled = false,
  icd10 = false,
  onICDSelect,
}) => {
  const classes = useStyles();
  const today = new Date().toISOString().split('T')[0];

  return icd10 ? (
    <>
      <Box className={classes.icdContainer}>
        <Typography
          className={classes.label}
          sx={
            required
              ? {
                  '&:after': {
                    content: '"*"',
                    color: 'red',
                    marginLeft: '3px',
                  },
                }
              : {}
          }
        >
          {label}
        </Typography>
        <Button className={classes.icdselect} onClick={onICDSelect}>
          Select
        </Button>
      </Box>
      <FormControl
        error={error}
        variant="standard"
        className={classes.formControl}
        fullWidth={fullWidth}
      >
        <Input
          id={id}
          value={value}
          className={classes.formField}
          placeholder={placeholder}
          type={type}
          onChange={onFormValueChange}
          name={name}
          disabled
          inputProps={{
            max: type === 'date' ? today : undefined,
          }}
        />
        <FormHelperText
          id="component-error-text"
          className={classes.helperText}
        >
          {errorMsg}
        </FormHelperText>
      </FormControl>
    </>
  ) : (
    <>
      <Typography
        className={classes.label}
        sx={
          required
            ? {
                '&:after': {
                  content: '"*"',
                  color: 'red',
                  marginLeft: '3px',
                },
              }
            : {}
        }
      >
        {label}
      </Typography>
      <FormControl
        error={error}
        variant="standard"
        className={classes.formControl}
        fullWidth={fullWidth}
      >
        <Input
          id={id}
          value={value}
          className={classes.formField}
          placeholder={placeholder}
          type={type}
          onChange={onFormValueChange}
          name={name}
          disabled={disabled}
          inputProps={{
            max: type === 'date' ? today : undefined,
          }}
        />
        <FormHelperText
          id="component-error-text"
          className={classes.helperText}
        >
          {errorMsg}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default FormInput;
