import React, { useState, useEffect, useRef, useCallback } from 'react';
import useStyles from './style';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  ListItemIcon,
  Select,
  CircularProgress,
} from '@mui/material';
import {
  ReturnArrowIcon,
  InfoIcon,
  QueueReportIcon,
  AssignUserIcon,
  PencilIcon,
} from 'icons/customIcons';
import FilterInfo from './filter-info';
import AssignUser from './assignUser';
import {
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { queueAPI } from 'api';
import DataRow from 'components/controls/dataRow';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import LabelBtn from 'components/labelBtn';
import ReportsSection from 'components/reportsSection';
import { getQueueUsers, getReportByQueueId } from 'api/queue';
import { assignUserByChartId } from 'api/charts';
import BulkAssignDialog from './bulkAssignDialog';
import Notification from 'components/notification';

const Queue = ({
  handleBackBtn,
  queueId,
  selectedQueue,
  onChartOpen = () => {},
  setSelectedQueue = () => {},
  setQueueId = () => {},
  setReadOnlyMode,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(selectedQueue);
  const [isFilterInfo, setIsFilterInfo] = useState(false);
  const [queueCurrentScreen, setQueueCurrentScreen] = useState('queue');
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [chartsList, setChartsList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [queueList, setQueueList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [userRole, setUserRole] = useState('');
  const userRoles = useSelector(UserSelector.getUserRoles);
  const userDetails = useSelector(UserSelector.getUserDetail);
  const [openReportsSection, setOpenReportsSection] = useState(false);
  const [reportData, setReportData] = useState({});
  const [assignLoading, setAssignLoading] = useState({});
  const [prevPage, setPrevPage] = useState(page - 1);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [checkedRows, setCheckedRows] = useState([]);
  const [openBulkAssignDialog, setOpenBulkAssignDialog] = useState(false);
  const userRoleRef = useRef(userRole);
  const [dataRowFilter, setDataRowFilter] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('error');
  const [tableHeight, setTableHeight] = useState(
    window.innerHeight >= 1080 ? 600 : 590
  );

  useEffect(() => {
    userRoleRef.current = userRole;
  }, [userRole]);

  // variable screen height
  useEffect(() => {
    const updateHeight = () => {
      setTableHeight(window.innerHeight >= 1080 ? 800 : 590);
    };

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    const fetchChartsQueueFilter = async (
      pageNumber,
      queueId,
      filter,
      prevChartsList = []
    ) => {
      try {
        // Fetch queue users
        const usersRes = await getQueueUsers(queueId);
        const thisQueueUsers = usersRes?.data || [];

        // Fetch charts data
        const res = await queueAPI.getChartsByQueueId({
          queueID: queueId,
          rowsPerPage: 50,
          pagenum: pageNumber,
          filter: filter,
        });

        if (res?.data?.length === 0) {
          setChartsList(prevChartsList);
          setSnackbarMessage('No record found!');
          setSnackbarOpen(true);
          return;
        }

        setPrevPage((prev) => prev + 1);
        setFilterData(res?.data[0]?.filterCondition || {});
        setSelectedOption({
          _id: res?.data[0]?.queueID,
          queueName: res?.data[0]?.queueName,
          queueUsers: res?.data[0]?.queueUsers,
        });
        setTotalCount(res?.data[0]?.chartCount);

        updateChartsListWithNewData(res, thisQueueUsers);
        // exception
        updateChartsListWithoutNewData(thisQueueUsers);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching charts:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (dataRowFilter && Object.keys(dataRowFilter).length > 0) {
      const transformFilter = toCamelCase(dataRowFilter);
      const prevChartsList = [...chartsList];
      setPage(0);
      setPrevPage(-1);
      setChartsList([]);
      setIsLoading(true);
      fetchChartsQueueFilter(page, queueId, transformFilter, prevChartsList);
    }
  }, [dataRowFilter]);

  useEffect(() => {
    if (queueId) {
      const transformFilter = toCamelCase(dataRowFilter);
      fetchCharts(page, queueId, transformFilter);
    }
  }, [page, queueId, assignLoading, selectedUsers, queueCurrentScreen]);

  useEffect(() => {
    const fetchQueueList = async () => {
      const res = await queueAPI.getQueuesList({
        rowsPerPage: 30,
        pageno: 1,
      });

      let options = res?.data?.records || [];
      if (selectedOption) {
        options = options.filter((option) => option._id !== selectedOption._id);
      }
      setOptions(options);
      setQueueList(res?.data?.records);
    };

    fetchQueueList();
  }, []);

  useEffect(() => {
    const determineUserRole = () => {
      const queueUsers = selectedOption?.queueUsers;
      const currentUser = JSON.parse(sessionStorage.getItem('rstate'))?.user;
      if (currentUser && queueUsers) {
        const matchedUser = queueUsers.find(
          (user) => user.userid === currentUser.detail.userid
        );
        setUserRole(matchedUser?.role || userRoles);
      } else {
        setUserRole(userRoles);
      }
    };

    if (selectedOption) {
      determineUserRole();
    }
  }, [selectedOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getReportByQueueId(queueId);
        setReportData(res?.data);
      } catch (error) {
        console.error({ error });
      }
    };
    if (userRoles === 'SuperAdmin' || userRoles === 'ClientAdmin') {
      if (queueId) {
        fetchData();
      }
    }
  }, [queueId]);

  const updateChartsListWithNewData = (res, thisQueueUsers) => {
    setChartsList((prevData) => [
      ...prevData,
      ...res?.data[0]?.chartsList.map((record) => ({
        id: record?._id,
        fields: [
          { field: 'ID', value: record?.memberID, space: 12 },
          { field: 'First Name', value: record?.memberFirstName, space: 20 },
          { field: 'Last Name', value: record?.memberLastName, space: 20 },
          { field: 'Gender', value: record?.memberGender, space: 1 },
          { field: 'DOB', value: record?.memberDOB, space: 10 },
          { field: 'Project Name', value: record?.projectName, space: 20 },
          { field: 'Pages', value: record?.numberOfPages, space: 4 },
          {
            field: 'Total Dx Suggested',
            value: record?.totalDxSuggested || 0,
            space: 3,
          },
          { field: 'User Added Dx', value: record?.userAddedDx, space: 3 },
          {
            field: 'Total Dx Accepted',
            value: record?.totalDxAccepted,
            space: 3,
          },
          {
            field: 'Claim Confirmed',
            value: record?.claimConfirmedCount,
            space: 3,
          },
          {
            field: 'Claim Deleted',
            value: record?.claimDeletedCount,
            space: 3,
          },
          { field: 'Phase', value: record?.chartStatus, space: 12 },
          {
            field: 'Assignee',
            value:
              record?.chartStatus?.toLowerCase() === 'processed' ||
              record?.chartStatus?.toLowerCase() === 'coded' ? (
                <Box className={classes.assignmentContainer}>
                  <Select
                    variant="filled"
                    defaultValue="na"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedUsers((prev) => ({
                        ...prev,
                        [record._id]: {
                          value: e.target.value?.split('--')[0],
                          text: e.target.value?.split('--')[1],
                        },
                      }));
                    }}
                    className={
                      userRoleRef.current === 'analyst' ||
                      userRoleRef.current === 'reviewer'
                        ? classes.assigneeDropDownDisabled
                        : classes.assigneeDropDown
                    }
                    disabled={
                      userRoleRef.current === 'analyst' ||
                      userRoleRef.current === 'reviewer'
                    }
                  >
                    <MenuItem
                      value="na"
                      key={String(Date.now())}
                      className={classes.menuItemCell}
                      name="Unassigned"
                      disabled
                    >
                      <Typography
                        variant="h6"
                        className={classes.menuItemLabel}
                      >
                        Unassigned
                      </Typography>
                      <Typography
                        paragraph
                        className={classes.menuItemSubLabel}
                      >
                        N/A
                      </Typography>
                    </MenuItem>
                    {thisQueueUsers
                      ?.filter((user) =>
                        record?.chartStatus?.toLowerCase() === 'processed'
                          ? user?.role === 'analyst'
                            ? user
                            : false
                          : record?.chartStatus?.toLowerCase() === 'coded'
                          ? user?.role === 'reviewer'
                            ? user
                            : false
                          : 'N/A'
                      )
                      .map((user) => (
                        <MenuItem
                          value={`${user?._id}--${user?.name}`}
                          key={user?._id}
                          className={classes.menuItemCell}
                          name={user?.name}
                        >
                          <Typography
                            variant="h6"
                            className={classes.menuItemLabel}
                          >
                            {user?.name}
                          </Typography>
                          <Typography
                            paragraph
                            className={classes.menuItemSubLabel}
                          >
                            {user?.role}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              ) : record?.chartStatus?.toLowerCase() === 'code' ? (
                record?.chartUserAssignment?.coder?.name
              ) : record?.chartStatus?.toLowerCase() === 'review' ||
                record?.chartStatus?.toLowerCase() === 'validated' ? (
                record?.chartUserAssignment?.reviewer?.name
              ) : (
                'unassigned'
              ),
            space: 15,
          },
          {
            field: 'Updated Date',
            value: record?.lastUpdatedBy?.updatedDate
              ? new Date(
                  record?.lastUpdatedBy?.updatedDate.$date
                ).toLocaleDateString()
              : 'N/A',
            space: 10,
          },
        ],
        disableOpen: Boolean(record?.chartStatus === 'in_process'),
        expansionContent: (
          <Box className={classes.description}>
            <Typography variant="h6">Description:</Typography>
            <Typography paragraph>{record?.projectName}</Typography>
          </Box>
        ),
        record,
      })),
    ]);
  };

  const updateChartsListWithoutNewData = (thisQueueUsers) => {
    setChartsList((prevData) =>
      prevData?.map((data) => {
        if (Object.keys(assignLoading)?.includes(data?.id)) {
          if (assignLoading[data?.id]) {
            return {
              ...data,
              fields: data.fields?.map((field) =>
                field.field?.toLowerCase() === 'assignee'
                  ? {
                      field: 'Assignee',
                      value: (
                        <CircularProgress
                          className={classes.singleAssignSpinner}
                        />
                      ),
                    }
                  : field
              ),
            };
          }

          return {
            ...data,
            fields: data.fields?.map((field) => {
              if (field.field?.toLowerCase() === 'assignee') {
                return {
                  field: 'Assignee',
                  value: selectedUsers[data?.id]?.text,
                };
              }

              if (field.field?.toLowerCase() === 'phase') {
                const newPhase =
                  data.record.chartStatus?.toLowerCase() === 'processed'
                    ? 'code'
                    : field.value;

                return {
                  field: 'Phase',
                  value: newPhase,
                };
              }

              return field;
            }),
          };
        }

        return {
          ...data,
          fields: data.fields?.map((field) => {
            if (field.field?.toLowerCase() === 'assignee') {
              return {
                field: 'Assignee',
                value: ['processed', 'coded'].includes(
                  data.record.chartStatus?.toLowerCase()
                ) ? (
                  <Box className={classes.assignmentContainer}>
                    <Select
                      variant="filled"
                      value={
                        selectedUsers[data.id]?.value
                          ? `${selectedUsers[data.id]?.value}--${
                              selectedUsers[data.id]?.text
                            }`
                          : 'na'
                      }
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        const [userId, userName] = e.target.value.split('--');
                        setSelectedUsers((prev) => ({
                          ...prev,
                          [data.id]: { value: userId, text: userName },
                        }));
                      }}
                      className={classes.assigneeDropDown}
                    >
                      <MenuItem value="na" disabled>
                        <Typography
                          variant="h6"
                          className={classes.menuItemLabel}
                        >
                          Unassigned
                        </Typography>
                        <Typography
                          paragraph
                          className={classes.menuItemSubLabel}
                        >
                          N/A
                        </Typography>
                      </MenuItem>
                      {thisQueueUsers
                        ?.filter((user) =>
                          data.record.chartStatus?.toLowerCase() === 'processed'
                            ? user.role === 'analyst'
                            : data.record.chartStatus?.toLowerCase() === 'coded'
                            ? user.role === 'reviewer'
                            : false
                        )
                        .map((user) => (
                          <MenuItem
                            value={`${user._id}--${user.name}`}
                            key={user._id}
                            className={classes.menuItemCell}
                          >
                            <Typography
                              variant="h6"
                              className={classes.menuItemLabel}
                            >
                              {user.name}
                            </Typography>
                            <Typography
                              paragraph
                              className={classes.menuItemSubLabel}
                            >
                              {user.role}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>

                    {/* Ensure Check icon appears only for the selected user */}
                    {selectedUsers[data.id]?.value &&
                      selectedUsers[data.id]?.value !== 'na' && (
                        <IconButton
                          className={classes.assignButton}
                          onClick={async (e) => {
                            e.stopPropagation();
                            const { value: userId } = selectedUsers[data.id];

                            if (userId && userId !== 'na') {
                              setAssignLoading((prev) => ({
                                ...prev,
                                [data.id]: true,
                              }));

                              const assignedRole =
                                data.record.chartStatus?.toLowerCase() ===
                                'processed'
                                  ? 'coder'
                                  : 'reviewer';

                              await assignUserByChartId({
                                chartId: data.id,
                                userId,
                                projectId: data.record.projectID,
                                queueId,
                                role: assignedRole,
                              });

                              setChartsList((prevData) =>
                                prevData.map((chart) =>
                                  chart.id === data.id
                                    ? {
                                        ...chart,
                                        fields: chart.fields.map((field) =>
                                          field.field.toLowerCase() === 'phase'
                                            ? {
                                                ...field,
                                                value:
                                                  assignedRole === 'reviewer'
                                                    ? 'review'
                                                    : field.value,
                                              }
                                            : field
                                        ),
                                      }
                                    : chart
                                )
                              );

                              setAssignLoading((prev) => ({
                                ...prev,
                                [data.id]: false,
                              }));
                            }
                          }}
                        >
                          <Check />
                        </IconButton>
                      )}
                  </Box>
                ) : data.record.chartStatus?.toLowerCase() === 'code' ? (
                  data.record.chartUserAssignment?.coder?.name
                ) : ['review', 'validated'].includes(
                    data.record.chartStatus?.toLowerCase()
                  ) ? (
                  data.record.chartUserAssignment?.reviewer?.name
                ) : (
                  'unassigned'
                ),
              };
            }

            return field;
          }),
        };
      })
    );
  };

  const fetchCharts = useCallback(
    async (pageNumber, queueId, filter, prevChartsList = []) => {
      try {
        if (!openBulkAssignDialog) {
          if (pageNumber > prevPage) {
            setIsLoading(true);
            setPrevPage((prev) => prev + 1);
          }

          const usersRes = await getQueueUsers(queueId);
          const thisQueueUsers = usersRes?.data;

          const pagenum = pageNumber;
          const res = await queueAPI.getChartsByQueueId({
            queueID: queueId,
            rowsPerPage: 50,
            pagenum: pagenum,
            filter: filter,
          });

          if (res?.data?.length === 0) {
            setChartsList(prevChartsList);
            setSnackbarMessage('No record found!');
            setSnackbarOpen(true);
            return;
          }

          setPrevPage((prev) => prev + 1);
          setFilterData(res?.data[0]?.filterCondition || {});
          setSelectedOption({
            _id: res?.data[0]?.queueID,
            queueName: res?.data[0]?.queueName,
            queueUsers: res?.data[0]?.queueUsers,
          });
          setTotalCount(res?.data[0]?.chartCount);

          if (pageNumber > prevPage) {
            updateChartsListWithNewData(res, thisQueueUsers);
          } else {
            updateChartsListWithoutNewData(thisQueueUsers);
          }
        }
      } catch (error) {
        console.error('Error fetching charts:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [assignLoading, selectedUsers, queueCurrentScreen]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIsFilterInfo = () => {
    setIsFilterInfo(false);
  };

  const handleIsFilterInfoOpen = () => {
    setIsFilterInfo(true);
  };

  const handleSelect = (option) => {
    setQueueId(option._id);
    setSelectedOption(option);
    setSelectedQueue(option);
    setOpenReportsSection(false);
    setOptions((prevOptions) => {
      return queueList.filter((queue) => queue._id !== option._id);
    });

    setPage(0);
    setPrevPage(-1);
    setChartsList([]);
    setDataRowFilter({});

    handleClose();
  };

  const handleReportOpen = () => {
    setOpenReportsSection((prev) => !prev);
  };

  const handleBulkAssignment = () => {
    setOpenBulkAssignDialog(true);
  };

  const handleCheckChange = (rec) => {
    setCheckedRows(rec);
  };

  const renderContent = () => {
    switch (queueCurrentScreen) {
      case 'assignUser':
        return (
          <Box className={classes.assignUserScreen}>
            <AssignUser
              setCurrentScreen={setQueueCurrentScreen}
              selectQueue={selectedOption}
            />
          </Box>
        );
      case 'queue':
      default:
        return (
          <>
            <Box className={classes.queueContainer}>
              <Box className={classes.header}>
                <Box className={classes.headerLeft}>
                  <Button
                    className={classes.queueBackBtn}
                    onClick={handleBackBtn}
                  >
                    <ReturnArrowIcon />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClick}
                    className={classes.dropdownButton}
                    endIcon={
                      anchorEl ? <KeyboardArrowDown /> : <KeyboardArrowRight />
                    }
                  >
                    <Typography
                      sx={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '18px',
                      }}
                    >
                      {selectedOption.queueName || 'Loading...'}
                    </Typography>
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.menu}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option?._id}
                        onClick={() => handleSelect(option)}
                        className={classes.menuItem}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            fontSize: '17px',
                          }}
                        >
                          <Typography
                            sx={{
                              display: 'block',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              marginRight: '8px',
                            }}
                          >
                            {option.queueName}
                          </Typography>
                          <ListItemIcon>
                            <PencilIcon />
                          </ListItemIcon>
                        </Box>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box className={classes.headerRight}>
                  <LabelBtn
                    label="Selected filter"
                    singleIcon={InfoIcon}
                    singleAction={handleIsFilterInfoOpen}
                  />
                  {/* {(userRoles === 'SuperAdmin' ||
                    userRoles === 'ClientAdmin') &&
                    ((typeof checkedRows === 'boolean' && checkedRows) ||
                      checkedRows?.filter(
                        (row) =>
                          row?.record?.chartStatus?.toLowerCase() ===
                          'processed'
                      )?.length !== 0) && (
                      <Box className={classes.bulkAssignLogo}>
                        <Tooltip
                          title="Bulk Assign"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <IconButton onClick={handleBulkAssignment}>
                            <PlaylistAddCheck />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )} */}
                  {(userRoles === 'SuperAdmin' ||
                    userRoles === 'ClientAdmin') && (
                    <Box className={classes.reportLogo}>
                      <Tooltip
                        title="Report"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton onClick={handleReportOpen}>
                          <QueueReportIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {(userRoles === 'SuperAdmin' ||
                    userRoles === 'ClientAdmin') && (
                    <Box className={classes.reportLogo}>
                      <Tooltip
                        title="Assign User"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          onClick={() => setQueueCurrentScreen('assignUser')}
                        >
                          <AssignUserIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <FilterInfo
                    open={isFilterInfo}
                    onClose={handleIsFilterInfo}
                    filterData={filterData}
                    queueName={selectedOption.queueName}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '10px',
                }}
              >
                {userRole && (
                  <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>
                    Queue Role: {userRole}
                  </Typography>
                )}
              </Box>
              <Box sx={{ marginBottom: '15px' }}>
                {Object.entries(reportData).length === 0 ? (
                  <></>
                ) : (
                  <ReportsSection
                    props={reportData}
                    expand={openReportsSection}
                  />
                )}
              </Box>
              <Box className={classes.tableContainer}>
                <DataRow
                  width="200%"
                  // tableHeight={340}
                  tableHeight={tableHeight}
                  totalRows={totalCount}
                  loading={isLoading}
                  placeholderCount={3}
                  emptyDataMessage="No Charts available yet"
                  data={chartsList}
                  onOpen={(event, record) => {
                    (userRole === 'reviewer' &&
                      record?.isReviewed?.reviewStatus === true) ||
                    (userRole === 'analyst' &&
                      record?.isSubmitted?.codedStatus === true) ||
                    ((userRole === 'supervisor' ||
                      userRole === 'SuperAdmin' ||
                      userRole === 'ClientAdmin') &&
                      record?.lockStatus?.isLocked === true)
                      ? setReadOnlyMode(true)
                      : setReadOnlyMode(false);
                    onChartOpen(event, {
                      ...record,
                      queueName: selectedOption?.queueName,
                      queueRole: userRole,
                      queueId: selectedOption?._id,
                    });
                  }}
                  onItemCheck={handleCheckChange}
                  onLoadMore={() => setPage((prevPage) => prevPage + 1)}
                  allowRowExpansion
                  defaultExpanded
                  virtualized
                  hidePlusBtn
                  allowChecks
                  showFilter
                  setFilter={setDataRowFilter}
                  filter={dataRowFilter}
                />
              </Box>
            </Box>
            <BulkAssignDialog
              openBulkAssignDialog={openBulkAssignDialog}
              setOpenBulkAssignDialog={setOpenBulkAssignDialog}
              queueId={queueId}
              setChartsList={setChartsList}
              chartList={
                typeof checkedRows === 'boolean' && checkedRows
                  ? chartsList
                  : checkedRows
              }
            />
            <Notification
              type={snackbarType}
              message={snackbarMessage}
              openNotification={snackbarOpen}
              setOpenNotification={setSnackbarOpen}
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

const toCamelCase = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, '');
    acc[camelKey] = obj[key];
    return acc;
  }, {});
};

export default Queue;
