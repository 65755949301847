import { Fragment } from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

const RadioButtonGroup = (props) => {
  const { name, label, value = '', row, onChange, items } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        sx={{
          '.Mui-checked': { color: '#000 !important' },
        }}
        {...(row && { row })}
      >
        {items.map((item) => (
          <Fragment key={item.value}>
            <FormControlLabel
              id={item.value}
              value={item.value}
              control={<Radio />}
              label={item.label}
              sx={{
                color: '#000',
              }}
            />
          </Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;

/************************************************************************************************
 *  Example to import Radiobuttongroup
 *   <RadioButtonGroup 
    name="rbutton"
    label="Gender"
    value= {value}            
    onChange={onChange}
    items = {[{value: 'male', label: 'Male'},{value: 'female', label: 'Female'}]}
    />
 */
