import { adminAPI } from 'config/api-routlink';
import request from 'shared/service/request';

// GET request - Get MCC project list
export const getChartsProjects = async ({
  pageno,
  rowsPerPage,
  filter,
  sort,
}) => {
  const rdata = await request.get(adminAPI.PROJECT_GET_CHART_PROJECTS, {
    params: {
      pageno,
      rowsPerPage,
      filter,
      sort,
    },
  });

  return rdata.data?.data;
};

export const getChartProjectsById = async (projectid) => {
  const rdata = await request.get(
    adminAPI.PROJECT_GET_CHART_PROJECT_BY_ID.replace(':projectid', projectid),
    {}
  );

  return rdata.data?.data;
};

export const upsertChartsProject = async ({
  projectID,
  projectName,
  projectDescription,
  priority,
  projectStartDate,
  projectEndDate,
  lineOfBusiness,
  // yearOfService,
  hccVersion,
  llm,
  QAPercentage,
}) => {
  const rdata = await request.post(adminAPI.PROJECT_UPSERT_CHART_PROJECT, {
    projectID,
    projectName,
    projectDescription,
    priority,
    projectStartDate,
    projectEndDate,
    lineOfBusiness,
    // yearOfService,
    hccVersion,
    llm,
    QAPercentage,
  });

  return rdata.data?.data;
};

// GET request - Get Retrospective Review by ChartId
export const getRetrospectiveReview = async (chartId, projectId) => {
  const rdata = await request.get(adminAPI.RETROSPECTIVE_REVIEW, {
    params: { chartId, projectId },
  });
  return {
    data: rdata.data?.data,
  };
};

// GET request - Get all users assigned to a project
export const getAssignedUsersByProjectId = async ({ projectId }) => {
  const rdata = await request.get(`${adminAPI.GET_PROJECT_USERS}/${projectId}`);
  return { data: rdata.data?.data };
};

export const getReportByProjectId = async (projectId) => {
  const rdata = await request.get(
    `${adminAPI.GET_REPORT_BY_PROJECT_ID}/${projectId}`
  );
  return { data: rdata.data?.data?.details ?? {} };
};
