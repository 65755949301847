import { Box, Button, CircularProgress, Typography } from '@mui/material';
import useStyles from './style';
import SearchBar from '../../../projects/projectListBar/searchBar';
import UserItem from './userItem';
import { useEffect, useState } from 'react';
import { queueAPI } from 'api';
import Notification from 'components/notification';

const AssignUser = ({ setCurrentScreen, selectQueue }) => {
  const classes = useStyles();
  const rowsPerPage = 20;

  const [clientUserList, setClientUserList] = useState([]);
  const [pageState, setPageState] = useState({
    pageNo: 1,
    recordCount: 0,
    totalPages: 1,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationType, setNotificationType] = useState('success');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await queueAPI.getListClientUser({
          queueID: selectQueue._id,
          pageno: pageState.pageNo,
          rowsPerPage,
        });
        setPageState((currState) => ({
          ...currState,
          recordCount: res?.data?.count,
          totalPages: Math.ceil(res?.data?.count / rowsPerPage),
        }));
        setClientUserList(res?.data?.clientUsers || []);
        setIsLoading(false);
      } catch (e) {
        setClientUserList([]);
        setIsLoading(false);
      }
    })();
  }, [pageState.pageNo, selectQueue._id]);

  const ReturnArrow = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      style={style}
    >
      <path
        d="M17.0625 28.3047C17.8594 28.3047 18.4336 27.7188 18.4336 26.9336V22.457H18.7734C23.3203 22.457 26.1797 23.6055 28.2305 27.4375C28.6406 28.1875 29.1797 28.3047 29.6719 28.3047C30.293 28.3047 30.8789 27.7422 30.8789 26.7344C30.8789 18.0742 27.2109 12.6719 18.7734 12.6719H18.4336V8.24219C18.4336 7.45703 17.8594 6.8125 17.0391 6.8125C16.4648 6.8125 16.0781 7.05859 15.457 7.64453L6.21094 16.293C5.75391 16.7266 5.61328 17.1602 5.61328 17.5586C5.61328 17.9453 5.76562 18.3906 6.21094 18.8125L15.457 27.543C16.0195 28.0703 16.4883 28.3047 17.0625 28.3047ZM16.3945 25.7852C16.3242 25.7852 16.2539 25.75 16.1953 25.6797L7.83984 17.7812C7.74609 17.6875 7.71094 17.6289 7.71094 17.5586C7.71094 17.4883 7.74609 17.418 7.83984 17.3359L16.1836 9.33203C16.2422 9.28516 16.3125 9.23828 16.3828 9.23828C16.4883 9.23828 16.5469 9.30859 16.5469 9.40234V14.0547C16.5469 14.3242 16.6758 14.4414 16.9453 14.4414H18.5156C26.5547 14.4414 28.9805 20.0195 29.2148 25.5508C29.2148 25.6445 29.1797 25.6797 29.1211 25.6797C29.0742 25.6797 29.0508 25.6445 29.0156 25.5625C27.6328 22.6211 24.082 20.6875 18.5156 20.6875H16.9453C16.6758 20.6875 16.5469 20.8047 16.5469 21.0742V25.6094C16.5469 25.7148 16.4883 25.7852 16.3945 25.7852Z"
        fill="#212121"
      />
    </svg>
  );

  const handleNext = () => {
    setPageState((currState) => {
      return { ...currState, pageNo: currState.pageNo + 1 };
    });
  };

  const handlePrev = () => {
    setPageState((currState) => {
      return { ...currState, pageNo: currState.pageNo - 1 };
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    const queueUsers = clientUserList.map((user) => ({
      userid: user._id,
      role: user.roles,
    }));
    await queueAPI.assignUserToQueue({
      queueID: selectQueue._id,
      queueUsers,
    });
    setIsSaving(false);
    setNotificationMsg('User assignment successful!');
    setNotificationType('success');
    setOpenNotification(true);
  };

  return (
    <Box className={classes.container}>
      <Button
        className={classes.backButton}
        type="button"
        onClick={() => {
          setCurrentScreen({ screen: 'view', props: '' });
        }}
      >
        <ReturnArrow />
        <Box></Box>
      </Button>
      <Box className={classes.headerBar}>
        <Box className={classes.headerBox}>
          <Typography variant="h6" className={classes.headerLabel}>
            Client Users
          </Typography>
          <Box className={classes.totalUsers}>
            {isLoading
              ? '...'
              : `${(pageState?.pageNo - 1) * 20} - ${
                  (pageState?.pageNo - 1) * 20 + clientUserList?.length
                } | ${pageState?.recordCount}`}
          </Box>
          <Typography
            variant="h6"
            className={classes.headerLabel + ' ' + classes.projectName}
          >
            <span>Assigning to</span> <span>{selectQueue.queueName}</span>
          </Typography>
        </Box>
        <Box className={classes.headerBox}>
          <Box className={classes.searchBarContainer}>
            <SearchBar />
          </Box>
          <Box className={classes.saveButtonContainer}>
            <Button
              type="button"
              className={classes.saveButton}
              onClick={handleSave}
              disabled={isSaving || isLoading}
            >
              Save
            </Button>
            {isSaving && <CircularProgress className={classes.saveSpinner} />}
          </Box>
        </Box>
      </Box>
      <Box className={classes.usersContainer}>
        {isLoading ? (
          <CircularProgress className={classes.loadingSpinner} />
        ) : clientUserList.length ? (
          clientUserList.map((user, index) => (
            <UserItem
              key={user.email + index}
              id={user._id}
              userName={user.name}
              email={user.email}
              role={user.roles}
              chartsCount={user.chartCount}
              onRoleChange={setClientUserList}
            />
          ))
        ) : (
          <Box className={classes.noDataContainer}>
            <Typography variant="h6">
              Looks like there are no users yet
            </Typography>
            <img src={'/assets/images/no-data.svg'} alt="no users found" />
          </Box>
        )}
      </Box>
      {pageState.totalPages > 1 ? (
        <Box className={classes.loadMoreButtonContainer}>
          <Button
            type="button"
            disabled={pageState.pageNo === 1 || isLoading || isSaving}
            onClick={handlePrev}
          >
            Prev
          </Button>
          <Button
            type="button"
            disabled={
              pageState.pageNo === pageState.totalPages || isLoading || isSaving
            }
            onClick={handleNext}
          >
            Next
          </Button>
        </Box>
      ) : (
        <></>
      )}
      <Notification
        type={notificationType}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
    </Box>
  );
};

export default AssignUser;
