import { format } from 'date-fns';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DatePicker = (props) => {
  const { name, label, value = null, onChange, ...rest } = props;
  const convertDateEvent = (name, value) => ({
    target: { id: name, name: name, value: format(value, 'yyyy-MM-dd') },
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={1}>
        <DesktopDatePicker
          id={name}
          label={label}
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={(date) => onChange(convertDateEvent(name, date))}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              onKeyDown={(e) => e.preventDefault()}
            />
          )}
          //variant="standard"
          // orientation="landscape"
          //| 'portrait'
          //  margin="normal"
          //minDate={format(subYears(new Date(), 10), 'yyyy-MM-dd')}
          //maxDate={format(addYears(new Date(), 5), 'yyyy-MM-dd')}
          {...rest}
        />
      </Stack>
    </LocalizationProvider>
  );
};

/*
 <DatePicker
            name="date"
            label="My Date"
            value={controlVal.date}
            onChange={onChange}
          />

*/

export default DatePicker;

/*
<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        // disableToolbar
        // inputVariant="outlined"
        variant="inline"
        minDate={format(subYears(new Date(), 10), 'yyyy-MM-dd')}
        maxDate={format(addYears(new Date(), 5), 'yyyy-MM-dd')}
        format="MM/dd/yyyy"
        openTo="year"
        margin="normal"
        autoOk
        id={name}
        label={label}
        value={value}
        onChange={(date) => onChange(convertDateEvent(name, date))}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
*/
