import { VariableSizeList as List } from 'react-window';
import useStyles from './style';

const VirtualizedList = ({
  data = [],
  totalRows = 0,
  loading = true,
  children,
  listHeight = 400,
  listRef = null,
  rowHeight,
  onLoadMore = () => {},
}) => {
  const classes = useStyles();
  // Load more data when reaching the end of the list
  const handleItemsRendered = ({ visibleStopIndex }) => {
    if (
      visibleStopIndex >= data.length - 1 && // User scrolled to the last loaded record
      data.length < totalRows && // More data is available
      !loading // Not already loading
    ) {
      onLoadMore(); // Request more data
    }
  };

  return (
    <List
      ref={listRef}
      height={listHeight} // Height of the visible list area
      itemCount={data.length || totalRows} // Total rows including placeholders
      itemSize={rowHeight} // Height of each row
      onItemsRendered={({ visibleStartIndex, visibleStopIndex }) =>
        handleItemsRendered({ visibleStopIndex })
      } // Detect when to load more data
      width="100%"
      className={classes.hideScroll}
    >
      {children}
    </List>
  );
};

export default VirtualizedList;
