import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: 'rgba(179, 179, 179, 0.41)',
    fontSize: '18px',
    marginBottom: '6px',
    padding: '2px 6px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  },
  headButton: {
    minWidth: 'auto',
    lineHeight: 1,
    padding: 0,
    '& svg': { transform: 'scale(0.8)' },
  },
  inferredContainer: {
    flex: '60%',
    borderRadius: '10px',
    overflow: 'hidden',
    maxHeight: '50vh',
  },
  inferredDiagnosis: {
    backgroundColor: '#fff',
    padding: '4px',
    borderRadius: '0 0 10px 10px',
    overflowY: 'auto',
    height: 'calc(50vh - 40px)',
    maxHeight: '50vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  capturedContainer: {
    flex: '40%',
    borderRadius: '10px',
    overflow: 'auto',
    '& h4': {
      fontSize: '15px',
      fontWeight: 400,
      backgroundColor: 'rgba(179, 179, 179, 0.41)',
      padding: '4px 6px',
    },
  },
  capturedDiagnosis: {
    backgroundColor: '#fff',
    padding: '4px',
    borderRadius: '0 0 10px 10px',
  },
  filterMenu: {
    // '.MuiMenu-paper': {
    //   top: '120px',
    //   left: 'unset !important',
    //   right: '50px',
    //   backgroundColor: 'red',
    // },
  },
  filterMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
  },
}));

export default useStyles;
