export const filterFieldsMetadata = [
  {
    groupName: 'Member',
    fieldId: 'Health_Plan_No',
    fieldLabel: 'Health Plan#',
    type: 'dropdown',
    operators: ['='],
    items: [],
    isDynamic: true,
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_ID',
    fieldLabel: 'Member ID/ MBI',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_FirstName',
    fieldLabel: 'First Name',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_LastName',
    fieldLabel: 'Last Name',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_DOB',
    fieldLabel: 'DOB',
    type: 'date',
    operators: ['='],
  },
];
