import { createContext, useState } from 'react';

import useControlEvent from 'utils/controlevent';

export const memberContext = createContext();
memberContext.displayName = 'Member';

export const viewMemberContext = createContext();
viewMemberContext.displayName = 'ViewMember';

const MemberProvider = (props) => {
  // Filter
  const [filters, setFilters] = useState([]);
  const { controlVal, setControlVal, onFieldValueChange } = useControlEvent({});
  const [filterMetadata, setFilterMetadata] = useState([]);

  // Table
  const [tableMetadata, setTableMetadata] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [options, setOptions] = useState({
    pageno: 1,
    rowsPerPage: 5,
    filter: [],
    sort: {},
  });
  const [records, setRecords] = useState([]);

  // Modal - Member View
  const initalSelectedMember = {
    type: 'view',
    memberUID: 'memberUID',
    status: 'Status',
    memberID: 'MemberID',
    basicInfo: [],
  };
  const [openModal, setOpenModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(initalSelectedMember);
  const [currentTab, setCurrentTab] = useState({});
  const [selectedYear, setSelectedYear] = useState(1900);

  return (
    <memberContext.Provider
      value={{
        filters,
        setFilters,
        controlVal,
        setControlVal,
        onFieldValueChange,
        filterMetadata,
        setFilterMetadata,
        tableMetadata,
        setTableMetadata,
        recordCount,
        setRecordCount,
        options,
        setOptions,
        records,
        setRecords,
      }}
    >
      <viewMemberContext.Provider
        value={{
          openModal,
          setOpenModal,
          selectedMember,
          setSelectedMember,
          currentTab,
          setCurrentTab,
          selectedYear,
          setSelectedYear,
        }}
      >
        {props.children}
      </viewMemberContext.Provider>
    </memberContext.Provider>
  );
};

export default MemberProvider;
