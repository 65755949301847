import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.grey,
    paddingBottom: '10px',
  },
  title: {
    backgroundColor: theme.palette.secondary.grey,
    fontSize: '18px',
    marginBottom: '6px',
    padding: '2px 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  },
  addButton: {
    minWidth: 'auto',
    lineHeight: 1,
    padding: '2px',
    marginRight: '4px',
    backgroundColor: theme.palette.primary.main,
    '& svg': { maxWidth: '22px', maxHeight: '22px' },
    '&:hover': { backgroundColor: theme.palette.primary.hover },
    '&:disabled': { backgroundColor: theme.palette.primary.main, opacity: 0.4 },
  },
  filterButton: {
    minWidth: 'auto',
    lineHeight: 1,
    padding: '3px',
    marginRight: '4px',
    '& svg': { maxWidth: '24px', maxHeight: '24px' },
    '&:disabled': { opacity: 0.4 },
  },
  innerContainer: { padding: '0 6px' },
  progress: {
    color: theme.palette.primary.main,
    maxWidth: '20px',
    maxHeight: '20px',
    marginLeft: '4px',
  },
}));

export default useStyles;
