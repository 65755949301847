import React, { useState, useEffect, useContext, createContext } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Button,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import useStyles from './style';
import theme from 'theme/theme';
import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'icons/customIcons';
import CloseIcon from '@mui/icons-material/Close';
import { queueAPI } from 'api';
import Notification from 'components/notification';

const QueueContext = createContext();

const CustomScrollButton = ({ direction, ...props }) => {
  return (
    <IconButton
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '33px',
        height: '33px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
        margin: '5px 5px',
        '&:hover': {
          backgroundColor: theme.palette.primary.hover,
          color: theme.palette.primary.white,
        },
      }}
    >
      {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
};

const QueueFilter = ({ projectIdList, defaultFilters, onFilterChange }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const [selectedHCCCount, setSelectedHCCCount] = useState(
    defaultFilters.selectedHCCCount || []
  );
  const [selectedComplexHCCs, setSelectedComplexHCCs] = useState(
    defaultFilters.selectedComplexHCCs || []
  );
  const [selectedDiseaseCategories, setSelectedDiseaseCategories] = useState(
    defaultFilters.selectedDiseaseCategories || []
  );
  const [pageCountRange, setPageCountRange] = useState(
    defaultFilters.pageCountRange || { min: 0, max: 5000 }
  );
  const [selectedAgeRange, setSelectedAgeRange] = useState(
    defaultFilters.selectedAgeRange || []
  );
  const [selectedGender, setSelectedGender] = useState(
    defaultFilters.selectedGender || []
  );
  const [selectedChartStatus, setSelectedChartStatus] = useState(
    defaultFilters.selectedChartStatus || []
  );
  const [selectedProviderSelect, setSelectedProviderSelect] = useState(
    defaultFilters.selectedProviderSelect || []
  );
  const [selectedMemberName, setSelectedMemberName] = useState(
    defaultFilters.selectedMemberName || []
  );
  const [totalProviderMemberCategory, setTotalProviderMemberCategory] =
    useState({});

  const tabs = [
    { id: 1, label: 'HCC Count', component: <HCCCount /> },
    { id: 2, label: 'Complex HCCs', component: <ComplexHCCs /> },
    { id: 3, label: 'Disease Categories', component: <DiseaseCategories /> },
    { id: 4, label: 'Page Count', component: <PageCount /> },
    { id: 5, label: 'Provider Select', component: <ProviderSelect /> },
    { id: 6, label: 'Age Range', component: <AgeRange /> },
    { id: 7, label: 'Member Name', component: <MemberName /> },
    { id: 8, label: 'Gender', component: <Gender /> },
    { id: 9, label: 'Chart Status', component: <ChartStatus /> },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await queueAPI.getProviderMemberCategories(
          projectIdList
        );
        setTotalProviderMemberCategory(result?.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  const handleApply = () => {
    const updatedFilters = {
      selectedHCCCount,
      selectedComplexHCCs,
      selectedDiseaseCategories,
      pageCountRange,
      selectedAgeRange,
      selectedGender,
      selectedChartStatus,
      selectedProviderSelect,
      selectedMemberName,
    };

    onFilterChange(updatedFilters);
  };

  const handleHCCCountClick = (range) => {
    setSelectedHCCCount((prev) => {
      if (prev.includes(range)) {
        return prev.filter((r) => r !== range);
      } else {
        return [...prev, range];
      }
    });
  };

  const handleComplexHCCClick = (condition) => {
    setSelectedComplexHCCs((prev) => {
      if (prev.includes(condition)) {
        return prev.filter((r) => r !== condition);
      } else {
        return [...prev, condition];
      }
    });
  };

  const handleDiseaseCategoryClick = (category) => {
    setSelectedDiseaseCategories((prev) => {
      if (prev.includes(category)) {
        return prev.filter((cat) => cat !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handleRangeChange = (newMin, newMax) => {
    setPageCountRange({ min: newMin, max: newMax });
  };

  const handleAgeRange = (range) => {
    setSelectedAgeRange((prev) => {
      if (prev.includes(range)) {
        return prev.filter((r) => r !== range);
      } else {
        return [...prev, range];
      }
    });
  };

  const handleGender = (range) => {
    setSelectedGender((prev) => {
      if (prev.includes(range)) {
        return prev.filter((r) => r !== range);
      } else {
        return [...prev, range];
      }
    });
  };

  const handleChartStatus = (range) => {
    setSelectedChartStatus((prev) => {
      if (prev.includes(range)) {
        return prev.filter((r) => r !== range);
      } else {
        return [...prev, range];
      }
    });
  };

  const handleProviderSelect = (category) => {
    setSelectedProviderSelect((prev) => {
      if (prev.includes(category)) {
        return prev.filter((cat) => cat !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handleMemberName = (category) => {
    setSelectedMemberName((prev) => {
      if (prev.includes(category)) {
        return prev.filter((cat) => cat !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  return (
    <QueueContext.Provider
      value={{
        selectedHCCCount,
        handleHCCCountClick,
        selectedComplexHCCs,
        handleComplexHCCClick,
        selectedDiseaseCategories,
        handleDiseaseCategoryClick,
        pageCountRange,
        handleRangeChange,
        selectedAgeRange,
        handleAgeRange,
        selectedGender,
        handleGender,
        selectedChartStatus,
        handleChartStatus,
        selectedProviderSelect,
        handleProviderSelect,
        selectedMemberName,
        handleMemberName,
        projectIdList,
        totalProviderMemberCategory,
      }}
    >
      <Box className={classes.queueFilter}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          className={classes.tabs}
          ScrollButtonComponent={CustomScrollButton}
        >
          {tabs.map((tab, index) => {
            const isFilterSelected = () => {
              switch (tab.label) {
                case 'HCC Count':
                  return selectedHCCCount.length > 0;
                case 'Complex HCCs':
                  return selectedComplexHCCs.length > 0;
                case 'Disease Categories':
                  return selectedDiseaseCategories.length > 0;
                case 'Page Count':
                  return (
                    pageCountRange.min !== 0 || pageCountRange.max !== 5000
                  );
                case 'Provider Select':
                  return selectedProviderSelect.length > 0;
                case 'Age Range':
                  return selectedAgeRange.length > 0;
                case 'Member Name':
                  return selectedMemberName.length > 0;
                case 'Gender':
                  return selectedGender.length > 0;
                case 'Chart Status':
                  return selectedChartStatus.length > 0;
                default:
                  return false;
              }
            };

            return (
              <Tab
                key={tab.id}
                label={
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {isFilterSelected() && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '-8px',
                          right: '-6px',
                          width: '6px',
                          height: '6px',
                          borderRadius: '50%',
                          backgroundColor: '#02375F',
                        }}
                      />
                    )}
                    {tab.label}
                  </Box>
                }
                className={classes.tab}
              />
            );
          })}
        </Tabs>
        <Box className={classes.tabContent}>{tabs[activeTab].component}</Box>
        <Box className={classes.applyButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            className={classes.applyButton}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </QueueContext.Provider>
  );
};

const HCCCount = () => {
  const classes = useStyles();
  const { selectedHCCCount, handleHCCCountClick } = useContext(QueueContext);

  return (
    <Box className={classes.hccCount}>
      <Box className={classes.rangeButtons}>
        {['0', '1-2', '3-5', '>5'].map((range) => (
          <Button
            key={range}
            variant={
              selectedHCCCount.includes(range) ? 'contained' : 'outlined'
            }
            color="primary"
            onClick={() => handleHCCCountClick(range)}
            className={classes.rangeButton}
          >
            {range}
            {selectedHCCCount.includes(range) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleHCCCountClick(range);
                }}
                className={classes.clearIcon}
                size="small"
              >
                <CloseIcon
                  sx={{
                    width: '15px',
                  }}
                />
              </IconButton>
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const ComplexHCCs = () => {
  const classes = useStyles();
  const { selectedComplexHCCs, handleComplexHCCClick } =
    useContext(QueueContext);

  const complexHCCList = [
    'Major Depressive Disorder',
    'Vascular Claudication',
    'Embolism',
    'Acute Stroke and Acute Heart Attack',
    'Lung Cancer',
    'Breast Cancer',
    'Colon Cancer',
    'Prostate Cancer',
    'Acute Heart Attack',
  ];

  return (
    <Box className={classes.hccCount}>
      <Grid container spacing={2}>
        {complexHCCList.map((condition) => (
          <Grid item xs={12} sm={4} md={4} lg={4} key={condition}>
            <Tooltip title={condition} classes={{ tooltip: classes.tooltip }}>
              <Button
                variant={
                  selectedComplexHCCs.includes(condition)
                    ? 'contained'
                    : 'outlined'
                }
                color="primary"
                onClick={() => handleComplexHCCClick(condition)}
                fullWidth
                className={classes.complexHccBtn}
              >
                {condition}
                {selectedComplexHCCs.includes(condition) && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleHCCCountClick(range);
                    }}
                    className={classes.clearIcon}
                    size="small"
                  >
                    <CloseIcon
                      sx={{
                        width: '15px',
                      }}
                    />
                  </IconButton>
                )}
              </Button>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const DiseaseCategories = () => {
  const classes = useStyles();
  const {
    selectedDiseaseCategories,
    handleDiseaseCategoryClick,
    totalProviderMemberCategory,
  } = useContext(QueueContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedCategories(selectedDiseaseCategories || []);
  }, [selectedDiseaseCategories]);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      setLoading(true);
      try {
        const res = await queueAPI.getDiseaseCategoriesBySearch(value);
        setFilteredCategories(res?.data || []);
      } catch (error) {
        console.error('Error fetching disease categories:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setFilteredCategories([]);
    }
  };

  const handleCategorySelect = (condition) => {
    handleDiseaseCategoryClick(condition);

    setSelectedCategories((prev) => {
      if (prev.includes(condition)) {
        return prev.filter((item) => item !== condition);
      }
      return [...prev, condition];
    });
  };

  return (
    <Box className={classes.diseaseCategories}>
      <Box className={classes.stickySearchContainer}>
        <OutlinedInput
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Type any 3 characters to search & select"
          className={classes.searchBar}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="end">
                <MagnifyingGlassIcon />
              </IconButton>
            </InputAdornment>
          }
          margin="dense"
        />
        <Typography className={classes.count}>
          {' '}
          {selectedCategories.length} /{' '}
          {totalProviderMemberCategory.diseaseCategory || '0'}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          (searchTerm ? filteredCategories : selectedCategories).map(
            (category) => (
              <Grid item xs={6} key={category}>
                <Button
                  variant={
                    selectedDiseaseCategories.includes(category)
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  onClick={() => handleCategorySelect(category)}
                  fullWidth
                  className={classes.diseaseCategoryBtn}
                >
                  {category}
                  {selectedDiseaseCategories.includes(category) && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCategorySelect(category);
                      }}
                      className={classes.clearIcon}
                      size="small"
                    >
                      <CloseIcon
                        sx={{
                          width: '15px',
                        }}
                      />
                    </IconButton>
                  )}
                </Button>
              </Grid>
            )
          )
        )}
      </Grid>
    </Box>
  );
};

const PageCount = () => {
  const classes = useStyles();
  const { pageCountRange, handleRangeChange } = useContext(QueueContext);

  const [minValue, setMinValue] = useState(pageCountRange.min);
  const [maxValue, setMaxValue] = useState(pageCountRange.max);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('error');

  const handleMinBlur = () => {
    const newMinValue = minValue === '' ? 0 : Number(minValue);
    if (newMinValue >= 0 && newMinValue <= maxValue) {
      handleRangeChange(newMinValue, maxValue);
    } else {
      setSnackbarMessage('Min value should be between 0 and Max value.');
      setSnackbarOpen(true);
      setMinValue(pageCountRange.min);
    }
  };

  const handleMaxBlur = () => {
    const newMaxValue = maxValue === '' ? 5000 : Number(maxValue);
    if (newMaxValue > minValue) {
      handleRangeChange(minValue, newMaxValue);
    } else {
      setSnackbarMessage('Max value should be greater than Min value.');
      setSnackbarOpen(true);
      setMaxValue(pageCountRange.max);
    }
  };

  return (
    <Box className={classes.pageCount}>
      <Box className={classes.inputGroup}>
        <TextField
          className={classes.input}
          label="Min"
          type="number"
          value={minValue}
          onChange={(e) => setMinValue(e.target.value)}
          onBlur={handleMinBlur}
          variant="outlined"
          sx={{
            borderRadius: '50px 50px 50px 50px',
            fontSize: '12px',
            height: '32px',
            width: '90px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '50px 50px 50px 50px',
              fontSize: '12px',
              height: '32px',
            },
          }}
        />
      </Box>

      <Box className={classes.inputGroup}>
        <TextField
          className={classes.input}
          label="Max"
          type="number"
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
          onBlur={handleMaxBlur}
          variant="outlined"
          sx={{
            borderRadius: '50px 50px 50px 50px',
            fontSize: '12px',
            height: '32px',
            width: '90px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '50px 50px 50px 50px',
              fontSize: '12px',
              height: '32px',
            },
          }}
        />
      </Box>
      <Notification
        type={snackbarType}
        message={snackbarMessage}
        openNotification={snackbarOpen}
        setOpenNotification={setSnackbarOpen}
      />
    </Box>
  );
};

const ProviderSelect = () => {
  const classes = useStyles();
  const {
    selectedProviderSelect,
    handleProviderSelect,
    projectIdList,
    totalProviderMemberCategory,
  } = useContext(QueueContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedCategories(selectedProviderSelect || []);
  }, [selectedProviderSelect]);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      setLoading(true);
      try {
        const res = await queueAPI.getProvidersBySearch(value, projectIdList);
        setFilteredCategories(res?.data?.data || []);
      } catch (error) {
        console.error('Error fetching providers:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setFilteredCategories([]);
    }
  };

  const handleCategorySelect = (condition) => {
    handleProviderSelect(condition);

    setSelectedCategories((prev) => {
      if (prev.includes(condition)) {
        return prev.filter((item) => item !== condition);
      }
      return [...prev, condition];
    });
  };

  return (
    <Box className={classes.diseaseCategories}>
      <Box className={classes.stickySearchContainer}>
        <OutlinedInput
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Type any 3 characters to search & select"
          className={classes.searchBar}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="end">
                <MagnifyingGlassIcon />
              </IconButton>
            </InputAdornment>
          }
          margin="dense"
        />
        <Typography className={classes.count}>
          {' '}
          {selectedCategories.length} /{' '}
          {totalProviderMemberCategory.providerSelected || '0'}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          (searchTerm ? filteredCategories : selectedCategories).map(
            (condition) => (
              <Grid item xs={12} sm={4} md={4} lg={4} key={condition}>
                <Button
                  variant={
                    selectedProviderSelect.includes(condition)
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  onClick={() => handleCategorySelect(condition)}
                  fullWidth
                  className={classes.complexHccBtn}
                >
                  {condition}
                  {selectedProviderSelect.includes(condition) && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCategorySelect(condition);
                      }}
                      className={classes.clearIcon}
                      size="small"
                    >
                      <CloseIcon sx={{ width: '15px' }} />
                    </IconButton>
                  )}
                </Button>
              </Grid>
            )
          )
        )}
      </Grid>
    </Box>
  );
};

const AgeRange = () => {
  const classes = useStyles();
  const { selectedAgeRange, handleAgeRange } = useContext(QueueContext);
  return (
    <Box className={classes.hccCount}>
      <Box className={classes.rangeButtons}>
        {['<65', '65-70', '70-75', '>75'].map((range) => (
          <Button
            key={range}
            variant={
              selectedAgeRange.includes(range) ? 'contained' : 'outlined'
            }
            color="primary"
            onClick={() => handleAgeRange(range)}
            className={classes.rangeButton}
          >
            {range}
            {selectedAgeRange.includes(range) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleAgeRange(range);
                }}
                className={classes.clearIcon}
                size="small"
              >
                <CloseIcon
                  sx={{
                    width: '15px',
                  }}
                />
              </IconButton>
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const MemberName = () => {
  const classes = useStyles();
  const {
    selectedMemberName,
    handleMemberName,
    projectIdList,
    totalProviderMemberCategory,
  } = useContext(QueueContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedCategories(selectedMemberName || []);
  }, [selectedMemberName]);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      setLoading(true);
      try {
        const res = await queueAPI.getMembersBySearch(value, projectIdList);
        setFilteredCategories(res?.data || []);
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setFilteredCategories([]);
    }
  };

  const handleCategorySelect = (condition) => {
    handleMemberName(condition);

    setSelectedCategories((prev) => {
      if (prev.includes(condition)) {
        return prev.filter((item) => item !== condition);
      }
      return [...prev, condition];
    });
  };

  return (
    <Box className={classes.diseaseCategories}>
      <Box className={classes.stickySearchContainer}>
        <OutlinedInput
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Type any 3 characters to search & select"
          className={classes.searchBar}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="end">
                <MagnifyingGlassIcon />
              </IconButton>
            </InputAdornment>
          }
          margin="dense"
        />
        <Typography className={classes.count}>
          {' '}
          {selectedCategories.length} /{' '}
          {totalProviderMemberCategory.memberName || '0'}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          (searchTerm ? filteredCategories : selectedCategories).map(
            (condition) => (
              <Grid item xs={12} sm={4} md={4} lg={4} key={condition}>
                <Button
                  variant={
                    selectedMemberName.includes(condition)
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  onClick={() => handleCategorySelect(condition)}
                  fullWidth
                  className={classes.complexHccBtn}
                >
                  {condition}
                  {selectedMemberName.includes(condition) && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCategorySelect(condition);
                      }}
                      className={classes.clearIcon}
                      size="small"
                    >
                      <CloseIcon sx={{ width: '15px' }} />
                    </IconButton>
                  )}
                </Button>
              </Grid>
            )
          )
        )}
      </Grid>
    </Box>
  );
};

const Gender = () => {
  const classes = useStyles();
  const { selectedGender, handleGender } = useContext(QueueContext);
  return (
    <Box className={classes.hccCount}>
      <Box className={classes.rangeButtons}>
        {['Male', 'Female', 'Other'].map((range) => (
          <Button
            key={range}
            variant={selectedGender.includes(range) ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleGender(range)}
            className={classes.rangeButton}
          >
            {range}
            {selectedGender.includes(range) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleGender(range);
                }}
                className={classes.clearIcon}
                size="small"
              >
                <CloseIcon
                  sx={{
                    width: '15px',
                  }}
                />
              </IconButton>
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const ChartStatus = () => {
  const classes = useStyles();
  const { selectedChartStatus, handleChartStatus } = useContext(QueueContext);

  const ChartStatus = [
    'In Process',
    'Processed',
    'Code',
    'Coded',
    'Review',
    'Validated',
  ];

  return (
    <Box className={classes.hccCount}>
      <Box
        sx={{
          width: '60%',
        }}
      >
        <Grid container spacing={2}>
          {ChartStatus.map((condition) => (
            <Grid item xs={6} key={condition}>
              <Button
                variant={
                  selectedChartStatus.includes(condition)
                    ? 'contained'
                    : 'outlined'
                }
                color="primary"
                onClick={() => handleChartStatus(condition)}
                fullWidth
                className={classes.complexHccBtn}
              >
                {condition}
                {selectedChartStatus.includes(condition) && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChartStatus(range);
                    }}
                    className={classes.clearIcon}
                    size="small"
                  >
                    <CloseIcon
                      sx={{
                        width: '15px',
                      }}
                    />
                  </IconButton>
                )}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default QueueFilter;
