import { Box, Typography } from '@mui/material';
const { default: useStyles } = require('./style');

const Accepted = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="24 / Tick">
        <path
          id="Shape"
          d="M9.54961 17.9996L3.84961 12.2996L5.27461 10.8746L9.54961 15.1496L18.7246 5.97461L20.1496 7.39961L9.54961 17.9996Z"
          fill="#3D9F04"
        />
      </g>
    </svg>
  );
};

const Rejected = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="20 / Cross">
        <path
          id="Union"
          d="M7.2744 18L6 16.7256L10.7256 12L6 7.2744L7.2744 6L12 10.7256L16.7256 6L18 7.2744L13.2744 12L18 16.7256L16.7256 18L12 13.2744L7.2744 18Z"
          fill="#E70A0E"
        />
      </g>
    </svg>
  );
};

const DiagnoseCard = ({
  id,
  code,
  description,
  onClick,
  tabIndex,
  active,
  status,
  encounterDate,
  hcc = '',
  deleted = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.diagnos} ${active && classes.active} ${
        deleted && classes.deleted
      }`}
      id={id}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      <Typography variant="h5" className={classes.codeName}>
        <span>{description}</span>
      </Typography>
      <Typography variant="h6" className={classes.code}>
        <Box className={classes.status}>
          <span style={{ fontWeight: 600 }}>{code}</span>
          {status === 'accepted' ? (
            <Accepted />
          ) : status === 'rejected' ? (
            <Rejected />
          ) : (
            <></>
          )}
        </Box>
        <span>{`HCC: ${hcc}`}</span>
      </Typography>
      <Typography paragraph className={classes.codeDescription}>
        {!deleted &&
          (encounterDate === null ||
          encounterDate?.trim()?.toLowerCase() === 'not specified' ||
          encounterDate?.trim() === ''
            ? 'Date of service: N/A'
            : `Date of service: ${new Date(encounterDate).toLocaleDateString(
                'en-US'
              )}`)}
      </Typography>
    </Box>
  );
};

export default DiagnoseCard;
