import { Box } from 'components/controls';
import CopyRights from 'components/copyrights';
import Loading from 'components/loading';
import useStyles from './style';
import { useEffect } from 'react';
import Notification from 'components/notification';
import FirstComponent from './first-component';
import SecondComponent from './second-component';
import ThirdComponent from './third-component';
import { useContext } from 'react';
import { setupMfaContext } from 'shared/context/mfa';
import { SetupMfaProvider } from 'shared/context/mfa';
import { getMaskedEmail } from 'shared/context/mfa/localstoragedata';

// const maskedEmail = getMaskedEmail();

const MainComponent = () => {
  const classes = useStyles();
  const {
    setBtnDisabled,
    otp,
    stage,
    notificationMsg,
    openNotification,
    setOpenNotification,
    isSuccess,
    maskedEmail,
    setMaskedEmail,
  } = useContext(setupMfaContext);

  useEffect(() => {
    const storedData = getMaskedEmail();
    setMaskedEmail(storedData);
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [otp]);

  return (
    <Box className={classes.container} component="main">
      <Box className={classes.bgShape}></Box>
      <Box className={classes.content}>
        <Box className={classes.paper}>
          <Box></Box>
          <span className={classes.hr} />
          <Box className={classes.maindiv}>
            <Box className={classes.imagediv}>
              <img
                src="/icon_profile_circle.png"
                alt="logo"
                className={classes.logo}
              />
              <Box className={classes.heading}>Enable MFA</Box>
            </Box>
            {maskedEmail === null ? (
              <Loading />
            ) : (
              <>
                {stage === 1 ? (
                  <FirstComponent />
                ) : stage === 2 ? (
                  <SecondComponent />
                ) : (
                  <ThirdComponent />
                )}
              </>
            )}
            <Notification
              type={isSuccess ? 'success' : 'error'}
              message={notificationMsg}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
            />
            <Box className={classes.CopyRights}>
              <CopyRights />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Mfasetup = () => {
  return (
    <SetupMfaProvider>
      <MainComponent />
    </SetupMfaProvider>
  );
};

export default Mfasetup;
