import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import { Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { ButtonIcon } from 'components/controls';
import Loading from 'components/loading';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontSize: '20px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
  },
}));

const ExportMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <ButtonIcon
        iconName="Download"
        onClick={handleClick}
        tooltipText="Export Data format"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>CSV</MenuItem>
        <MenuItem onClick={handleClose}>Excel</MenuItem>
        <MenuItem onClick={handleClose}>PDF</MenuItem>
      </Menu>
    </div>
  );
};

const EnhancedTableToolbar = ({
  metadata,
  numSelected,
  isLoading,
  onFileUploadClick,
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      sx={{
        backgroundColor: '#F6F6F6 !important',
      }}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          {metadata.title || 'Search results'}
        </Typography>
      )}

      {isLoading && <Loading />}
      {metadata.enableFileUpload && (
        <ButtonIcon
          iconName="FileAttach"
          onClick={onFileUploadClick}
          tooltipText="Upload files"
        />
      )}

      <ExportMenu />

      {numSelected > 0 ? (
        <ButtonIcon
          iconName="Delete"
          //TODO: onClick={handleClick}
          tooltipText="Delete"
        />
      ) : (
        <ButtonIcon
          iconName="Filter"
          //TODO: onClick={handleClick}
          tooltipText="Filter list"
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
