import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 5px',
    color: '#000',
    borderRadius: '6px',
    transition: 'all 400ms linear',
    '&:hover': {
      backgroundColor: 'rgba(62, 158, 142, 0.33)',
    },
    '&:before': {
      content: '" "',
      display: 'block',
      width: '96%',
      height: '1px',
      position: 'absolute',
      backgroundColor: '#bbb',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  selected: { backgroundColor: 'rgba(62, 158, 142, 0.33)' },
  projectNameAndStatusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  projectIconsAndDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '4px 0',
  },
  iconButton: { padding: 0, minWidth: 'auto' },
  title: {
    marginLeft: '6px',
  },
  detail: {
    fontSize: '14px',
  },
}));

export default useStyles;
