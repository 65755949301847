import { useState, useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { Box, Grid } from 'components/controls';
import { BasicTable } from 'components/table';
import { displayFields } from './metadata';
import { extractMMRTableData } from './service';
import { viewMemberContext } from 'shared/context/member';

const useStyles = makeStyles(() => ({
  fields: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

const MMR = ({}) => {
  const classes = useStyles();
  const { selectedMember } = useContext(viewMemberContext);
  // const [keyFields, setKeyFields] = useState(displayFields);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(extractMMRTableData(selectedMember));
  }, [selectedMember]);
  return (
    <Box overflow="auto" flex={1} p={2}>
      <Grid container spacing={2}>
        {displayFields.map((x, index) => (
          <Grid key={index} item xs={6} className={classes.fields}>
            <Typography style={{ fontWeight: 'bold' }} variant="subtitle2">
              {x.label}:
            </Typography>
            <Typography variant="body1">{x.value}</Typography>
          </Grid>
        ))}
        <Grid item xs={12}>
          <BasicTable
            headFields={[
              'Year',
              'RAF Type',
              'Medicaid Status',
              'Medicaid Dual Status',
              'Orec',
              'Last Payment Date',
            ]}
            rows={tableData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MMR;
