import React, { useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import * as d3 from 'd3';

const MyPieChart = ({
  data,
  title,
  isPercentageVal = false,
  width = 280,
  height = 230,
  radius = 115,
  titalPos = 10,
  legendPos = 20,
  circlePos = 50,
}) => {
  const chartRef = useRef(null);
  const COLORS = ['#1C317C', '#2D67E3', '#5696EA', '#D0DFF4', '#3850d1'];

  useEffect(() => {
    // const width = 280;
    // const height = 230;
    // const radius = Math.min(width, height) / 2;
    const svg = d3
      .select(chartRef.current)
      .attr('width', width + 150)
      .attr('height', height)
      .append('g')
      // .attr('transform', `translate(${(width - 50) / 2}, ${height / 2})`);
      .attr(
        'transform',
        `translate(${(width - circlePos) / 2}, ${height / 2})`
      );

    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);

    const arc = d3
      .arc()
      .outerRadius(radius - 10)
      .innerRadius(radius - 35);

    const centerText = svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '0.35em')
      .style('font-size', '16px')
      .style('font-weight', '500')
      .style('fill', 'black');

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'rgba(0, 0, 0, 0.7)')
      .style('color', 'white')
      .style('padding', '2px 5px')
      .style('border-radius', '5px')
      .style('box-shadow', '0 4px 8px rgba(0, 0, 0, 0.2)')
      .style('height', '40px')
      .style('display', 'flex')
      .style('justify-content', 'center')
      .style('align-items', 'center');

    const arcData = pie(data);

    const shadowFilter = svg
      .append('defs')
      .append('filter')
      .attr('id', 'shadow')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .attr('width', '200%')
      .attr('height', '200%');

    shadowFilter
      .append('feDropShadow')
      .attr('dx', 0)
      .attr('dy', 0)
      .attr('stdDeviation', 4)
      .attr('flood-color', '#000')
      .attr('flood-opacity', 0.4);

    svg
      .selectAll('.arc')
      .data(arcData)
      .enter()
      .append('g')
      .attr('class', 'arc')
      .on('mouseover', (event, d) => {
        tooltip
          .style('visibility', 'visible')
          .html(`${d.data.name}: ${d.data.value}`);
        // d3.select(event.currentTarget).select('path').style('fill', '#FF5733');
        d3.select(event.currentTarget)
          .select('path')
          .style('filter', 'url(#shadow)')
          .transition()
          .duration(200)
          .attr('transform', 'scale(1.1)');
      })
      .on('mousemove', (event) => {
        tooltip
          .style('top', `${event.pageY + 5}px`)
          .style('left', `${event.pageX + 5}px`);
      })
      .on('mouseout', (event, d) => {
        tooltip.style('visibility', 'hidden');
        // d3.select(event.currentTarget)
        //   .select('path')
        //   .style('fill', (d, i) => COLORS[i % COLORS.length]);

        d3.select(event.currentTarget)
          .select('path')
          .style('filter', null)
          .transition()
          .duration(200)
          .attr('transform', 'scale(1)');
      })
      .append('path')
      .attr('d', arc)
      .style('fill', (d, i) => COLORS[i % COLORS.length]);

    // const legend = d3
    //   .select(chartRef.current)
    //   .append('g')
    //   .style('overflow-y', 'auto')
    //   .attr('transform', `translate(${width - legendPos}, 20)`);

    // // if (title) {
    // //   legend
    // //     .append('text')
    // //     .attr('x', `-${titalPos}`)
    // //     .attr('y', -20)
    // //     .style('font-size', '20px')
    // //     .style('font-weight', '500')
    // //     .style('fill', 'black')
    // //     .text(title);
    // // }

    // legend
    //   .selectAll('.legend')
    //   .data(data)
    //   .enter()
    //   .append('g')
    //   .attr('class', 'legend')
    //   .attr('transform', (d, i) => `translate(0, ${i * 20})`)
    //   .each(function (d, i) {
    //     const legendGroup = d3.select(this);

    //     legendGroup
    //       .append('rect')
    //       .attr('x', 0)
    //       .attr('width', 12)
    //       .attr('height', 12)
    //       .style('fill', COLORS[i % COLORS.length]);

    //     legendGroup
    //       .append('text')
    //       .attr('x', 20)
    //       .attr('y', 10)
    //       .style('font-size', '14px')
    //       .style('fill', 'black')
    //       .text(d.name);

    //     legendGroup
    //       .on('mouseover', () => {
    //         tooltip
    //           .style('visibility', 'visible')
    //           .html(`${d.name}: ${d.value}`);

    //         svg
    //           .selectAll('.arc path')
    //           .filter((arcD) => arcD.data.name === d.name)
    //           .style('filter', 'url(#shadow)')
    //           .transition()
    //           .duration(200)
    //           .attr('transform', 'scale(1.1)');

    //         // centerText.text(d.value + '%');
    //         centerText.text(`${d.value}${isPercentageVal ? '%' : ''}`);

    //         legendGroup
    //           .select('rect')
    //           .transition()
    //           .duration(200)
    //           .attr('transform', 'scale(1.2)')
    //           .style('filter', 'url(#shadow)');
    //       })
    //       .on('mousemove', (event) => {
    //         tooltip
    //           .style('top', `${event.pageY + 5}px`)
    //           .style('left', `${event.pageX + 5}px`);
    //       })
    //       .on('mouseout', () => {
    //         tooltip.style('visibility', 'hidden');

    //         svg
    //           .selectAll('.arc path')
    //           .filter((arcD) => arcD.data.name === d.name)
    //           .style('filter', null)
    //           .transition()
    //           .duration(200)
    //           .attr('transform', 'scale(1)');

    //         centerText.text('');

    //         legendGroup
    //           .select('rect')
    //           .transition()
    //           .duration(200)
    //           .attr('transform', 'scale(1)')
    //           .style('filter', null);
    //       });
    //   });

    const legend = d3
      .select(chartRef.current)
      .append('g')
      .attr('transform', `translate(${width - legendPos}, 20)`);

    const foreignObject = legend
      .append('foreignObject')
      .attr('width', 200)
      .attr('height', 200)
      .style('overflow-y', 'auto')
      .style('scrollbar-width', 'none')
      .style('-ms-overflow-style', 'none');

    const legendContainer = foreignObject
      .append('xhtml:div')
      .style('width', '100%')
      .style('height', '100%')
      .style('overflow-y', 'auto')
      .style('scrollbar-width', 'none')
      .style('-ms-overflow-style', 'none')
      .style('overflow', '-moz-scrollbars-none');

    legendContainer
      .selectAll('.legend')
      .data(data)
      .enter()
      .append('xhtml:div')
      .attr('class', 'legend')
      .style('display', 'flex')
      .style('align-items', 'center')
      .style('margin-bottom', '3px')
      .each(function (d, i) {
        const legendGroup = d3.select(this);

        legendGroup
          .append('xhtml:div')
          .style('width', '12px')
          .style('height', '12px')
          .style('background-color', COLORS[i % COLORS.length])
          .style('margin-right', '8px');

        legendGroup
          .append('xhtml:div')
          .style('font-size', '14px')
          .style('color', 'black')
          .style('text-align', 'left')
          .style('flex', '1')
          .text(d.name);

        legendGroup
          .on('mouseover', () => {
            tooltip
              .style('visibility', 'visible')
              .html(`${d.name}: ${d.value}`);

            svg
              .selectAll('.arc path')
              .filter((arcD) => arcD.data.name === d.name)
              .style('filter', 'url(#shadow)')
              .transition()
              .duration(200)
              .attr('transform', 'scale(1.1)');

            centerText.text(`${d.value}${isPercentageVal ? '%' : ''}`);

            legendGroup
              .select('div')
              .transition()
              .duration(200)
              .style('transform', 'scale(1.2)')
              .style('filter', 'url(#shadow)');
          })
          .on('mousemove', (event) => {
            tooltip
              .style('top', `${event.pageY + 5}px`)
              .style('left', `${event.pageX + 5}px`);
          })
          .on('mouseout', () => {
            tooltip.style('visibility', 'hidden');

            svg
              .selectAll('.arc path')
              .filter((arcD) => arcD.data.name === d.name)
              .style('filter', null)
              .transition()
              .duration(200)
              .attr('transform', 'scale(1)');

            centerText.text('');

            legendGroup
              .select('div')
              .transition()
              .duration(200)
              .style('transform', 'scale(1)')
              .style('filter', null);
          });
      });
  }, [data]);

  return (
    <Box
      sx={{
        textAlign: 'start',
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        height: '250px',
      }}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <svg ref={chartRef}></svg>
    </Box>
  );
};

export default MyPieChart;
