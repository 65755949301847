import { useCallback, useEffect, useState } from 'react';
import DataRow from 'components/controls/dataRow';
import { getQueuesList } from 'api/queue';
import useStyles from './style';
import SelectProject from '../select-project';

const {
  Box,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} = require('@mui/material');

const Queues = ({
  isQueueCreate = false,
  setQueueName = () => {},
  setIsQueueCreate = () => {},
  setSelectedProjects = () => {},
  setProjectData = () => {},
  setCurrentScreen = () => {},
  setQueueId = () => {},
  setQueuesList = () => {},
  setSelectedQueue = () => {},
}) => {
  const ROW_SIZE = 20;
  const [page, setPage] = useState(1);
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [tableHeight, setTableHeight] = useState(
    window.innerHeight >= 1080 ? 600 : 590
  );

  // variable screen height
  useEffect(() => {
    const updateHeight = () => {
      setTableHeight(window.innerHeight >= 1080 ? 800 : 590);
    };

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const classes = useStyles();

  const fetchData = useCallback(async (page) => {
    if (loading) return;

    setLoading(true);
    try {
      const res = await getQueuesList({
        rowsPerPage: ROW_SIZE,
        pageno: page,
      });
      setTotalCount(res?.data?.recordCount);
      setQueues((prev) => [
        ...prev,
        ...res?.data?.records?.map((record) => ({
          id: record?._id,
          fields: [
            { field: 'Queue Name', value: record?.queueName, space: 20 },
            {
              field: 'Total number of Charts',
              value: record?.queueCharts?.length,
              space: 4,
            },
            {
              field: 'Date Created',
              value: new Date(
                record?.createdBy?.createdDate?.$date
              ).toLocaleDateString('en-US'),
              space: 10,
            },
          ],
          expansionContent: (
            <TableContainer className={classes.tableContainer}>
              <Table size="small" className={classes.filterTable}>
                <TableHead className={classes.filterHead}>
                  <TableRow>
                    <TableCell>Filter Name</TableCell>
                    <TableCell>Filter Values Selected</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.filteRows}>
                  <TableRow>
                    <TableCell>HCC Count</TableCell>
                    <TableCell>
                      {record?.filterCondition?.hccCount?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.hccCount?.map((val, idx) => (
                          <Pill key={idx}>{val}</Pill>
                        ))
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Complex HCCs</TableCell>
                    <TableCell>
                      {record?.filterCondition?.complexHccs?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.complexHccs?.map(
                          (val, idx) => <Pill key={idx}>{val}</Pill>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Disease Categories</TableCell>
                    <TableCell>
                      {record?.filterCondition?.diseaseCategories?.length ===
                      0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.diseaseCategories?.map(
                          (val, idx) => <Pill key={idx}>{val}</Pill>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Page Count</TableCell>
                    <TableCell>
                      <Pill>
                        {isNaN(
                          Number(record?.filterCondition?.pageCount?.min)
                        ) ||
                        isNaN(
                          Number(record?.filterCondition?.pageCount?.max)
                        ) ||
                        (Number(record?.filterCondition?.pageCount?.max) ===
                          0 &&
                          Number(record?.filterCondition?.pageCount?.min) === 0)
                          ? 'All'
                          : `${record?.filterCondition?.pageCount?.min} - ${record?.filterCondition?.pageCount?.max}`}
                      </Pill>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Provider Select</TableCell>
                    <TableCell>
                      {record?.filterCondition?.providerName?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.providerName?.map(
                          (val, idx) => <Pill key={idx}>{val}</Pill>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Age Range</TableCell>
                    <TableCell>
                      {record?.filterCondition?.ageRange?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.ageRange?.map((val, idx) => (
                          <Pill key={idx}>{val}</Pill>
                        ))
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Member Name</TableCell>
                    <TableCell>
                      {record?.filterCondition?.memberName?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.memberName?.map((val, idx) => (
                          <Pill key={idx}>{val}</Pill>
                        ))
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gender</TableCell>
                    <TableCell>
                      {record?.filterCondition?.gender?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.gender?.map((val, idx) => (
                          <Pill key={idx}>{val}</Pill>
                        ))
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Chart Status</TableCell>
                    <TableCell>
                      {record?.filterCondition?.chartStatus?.length === 0 ? (
                        <Pill>All</Pill>
                      ) : (
                        record?.filterCondition?.chartStatus?.map(
                          (val, idx) => <Pill key={idx}>{val}</Pill>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ),
          record,
        })),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(page);
  }, [page, fetchData]);

  const Pill = ({ children }) => <Box className={classes.pill}>{children}</Box>;

  const handleQueueopen = ({ record }) => {
    setSelectedQueue(record);
    setQueueId(record?._id);
    setCurrentScreen((prev) => ({ ...prev, screen: 'queue' }));
  };

  const handleSelectProjectOpen = () => {
    setIsQueueCreate(true);
  };

  const handleSelectProjectClose = () => {
    setIsQueueCreate(false);
  };

  const handleProjectData = (queueName, selectedProjects, chartProjects) => {
    setQueueName(queueName);
    setSelectedProjects(selectedProjects);
    setProjectData(chartProjects);
  };

  const handleLoadMore = () => {
    if (queues?.length >= totalCount) return;
    setPage((prev) => prev + 1);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <DataRow
        loading={loading}
        tableHeight={tableHeight}
        data={queues}
        onCreateClick={handleSelectProjectOpen}
        onBottomInView={handleLoadMore}
        onOpen={(event, record) => {
          handleQueueopen({ record });
        }}
        allowRowExpansion
        defaultExpanded
        width="100%"
        fixedWidth
      />
      <SelectProject
        open={isQueueCreate}
        onClose={handleSelectProjectClose}
        setCurrentScreen={setCurrentScreen}
        onProjectData={handleProjectData}
      />
    </Box>
  );
};

export default Queues;
