import MuiTypography from '@mui/material/Typography';

const Typography = ({ children, ...rest }) => {
  return <MuiTypography {...rest}>{children}</MuiTypography>;
};
export default Typography;

/*******************************
 * Example for import
    <Typography>
        <Label
            name="username"
            label={en.login.username}
          />
    </Typography>
 */
