import { useEffect } from 'react';

import { Box } from 'components/controls';

import { getFilterData } from './service';
import WithLoadingHOC from 'components/loadinghoc';
import { AdvanceFilter } from 'components/filter';

import { filterFieldsMetadata } from './metadata';
import { updateDynamicFilterFields } from './service';

const FilterAction = ({ hocContext, setLoading }) => {
  const {
    filters,
    setFilters,
    recordCount,
    setRecordCount,
    options,
    setOptions,
    setRecords,
    filterMetadata,
    setFilterMetadata,
  } = hocContext;

  // Refresh Table
  // if filter values change or
  // table parameters change(Page navigation, Records per page)
  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      await getFilterData({
        options: { ...options },
        setRecords,
        recordCount,
        setRecordCount,
      });
      setLoading(false);
    };
    asyncEffect();
  }, [options]);

  // update options if filter value changes
  useEffect(() => {
    setOptions({
      filter: filters,
      pageno: 1,
      rowsPerPage: options.rowsPerPage,
      sort: options.sort,
    });
  }, [filters]);

  // Filter metadata update
  useEffect(() => {
    setFilterMetadata(filterFieldsMetadata);
    setLoading(false);
    updateDynamicFilterFields(setFilterMetadata);
  }, []);

  return (
    <Box>
      <AdvanceFilter
        filterMetadata={filterMetadata}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  );
};

export default WithLoadingHOC(FilterAction);
