import { grey } from '@mui/material/colors';
export const StyleTreeView = {
  width: '30%',
  // height: '100%',
  backgroundColor: grey[50],
};

export const StyleMainContent = {
  width: '70%',
  //  height: '100%',
};
