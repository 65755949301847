import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import EnhancedTableHead from './tablehead';
import EnhancedTableToolbar from './tabletoolbar';
import useStyles from './style';
import CollapseTableRow from './collapsetablerow';
import { RowActions } from './actions';
import { IconList } from 'icons';
import Skeleton from '@mui/material/Skeleton';

const AdvanceTable = ({
  metadata,
  data,
  recordCount,
  options,
  setOptions,
  onClick,
  onFileUploadClick,
  isLoading,
}) => {
  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [userHealthPlans, setUserHealthPlans] = useState([]);
  const [userRole, setUserRole] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    let toptions = { ...options };
    toptions.pageno = 1;
    toptions.sort = {};
    toptions.sort[property] = isAsc ? 'desc' : 'asc';
    setOptions(toptions);
    setPage(0);
  };

  useEffect(() => {
    const currentUser = JSON.parse(sessionStorage.getItem('rstate'))?.user;
    const clientId = currentUser.currentClient.clientID;

    if (currentUser) {
      setUserRole(currentUser.roles);
      const healthPlans = currentUser.clients[clientId]?.healthPlanIds || [];
      setUserHealthPlans(healthPlans);
    }
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[metadata.keyid]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    let toptions = { ...options };
    toptions.pageno = parseInt(newPage) + 1;
    setOptions(toptions);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    //setRowsPerPage(parseInt(event.target.value, 10));
    let toptions = { ...options };
    toptions.rowsPerPage = parseInt(event.target.value, 10);
    toptions.pageno = 1;
    setOptions(toptions);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    options.rowsPerPage -
    Math.min(options.rowsPerPage, recordCount - page * options.rowsPerPage);

  const [openCollapse, setOpenCollapse] = useState(-1);

  const onCollapseClick = (currentIndex, collapseIndex) => {
    if (currentIndex === collapseIndex) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(currentIndex);
    }
  };

  return (
    metadata && (
      <Paper
        sx={{
          //  maxWidth: '910px',
          overflow: 'auto',
        }}
        className={classes.muiPaper}
      >
        {(metadata.enableToolbar === undefined || metadata.enableToolbar) && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            metadata={metadata}
            isLoading={isLoading}
            onFileUploadClick={onFileUploadClick}
          />
        )}

        <TableContainer key="tableContainer" component={Paper}>
          <Table
            sx={{ overflow: 'auto' }}
            //  stickyHeader
            //  className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              metadata={metadata}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={recordCount}
            />
            <TableBody key="Tbody">
              {data.map((row, index) => {
                const isItemSelected = isSelected(row[metadata.keyid]);
                const shouldShowRowActions =
                  userRole === 'SuperAdmin' ||
                  (userRole === 'ClientAdmin' &&
                    row.healthPlanId.some((id) =>
                      userHealthPlans.includes(id)
                    ));

                return (
                  <React.Fragment key={`TableRowFragement-${index}`}>
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`Tablerow-${index}`}
                      selected={isItemSelected}
                      className={classes.tableRow}
                      sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
                      }}
                    >
                      {shouldShowRowActions ? (
                        <RowActions
                          metadata={metadata}
                          row={row}
                          index={index}
                          isItemSelected={isItemSelected}
                          handleClick={handleClick}
                          onClick={onClick}
                          onCollapseClick={onCollapseClick}
                          openCollapse={openCollapse}
                        />
                      ) : (
                        <TableCell sx={{ padding: 0 }}>
                          <Skeleton
                            sx={{ bgcolor: 'transparent', height: '10px' }}
                            animation={false}
                          />
                        </TableCell>
                      )}
                      {metadata.fields
                        .filter((x) => !x.collapse)
                        .map((col, colIndex) => (
                          <TableCell
                            align={col.alignRight ? 'right' : 'left'}
                            style={{ backgroundColor: col.backgroundColor }}
                            key={colIndex}
                            sx={{
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            {col.dataType === 'icon'
                              ? IconList[row[col.id]]
                              : Array.isArray(row[col.id])
                              ? row[col.id].join(', ')
                              : row[col.id]}
                          </TableCell>
                        ))}
                    </TableRow>
                    <CollapseTableRow
                      key={`collapase-${index}`}
                      row={row}
                      rowIndex={index}
                      openCollapse={openCollapse}
                      metadata={metadata}
                      classes={classes}
                    />
                  </React.Fragment>
                );
              })}
              {emptyRows > 0 && (
                <TableRow key="EmptyRow" style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} key="EmptyRowCell" />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={recordCount}
          rowsPerPage={options.rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          key="Pagination"
          sx={{
            '& .MuiTablePagination-toolbar': {
              background: '#F6F6F6 !important',
              color: 'black',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            },
          }}
        />
      </Paper>
    )
  );
};
AdvanceTable.propTypes = {
  metadata: PropTypes.object,
  data: PropTypes.array,
  recordCount: PropTypes.number,
  options: PropTypes.object,
  setOptions: PropTypes.func,
  onClick: PropTypes.func,
};
export default AdvanceTable;
/*
Example to import AdvanceTable
Refer Sample.js for data params
<AdvanceTable
                metadata= {metadata}
                data= {data}
                recordCount= {recordCount}
                options= {options}
                setOptions= {setOptions}
                onClick= {onTableClick}
        />

*/
