import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
} from '@mui/material';
import useStyles from './style';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const FilterInfo = ({ open, onClose, filterData = {}, queueName = '' }) => {
  const classes = useStyles();
  const [filterInfo, setFilterInfo] = useState(filterData);
  const [queueNames, setQueueNames] = useState(queueName);
  const [expandedFilters, setExpandedFilters] = useState({});

  useEffect(() => {
    setFilterInfo(filterData);
    setQueueNames(queueName);

    const initialExpandedState = Object.keys(filterData).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setExpandedFilters(initialExpandedState);
  }, [filterData, queueName]);

  const toggleFilterExpand = (filterName) => {
    setExpandedFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName],
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.textCenter}>
          <Typography variant="body1">
            These are the filters that were selected to create the Queue:{' '}
            <Typography
              sx={{
                display: 'inline',
                fontWeight: '500',
                fontSize: '1.1rem',
              }}
            >
              {queueNames}{' '}
            </Typography>
          </Typography>
        </Box>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableCell}>Filter Name</TableCell>
                <TableCell className={classes.tableCell}>
                  Filter Value Selected
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(filterInfo)
                .filter(
                  ([filterName]) =>
                    ![
                      'projectIdList',
                      'ignoreChartIds',
                      'includeChartIds',
                    ].includes(filterName)
                )
                .map(([filterName, filterValue]) => {
                  const isExpandable =
                    Array.isArray(filterValue) && filterValue.length > 3;
                  const isExpanded = expandedFilters[filterName];
                  return (
                    <TableRow key={filterName}>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() =>
                          isExpandable && toggleFilterExpand(filterName)
                        }
                        sx={{
                          cursor: isExpandable ? 'pointer' : 'default',
                          fontWeight: '500',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                          }}
                        >
                          {filterName}{' '}
                          {isExpandable && (
                            <IconButton size="small">
                              {isExpanded ? <ExpandMore /> : <ExpandLess />}
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCell} ${classes.filterValueCell}`}
                      >
                        {Array.isArray(filterValue) ? (
                          <Box
                            sx={{
                              maxHeight: '150px',
                              height: isExpanded ? '30px' : 'auto',
                              overflowY: isExpanded ? 'hidden' : 'auto',
                              width: '100%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '10px',
                              padding: '5px',
                              scrollbarWidth: 'none',
                              '&::-webkit-scrollbar': {
                                display: 'none',
                              },
                            }}
                          >
                            {filterValue.length > 0 ? (
                              filterValue.map((value, index) => (
                                <Chip
                                  key={index}
                                  label={value}
                                  color="primary"
                                  variant="outlined"
                                  className={classes.chip}
                                />
                              ))
                            ) : (
                              <Chip
                                label="All"
                                color="secondary"
                                variant="outlined"
                                className={classes.chip}
                              />
                            )}
                          </Box>
                        ) : typeof filterValue === 'object' &&
                          filterValue !== null ? (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '10px',
                            }}
                          >
                            <Chip
                              label={`Min: ${filterValue.min}`}
                              color="primary"
                              variant="outlined"
                              className={classes.chip}
                            />
                            <Chip
                              label={`Max: ${filterValue.max}`}
                              color="primary"
                              variant="outlined"
                              className={classes.chip}
                            />
                          </Box>
                        ) : (
                          <Chip
                            label={filterValue || 'All'}
                            color="primary"
                            variant="outlined"
                            className={classes.chip}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.actionbtn} onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterInfo;
