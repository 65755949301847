import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '18px',
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: '12px',
    marginBottom: '6px',
  },
  title: { marginBottom: '12px' },
  chartLink: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: '#105166',
    marginBottom: '2px',
  },
  teamButton: { color: '#000', gap: '2px', padding: 0, fontSize: '16px' },
  uploadButton: {
    backgroundColor: '#105166',
    borderRadius: '9999px',
    color: '#fff',
    padding: '12px',
    minWidth: 0,
    fontSize: '18px',
    '&:hover': { backgroundColor: '#105166' },
    '& svg': {
      maxWidth: '24px',
      maxHeight: '24px',
    },
  },
  uploadMenu: {
    padding: 0,
    top: '10px',
    '& .MuiPaper-root': {
      boxShadow: 'none',
      border: 'none',
      color: '#fff',
      backgroundColor: '#105166',
      borderRadius: '6px',
      '& ul': {
        padding: 0,
        '& li': {
          textAlign: 'center',
          justifyContent: 'center',
          padding: '6px 16px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#0d3f4f',
          },
        },
      },
    },
  },
  chaseMenu: {
    padding: 0,
    top: '10px',
    '& .MuiPaper-root': {
      boxShadow: 'none',
      border: 'none',
      color: '#fff',
      '& ul': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '& li': {
          backgroundColor: '#105166',
          textAlign: 'center',
          justifyContent: 'center',
          padding: '1px 12px',
          fontSize: '16px',
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: '#0d3f4f',
          },
        },
      },
    },
  },
  disabledButton: {
    backgroundColor: 'gray',
    color: '#fff',
    pointerEvents: 'none',
  },
}));

export default useStyles;
