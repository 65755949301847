import { getMemberByUid as _getMemberByUid } from 'api/member';

export const getMemberByUid = async (memberUID) => {
  const result = (await _getMemberByUid(memberUID)).data;
  result.basicInfo = [];
  if (result.Subscriber_ID) {
    const id = result.Subscriber_ID;
    const name = `${
      result.Subscriber_FirstName ? result.Subscriber_FirstName + ', ' : ''
    }${result.Subscriber_LastName}`;
    const dob = result.Subscriber_DOB;
    const gender =
      result.Subscriber_Gender == 'M'
        ? 'Male'
        : result.Subscriber_Gender == 'F'
        ? 'Female'
        : result.Subscriber_Gender;
    result.basicInfo.push({
      Label: 'Member ID',
      Value: id,
    });
    result.basicInfo.push({
      Label: 'DOB',
      Value: dob,
    });
    result.basicInfo.push({
      Label: 'Gender',
      Value: gender,
    });
    result.basicInfo.push({
      Label: 'Name',
      Value: name,
    });
  }

  if (result.Health_Plan_No) {
    result.basicInfo.push({
      Label: 'Health Plan#',
      Value: result.Health_Plan_No,
    });
  }

  return result;
};
