import DropdownCheckList from './dropdownCheckList';
import FormInput from './formInput';
import useStyles from './style';
import { Box, Button, TextareaAutosize, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import IcdLookup from './icdLookup';

const Form = ({
  onAccept,
  onReject,
  onCancel,
  onSave,
  checkboxes,
  setCheckboxes,
  ischecked,
  comments,
  setComments,
  supportdoc,
  setSupportDoc,
  handleSupportDocChange,
  formelements,
  setFormElements,
  focusId,
  selectedDiagnose = {},
  addDiagnose = false,
  disabled = false,
  hccVersion,
  yearOfService,
}) => {
  const [display, setDisplay] = useState(false);
  const [diagnoseStatus, setDiagnoseStatus] = useState('');
  const [isaccepted, setIsAccepted] = useState(false);
  const [isrejected, setIsRejected] = useState(false);
  const [openICDLookup, setOpenICDLookup] = useState(false);

  useEffect(() => {
    if (!addDiagnose) {
      setDiagnoseStatus(selectedDiagnose?.data?.Status ?? null);
      if (diagnoseStatus === 'accepted') {
        setIsAccepted(true);
      }
      if (diagnoseStatus === 'rejected') {
        setIsRejected(true);
      }
    }
  }, [diagnoseStatus]);

  if (!supportdoc && selectedDiagnose?.type === 'add')
    setSupportDoc(selectedDiagnose?.data?.attributes);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const handleClose = () => {
    setOpenICDLookup(false);
  };

  const previousStage = () => {
    setDisplay(false);
  };

  const nextStage = () => {
    setDisplay(true);
  };

  const onCommentsChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 256) {
      setComments(inputValue);
    }
  };

  const onFormValueChange = (e) => {
    setFormElements((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const classes = useStyles();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onAccept(e);
      }}
      className={classes.container}
    >
      <Typography
        className={classes.descriptionLabel}
        sx={addDiagnose && { textAlign: 'center' }}
      >
        {!addDiagnose
          ? selectedDiagnose.data.dxDescription
          : 'Select your Diagnosis'}
      </Typography>
      <FormInput
        label="Encounter Date"
        type="date"
        name="Encounter_Date"
        value={
          /^\d{2}-\d{2}-\d{4}$/.test(formelements?.Encounter_Date)
            ? formelements?.Encounter_Date?.split('-')
                .reverse()
                .join('-')
                .replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$1-$3-$2')
            : formelements?.Encounter_Date
        }
        onFormValueChange={onFormValueChange}
        disabled={disabled}
        required
      />
      <FormInput
        label="ICD - 10"
        name="ICD_10"
        type="text"
        value={formelements?.ICD_10}
        required
        fullWidth
        disabled={disabled}
        icd10
        onICDSelect={() => {
          setOpenICDLookup(true);
        }}
      />
      <FormInput
        label="Provider"
        name="Provider"
        type="text"
        value={formelements?.Provider}
        onFormValueChange={onFormValueChange}
        disabled={disabled}
      />
      <FormInput
        label="Note Type"
        name="Note_Type"
        type="text"
        value={formelements?.Note_Type}
        onFormValueChange={onFormValueChange}
        disabled={disabled}
      />
      <FormInput
        label="Place of Service"
        name="Place_of_Service"
        type="text"
        value={formelements?.Place_of_Service}
        fullWidth
        onFormValueChange={onFormValueChange}
        disabled={disabled}
      />
      {/* {!addDiagnose && (
        <DropdownCheckList
          title="Related Diagnosis"
          list={[]}
          disabled={disabled}
        />
      )} */}
      {supportdoc?.length ? (
        <DropdownCheckList
          title="Supporting Documentation"
          list={supportdoc.map((attribute) => ({
            id: attribute.id,
            value: attribute.text,
            isVerified: attribute.isVerified,
          }))}
          handleSupportDocChange={handleSupportDocChange}
          disabled={disabled}
          focusId={focusId}
        />
      ) : (
        <></>
      )}
      {addDiagnose ? (
        <Box className={classes.addNewActionsContainer}>
          <Button
            type="button"
            className={`${classes.actionButton} ${classes.acceptButton}`}
            onClick={onSave}
            disabled={disabled}
          >
            Save
          </Button>
          <Button
            type="button"
            className={`${classes.actionButton} ${classes.cancelButton}`}
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </Button>
        </Box>
      ) : display === false ? (
        <Box className={classes.actionButtonsContainer}>
          <Button
            type="submit"
            className={`${classes.actionButton} ${classes.acceptButton}`}
            onClick={onAccept}
            disabled={isaccepted || disabled}
          >
            Accept
          </Button>
          <Button
            type="button"
            className={`${classes.actionButton} ${classes.rejectButton}`}
            onClick={nextStage}
            disabled={isrejected || disabled}
          >
            Reject
          </Button>
          <Button
            type="button"
            className={`${classes.actionButton} ${classes.cancelButton}`}
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box>
          <Box className={classes.actionButtonsBottom}>
            <Button
              type="button"
              className={`${classes.actionButton} ${classes.cancelButton}`}
              disabled={!ischecked || disabled}
              onClick={onReject}
            >
              Reject
            </Button>
            <Button
              type="button"
              className={`${classes.actionButton} ${classes.cancelButton}`}
              onClick={previousStage}
              disabled={disabled}
            >
              Back
            </Button>
          </Box>
          <FormGroup className={classes.selectOptions}>
            {/* {checkboxes.map((checkbox) => (
              <FormControlLabel
                className={classes.checkboxdiv}
                control={
                  <Checkbox
                    checked={checkbox.checked}
                    id={checkbox.id}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                }
                label={checkbox.label}
                key={checkbox.id}
              />
            ))} */}

            {Object.entries(checkboxes).map(([label, value], index) => {
              function camelCaseToString(camelCaseStr) {
                return camelCaseStr
                  .replace(/([a-z])([A-Z])/g, '$1 $2')
                  .toLowerCase();
              }
              return (
                <FormControlLabel
                  className={classes.checkboxdiv}
                  key={label}
                  control={
                    <Checkbox
                      name={label}
                      checked={value}
                      onChange={handleChange}
                      disabled={disabled}
                      // name={label}
                    />
                  }
                  label={camelCaseToString(label)}
                />
              );
            })}
          </FormGroup>
        </Box>
      )}
      {!addDiagnose && (
        <Box className={classes.textareaContainer}>
          <Typography className={classes.textareaLabel}>Comments</Typography>
          <TextareaAutosize
            value={comments}
            onChange={onCommentsChange}
            minRows={4}
            className={classes.textarea}
            disabled={disabled}
          />
        </Box>
      )}
      <IcdLookup
        title={'Refine your ICD-10 Code'}
        caption={
          'Please enter a minimum of 3 Characters to filter the desired diagnosis. Please select the desired code to update your code.'
        }
        open={openICDLookup}
        onClose={handleClose}
        code={formelements?.ICD_10?.split('-')[0]?.trim()}
        description={formelements?.ICD_10?.split('-').slice(1).join('-').trim()}
        HCC_Code={formelements?.HCC_Code}
        HCC_description={formelements?.HCC_description}
        setFormElements={setFormElements}
        hccVersion={hccVersion}
        yearOfService={yearOfService}
      />
    </form>
  );
};
export default Form;
