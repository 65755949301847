import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    // backgroundColor: '#f0f1f5',
    // backgroundColor: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    height: '5vh',
    //width: '100%'
  },
  avatar: {
    marginLeft: '0',
    // marginBottom: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(4),
    flexShrink: 0,
    flexGrow: 0,
  },
  verticaltabs: {
    height: '74vh',
  },

  flipType: {
    '&:hover': {
      backgroundColor: '#adafb3',
    },
  },
  swapType: {
    position: 'absolute',
    bottom: '5%',
    width: 190,
    height: 70,
  },
}));
export default useStyles;
