import { Box } from 'components/controls';
import useStyles from '../style';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { userSendcode } from '../service';
import { useContext } from 'react';
import { setupMfaContext } from 'shared/context/mfa';
import { removeMaskedEmail } from 'shared/context/mfa/localstoragedata';

const SecondComponent = () => {
  const {
    setstage,
    setNotificationMsg,
    setIsSuccess,
    setOpenNotification,
    loading,
    setLoading,
    setSendDisabled,
    maskedEmail,
  } = useContext(setupMfaContext);
  const classes = useStyles();
  const history = useHistory();

  const callFirst = () => {
    setstage(1);
  };

  const Sendotp = async () => {
    setLoading(true);
    setSendDisabled(true);
    const { success, message } = await userSendcode();
    setLoading(false);
    if (success) {
      setNotificationMsg(message);
      setIsSuccess(true);
      setOpenNotification(true);
      setstage(3);
    } else {
      if (
        message === 'User not found' ||
        message === 'User already has MFA enabled for email'
      ) {
        removeMaskedEmail();
        history.replace('/login');
      }
      setNotificationMsg(message);
      setIsSuccess(false);
      setOpenNotification(true);
      if (message === 'Session expired') {
        setTimeout(() => {
          removeMaskedEmail();
          history.replace('/login');
        }, 3000);
      }
    }
    setTimeout(() => {
      setSendDisabled(false);
    }, 120000);
  };

  return (
    <Box className={classes.stagetwodiv}>
      <Box className={classes.formtwodiv}>
        <Button onClick={Sendotp} className={classes.btntwo} disabled={loading}>
          <img src="/mingcute_send-fill.png" alt="logo" />
          send code
        </Button>
        <Box className={classes.email}>{maskedEmail}</Box>
      </Box>
      <Button
        onClick={callFirst}
        className={classes.btntwoback}
        disabled={loading}
      >
        Back
      </Button>
    </Box>
  );
};

export default SecondComponent;
