import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  navGuideAccordion: {
    borderRadius: '10px !important',
    backgroundColor: 'rgba(193, 209, 214, 0.30)',
    color: '#fff',
    fontSize: '16px',
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      minHeight: 'auto',
      padding: '4px 20px',
    },
    '& .MuiAccordionSummary-content': { margin: 0 },
    '&::before': {
      content: '',
      height: '0 !important',
    },
  },
  navGuideAccordionSummary: {},
  navGuideAccordionDetails: {
    padding: '2px',
  },
  navGuideAccordionDetailItem: {
    padding: '2px 20px',
  },
}));

export default useStyles;
