import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-container': {
      width: '900px',
      position: 'relative',
      margin: '0 auto',
      '& .MuiPaper-root.MuiDialog-paper': {
        '&::-webkit-scrollbar': { display: 'none' },
        '-ms-overflow-style': 'none',
        padding: theme.spacing(1),
        scrollbarWidth: 'none',
        margin: '10px 14px',
        backgroundColor: '#F2F3F4',
        borderRadius: '4px',
        maxWidth: '100%',
      },
    },

    '& .MuiFormHelperText-root': {
      width: '100%',
      margin: 0,
      marginTop: '4px',
    },
  },
  titleBar: {
    backgroundColor: '#3E7C9E',
    marginBottom: '10px',
    borderRadius: '4px',
    padding: '6px 14px',
    color: '#fff',
  },
  formContainer: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: `${theme.spacing(3)} ${theme.spacing(6)}`,
    flexWrap: 'wrap',
    width: '100%',
    backgroundColor: '#fff',
    padding: '3rem 2rem',
    '& > *': {
      width: '100%',
      flex: '0 40%',
    },
  },
  inputField: {
    '& .MuiInputBase-root.Mui-error:after': { borderBottom: '2px solid #f00' },
    '& .MuiInputLabel-root.Mui-focused': { color: '#2E0A70' },
    '& .MuiInputBase-root': {
      '&:before': {
        borderBottom: '2px solid #2E0A70',
      },
      '&:after': { borderBottom: '2px solid #2E0A70' },
    },
    '& .MuiInputBase-root:hover:before': { borderBottom: '2px solid #2E0A70' },
    '& .MuiInputBase-root:focus:before': { borderBottom: '2px solid #2E0A70' },
    '& .MuiInput-root': {
      borderBottom: 0,
    },
    '& div': { width: '100%' },
    display: 'block',
    '-moz-appearance': 'textfield',
    '& ::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  QAContainer: {
    display: 'flex',
    '& .MuiFormControl-root': {
      maxWidth: '100px',
      bottom: '-2px',
    },
    '& h6': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#ccc',
      padding: '0 12px',
      fontWeight: '600',
      borderRadius: '0 8px 8px 0',
      marginTop: theme.spacing(2),
      borderBottom: '2px solid #2E0A70',
      boxSizing: 'border-box',
    },
  },
  priorityContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '64px',
  },
  buttonContainer: {
    padding: '1rem 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6rem',
  },
  button: {
    '&:hover': { backgroundColor: theme.palette.primary.main },
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3.447px 3.447px 0px rgba(0, 0, 0, 0.25)',
    padding: '6px 30px',
    '&:disabled': { backgroundColor: '#fff' },
  },
  saveButton: { position: 'relative' },
  spinner: {
    color: theme.palette.primary.main,
    maxWidth: '20px',
    maxHeight: '20px',
    position: 'absolute',
  },
  customWidth: {
    minWidth: '52%',
  },
  muiSelect: {
    '& .MuiSelect-select': {
      border: 0,
      padding: '10px',
    },
    '& fieldset': {
      border: 0,
    },
    '& svg': {
      // color: theme.palette.primary.main,
    },
    '& .Mui-error svg': { color: 'red' },
    maxWidth: '100px',
  },
  lobSelect: {
    '& .MuiSelect-select': {
      border: 0,
      padding: '10px',
    },
    '& fieldset': {
      border: 0,
    },
    '& svg': {
      // color: theme.palette.primary.main,
    },
    '& .Mui-error svg': { color: 'red' },
  },
  dropdown: {
    backgroundColor: '#F9FDFF',
    // color: theme.palette.primary.main,
    borderRadius: '14px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  dropdownLabel: {
    '&.Mui-focused': { color: '#2E0A70' },
    // color: theme.palette.primary.main,
    fontSize: '14px',
    top: '-4px',
  },
  editLoadingContainer: {
    backgroundColor: '#ffffffbb',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': { transform: 'scale(2)' },
  },
  yearOfServiceLabel: {
    cursor: 'pointer',
    '&:hover': { cursor: 'pointer' },
  },
  yearOfServiceHccDropDown: {
    backgroundColor: '#F9FDFF',
    // color: theme.palette.primary.main,
    '&:hover': {
      color: '#F9FDFF',
      // backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
