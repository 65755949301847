import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  selectFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '99px',
    maxWidth: '260px',
    width: '200px',
    height: '36px',
    backgroundColor: theme.palette.primary.white,
  },
  selectFilterLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export default useStyles;
