/**
 * Extract MMR details with minimum fields
 * @params
 * member
 */

export const extractMMRTableData = (member) => {
  let mmr = [];
  if (member.MMR?.Details) {
    mmr = member.MMR?.Details.map((item) => {
      const fields = {};
      fields.Year = item.Year || '';
      fields.RAF_Type = item.RAF_Type || '';
      fields.Medicaid_Status = item.Medicaid_Status || '';
      fields.Medicaid_Dual_Status = item.Medicaid_Dual_Status || '';
      fields.Orec = item.Orec || '';
      fields.Last_Payment_Date = item.Last_Payment_Date || '';
      return fields;
    });
  }
  return mmr;
};
