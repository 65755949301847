import { useCallback, useEffect, useRef, useState } from 'react';
import { lineOfBusiness } from 'config/constants';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Skeleton,
  Tooltip,
  Box,
  IconButton,
  Button,
  Checkbox,
  Menu,
  TextField,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from '@mui/material';

import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import VirtualizedList from 'components/virtualizedList';

import { ExpandIcon, FolderIcon, PenIcon, PlusIcon } from 'icons/customIcons';
import { useTheme } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';

const LoadingPlaceholder = ({ qty }) => {
  const theme = useTheme();
  return [...Array(qty).keys()]?.map((_, idx) => (
    <Skeleton
      animation="wave"
      key={idx}
      variant="rounded"
      height={45}
      sx={{ borderRadius: theme.radius, mb: '8px' }}
    />
  ));
};

const DataRow = ({
  placeholderCount = 1,
  data = [],
  width = '100%',
  emptyDataMessage = 'No data available',
  defaultExpanded = false,
  loading = false,
  allowRowExpansion = false,
  allowEdit = false,
  allowChecks = false,
  virtualized = false,
  hidePlusBtn = false,
  totalRows = 0,
  tableHeight = 0,
  onLoadMore = () => {},
  onCreateClick = () => {},
  onOpen = () => {},
  onItemCheck = () => {},
  onTopInView = () => {},
  onBottomInView = () => {},
  onEditClick = () => {},
  showFilter = false,
  setFilter = () => {},
  filter = {},
  fixedWidth = false,
}) => {
  const [rowCheck, setRowChecked] = useState({ selectall: false });
  const [rowExpansion, setRowExpansion] = useState(
    data?.reduce((acc, record) => {
      acc[record?.id] = false;
      return acc;
    }, {})
  );
  const firstRowRef = useRef(null);
  const lastRowRef = useRef(null);
  const sizeMap = useRef({});
  const listRef = useRef();

  const setSize = useCallback((index, size) => {
    if (!isNaN(size) && size > 0) {
      sizeMap.current[index] = size;
      listRef.current.resetAfterIndex(index);
    }
  }, []);

  const getSize = useCallback(
    (index) => sizeMap.current[index] || 50, // Default size for collapsed accordion
    []
  );

  const role = useSelector(UserSelector?.getUserRoles);

  const iconGutter = '25px';
  const checkBoxGutter = '20px';
  const headers = data[0]?.fields?.map((item) => item?.field);
  const gridColumns = data[0]?.fields
    ?.map(
      (item) =>
        `minmax(auto, ${Math.max(item?.field.length, item?.space ?? 1)}ch)`
    )
    .join(' ');
  const tableWidth = data[0]?.fields
    ?.map((item) => Math.max(item?.field.length, item?.space ?? 1) + 2)
    .reduce((acc, num) => acc + num, 0);
  const checkboxesVisible = Object.keys(rowCheck).some((key) => rowCheck[key]);

  const theme = useTheme();

  useEffect(() => {
    // Initialize rowCheck state based on the provided data
    if (data && data.length > 0) {
      const initialRowCheck = data.reduce(
        (acc, row) => {
          acc[row?.id] = false; // Initialize each row's checkbox to false
          return acc;
        },
        { selectall: false }
      );

      setRowChecked(initialRowCheck);
    }
  }, [data]); // This ensures rowCheck is recalculated whenever `data` changes

  useEffect(() => {
    // Initialize Intersection Observer
    const observerOptions = {
      root: null, // Use viewport as the container
      rootMargin: '0px',
      threshold: 0.1, // Trigger when at least 10% is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === firstRowRef.current) {
            onTopInView(); // Trigger top in view event
          } else if (entry.target === lastRowRef.current) {
            onBottomInView(); // Trigger bottom in view event
          }
        }
      });
    }, observerOptions);

    // Observe first and last rows
    if (firstRowRef.current) observer.observe(firstRowRef.current);
    if (lastRowRef.current) observer.observe(lastRowRef.current);

    return () => {
      if (firstRowRef.current) observer.unobserve(firstRowRef.current);
      if (lastRowRef.current) observer.unobserve(lastRowRef.current);
    };
  }, [data, onTopInView, onBottomInView]);

  useEffect(() => {
    onItemCheck(
      rowCheck?.selectall === true
        ? true
        : data
            ?.filter((item) => rowCheck[item?.id] === true)
            .map((item) => ({ id: item?.id, record: item?.record }))
    );
  }, [rowCheck]);

  const handleCheckChange = (e) => {
    e.stopPropagation();
    const { name } = e.target;

    setRowChecked((prev) => {
      // If "selectall" checkbox is toggled
      if (name === 'selectall') {
        const newSelectAllState = !prev.selectall;
        const updatedRows = Object.keys(prev).reduce((acc, key) => {
          acc[key] = newSelectAllState; // Apply the new state to all rows
          return acc;
        }, {});
        return updatedRows;
      }

      // For individual checkboxes
      return {
        ...prev,
        [name]: !prev[name], // Toggle the specific checkbox
        selectall: Object.entries({ ...prev, [name]: !prev[name] })
          ?.filter(([key]) => key !== 'selectall')
          ?.every(([_, value]) => value)
          ? true
          : false, // Deselect "Select All" if any individual checkbox is toggled
      };
    });
  };

  const Row = ({ index, style }) => {
    const row = data[index];
    return (
      <div style={style}>
        <RowItem row={row} rowIndex={index} />
        {index === data?.length - 1 && loading ? (
          <LoadingPlaceholder qty={placeholderCount} />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getLabelByValue = (value) => {
    const business = lineOfBusiness.find((item) => item.value === value);
    return business ? business.label : 'Unknown';
  };
  const RowItem = ({ row, rowIndex = null }) => {
    const detailsRef = useRef(null);
    const summaryRef = useRef(null);

    const handleAccordionChange = (event, isExpanded) => {
      setRowExpansion((prev) => ({ ...prev, [row?.id]: isExpanded }));
      if (isExpanded && detailsRef?.current) {
        const newSize =
          detailsRef?.current?.scrollHeight + summaryRef?.current?.scrollHeight; // Safely access the DOM node
        setSize(rowIndex, newSize);
      } else {
        setSize(rowIndex, summaryRef?.current?.scrollHeight + 6); // Set a default collapsed size
      }
    };

    return (
      <Accordion
        expanded={rowExpansion[row?.id]}
        onChange={handleAccordionChange}
        sx={{
          '&::before': {
            content: '""',
            display: 'none',
          },
          my: '8px',
          boxShadow: theme.shadows[0],
        }}
      >
        <AccordionSummary
          ref={summaryRef}
          expandIcon={
            <ExpandIcon
              style={{
                width: '20px',
                pointerEvents: 'auto',
                pointer: 'pointer',
                visibility: allowRowExpansion ? 'visible' : 'hidden',
              }}
            />
          }
          sx={{
            '&.Mui-expanded': {
              '& .MuiAccordionSummary-expandIconWrapper': {
                svg: {
                  transform: 'rotate(-90deg)',
                },
              },
            },
            '& .MuiAccordionSummary-content': {
              my: '2px',
              '&.Mui-expanded': { my: '2px' },
            },
            '&:active': {
              pointerEvents: 'none',
            },
            '&:hover': { '& .MuiCheckbox-root': { visibility: 'visible' } },
            flexDirection: 'row-reverse',
            borderRadius: theme.radius,
            minHeight: 'auto !important',
            boxShadow: theme.boxShadow.dataRow,
            mx: '2px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: fixedWidth
                ? `${allowChecks ? checkBoxGutter : ''} ${iconGutter} ${
                    allowEdit ? iconGutter : ''
                  } repeat(${headers.length}, 1fr)`.trim()
                : (
                    `${allowChecks ? checkBoxGutter : ''} ${iconGutter} ${
                      allowEdit ? iconGutter : ''
                    }` +
                    ' ' +
                    gridColumns
                  ).trim(),
              width: '100%',
              gap: fixedWidth ? '10px' : '2ch',
              maxWidth: fixedWidth ? width : `${tableWidth}ch`,
              alignItems: 'center',
            }}
          >
            {allowChecks && (
              <Checkbox
                checked={rowCheck[row?.id] || false}
                name={row?.id}
                sx={{
                  width: checkBoxGutter,
                  height: checkBoxGutter,
                  pointerEvents: 'auto',
                  visibility: checkboxesVisible ? 'visible' : 'hidden',
                }}
                onClick={handleCheckChange}
              />
            )}
            <IconButton
              sx={{
                width: iconGutter,
                height: iconGutter,
                maxWidth: '100%',
                p: '4px',
                pointerEvents: 'auto',
                '&:disabled': {
                  opacity: 0.2,
                },
              }}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onOpen(e, row?.record);
              }}
              disabled={row?.disableOpen}
            >
              <FolderIcon />
            </IconButton>
            {allowEdit && (
              <IconButton
                sx={{
                  width: iconGutter,
                  height: iconGutter,
                  maxWidth: '100%',
                  p: '4px',
                  pointerEvents: 'auto',
                  '&:disabled': {
                    opacity: 0.2,
                  },
                }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onEditClick(e, row?.record);
                }}
                disabled={row?.disableEdit}
              >
                <PenIcon />
              </IconButton>
            )}

            {row?.fields?.map((item, itemIndex) => (
              <Tooltip
                key={itemIndex}
                title={item?.value}
                arrow
                disableInteractive
                // placement="bottom-start"
                sx={{
                  zIndex: 0,
                  justifyContent: 'center',
                  marginLeft: '0px',
                }}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -20],
                      },
                    },
                  ],
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '9px',
                      color: theme.palette.secondary.main,
                      bgcolor: theme.palette.primary.main,
                      '& .MuiTooltip-arrow': {
                        color: theme.palette.primary.main,
                      },
                      display:
                        typeof item?.value !== 'string' ? 'none' : 'block',
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign:
                      item?.field?.includes('Name') ||
                      item?.field?.includes('Assignee') ||
                      item?.field?.includes('Place of Service') ||
                      item?.field?.includes('Facility') ||
                      item?.field?.includes('Phase') ||
                      item?.field?.includes('Business')
                        ? 'left'
                        : 'center',
                    paddingLeft:
                      item?.field?.includes('Name') ||
                      item?.field?.includes('Assignee') ||
                      item?.field?.includes('Place of Service') ||
                      item?.field?.includes('Facility') ||
                      item?.field?.includes('Business')
                        ? '5px'
                        : '0px',
                    paddingRight:
                      item?.field?.includes('Name') ||
                      item?.field?.includes('Assignee') ||
                      item?.field?.includes('Place of Service') ||
                      item?.field?.includes('Facility') ||
                      item?.field?.includes('Business')
                        ? '0px'
                        : '5px',
                    height: '40px',
                    lineHeight: '40px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                  }}
                >
                  {item?.field === 'Line of Business'
                    ? getLabelByValue(item?.value)
                    : item?.value}
                </Typography>
              </Tooltip>
            ))}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          ref={detailsRef}
          sx={{
            backgroundColor: theme.palette.primary.grey,
            borderBottomLeftRadius: theme.radius,
            borderBottomRightRadius: theme.radius,
          }}
        >
          {/* Additional content for the row */}
          {row?.expansionContent}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderDropdown = (header, showFilter) => {
    const sortHeaderList = [
      'Project Name',
      'Pages',
      'Total Dx Suggested',
      'Updated Date',
      'Assignee',
    ];
    if (
      showFilter &&
      (header === 'First Name' ||
        header === 'Last Name' ||
        header === 'ID' ||
        header === 'Member ID' ||
        header === 'Claim ID')
    ) {
      return (
        <DropdownWithSearch
          header={header}
          setFilter={setFilter}
          filter={filter}
        />
      );
    }
    if (showFilter && sortHeaderList.includes(header)) {
      return (
        <DropdownWithFilterOrder
          header={header}
          setFilter={setFilter}
          filter={filter}
        />
      );
    }
    return null;
  };

  return loading && (!data || data?.length === 0) ? (
    <LoadingPlaceholder qty={placeholderCount} />
  ) : !data || data?.length === 0 ? (
    <Box
      sx={{
        maxWidth: '50%',
        width: '400px',
        mx: 'auto',
      }}
    >
      <img
        src="/assets/images/empty.svg"
        alt="no data"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
      <Typography sx={{ textAlign: 'center' }}>
        {emptyDataMessage}
        {role?.toLowerCase()?.trim() !== 'clientuser' ? (
          <Button sx={{ ml: '4px' }} onClick={onCreateClick}>
            Create New ?
          </Button>
        ) : (
          <>.</>
        )}
      </Typography>
    </Box>
  ) : (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        width: loading ? '100%' : fixedWidth ? width : `${tableWidth}ch`,
        borderRadius: theme.radius,
        // overflow: 'hidden',
        boxShadow: theme.shadows[0],
      }}
    >
      {/* Table Header Accordion */}
      <AccordionSummary
        expandIcon={<ExpandIcon style={{ width: '20px' }} />}
        sx={{
          '&.Mui-expanded': {
            '& .MuiAccordionSummary-expandIconWrapper': {
              svg: {
                transform: 'rotate(-90deg)',
              },
            },
          },
          '& .MuiAccordionSummary-content': {
            my: '10px',
            '&.Mui-expanded': { my: '10px' },
          },
          '&:hover': { '& .MuiCheckbox-root': { visibility: 'visible' } },
          flexDirection: 'row-reverse',
          backgroundColor: theme.palette.primary.grey,
          borderRadius: theme.radius,
          minHeight: 'auto !important',
          boxShadow: theme.boxShadow.dataRow,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: fixedWidth
              ? `${allowChecks ? checkBoxGutter : ''} ${iconGutter} ${
                  allowEdit ? iconGutter : ''
                } repeat(${headers?.length}, 1fr)`.trim()
              : (
                  `${allowChecks ? checkBoxGutter : ''} ${iconGutter} ${
                    allowEdit ? iconGutter : ''
                  }` +
                  ' ' +
                  gridColumns
                ).trim(),
            gap: fixedWidth ? '10px' : '2ch',
            width: '100%',
            maxWidth: fixedWidth ? width : `${tableWidth}ch`,
            alignItems: 'center',
            padding: '10px',
          }}
        >
          {allowChecks && (
            <Checkbox
              checked={rowCheck?.selectall || false}
              name="selectall"
              sx={{
                width: checkBoxGutter,
                height: checkBoxGutter,
                visibility: checkboxesVisible ? 'visible' : 'hidden',
              }}
              onClick={handleCheckChange}
            />
          )}
          <IconButton
            size="small"
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: iconGutter,
              height: iconGutter,
              visibility:
                role?.toLowerCase()?.trim() !== 'clientuser' && !hidePlusBtn
                  ? 'visible'
                  : 'hidden',
              maxWidth: '100%',
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              p: '2px',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onCreateClick();
            }}
          >
            <PlusIcon />
          </IconButton>
          {allowEdit && (
            <IconButton
              size="small"
              sx={{
                width: iconGutter,
                height: iconGutter,
                visibility: 'hidden',
              }}
            >
              <PenIcon />
            </IconButton>
          )}
          {/* Render Header Fields */}
          {headers?.map((header, index) => (
            <Typography
              key={index}
              sx={{
                fontWeight: 'bold',
                textAlign:
                  header.includes('Name') ||
                  header.includes('Assignee') ||
                  header.includes('Place of Service') ||
                  header.includes('Facility') ||
                  header.includes('Business')
                    ? 'left'
                    : 'center',
                fontSize: '14px',
                display: 'flex',
                justifyContent:
                  header.includes('Name') ||
                  header.includes('Assignee') ||
                  header.includes('Place of Service') ||
                  header.includes('Facility') ||
                  header.includes('Business')
                    ? 'flex-start'
                    : 'center',
                alignItems: 'center',
                paddingLeft:
                  header.includes('Name') ||
                  header.includes('Assignee') ||
                  header.includes('Place of Service') ||
                  header.includes('Facility') ||
                  header.includes('Business')
                    ? '5px'
                    : '0px',
                paddingRight:
                  header.includes('Name') ||
                  header.includes('Assignee') ||
                  header.includes('Place of Service') ||
                  header.includes('Facility') ||
                  header.includes('Business')
                    ? '0px'
                    : '5px',
              }}
            >
              {header} {renderDropdown(header, showFilter)}
            </Typography>
          ))}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        {/* Render Rows */}
        {virtualized ? (
          <VirtualizedList
            data={data}
            loading={loading}
            totalRows={totalRows}
            onLoadMore={onLoadMore}
            rowHeight={getSize}
            listHeight={tableHeight === 0 ? 450 : tableHeight}
            itemData={{}}
            listRef={listRef}
          >
            {Row}
          </VirtualizedList>
        ) : (
          <Box
            sx={{
              maxHeight:
                tableHeight === 0
                  ? 'auto'
                  : isNaN(tableHeight)
                  ? tableHeight
                  : `${tableHeight}px`,
            }}
          >
            <div ref={firstRowRef} style={{ height: '1px', width: '100%' }} />
            {data?.map((row, rowIndex) => (
              <RowItem
                row={row}
                key={`${row?.id}-${rowIndex}`}
                rowIndex={rowIndex}
              />
            ))}{' '}
            <div
              ref={lastRowRef}
              style={{ height: '1px', width: '100%' }}
            ></div>
            {loading ? <LoadingPlaceholder qty={placeholderCount} /> : <></>}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const DropdownWithSearch = ({
  header = '',
  setFilter = () => {},
  filter = {},
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const open = Boolean(anchorEl);

  const filteredOptions = [
    {
      label: 'A to Z',
      sort: 'ascending',
    },
    {
      label: 'Z to A',
      sort: 'descending',
    },
  ];

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && search.length >= 3) {
      setFilter((prev) => ({ ...prev, [header]: search }));
    }
  };

  useEffect(() => {
    if (filter[header]) {
      setSearch(filter[header]);
    }
  }, [filter, header]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option.sort);

    setFilter((prevFilters) => ({
      ...prevFilters,
      [header]: option.sort,
    }));
  };

  return (
    <Box
      sx={{ position: 'relative', display: 'inline-block', boxShadow: 'none' }}
    >
      <IconButton onClick={handleOpen} sx={{ borderRadius: '50%', p: 1 }}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 170,
            padding: '0px',
            borderRadius: '20px',
            background: 'transparent',
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* Search Bar */}
        <Box sx={{ p: 1 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Search..."
            value={search}
            onChange={handleSearchChange}
            onFocus={(event) => event.stopPropagation()}
            autoFocus
            onKeyDown={handleKeyDown}
            sx={{
              backgroundColor: theme.palette.primary.white,
              maxWidth: '150px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                height: '33px',
                padding: '0',
                '& fieldset': { borderColor: theme.palette.primary.gray },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.hover,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '50%',
                      width: '33px',
                      height: '33px',
                      background: theme.palette.primary.main,
                      mr: 1,
                    }}
                  >
                    <SearchIcon sx={{ color: theme.palette.primary.white }} />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* List Items */}
        <List
          dense
          sx={{
            maxHeight: 100,
            height: 80,
            padding: '4px 0px',
            overflowY: 'auto',
            background: theme.palette.primary.grey,
            borderRadius: '20px',
            boxShadow: theme.boxShadow.dataRow,
          }}
        >
          {filteredOptions.map((option, index) => (
            <ListItem
              key={index}
              onClick={() => handleOptionSelect(option)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                '&:hover': { backgroundColor: '#f0f0f0' },
              }}
            >
              <ListItemText
                primary={option?.label}
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          ))}
        </List>
      </Menu>
    </Box>
  );
};

const DropdownWithFilterOrder = ({
  header = '',
  setFilter = () => {},
  filter = {},
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const open = Boolean(anchorEl);

  const filteredOptions = [
    {
      label: 'A to Z',
      sort: 'ascending',
    },
    {
      label: 'Z to A',
      sort: 'descending',
    },
  ];

  const handleOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (filter[header]) {
      setSearch(filter[header]);
    }
  }, [filter, header]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option.sort);

    setFilter((prevFilters) => ({
      ...prevFilters,
      [header]: option.sort,
    }));
  };

  return (
    <Box
      sx={{ position: 'relative', display: 'inline-block', boxShadow: 'none' }}
    >
      <IconButton onClick={handleOpen} sx={{ borderRadius: '50%', p: 1 }}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 170,
            padding: '0px',
            borderRadius: '20px',
            background: 'transparent',
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* List Items */}
        <List
          dense
          sx={{
            maxHeight: 100,
            height: 80,
            padding: '4px 0px',
            overflowY: 'auto',
            background: theme.palette.primary.grey,
            borderRadius: '20px',
            boxShadow: theme.boxShadow.dataRow,
          }}
        >
          {filteredOptions.map((option, index) => (
            <ListItem
              key={index}
              onClick={() => handleOptionSelect(option)}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                '&:hover': { backgroundColor: '#f0f0f0' },
              }}
            >
              <ListItemText
                primary={option?.label}
                sx={{ textAlign: 'center' }}
              />
            </ListItem>
          ))}
        </List>
      </Menu>
    </Box>
  );
};

export default DataRow;
