import { getChartsProjects, getReportByProjectId } from 'api/admin/project';
import { find } from 'api/charts';
import DataRow from 'components/controls/dataRow';
import {
  QueueReportIcon,
  ReturnArrowIcon,
  UploadIcon,
} from 'icons/customIcons';
import { useCallback, useEffect, useState } from 'react';
import useStyles from './style';
import { DropDown } from 'components/controls';
import UploadFile from '../uploadFile';
import ReportsSection from '../../../components/reportsSection';

const { Box, IconButton, Skeleton } = require('@mui/material');

const ProjectView = ({
  currentProject = null,
  onBackClick = () => {},
  onChartOpen = () => {},
  setCurrentScreen = () => {},
}) => {
  const ROW_SIZE = 50;

  const [projectsList, setProjectsList] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(
    currentProject?._id
  );
  const [listLoading, setListLoading] = useState(true);
  const [chartsLoading, setChartsLoading] = useState(true);
  const [chartsList, setChartsList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCharts, setTotalCharts] = useState(0);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [projectName, setProjectName] = useState(currentProject?.projectName);
  const [openReportsSection, setOpenReportsSection] = useState(false);
  const [reportData, setReportData] = useState({});
  const classes = useStyles();
  const [tableHeight, setTableHeight] = useState(
    window.innerHeight >= 1080 ? 600 : 590
  );

  // variable screen height
  useEffect(() => {
    const updateHeight = () => {
      setTableHeight(window.innerHeight >= 1080 ? 800 : 590);
    };

    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const fetchMore = useCallback(async (page) => {
    try {
      setChartsLoading(true);
      const res = await find({
        pageno: page,
        rowsPerPage: ROW_SIZE,
        projectId: selectedProjectId,
      });
      setTotalCharts(res?.count);
      setChartsList((prev) => [
        ...prev,
        ...res?.records?.map((record) => ({
          id: record?.ID,
          fields: [
            { field: 'ID', value: record?.memberId, space: 12 },
            { field: 'First Name', value: record?.firstName, space: 20 },
            { field: 'Last Name', value: record?.lastName, space: 20 },
            { field: 'Gender', value: record?.Gender, space: 1 },
            { field: 'DOB', value: record?.DOB, space: 10 },
            { field: 'Pages', value: record?.Pages, space: 4 },
            {
              field: 'Total DX Suggested',
              value: record.totalDxSuggested ? record?.totalDxSuggested : '-',
              space: 3,
            },
            {
              field: 'User Added DX',
              value: record.userAddedDx ? record?.userAddedDx : '-',
              space: 3,
            },
            {
              field: 'Total DX Accepted',
              value: record.totalDxAccepted ? record?.totalDxAccepted : '-',
              space: 3,
            },
            { field: 'Phase', value: record?.Phase, space: 12 },
            {
              field: 'Assignee',
              value:
                record?.Phase?.trim()?.toLowerCase() === 'code'
                  ? record?.coderAssignee
                  : record?.Phase?.trim()?.toLowerCase() === 'review' ||
                    record?.Phase?.trim()?.toLowerCase() === 'validated'
                  ? record?.reviewerAssignee
                  : 'Unassigned',
              space: 15,
            },
            {
              field: 'Last Updated Time',
              value: new Date(record?.lastReviewedTime)
                .toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })
                .replace(',', '')
                .replace(/\//g, '-'),
              space: 20,
            },
          ],
          disableOpen: Boolean(record?.Phase?.toLowerCase() === 'in_process'),
          expansionContent: <Box>More info coming soon...</Box>,
          record,
        })),
      ]);
    } catch (error) {
      console.error({ error });
    } finally {
      setChartsLoading(false);
    }
  }, []);

  const fetchProjectCharts = useCallback(async (projectId) => {
    if (!projectId) return;

    try {
      setChartsLoading(true);
      const res = await find({
        pageno: 1,
        rowsPerPage: ROW_SIZE,
        projectId: projectId,
      });
      setPage(1);
      setTotalCharts(res?.count);
      setChartsList(
        res?.records?.map((record) => ({
          id: record?.ID,
          fields: [
            { field: 'ID', value: record?.memberId, space: 12 },
            { field: 'First Name', value: record?.firstName, space: 20 },
            { field: 'Last Name', value: record?.lastName, space: 20 },
            { field: 'Gender', value: record?.Gender, space: 1 },
            { field: 'DOB', value: record?.DOB, space: 10 },
            { field: 'Pages', value: record?.Pages, space: 4 },
            {
              field: 'Total DX Suggested',
              value: record.totalDxSuggested ? record?.totalDxSuggested : '-',
              space: 3,
            },
            {
              field: 'User Added DX',
              value: record.userAddedDx ? record?.userAddedDx : '-',
              space: 3,
            },
            {
              field: 'Total DX Accepted',
              value: record.totalDxAccepted ? record?.totalDxAccepted : '-',
              space: 3,
            },
            { field: 'Phase', value: record?.Phase, space: 12 },
            {
              field: 'Assignee',
              value:
                record?.Phase?.trim()?.toLowerCase() === 'code'
                  ? record?.coderAssignee
                  : record?.Phase?.trim()?.toLowerCase() === 'review' ||
                    record?.Phase?.trim()?.toLowerCase() === 'validated'
                  ? record?.reviewerAssignee
                  : 'Unassigned',
              space: 15,
            },
            {
              field: 'Last Updated Time',
              value: new Date(record?.lastReviewedTime)
                .toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })
                .replace(',', '')
                .replace(/\//g, '-'),
              space: 20,
            },
          ],
          disableOpen: Boolean(record?.Phase?.toLowerCase() === 'in_process'),
          expansionContent: <Box>More info coming soon...</Box>,
          record,
        }))
      );
    } catch (error) {
      console.error({ error });
    } finally {
      setChartsLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getReportByProjectId(selectedProjectId);
        setReportData(res?.data);
      } catch (error) {}
    };
    if (selectedProjectId) {
      fetchData();
    }
  }, [selectedProjectId]);

  useEffect(() => {
    (async () => {
      try {
        setListLoading(true);
        const res = await getChartsProjects({ pageno: 1, rowsPerPage: 50 });
        setProjectsList(res?.records);
      } catch (error) {
        console.error({ error });
      } finally {
        setListLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setChartsList([]);
    if (selectedProjectId) {
      fetchProjectCharts(selectedProjectId);
    }
  }, [selectedProjectId, fetchProjectCharts]);

  useEffect(() => {
    if (page !== 1) fetchMore(page);
  }, [page, fetchMore]);

  const handleLoadMoreCharts = () => {
    setPage((prev) => prev + 1);
  };

  const handleChartOpen = (e, record) => {
    onChartOpen(e, record);
  };

  const handleSelectedProjectChange = (e) => {
    setSelectedProjectId(e?.target?.value);
    const selectedProject = projectsList.find(
      (project) => project._id === e.target.value
    );
    setOpenReportsSection(false);
    setCurrentScreen((prev) => ({
      ...prev,
      pdata: selectedProject,
    }));
    setProjectName(selectedProject?.projectName || 'N/A');
  };

  const handleUploadClick = () => {
    setOpenUploadDialog(true);
  };

  const handleFileUpload = () => {
    setIsUploadDisabled(true);
  };

  const handleReportsClick = () => {
    setOpenReportsSection((prev) => !prev);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.top}>
        <Box className={classes.projects}>
          <IconButton onClick={onBackClick}>
            <ReturnArrowIcon />
          </IconButton>
          {listLoading ? (
            <Skeleton variant="rounded" height={50} width={220} />
          ) : (
            <DropDown
              options={projectsList?.map((item) => ({
                value: item?._id,
                label: item?.projectName,
              }))}
              value={selectedProjectId}
              onChange={handleSelectedProjectChange}
            />
          )}
        </Box>
        <Box>
          <IconButton
            onClick={handleReportsClick}
            className={classes.topButtons}
          >
            <QueueReportIcon />
          </IconButton>
          <IconButton
            className={classes.topButtons}
            onClick={handleUploadClick}
            disabled={chartsList.length > 1 || isUploadDisabled}
          >
            <UploadIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        {Object.keys(reportData).length === 0 ? (
          <></>
        ) : (
          <ReportsSection props={reportData} expand={openReportsSection} />
        )}
        <Box className={classes.dataRowContainer}>
          <DataRow
            loading={chartsLoading}
            placeholderCount={10}
            data={chartsList}
            totalRows={totalCharts}
            // tableHeight={310}
            tableHeight={tableHeight}
            onLoadMore={handleLoadMoreCharts}
            onOpen={handleChartOpen}
            defaultExpanded
            allowChecks
            allowRowExpansion
            virtualized
            hidePlusBtn
          />
        </Box>
      </Box>
      <UploadFile
        open={openUploadDialog}
        onClose={setOpenUploadDialog}
        projectID={selectedProjectId}
        projectName={projectName}
        handleFileUpload={handleFileUpload}
      />
    </Box>
  );
};

export default ProjectView;
