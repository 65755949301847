import makeStyles from '@mui/styles/makeStyles';
import theme from 'theme/theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px  5px',
    borderRadius: '10px',
    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
    marginBottom: '5px',
    width: '200%',
    '&:hover .MuiCheckbox-root': {
      visibility: 'visible',
    },
  },
  assignCheck: {
    padding: 0,
    visibility: 'hidden',
  },
  buttons: {
    display: 'flex',
    borderRadius: '5px',
    padding: '5px 0',
    minWidth: 'auto',
    width: '100%',
    minHeight: 'auto',
    height: '100%',
    '& svg': { maxWidth: '22px' },
  },
  text: {
    width: '100%',
    textAlign: 'center',
    fontSize: '13px',
  },
  chartItemAccordion: {
    width: '100%',
    background: 'transparent',
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  chartItemAccordionSummary: {
    '& .MuiAccordionSummary-content': {
      margin: 0,
      gap: '0.33%',
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      '& button': {
        transform: 'rotate(-90deg)',
      },
    },
  },
  chartItemAccordionDetails: {
    padding: '8px 16px',
  },
  expansionContainer: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '8px',
  },

  folderIcon: {
    flex: '0 0 3%',
    '&:disabled': {
      opacity: 0.3,
    },
  },
  dotsIcon: { flex: '0 0 1%' },
  id: { flex: '0 0 9%', fontSize: '12px' },
  firstName: {
    flex: '0 0 7%',
    textAlign: 'left',
  },
  lastName: {
    flex: '0 0 7%',
    textAlign: 'left',
  },
  gender: { flex: '0 0 4%' },
  DOB: { flex: '0 0 8%' },
  pages: { flex: '0 0 4%' },
  totalDxSuggested: { flex: '0 0 8%' },
  userAddedDx: { flex: '0 0 7%' },
  totalDxAccepted: { flex: '0 0 7%' },
  phase: {
    flex: '0 0 5%',
    textAlign: 'left',
  },
  assignee: { flex: '0 0 9%' },
  lastReviewedTime: { flex: '0 0 16%' },

  phaseContainer: { position: 'relative' },
  chartProgressContainer: {
    position: 'absolute',
    content: '" "',
    height: '2px',
    width: '100%',
    backgroundColor: '#00000033',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      position: 'absolute',
      right: '102%',
      fontSize: '8px',
    },
  },
  chartProgressPercent: {
    height: '100%',
    display: 'flex',
    background: '#00000066',
  },
  chartAccepted: {
    height: '100%',
    backgroundColor: '#36BF04',
  },
  chartRejected: {
    height: '100%',
    backgroundColor: '#ff000099',
  },

  dotsMenu: {
    padding: 0,
    top: '-10px',
    '& .MuiPaper-root': {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: '#eee',
      '& ul': { padding: 0, '& li': { padding: '4px 16px' } },
    },
  },
  usersDropDown: {
    '& .MuiSelect-select': {
      border: 0,
      padding: '3px 8px !important',
    },
    '&:has(.Mui-disabled)': {
      backgroundColor: '#00000000',
    },
    '& fieldset': {
      border: 0,
    },
    '& svg': {
      display: 'none',
    },
    '& .Mui-error svg': { color: 'red' },
    '&:hover': {
      backgroundColor: '#ccc',
      '&:has(.Mui-disabled)': {
        backgroundColor: '#00000000',
      },
    },
    maxWidth: 'auto',
    borderRadius: '10px',
    margin: 0,
    textTransform: 'capitalize',
    transition: 'background 300ms linear',
  },
  assigneeDD: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  username: { fontWeight: 500, lineHeight: 1 },
  role: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#000000aa',
    lineHeight: 1,
  },
  userSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      position: 'relative',
      minWidth: 0,
      minHeight: 0,
      padding: '5px !important',
      '& svg': {
        maxWidth: '20px',
        maxHeight: '20px',
      },
      '&:disabled': {
        filter: 'grayscale(1)',
      },
    },
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      maxWidth: '20px',
      maxHeight: '20px',
    },
  },
}));

export default useStyles;
