import { Box, Typography } from '@mui/material';
import HorizontalBar from 'components/charts/bar-horizontal';
import MyPieChart from 'components/charts/pie-donut-chart';
import SingleBarChart from 'components/charts/single-bar';
import MyVerticalBarChart from 'components/charts/vertical-bar-chart';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import { ChartsIcon, MemberIcon } from 'icons/customIcons';
import ReportCard from '../charts/reports/reportCard';
import useStyles from './style';

const Dashboard = () => {
  const userRoles = useSelector(UserSelector.getUserRoles);
  const classes = useStyles();

  const memberClaimData = [
    { 'Members with Claims': 8545 },
    { 'Members with HCCs': 5643 },
    { 'Members with Complex HCCs': 1328 },
  ];

  const claimWithHcc = [
    { 'Total Claims': 108250 },
    { 'Claims with HCCs': 30968 },
  ];

  const eligibleRAClaim = [
    { 'RA Eligible Claims': 75324 },
    { 'RA Eligible Claims with HCCs': 30968 },
  ];

  const chartProcessData = [
    { 'Total Charts Loaded': 1243 },
    { 'Total Charts Processed': 1178 },
  ];

  const hccMetrics = [
    { name: 'Accepted', value: 722 },
    { name: 'Rejected', value: 163 },
    { name: 'Remaining', value: 533 },
    { name: 'Added', value: 83 },
    { name: 'Deleted', value: 33 },
    { name: 'Linked to Claims', value: 0 },
  ];

  const dxMetrics = [
    { name: 'Accepted', value: 788 },
    { name: 'Rejected', value: 181 },
    { name: 'Remaining', value: 639 },
    { name: 'Added', value: 98 },
    { name: 'Deleted', value: 43 },
    { name: 'Linked to Claims', value: 388 },
  ];

  const chartPhase = [
    { name: 'Coding', value: 283 },
    { name: 'Review', value: 83 },
    { name: 'Coded', value: 169 },
    { name: 'Validated', value: 362 },
  ];

  const supplementalData = [
    { name: 'Unlinked Supplemental', value: 9562 },
    { name: 'Linked Supplemental', value: 5249 },
    { name: 'Original Encounters', value: 75224 },
  ];

  const fileTypeData = [
    { name: 'Total Encounters', value: 100 },
    { name: '999 Accepted', value: 98 },
    { name: '277 Accepted', value: 96 },
    { name: 'MAO-002 Accepted', value: 95 },
  ];

  const riskScoreModelData = [
    { name: 'Community Non-Dual (CND)', value: 40 },
    { name: 'Community Dual (CD)', value: 25 },
    { name: 'Community Non-Aged (CNA)', value: 5 },
    { name: 'Institutional (INS)', value: 10 },
    { name: 'New Enrollee Community', value: 4 },
    { name: 'New Enrollee Institutional', value: 1 },
    { name: 'Dialysis (DIA)', value: 2 },
    { name: 'Post-Graft (PGR)', value: 1 },
    { name: 'Medicaid Dual (MCD)', value: 8 },
    { name: 'Dual-Eligible SNP (DSNP)', value: 3 },
    { name: 'Chronic Condition SNP', value: 1 },
    { name: 'Other (OTH)', value: 0.3 },
    { name: 'Unknown (UNN)', value: 0.2 },
  ];

  return (
    <Box className={classes.Dashboard}>
      <Box className={classes.greeting}>
        <Typography variant="h6">Welcome</Typography>
        <Box className={classes.box}>
          <Typography variant="h6">{userRoles}</Typography>
        </Box>
      </Box>
      <Box className={classes.gridContainer}>
        <Box className={classes.column}>
          <Box className={classes.item}>
            <ReportCard
              icon={<MemberIcon />}
              count={'11,282'}
              label={'Total Members'}
              list={[]}
              loading={false}
            />
          </Box>
          <Box className={classes.item}>
            <ReportCard
              icon={
                <img
                  src="/assets/shapes/claim.png"
                  alt="claim"
                  width="22px"
                  height="22px"
                />
              }
              count={'108,250'}
              label={'Total Claims'}
              list={[]}
              loading={false}
            />
          </Box>
          <Box className={classes.item}>
            <ReportCard
              icon={
                <img
                  src="/assets/shapes/contract.png"
                  alt="claim"
                  width="22px"
                  height="22px"
                />
              }
              count={'90,035'}
              label={'Total Encounters'}
              list={[]}
              loading={false}
            />
          </Box>
          <Box className={classes.item}>
            <ReportCard
              icon={<ChartsIcon />}
              count={'1,243'}
              label={'Total Members Charts'}
              list={[]}
              loading={false}
            />
          </Box>
        </Box>

        <Box className={classes.column}>
          <Box className={classes.item}>
            <SingleBarChart data={memberClaimData} title="Members By HCCs" />
          </Box>
          <Box className={classes.item}>
            <SingleBarChart data={claimWithHcc} title="Source Claims By HCCs" />
          </Box>
          <Box className={classes.item}>
            <MyPieChart
              data={supplementalData}
              title={'Encounters/ CRRs Submitted'}
              radius={70}
              titalPos={90}
              legendPos={120}
              height={150}
              circlePos={140}
            />
          </Box>
          <Box className={classes.item}>
            <MyPieChart
              data={chartPhase}
              title={'Charts Status'}
              radius={70}
              titalPos={40}
              legendPos={120}
              height={150}
              circlePos={140}
            />
          </Box>
        </Box>

        <Box
          className={classes.column}
          // sx={{
          //   display: 'grid',
          //   gridTemplateColumns: '25% 25% 350px 32%',
          // }}
        >
          <Box
            className={classes.item}
            sx={{
              padding: '0px 0px 100px 0px',
            }}
          >
            <MyPieChart
              data={riskScoreModelData}
              title={'Members By Risk Segment'}
              isPercentageVal
              width={300}
              height={290}
              radius={80}
              titalPos={190}
              legendPos={120}
              circlePos={140}
            />
          </Box>
          <Box
            className={classes.item}
            sx={
              {
                // paddingLeft: '20px !important',
                // marginLeft: '15px',
              }
            }
          >
            <SingleBarChart data={eligibleRAClaim} title="RA Eligible Claims" />
          </Box>
          <Box
            className={classes.item}
            // sx={{
            //   minWidth: '150px',
            //   paddingLeft: '20px !important',
            //   marginLeft: '11px',
            // }}
          >
            <HorizontalBar
              data={fileTypeData}
              title={'CMS Encounters By Status(%)'}
            />
          </Box>
          <Box className={classes.item}>
            <MyVerticalBarChart
              data1={dxMetrics}
              data2={hccMetrics}
              label1={'Dx Metrics'}
              label2={'HCCs Metrics'}
              title={'Charts Conditions Distribution'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
