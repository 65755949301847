import { useEffect } from 'react';

import { AdvanceTable } from 'components/table';

import { tableFieldsMetaData } from './metadata';
import { getMemberByUid } from './service';

const Table = ({ hocContext, hocContextView }) => {
  const {
    tableMetadata,
    setTableMetadata,
    recordCount,
    options,
    setOptions,
    records,
  } = hocContext;
  const { setOpenModal, setSelectedMember } = hocContextView;

  const onTableClick = async (type, id) => {
    setOpenModal(true);

    const member = await getMemberByUid(id);
    const newSelectedMember = {
      memberUID: id,
      ...member,
    };
    setSelectedMember(newSelectedMember);
  };

  useEffect(() => {
    // if any dropdown dynamic, include useEffect options and api call
    setTableMetadata(tableFieldsMetaData);
  }, []);

  return (
    <div style={{ overflow: 'auto' }}>
      <AdvanceTable
        metadata={tableMetadata}
        data={records}
        recordCount={recordCount}
        options={options}
        setOptions={setOptions}
        onClick={onTableClick}
      />
    </div>
  );
};

export default Table;
