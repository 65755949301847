export const filterFieldsMetadata = [
  {
    groupName: 'Claim',
    fieldId: 'ClaimID',
    fieldLabel: 'Claim ID',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Claim',
    fieldId: 'Status',
    fieldLabel: 'Status',
    type: 'dropdown',
    operators: ['='],
    items: [],
    isDynamic: true,
  },
  {
    groupName: 'Claim',
    fieldId: 'Health_Plan_No',
    fieldLabel: 'Health Plan#',
    type: 'dropdown',
    operators: ['='],
    items: [],
    isDynamic: true,
  },
  {
    groupName: 'Claim',
    fieldId: 'DOS_Min',
    fieldLabel: 'From DOS',
    type: 'date',
    operators: ['>='],
  },
  {
    groupName: 'Claim',
    fieldId: 'DOS_Max',
    fieldLabel: 'To DOS',
    type: 'date',
    operators: ['<='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_ID',
    fieldLabel: 'Member ID',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_FirstName',
    fieldLabel: 'Member First Name',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Member',
    fieldId: 'Subscriber_LastName',
    fieldLabel: 'Member Last Name',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Provider',
    fieldId: 'NPI',
    fieldLabel: 'NPI',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Provider',
    fieldId: 'BillProv_FirstName',
    fieldLabel: 'Provider First Name',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'Provider',
    fieldId: 'BillProv_LastName',
    fieldLabel: 'Provider Last Name',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'File',
    fieldId: 'File_UID',
    fieldLabel: 'File UID',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'File',
    fieldId: 'File_ID',
    fieldLabel: 'File ID',
    type: 'textbox',
    operators: ['='],
  },
  {
    groupName: 'File',
    fieldId: 'File_Name',
    fieldLabel: 'File Name',
    type: 'textbox',
    operators: ['='],
  },
];
