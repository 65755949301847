const { Box, CircularProgress, Button, Typography } = require('@mui/material');
import { useEffect, useState } from 'react';

import '@react-pdf-viewer/core/lib/styles/index.css';

import '@react-pdf-viewer/highlight/lib/styles/index.css';

import useStyles from './style';
import PdfViewer from './pdfViewer';

import NavGuide from './navGuide';
import DiagnoseBox from './diagnoseBox';
import { getChartById } from 'api/charts';
import { CHARTS_URL } from 'config/api-endpoints';
import { loadState } from 'state/storagestate';
import {
  getMemberDetails,
  getOfficeVisits,
  getSubmittedCodes,
  getTitles,
} from 'api/charts';
import { getRetrospectiveReview } from 'api/admin/project';
import ReadonlyAlert from './readonlyAlert';

const PdfView = ({
  setCurrentScreen,
  chartId,
  projectId,
  projectTitle,
  hccVersion,
  yearOfService,
  operator,
  submissionStatus,
  readonly = false,
}) => {
  const [jsonData, setJsonData] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [focus, setFocus] = useState({});
  const [selectedDiagnose, setSelectedDiagnose] = useState(null);
  // const [ciData, setCiData] = useState({ text: '', attributes: [] });
  // const [isCiOpen, setIsCiOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFound, setIsFound] = useState(true);
  const [readonlyAlert, setReadonlyAlert] = useState(false);

  const [memberDetails, setMemberDetails] = useState([]);
  const [retrospectiveReview, setRetrospectiveReview] = useState([]);
  const [officeVisits, setOfficeVisits] = useState([]);
  const [submittedCode, setSubmittedCode] = useState([]);
  const [visitCoordinates, setVisitCoordinates] = useState([]);
  const [titles, setTitles] = useState([]);
  const [titleCoordinates, setTitleCoordinates] = useState([]);
  const [diagnoseFilter, setDiagnoseFilter] = useState({
    accepted: false,
    rejected: false,
    inferred: false,
    all: true,
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;
  }

  const classes = useStyles();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await getChartById(chartId);
        await setJsonData(res?.data);
      } catch (e) {
        setIsFound(false);
      }
    })();
  }, []);

  useEffect(() => {
    setCoordinates([]);
    setAttributes([]);
    handleHighlightGeneration();
  }, [jsonData, diagnoseFilter]);

  useEffect(() => {
    handleVisitHighlightGeneration();
    handleTitleHighlightGeneration();
  }, [officeVisits, titles]);

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        setIsLoading(true);
        const res = await getMemberDetails(chartId, projectId);

        const contentArray = Object.keys(res?.data)?.map((key) => ({
          key: key,
          text: key === 'DOB' ? formatDate(res?.data[key]) : res?.data[key],
        }));

        setMemberDetails(contentArray);
      } catch (error) {
        console.error('Error fetching member details:', error);
      }
    };

    fetchMemberDetails();
  }, [chartId]);

  useEffect(() => {
    const fetchRetrospectiveReview = async () => {
      try {
        setIsLoading(true);
        const res = await getRetrospectiveReview(chartId, projectId);

        const contentArray = Object.keys(res?.data)?.map((key) => ({
          key: key,
          text:
            key.includes('Date') && !key.includes('CMS')
              ? formatDate(res?.data[key])
              : res?.data[key],
        }));

        await setRetrospectiveReview(contentArray);
      } catch (error) {
        console.error('Error fetching Retrospective review:', error);
      }
    };

    fetchRetrospectiveReview();
  }, [chartId]);

  useEffect(() => {
    const fetchSubmittedCodes = async () => {
      try {
        setIsLoading(true);
        const res = await getSubmittedCodes(chartId);

        await setSubmittedCode(res?.data);
      } catch (error) {
        console.error('Error fetching office visits:', error);
      }
    };

    fetchSubmittedCodes();
  }, [chartId]);

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        setIsLoading(true);
        const res = await getTitles(chartId);
        setTitles(res?.data?.titles);
      } catch (error) {}
    };
    fetchTitles();
  }, [chartId]);

  useEffect(() => {
    const fetchOfficeVisits = async () => {
      try {
        setIsLoading(true);
        const res = await getOfficeVisits(chartId);

        await setOfficeVisits(res?.data[0]?.visitDetail);
        // setIsLoading(false);
      } catch (error) {
        console.error('Error fetching office visits:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOfficeVisits();
  }, [chartId]);

  const userData = loadState();

  const handleVisitHighlightGeneration = () => {
    if (officeVisits) {
      officeVisits?.forEach((word) => {
        const _word = {
          id: word?.visitId,
          visit: word?.visit,
          bounds: [],
        };

        _word.bounds = word?.boundingBox?.map((bounds) => {
          const width = ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width;
          const height = ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height;

          if (!isNaN(width) && !isNaN(height)) {
            return {
              pageIndex: bounds?.pageNumber - 1,
              top: (bounds?.y0 * 100) / bounds?.height,
              left: (bounds?.x0 * 100) / bounds?.width,
              width: width,
              height: height,
            };
          }

          return null;
        });

        const validBounds = _word?.bounds?.filter(Boolean);
        if (validBounds?.length > 0) {
          _word.bounds = validBounds;
          setVisitCoordinates((prev) => [...prev, _word]);
        }
      });
    }
  };

  const handleTitleHighlightGeneration = (filter = '') => {
    if (titles) {
      titles?.forEach((word) => {
        const _word = {
          id: word?.elementId,
          text: word?.text,
          bounds: [],
        };

        const bounds = word?.boundingBox;
        if (bounds) {
          _word.bounds = [
            {
              pageIndex: bounds.pageNumber - 1,
              top: (bounds?.y0 * 100) / bounds?.height,
              left: (bounds?.x0 * 100) / bounds?.width,
              width: ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width,
              height: ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height,
            },
          ];
        }

        setTitleCoordinates((prev) => [...prev, _word]);
      });
    }
  };

  const handleHighlightGeneration = (filter = '') => {
    if (jsonData) {
      const filteredData = diagnoseFilter?.all
        ? jsonData
        : jsonData?.filter(
            (item) => diagnoseFilter[item?.Status.toLowerCase().trim()]
          );
      filteredData?.forEach((word) => {
        if (filter === '' || word?.text === filter) {
          const _word = {
            id: word?.dxCodeCaptured,
            data: word,
            bounds: [],
            diagnoseId: word?.Id,
            // dx_actions: word.dxActions,
          };
          setFocus((i) => ({ [word?.dxCodeCaptured]: false, ...i }));

          _word.bounds = word?.boundingBox?.map((bounds) => ({
            pageIndex: bounds?.pageNumber - 1,
            top: (bounds?.y0 * 100) / bounds?.height,
            left: (bounds?.x0 * 100) / bounds?.width,
            width: ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width,
            height: ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height,
          }));

          setCoordinates((curr) => [...curr, _word]);

          word?.attributes?.forEach((attribute, index) => {
            const _attribute = {
              id: `${word?.dxCodeCaptured}Attribute${index}`,
              data: attribute,
              bounds: [],
            };

            _attribute.bounds = attribute?.boundingBox?.map((bounds) => ({
              pageIndex: bounds?.pageNumber - 1,
              top: (bounds?.y0 * 100) / bounds?.height,
              left: (bounds?.x0 * 100) / bounds?.width,
              width: ((bounds?.x1 - bounds?.x0) * 100) / bounds?.width,
              height: ((bounds?.y1 - bounds?.y0) * 100) / bounds?.height,
            }));

            setAttributes((curr) => [...curr, _attribute]);
          });
        }
      });
    }
  };

  const handleDiagnoseClick = (e, word) => {
    handleFocusSet(e?.currentTarget?.id?.replace('diagnos', ''));

    document
      .getElementById(e?.currentTarget?.id?.replace('diagnos', 'highlight1'))
      ?.focus();
    document.getElementById(e?.currentTarget?.id)?.focus();

    if (readonly) setReadonlyAlert(true);
    else setSelectedDiagnose(word);
  };

  const handleHighlightClick = (e, highlight) => {
    handleFocusSet(e?.target?.id?.replace(/highlight\d*$/, ''));

    document
      .getElementById(e?.target?.id?.replace(/highlight\d*$/, 'diagnos'))
      ?.focus();

    // const text = highlight.data.text;
    // const code = highlight.data.dxCodeCaptured;
    // const attributes = highlight.data.attributes.map((attr) => ({
    //   id: attr.id,
    //   text: attr.text,
    // }));

    if (selectedDiagnose) setSelectedDiagnose(highlight);

    // setCiData({ text, attributes, code });
    // setIsCiOpen(true);
  };

  const handleFocusSet = (elementId) => {
    const ids = coordinates
      ?.filter((coordinate) => coordinate?.id === elementId && coordinate)
      ?.map((coordinate) => coordinate?.id);

    setFocus((prevFocus) => ({
      ...Object.keys(prevFocus)?.reduce(
        (acc, key) => ({ ...acc, [key]: key === ids[0] }),
        {}
      ),
    }));
  };

  // const handleCiClose = () => {
  //   setIsCiOpen(false);
  // };

  return (
    <Box className={classes.container}>
      {isLoading ? (
        <Box className={classes?.loadingSpinnerContainer}>
          <CircularProgress />
        </Box>
      ) : isFound ? (
        <>
          <NavGuide
            setCurrentScreen={setCurrentScreen}
            chartId={chartId}
            projectTitle={projectTitle}
            memberDetails={memberDetails}
            retrospectiveReview={retrospectiveReview}
            officeVisits={officeVisits}
            submittedCode={submittedCode}
            titles={titles}
            isLoading={isLoading}
            readonly={readonly}
            setAlert={setReadonlyAlert}
          />

          <PdfViewer
            pdfFile={`${CHARTS_URL}/pdf/${chartId}/${projectId}`}
            workerUrl={
              'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js'
            }
            coordinates={coordinates}
            visithighlights={visitCoordinates}
            titlehighlights={titleCoordinates}
            attributes={attributes}
            onHighlightClick={handleHighlightClick}
            focus={focus}
            setSelectedDiagnose={setSelectedDiagnose}
            rerender={setJsonData}
            fullDiagnoseList={jsonData}
            readonly={readonly}
            setAlert={setReadonlyAlert}
            chartId={chartId}
          />
          <DiagnoseBox
            coordinates={coordinates}
            setCoordinates={setCoordinates}
            onDiagnoseClick={handleDiagnoseClick}
            selectedDiagnose={selectedDiagnose}
            setSelectedDiagnose={setSelectedDiagnose}
            chartId={chartId}
            userName={userData?.user?.username}
            companyName={userData?.user?.currentClient?.clientName}
            readonly={readonly}
            setAlert={setReadonlyAlert}
            projectId={projectId}
            submissionStatus={submissionStatus}
            hccVersion={hccVersion}
            yearOfService={yearOfService}
            setCurrentScreen={setCurrentScreen}
            rerender={setJsonData}
            diagnoseFilter={diagnoseFilter}
            setDiagnoseFilter={setDiagnoseFilter}
            fullDiagnoseList={jsonData}
          />
        </>
      ) : (
        <Box className={classes.notFoundContainer}>
          <img src="./assets/images/not-found.svg" alt="file not found" />
          <Typography variant="h6">
            {`Something unexpected has happened, it seems we can't find the file
            you're looking for.`}
          </Typography>
          <Button
            type="button"
            onClick={() => {
              setCurrentScreen({ screen: 'view', props: '' });
            }}
          >
            Return to projects
          </Button>
        </Box>
      )}
      <ReadonlyAlert
        open={readonlyAlert}
        setOpen={setReadonlyAlert}
        operator={operator}
      />
    </Box>
  );
};

export default PdfView;
