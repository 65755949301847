import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  '@keyframes slideIn': {
    from: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes slideOut': {
    from: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    to: {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
  },
  CreateQueue: {
    width: '100%',
    height: 'calc(100vh - 60px)',
    padding: '1rem 2rem 0 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  BarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px',
    padding: '10px 10px',
    width: '100%',
    height: 'auto',
    backgroundColor: theme.palette.primary.grey,
    marginTop: '1rem',
  },
  BarContainerLeft: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '1rem',
  },
  BarContainerRight: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '1rem',
  },
  searchBar: {
    borderRadius: '999px',
    maxWidth: '260px',
    height: '36px',
    '& fieldset': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.palette.primary.hover} !important`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.palette.primary.main} !important`,
    },
    '& .MuiInputBase-input': {
      padding: '8px 0 8px 16px',
    },
    '& button': {
      maxWidth: '40px',
      maxHeight: '40px',
    },
    '& input': {
      color: 'black',
      '&::placeholder': {
        color: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  selectFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '99px',
    maxWidth: '260px',
    width: '200px',
    height: '36px',
    backgroundColor: theme.palette.primary.white,
  },
  selectFilterLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  createBtn: {
    backgroundColor: theme.palette.primary.main,
    padding: '6px 16px',
    minWidth: '120px',
    borderRadius: '10px',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      color: theme.palette.primary.white,
    },
    '&:disabled': {
      backgroundColor: 'grey',
      color: theme.palette.primary.white,
      cursor: 'not-allowed',
    },
  },
  filterContainer: {
    width: '100%',
    height: '40vh',
    marginTop: '12px',
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.grey,
    opacity: 1,
    animation: '$slideIn 0.3s ease-out',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  queueHeading: {
    display: 'flex',
    alignItems: 'center',
  },
  queueHeadingDetail: {},
  queueBackBtn: {
    width: '10px',
    borderRadius: '50%',
  },
  chartTable: {
    margin: '1rem 0',
    width: '100%',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    flex: 1,
  },
}));

export default useStyles;
