import { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import Tabs from 'components/controls/tabs';
import { useTheme } from '@mui/styles';
import useStyles from './style';
import Projects from './Projects';
import CreateQueue from './create-queue';
import Queues from './queues';
import Queue from './queue';
import ProjectView from './projectView';
import ChartView from './chartView';
import Reports from './reports';

const { Box } = require('@mui/material');
import ToggleButton from '@mui/material/ToggleButton';

const Charts = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [currentTab, setCurrrentTab] = useState('reports');
  const [isQueueCreate, setIsQueueCreate] = useState(false);
  const [queueName, setQueueName] = useState('');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [currentScreen, setCurrentScreen] = useState({
    screen: 'landing',
    id: '',
  });
  const [projectData, setProjectData] = useState([]);
  const [queueId, setQueueId] = useState(null);
  const [queueList, setQueueList] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState({});
  const userRoles = useSelector(UserSelector.getUserRoles);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const menu = [
    { value: 'reports', label: 'Reports' },
    { value: 'projects', label: 'Projects' },
    { value: 'queues', label: 'Queues' },
  ];

  const handleMenuChange = (e, val) => {
    setCurrrentTab(val);
  };

  const handleQueueCreated = (newQueueId) => {
    setCurrentScreen((prev) => ({ ...prev, screen: 'queue' }));
    setIsQueueCreate(false);
    setQueueId(newQueueId);
  };

  const handleBackBtn = () => {
    setCurrentScreen((prev) => ({ ...prev, screen: 'landing' }));
    setIsQueueCreate(false);
  };

  const handleProjectOpen = (_, record) => {
    setCurrentScreen((prev) => ({
      ...prev,
      screen: 'viewProject',
      pdata: record,
    }));
  };

  const handleChartOpen = (_, record) => {
    setCurrentScreen((prev) => ({
      ...prev,
      screen: 'chartView',
      data: record,
    }));
  };

  const handleQueueChartOpen = (_, record) => {
    setCurrentScreen((prev) => ({
      ...prev,
      screen: 'queueChartView',
      data: record,
    }));
  };

  const handleCreateQueueChartOpen = (_, record) => {
    setCurrentScreen((prev) => ({
      ...prev,
      screen: 'chartViewCreateQueue',
      pdata: record,
    }));
  };

  switch (currentScreen?.screen) {
    case 'landing':
      return (
        <Box className={classes.container}>
          {userRoles === 'ClientUser' ? (
            <Box className={classes.subContainer}>
              <ToggleButton
                className={classes.queueMenu}
                sx={{
                  border: `1px solid ${theme.palette.primary.main} !important`,
                  borderRadius: theme.radius + ' !important',
                  minWidth: '250px',
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: theme.palette.primary.white,
                  fontSize: '22px',
                  p: '4px',
                  transition: 'all 300ms linear',
                  '&:hover': {
                    boxShadow: theme.shadows.button,
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
              >
                Queues
              </ToggleButton>
              <Queues
                isQueueCreate={isQueueCreate}
                setIsQueueCreate={setIsQueueCreate}
                setQueueName={setQueueName}
                setSelectedProjects={setSelectedProjects}
                setProjectData={setProjectData}
                setCurrentScreen={setCurrentScreen}
                setQueueId={setQueueId}
                setQueuesList={setQueueList}
                setSelectedQueue={setSelectedQueue}
              />
            </Box>
          ) : (
            <>
              <Tabs
                tabItems={menu}
                onTabSwitch={handleMenuChange}
                className={classes.menu}
                defaultSelection={currentTab}
              />
              {currentTab === 'reports' ? (
                <Reports />
              ) : currentTab === 'projects' ? (
                <Projects onProjectOpen={handleProjectOpen} />
              ) : currentTab === 'queues' ? (
                <Queues
                  isQueueCreate={isQueueCreate}
                  setIsQueueCreate={setIsQueueCreate}
                  setQueueName={setQueueName}
                  setSelectedProjects={setSelectedProjects}
                  setProjectData={setProjectData}
                  setCurrentScreen={setCurrentScreen}
                  setQueueId={setQueueId}
                  setQueuesList={setQueueList}
                  setSelectedQueue={setSelectedQueue}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      );
    case 'createQueue':
      return (
        <CreateQueue
          queueName={queueName}
          selectedProjects={selectedProjects}
          projectData={projectData}
          handleBackBtn={handleBackBtn}
          handleQueueCreated={handleQueueCreated}
          onChartOpen={handleCreateQueueChartOpen}
        />
      );
    case 'queue':
      return (
        <Queue
          handleBackBtn={handleBackBtn}
          queueId={queueId}
          queueList={queueList}
          selectedQueue={selectedQueue}
          onChartOpen={handleQueueChartOpen}
          setSelectedQueue={setSelectedQueue}
          setQueueId={setQueueId}
          setReadOnlyMode={setReadOnlyMode}
        />
      );
    case 'viewProject':
      return (
        <ProjectView
          currentProject={currentScreen?.pdata}
          onBackClick={handleBackBtn}
          onChartOpen={handleChartOpen}
          setCurrentScreen={setCurrentScreen}
        />
      );
    case 'chartView':
      return (
        <ChartView
          setCurrentScreen={setCurrentScreen}
          chartId={currentScreen?.data?.ID}
          projectId={currentScreen?.pdata?._id}
          hccVersion={currentScreen?.pdata?.hccVersion}
          yearOfService={currentScreen?.pdata?.yearOfService}
          projectTitle={currentScreen?.pdata?.projectName
            ?.toUpperCase()
            ?.trim()}
          submissionStatus={
            currentScreen?.data?.isSubmitted?.codedStatus
              ? currentScreen?.data?.isReviewed?.reviewStatus
                ? 'supervisor'
                : 'reviewer'
              : 'coder'
          }
          readonly
          currentView={'project'}
        />
      );
    case 'chartViewCreateQueue':
      return (
        <ChartView
          setCurrentScreen={setCurrentScreen}
          chartId={currentScreen?.pdata?._id}
          projectId={currentScreen?.pdata?.projectID}
          hccVersion={currentScreen?.pdata?.hccVersion}
          yearOfService={currentScreen?.pdata?.yearOfService}
          projectTitle={currentScreen?.pdata?.projectName
            ?.toUpperCase()
            ?.trim()}
          submissionStatus={
            currentScreen?.data?.isSubmitted?.codedStatus
              ? currentScreen?.data?.isReviewed?.reviewStatus
                ? 'supervisor'
                : 'reviewer'
              : 'coder'
          }
          readonly
          currentView={'createQueueView'}
        />
      );
    case 'queueChartView':
      return (
        <ChartView
          setCurrentScreen={setCurrentScreen}
          chartId={currentScreen?.data?._id}
          projectId={currentScreen?.data?.projectID}
          hccVersion={currentScreen?.data?.hccVersion}
          yearOfService={currentScreen?.data?.yearOfService}
          projectTitle={currentScreen?.data?.projectName?.toUpperCase()?.trim()}
          queueTitle={currentScreen?.data?.queueName?.toUpperCase()?.trim()}
          queueRole={currentScreen?.data?.queueRole?.toUpperCase()?.trim()}
          submissionStatus={
            currentScreen?.data?.isSubmitted?.codedStatus
              ? currentScreen?.data?.isReviewed?.reviewStatus
                ? 'supervisor'
                : 'reviewer'
              : 'coder'
          }
          currentView={'queue'}
          readonly={readOnlyMode}
        />
      );
  }
};

export default Charts;
