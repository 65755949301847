import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { TextBox, Button } from 'components/controls';
import CopyRights from 'components/copyrights';
import { IconList } from 'icons';
import Notification from 'components/notification';
import useStyles from './style';
import { updatepassword } from './service';
import FormControl from '@mui/material/FormControl';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const UpdatePassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState({
    oldpassword: false,
    newpassword: false,
    confirmpassword: false,
  });
  const [openSnackBar, setOpenSnackBar] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const [formValue, setFormvalue] = useState([
    {
      name: 'oldpassword',
      value: '',
    },
    {
      name: 'newpassword',
      value: '',
    },
    {
      name: 'confirmpassword',
      value: '',
    },
  ]);

  const onFieldValueChange = (event) => {
    setFormvalue((prev) =>
      prev.map((item) =>
        item.name === event.target.name
          ? { ...item, value: event.target.value }
          : item
      )
    );
  };

  const passwRequirements = (passw) => {
    const checkArray = [
      passw.length >= 8,
      /[a-z]/.test(passw),
      /[A-Z]/.test(passw),
      /[0-9]/.test(passw),
      /[!@#$%^&*(),.?":{}|<>]/.test(passw),
    ];
    return checkArray.every(Boolean);
  };

  const onSubmitClick = async () => {
    setLoading(true);
    if (
      formValue[0].value === '' ||
      formValue[1].value === '' ||
      formValue[2].value === ''
    ) {
      setIsSuccess('error');
      setMessage('All fields are required');
      setOpenNotification(true);
      setLoading(false);
      return;
    }
    if (
      !passwRequirements(formValue[1].value) ||
      !passwRequirements(formValue[2].value)
    ) {
      setIsSuccess('error');
      setMessage(
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      );
      setOpenNotification(true);
      setLoading(false);
      return;
    }
    if (formValue[1].value !== formValue[2].value) {
      setIsSuccess('error');
      setMessage('Passwords do not match');
      setOpenNotification(true);
      setLoading(false);
      return;
    }

    try {
      const data = await updatepassword({
        oldpassword: formValue[0].value,
        newpassword: formValue[1].value,
      });
      setIsSuccess('success');
      setMessage('Password updated successfully');
      setOpenNotification(true);
      history.push('/');
    } catch (error) {
      if (error.response.data.message === 'Session expired') {
        setIsSuccess('error');
        setMessage('Session expired');
        setOpenNotification(true);
        history.push('/');
        setLoading(false);
      }
      setIsSuccess('error');
      setMessage(error.response.data.message);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container} component="main">
      <Snackbar
        open={openSnackBar}
        autoHideDuration={10000} // 10 seconds
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          className={classes.notificationContainer}
          severity="info"
          sx={{ width: '100%' }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          Please update your password
        </Alert>
      </Snackbar>
      <Box className={classes.bgShape}></Box>
      <Box className={classes.content}>
        <Box className={classes.paper}>
          <Box></Box>
          <span className={classes.hr} />
          <Box className={classes.form}>
            <Avatar className={classes.logo}>{IconList.User}</Avatar>
            <Typography component="h1" variant="h5">
              Update Password
            </Typography>

            <FormControl className={classes.form} onSubmit={onSubmitClick}>
              <TextBox
                name={formValue[0].name}
                label="Old Password"
                value={formValue[0].value}
                onChange={onFieldValueChange}
                autoFocus
                required
                disabled={loading}
                type={`${showPassword.oldpassword ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => {
                            return {
                              ...state,
                              oldpassword: !state.oldpassword,
                            };
                          });
                        }}
                      >
                        {showPassword.oldpassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBox
                name={formValue[1].name}
                label="New Password"
                value={formValue[1].value}
                onChange={onFieldValueChange}
                required
                disabled={loading}
                type={`${showPassword.newpassword ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => {
                            return {
                              ...state,
                              newpassword: !state.newpassword,
                            };
                          });
                        }}
                      >
                        {showPassword.newpassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBox
                name={formValue[2].name}
                label="Confirm New Password"
                value={formValue[2].value}
                onChange={onFieldValueChange}
                required
                disabled={loading}
                type={`${showPassword.confirmpassword ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => {
                            return {
                              ...state,
                              confirmpassword: !state.confirmpassword,
                            };
                          });
                        }}
                      >
                        {showPassword.confirmpassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                label="Submit"
                onClick={onSubmitClick}
                className={classes.submit}
                disabled={loading}
              />

              <Notification
                type={isSuccess}
                message={message}
                openNotification={openNotification}
                setOpenNotification={setOpenNotification}
              />
            </FormControl>
            <Box>
              <CopyRights />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePassword;
