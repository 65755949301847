import { claimAPI } from 'config/api-routlink';
import request from 'shared/service/request';

// GET request - Search claims with filter
export const find = async ({ pageno, rowsPerPage, sort, filter }) => {
  const rdata = await request.get(claimAPI.FIND, {
    params: {
      pageno: pageno,
      rowsPerPage: rowsPerPage,
      sort: sort,
      filter: filter,
    },
  });

  return {
    count: rdata.data?.data?.recordCount,
    records: rdata.data?.data?.records,
  };
};

// GET request - Summarize claims data with filter
export const distinctValues = async ({ filter, field }) => {
  const rdata = await request.get(claimAPI.DISTINCT_VALUES, {
    params: {
      filter: filter,
      field: field,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

// GET request - Get claim details by claimUID
export const getClaimById = async (claimUID) => {
  const rdata = await request.get(`${claimAPI.VIEW}/${claimUID}`, {});
  return {
    data: rdata.data?.data,
  };
};

// POST request - Submit outbound generation request
export const submitOutboundRequest = async (params) => {
  const rdata = await request.post(claimAPI.SUBMISSION_REQUEST, params);
  return {
    data: rdata.data?.data,
  };
};
