import ReportButton from 'components/reportButton';
import useStyles from './style';
import { ChartsIcon, MemberIcon } from 'icons/customIcons';
const { Box, Typography, Skeleton } = require('@mui/material');
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';
import ReportTabs from './reportTabs/index.js';
const Reports = () => {
  const [alignment, setAlignment] = useState('Total Members');

  const handleChange = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.reportsCardContainer}>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{ border: 'none', display: 'flex', gap: '8px' }}
        >
          <ToggleButton
            value="Total Members"
            className={classes.buttonContainer}
          >
            <ReportButton
              label="Total Members"
              icon={<MemberIcon />}
              count={'11,282'}
              list={[]}
              loading={false}
            />
          </ToggleButton>
          <ToggleButton
            value="Total Claims"
            className={classes.buttonContainer}
          >
            <ReportButton
              label="Total Claims"
              icon={
                <img
                  src="/assets/shapes/claim.png"
                  alt="claim"
                  width="22px"
                  height="22px"
                />
              }
              count={'108,250'}
              list={[]}
              loading={false}
            />
          </ToggleButton>
          <ToggleButton
            value="Total Encounters"
            className={classes.buttonContainer}
          >
            <ReportButton
              label="Total Encounters"
              icon={
                <img
                  src="/assets/shapes/contract.png"
                  alt="claim"
                  width="22px"
                  height="22px"
                />
              }
              count={'90,035'}
              list={[]}
              loading={false}
            />
          </ToggleButton>
          <ToggleButton
            value="Total Member Charts"
            className={classes.buttonContainer}
          >
            <ReportButton
              label="Total Member Charts"
              icon={<ChartsIcon />}
              count={'1,243'}
              list={[]}
              loading={false}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <ReportTabs tableName={alignment} />
    </Box>
  );
};

export default Reports;
