import React from 'react';

import { ROLES_SUPER_ADMIN, ROLES_CLIENT_ADMIN, ROLES } from 'config/constants';

import Login from 'views/login';
import Claim from 'views/claim';
import Member from 'views/member';
import Dashboard from 'views/dashboard';
import Reports from 'views/reports';
// import Provider from 'views/provider';
import ForgetPassword from 'views/forgetpassw';
import ConfirmPassword from 'views/confirmpassw';
import UpdatePassword from 'views/updatepassw';
import Mfasetup from 'views/mfa-setup';
import Mfalogin from 'views/mfa-login';
import Charts from 'views/charts';
import Chat from 'views/chat';
import Projects from 'views/projects';
// Lazy loading components - Dynamic components render
const Admin = React.lazy(() => import('views/admin'));
const FileManager = React.lazy(() => import('views/filemanager'));
const Submission = React.lazy(() => import('views/submission'));
// Roles
const pathRoles = {
  login: {
    path: '/login',
  },
  forgotpassw: {
    path: '/forgotpassw',
  },
  confirmpassw: {
    path: '/confirmpassw',
  },
  updatepassw: {
    path: '/updatepassw',
  },
  main: {
    path: '/',
    roles: ROLES,
  },
  dashboard: {
    path: '/dashboard',
    roles: ROLES,
  },
  reports: {
    path: '/reports',
    roles: ROLES,
  },
  claim: {
    path: '/claim',
    roles: ROLES,
  },
  member: {
    path: '/member',
    roles: ROLES,
  },
  charts: {
    path: '/charts',
    roles: ROLES,
  },
  projects: {
    path: '/projects',
    roles: ROLES,
  },
  mfasetup: {
    path: '/mfa-setup',
    roles: ROLES,
  },
  mfalogin: {
    path: '/mfa-login',
    roles: ROLES,
  },
  // admin: {
  // filemanager: {
  // provider: {
  //   path: '/provider',
  //   roles: ROLES,
  // },
  filemanager: {
    path: '/filemanager',
    roles: [ROLES_SUPER_ADMIN, ROLES_CLIENT_ADMIN],
  },
  submission: {
    path: '/submission',
    roles: [ROLES_SUPER_ADMIN, ROLES_CLIENT_ADMIN],
  },
  reports: {
    path: '/reports',
    roles: ROLES,
  },
  chat: {
    path: '/chat',
    roles: ROLES,
  },
  admin: {
    path: '/admin',
    roles: [ROLES_SUPER_ADMIN, ROLES_CLIENT_ADMIN],
  },
  default: {
    path: '/*',
    roles: [ROLES_SUPER_ADMIN, ROLES_CLIENT_ADMIN],
  },
};

// Route Pages
export const routePages = [
  {
    path: pathRoles.login.path,
    component: Login,
  },
  {
    path: pathRoles.forgotpassw.path,
    component: ForgetPassword,
  },
  {
    path: pathRoles.confirmpassw.path,
    component: ConfirmPassword,
  },
  {
    path: pathRoles.updatepassw.path,
    component: UpdatePassword,
  },
  {
    path: pathRoles.mfasetup.path,
    component: Mfasetup,
    isProtectedRoute: true,
    check: 'setup',
  },
  {
    path: pathRoles.mfalogin.path,
    component: Mfalogin,
    isProtectedRoute: true,
    check: 'login',
  },
  {
    path: pathRoles.admin.path,
    component: Admin,
    roles: pathRoles.admin.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.main.path,
    component: Dashboard,
    roles: pathRoles.main.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.dashboard.path,
    component: Dashboard,
    roles: pathRoles.dashboard.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.chat.path,
    component: Chat,
    roles: pathRoles.chat.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.reports.path,
    component: Reports,
    roles: pathRoles.reports.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.claim.path,
    component: Claim,
    roles: pathRoles.claim.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.member.path,
    component: Member,
    roles: pathRoles.member.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.charts.path,
    component: Charts,
    roles: pathRoles.charts.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.projects.path,
    component: Projects,
    roles: pathRoles.projects.roles,
    isPrivateRoute: true,
    exact: true,
  },
  // {
  //   path: pathRoles.provider.path,
  //   component: Provider,
  //   roles: pathRoles.provider.roles,
  //   isPrivateRoute: true,
  //   exact: true,
  // },
  {
    path: pathRoles.filemanager.path,
    component: FileManager,
    roles: pathRoles.filemanager.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.submission.path,
    component: Submission,
    roles: pathRoles.submission.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.default.path,
    component: Dashboard,
    roles: pathRoles.default.roles,
    isPrivateRoute: true,
    exact: true,
  },
];

// Menus
export const menuList = [
  {
    Name: 'Dashboard',
    Path: pathRoles.dashboard.path,
    Roles: pathRoles.dashboard.roles,
    Icon: 'Dashboard',
  },
  {
    Name: 'Member',
    Path: pathRoles.member.path,
    Roles: pathRoles.member.roles,
    Icon: 'Member',
  },
  {
    Name: 'Encounters',
    Path: pathRoles.claim.path,
    Roles: pathRoles.claim.roles,
    Icon: 'Encounters',
  },

  {
    Name: 'Charts',
    Path: pathRoles.charts.path,
    Roles: pathRoles.charts.roles,
    Icon: 'Document',
  },
  {
    Name: 'Reports',
    Path: pathRoles.reports.path,
    Roles: pathRoles.reports.roles,
    Icon: 'Report',
  },
  {
    Name: 'Submission',
    Path: pathRoles.submission.path,
    Roles: pathRoles.submission.roles,
    Icon: 'Submission',
  },

  // {
  //   Name: 'Provider',
  //   Path: pathRoles.provider.path,
  //   Roles: pathRoles.provider.roles,
  //   Icon: 'Hospital',
  // },
  {
    Name: 'FileManager',
    Path: pathRoles.filemanager.path,
    Roles: pathRoles.filemanager.roles,
    Icon: 'FileUpload',
  },
  {
    Name: 'Chat',
    Path: pathRoles.chat.path,
    Roles: pathRoles.chat.roles,
    Icon: 'Document',
  },
  {
    Name: 'Admin',
    Path: pathRoles.admin.path,
    Roles: pathRoles.admin.roles,
    Icon: 'Admin',
  },
];

/*
================ Route Page - Metadata ====
Metadata = {
        path: string,
        component: Component,
        roles: Array,
        isPrivateRoute: boolean,
        exact: boolean
}

*/
