import { useEffect, useState, useContext } from 'react';

import { AdvanceTable } from 'components/table';
import { viewMemberContext } from 'shared/context/member';

import { tableFieldsMetaData } from './metadata';
import { getCharts } from './service';

const Charts = ({}) => {
  const { selectedMember, selectedYear } = useContext(viewMemberContext);

  const [tableMetadata, setTableMetadata] = useState(tableFieldsMetaData);
  const [recordCount, setRecordCount] = useState(25);
  const [options, setOptions] = useState({
    pageno: 1,
    rowsPerPage: 5,
    filter: {},
    sort: {},
  });
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const asyncEffect = async () => {
      await getCharts({
        selectedMember,
        options,
        setRecords,
        recordCount,
        setRecordCount,
      });
    };
    asyncEffect();
  }, [selectedMember.Subscriber_ID, selectedYear]);

  // Params: type, id
  const onTableClick = async () => {
    // ToDO: Implement modal open while clicking the chart
  };

  useEffect(() => {
    // if any dropdown dynamic, include useEffect options and api call
    setTableMetadata(tableFieldsMetaData);
  }, []);

  return (
    <div>
      <AdvanceTable
        metadata={tableMetadata}
        data={records}
        recordCount={recordCount}
        options={options}
        setOptions={setOptions}
        onClick={onTableClick}
      />
    </div>
  );
};

export default Charts;
