import { adminAPI } from 'config/api-routlink';
import request from 'shared/service/request';

export const create = async (data) => {
  const rdata = await request.post(`${adminAPI.FILES_CREATE}`, {
    data: data,
  });
  return {
    data: rdata.data?.data,
  };
};

export const find = async ({ pageno, rowsPerPage, sort, filter }) => {
  const rdata = await request.get(adminAPI.FILES_FIND, {
    params: {
      pageno: pageno,
      rowsPerPage: rowsPerPage,
      sort: sort,
      filter: filter,
    },
  });

  return {
    count: rdata.data?.data?.recordCount,
    records: rdata.data?.data?.records,
  };
};

// GET request - Aggregate field values of files with filter
export const distinctValues = async ({ filter, field }) => {
  const rdata = await request.get(adminAPI.FILES_DISTINCT_VALUES, {
    params: {
      filter: filter,
      field: field,
    },
  });

  return {
    data: rdata.data?.data,
  };
};

// Post Request - Upload edi File
export const uploadFile = async (file, preSignedUrlParams) => {
  const formData = new FormData();
  formData.append('file', file);

  for (const [key, value] of Object.entries(preSignedUrlParams)) {
    formData.append(key, value);
  }

  const rdata = await request.post(adminAPI.FILES_CREATE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return {
    data: rdata.data,
  };
};
