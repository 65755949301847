import { useEffect } from 'react';

import { AdvanceTable } from 'components/table';

import { tableFieldsMetaData } from './metadata';
import { getClaimById } from './service';

const Table = ({ hocContext, hocContextViewClaim }) => {
  const {
    tableMetadata,
    setTableMetadata,
    recordCount,
    options,
    setOptions,
    records,
  } = hocContext;
  const { setOpenModal, setSelectedClaim } = hocContextViewClaim;

  const onTableClick = async (type, id) => {
    setOpenModal(true);
    const claim = await getClaimById(id);
    const newSelectedClaim = {
      type: type,
      claimUID: id,
      status: claim.Overall.status,
      claimID: claim.Overall.claimID,
      basicInfo: claim.basicInfo,
      ...claim,
    };
    setSelectedClaim(newSelectedClaim);
  };

  useEffect(() => {
    // if any dropdown dynamic, include useEffect options and api call
    setTableMetadata(tableFieldsMetaData);
  }, []);

  return (
    <div>
      <AdvanceTable
        metadata={tableMetadata}
        data={records}
        recordCount={recordCount}
        options={options}
        setOptions={setOptions}
        onClick={onTableClick}
      />
    </div>
  );
};

export default Table;

/*

    */
