import { Box, Checkbox, Typography } from '@mui/material';

const { default: useStyles } = require('./style');

const DiagnoseItem = ({
  hcc,
  category,
  icd10,
  id,
  onCheckChange,
  disabled = false,
}) => {
  const classes = useStyles();

  const handleCheckChange = (e) => {
    onCheckChange(id, e.target.checked);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.checkboxContainer}>
        <Checkbox
          className={classes.checkbox}
          onChange={handleCheckChange}
          disabled={disabled}
        />
      </Box>
      <Box className={classes.detailCard}>
        <Box className={classes.detailCardUpper}>
          <Typography className={classes.hcc}>{hcc}</Typography>
          <Typography className={classes.category}>{category}</Typography>
        </Box>
        <Box className={classes.detailCardLower}>
          <Typography className={classes.icd10}>{icd10}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DiagnoseItem;
