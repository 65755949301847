import { FormControlLabel, Checkbox } from '@mui/material';

const CheckBox = (props) => {
  const { name, label, checked = false, onChange, ...rest } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={name}
          name={name}
          checked={checked}
          color="primary"
          onChange={onChange}
          {...rest}
        />
      }
      label={label}
    />
  );
};

export default CheckBox;

/********************************
 * Example import 
 * 
          <CheckBox 
            name="remember"
            label="Remember me"
            checked = {controlVal.remember}
            onChange={onChange}
          />
 */
