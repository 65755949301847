export const getUserDetail = ({ user: { detail } }) => detail;

export const getUserToken = ({ user: { token } }) => token;

export const getUserRefreshToken = ({ user: { refreshtoken } }) => refreshtoken;

export const getUserClients = ({ user: { clients } }) => clients;

export const getUserCurrentClient = ({ user: { currentClient } }) =>
  currentClient;

export const getUserCurrentClientName = ({ user: { currentClient } }) =>
  currentClient ? currentClient.clientName : null;

export const getUserCurrentClientID = ({ user: { currentClient } }) =>
  currentClient ? currentClient.clientID : null;

export const getUserCurrentBusType = ({ user: { currentBusType } }) =>
  currentBusType;

export const getUserCurrentBusSubType = ({ user: { currentBusSubType } }) =>
  currentBusSubType;

export const getUserRoles = ({ user: { roles } }) => roles;

export const getYearOfService = ({ user: { yearOfService } }) => yearOfService;

export const getHealthPlanId = ({ user: { healthPlanId } }) => healthPlanId;
