import MuiDivider from '@mui/material/Divider';

const Divider = ({ children, ...rest }) => {
  return <MuiDivider {...rest}>{children}</MuiDivider>;
};
export default Divider;

/*******************************
 * Example for import
   <Divider />
 */
