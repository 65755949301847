import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: '10px',
    flexWrap: 'wrap',
    position: 'relative',
    gap: '4px',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  loadingSpinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
    width: '100%',
  },
  notFoundContainer: {
    maxWidth: '50%',
    margin: '2em auto',
    textAlign: 'center',
    '& button': {
      backgroundColor: theme.palette.primary.main,
      padding: '6px 32px',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& img': {
      maxWidth: '50%',
      height: 'auto',
    },
    '& h6': {
      marginBottom: '1em',
      fontWeight: 600,
    },
  },
}));

export default useStyles;
