import { useRef, useState } from 'react';

import ProjectListItem from './projectListItem';
import SearchBar from './searchBar';

import useStyles from './style';
import CreateProject from '../createProject';

const { Box, Button, CircularProgress, Typography } = require('@mui/material');

const ProjectListBar = ({
  list,
  selectedItem,
  onChange,
  role,
  onPageChange,
  pageState,
  loading,
  empty,
  setRerenderSwitch,
}) => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const projectItemsContainer = useRef(null);

  const classes = useStyles();

  const PlusIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      style={style}
    >
      <path
        d="M16.1875 28V18.8125H7V16.1875H16.1875V7H18.8125V16.1875H28V18.8125H18.8125V28H16.1875Z"
        fill="white"
      />
    </svg>
  );

  const handleNext = () => {
    onPageChange((currPage) => {
      return { ...currPage, pageNo: currPage.pageNo + 1 };
    });
  };

  const handlePrev = () => {
    onPageChange((currPage) => {
      return { ...currPage, pageNo: currPage.pageNo - 1 };
    });
  };

  return (
    <Box className={classes.container} ref={projectItemsContainer}>
      <Box className={classes.innerContainer}>
        {role === 'SuperAdmin' || role === 'ClientAdmin' ? (
          <Button
            type="button"
            className={classes.addButton}
            sx={{ mb: '22px' }}
            onClick={() => {
              setProjectId(null);
              setOpenAddDialog(true);
            }}
          >
            <PlusIcon />
          </Button>
        ) : (
          <Box
            sx={{ height: '40px', display: 'block', marginBottom: '22px' }}
          ></Box>
        )}

        <Box sx={{ marginBottom: '24px' }}>
          <SearchBar />
        </Box>

        <Button
          type="button"
          className={classes.listButton}
          sx={{ width: '100%', mb: '10px' }}
          disabled={empty}
          onClick={() => {
            if (projectItemsContainer.current.style.height === '80vh') {
              projectItemsContainer.current.scrollTop = 0;
              projectItemsContainer.current.style.height = '203px';
              projectItemsContainer.current.style.overflow = 'hidden';
            } else {
              projectItemsContainer.current.style.height = '80vh';
              projectItemsContainer.current.style.overflow = 'auto';
            }
          }}
        >
          Projects List
        </Button>
        {loading ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '5vh auto',
            }}
          >
            <CircularProgress />
          </Box>
        ) : empty ? (
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', margin: '20px auto' }}
          >
            No projects available yet. Try adding new projects.
          </Typography>
        ) : (
          <Box className={classes.listItemContainer}>
            {list.map((project, index) => (
              <ProjectListItem
                key={project?.projectID}
                projectId={project?.projectID}
                title={project?.projectName}
                progress={project?.QAPercentage}
                dueDate={project?.projectEndDate}
                isSelected={selectedItem === index}
                onEditClick={setOpenAddDialog}
                setId={setProjectId}
                onClick={() => {
                  onChange(index);
                }}
                role={role}
              />
            ))}
          </Box>
        )}
        {pageState.totalPages > 1 && (
          <Box className={classes.LoadMoreContainer}>
            <Button
              className={classes.loadMoreButton}
              type="button"
              onClick={handlePrev}
              disabled={pageState.pageNo === 1 || loading}
            >
              Prev
            </Button>
            <Button
              className={classes.loadMoreButton}
              type="button"
              onClick={handleNext}
              disabled={pageState.pageNo === pageState.totalPages || loading}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>

      <CreateProject
        open={openAddDialog}
        onClose={setOpenAddDialog}
        projectid={projectId}
        setRerenderSwitch={setRerenderSwitch}
      />
    </Box>
  );
};

export default ProjectListBar;
