import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  stats: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '8px',
    marginBottom: '24px',
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      gap: '28px',
      '& h5': {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
      },
    },
    '& > div:last-child': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      '& h6': {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        fontSize: '16px',
      },
    },
  },
  ChartContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    padding: '30px 8px',
    overflow: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export default useStyles;
