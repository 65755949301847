import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  inferredContainer: {
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '50vh',
  },
  title: {
    backgroundColor: theme.palette.secondary.grey,
    fontSize: '18px',
    marginBottom: '6px',
    padding: '2px 6px',
    marginBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  },
  addButton: {
    minWidth: 'auto',
    lineHeight: 1,
    padding: '2px',
    marginRight: '4px',
    backgroundColor: theme.palette.primary.main,
    '& svg': { maxWidth: '22px', maxHeight: '22px' },
    '&:hover': { backgroundColor: theme.palette.primary.hover },
    '&:disabled': { backgroundColor: theme.palette.primary.main, opacity: 0.4 },
  },
  filterButton: {
    minWidth: 'auto',
    lineHeight: 1,
    padding: '3px',
    marginRight: '4px',
    '& svg': { maxWidth: '24px', maxHeight: '24px' },
    '&:disabled': { opacity: 0.4 },
  },
  inferredDiagnosis: {
    backgroundColor: '#fff',
    padding: '4px',
    borderRadius: '0 0 10px 10px',
    minHeight: '50px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.secondary.grey,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.hover,
    },
  },
  capturedContainer: {
    flex: '40%',
    borderRadius: '10px',
    overflow: 'auto',
    '& h4': {
      fontSize: '15px',
      fontWeight: 400,
      backgroundColor: theme.palette.secondary.grey,
      padding: '4px 6px',
    },
  },
  capturedDiagnosis: {
    backgroundColor: '#fff',
    padding: '4px',
    borderRadius: '0 0 10px 10px',
  },
  filterMenu: {},
  filterMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
  },
}));

export default useStyles;
