import { useEffect, useState } from 'react';
import useStyles from './style';
import { chartsAPI } from 'api';

const {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  Checkbox,
  Button,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
} = require('@mui/material');

const IcdLookup = ({
  title,
  caption,
  open,
  onClose,
  code,
  description,
  HCC_Code,
  HCC_description,
  setFormElements,
  hccVersion,
  yearOfService,
}) => {
  const [selectedCode, setSelectedCode] = useState({
    id: '',
    code,
    description,
    HCC_Code,
    HCC_description,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [hccVersionFormated, setHccVersionFormated] = useState('V28');
  const [hccCheckBoxChecked, setHccCheckBoxChecked] = useState(true);
  const [hccYearofService, setHccYearOfService] = useState();

  useEffect(() => {
    const input = hccVersion;
    const match = input?.match(/v(\d+)/);

    if (match) {
      const result = `V${match[1]}`;
      setHccVersionFormated(result);
    } else {
      console.error('No match found');
    }
  }, []);
  useEffect(() => {
    setHccYearOfService(
      hccCheckBoxChecked ? `${yearOfService}(${hccVersion})` : null
    );
  }, [hccCheckBoxChecked]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery && debouncedQuery?.length >= 3) handleICDSearch();
    else setResults([]);
  }, [debouncedQuery, hccYearofService]);

  const classes = useStyles();

  const LeftArrowIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="27"
      viewBox="0 0 30 27"
      fill="none"
      style={style}
    >
      <path
        d="M13.5417 19.9032L6.25 13.269M6.25 13.269L13.5417 6.63477M6.25 13.269H23.75"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const FilterIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      style={style}
    >
      <path
        d="M8.20062 12.7998H20.7445M5.41309 7.67969H23.5321M12.3819 17.92H16.5632"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="1.88469"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const RefreshIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        d="M17.6498 6.35C16.9096 5.60494 16.0293 5.01371 15.0596 4.61039C14.0899 4.20706 13.05 3.99962 11.9998 4C7.57977 4 4.00977 7.58 4.00977 12C4.00977 16.42 7.57977 20 11.9998 20C15.7298 20 18.8398 17.45 19.7298 14H17.6498C17.2378 15.1695 16.4731 16.1824 15.4611 16.8988C14.4491 17.6153 13.2397 18 11.9998 18C8.68977 18 5.99977 15.31 5.99977 12C5.99977 8.69 8.68977 6 11.9998 6C13.6598 6 15.1398 6.69 16.2198 7.78L12.9998 11H19.9998V4L17.6498 6.35Z"
        fill="black"
        fillOpacity="0.8"
      />
    </svg>
  );

  const CheckIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path>
    </svg>
  );

  const handleICDSearch = async () => {
    setIsLoading(true);
    const res = await chartsAPI.getICDBySearchString(
      debouncedQuery,
      hccYearofService
    );
    setResults(res?.data);
    setIsLoading(false);
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleCodeClick = (
    id,
    code,
    description,
    HCC_Code,
    HCC_description
  ) => {
    setSelectedCode({ id, code, description, HCC_Code, HCC_description });
  };

  const handleSaveClick = () => {
    setFormElements((prev) => ({
      ...prev,
      ICD_10: selectedCode?.code + ' - ' + selectedCode?.description,
      HCC_Code: selectedCode?.HCC_Code,
      HCC_description: selectedCode?.HCC_description,
    }));

    onClose();
  };

  const handleRefreshClick = () => {
    setSelectedCode({
      id: '',
      code,
      description,
    });
    setResults([]);
    setQuery('');
  };

  const handleCheckboxChange = () => {
    setHccCheckBoxChecked(() => 1 - hccCheckBoxChecked);
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={() => {
        onClose();
        handleRefreshClick();
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h6">{title}</Typography>
        <Typography paragraph>{caption}</Typography>
        <Box className={classes.topBar}>
          <Box className={classes.searchBarContainer}>
            <IconButton onClick={onClose}>
              <LeftArrowIcon />
            </IconButton>
            <Box className={classes.icdSearchBar}>
              <input
                type="text"
                placeholder="search a code or description"
                value={query}
                onChange={handleSearchChange}
                autoFocus
              />
              <IconButton>
                <FilterIcon />
              </IconButton>
              <IconButton
                onClick={handleRefreshClick}
                className={classes.refreshButton}
              >
                <RefreshIcon />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.hccCodeFilter}>
            {hccCheckBoxChecked ? (
              <Typography variant="string" className={classes.hccVersiontext}>
                {hccVersionFormated}
              </Typography>
            ) : null}

            <Box className={classes.hccCodeText} onClick={handleCheckboxChange}>
              <Checkbox
                checked={hccCheckBoxChecked}
                className={classes.hccCheck}
              />
              <Typography variant="string" className={classes.hccOnlytext}>
                HCCs Only
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.tableContainer}>
          <Table className={classes.muiTable}>
            <TableHead>
              <TableRow>
                <TableCell>ICD-10 Codes</TableCell>
                <TableCell>Short Description</TableCell>

                {hccCheckBoxChecked ? <TableCell>HCC Codes</TableCell> : null}

                {hccCheckBoxChecked ? (
                  <TableCell>HCC Description</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow className={classes.loadingImage}>
                  <TableCell colSpan={hccCheckBoxChecked ? 4 : 2}>
                    <img src="/assets/images/searching.gif" />
                  </TableCell>
                </TableRow>
              ) : results.length ? (
                results.map((icd) => (
                  <TableRow
                    key={icd?._id}
                    onClick={() => {
                      handleCodeClick(
                        icd?._id,
                        icd?.code,
                        icd?.shortDescription,
                        icd?.hcc_code,
                        icd?.hcc_description
                      );
                    }}
                  >
                    <TableCell
                      className={classes.code}
                      sx={{
                        textAlign: 'left',
                        verticalAlign: 'top',
                        borderBottom: 'none',
                      }}
                    >
                      <Box>
                        {selectedCode?.id === icd?._id && <CheckIcon />}{' '}
                      </Box>
                      {icd?.code}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left', verticalAlign: 'top' }}>
                      {icd?.shortDescription}
                    </TableCell>
                    {hccCheckBoxChecked ? (
                      <TableCell
                        sx={{ textAlign: 'left', verticalAlign: 'top' }}
                      >
                        {icd?.hcc_code}
                      </TableCell>
                    ) : null}
                    {hccCheckBoxChecked ? (
                      <TableCell
                        sx={{ textAlign: 'left', verticalAlign: 'top' }}
                      >
                        {icd?.hcc_description}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
              ) : (
                <TableRow className={classes.initialPlaceholder}>
                  <TableCell colSpan={hccCheckBoxChecked ? 4 : 2}>
                    <img src="/assets/images/search.svg" />
                    <Typography variant="h6">
                      Start typing to search for a code or a related description
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          type="button"
          className={`${classes.actionButtons} ${classes.saveButton}`}
          onClick={handleSaveClick}
          disabled={!selectedCode?.id}
        >
          Save
        </Button>
        <Button
          type="button"
          onClick={onClose}
          className={classes.actionButtons}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IcdLookup;
