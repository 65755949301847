export const ADMIN_URL = process.env.REACT_APP_API_ENDPOINT_ADMIN + '/admin';
export const AUTH_URL = process.env.REACT_APP_API_ENDPOINT_AUTH + '/auth';
// export const MEMBER_URL = process.env.REACT_APP_API_ENDPOINT_MEMBER + '/member';
export const CLAIM_URL = process.env.REACT_APP_API_ENDPOINT_CLAIM + '/claim';
export const CHARTS_URL = process.env.REACT_APP_API_ENDPOINT_CHARTS + '/charts';
export const MFA_URL = process.env.REACT_APP_API_ENDPOINT_MFA + '/mfa';
export const API_KEY = process.env.REACT_APP_API_KEY;
export const QUEUE_URL = process.env.REACT_APP_API_ENDPOINT_QUEUE + '/queues';
export const REPORTS_URL =
  process.env.REACT_APP_API_ENDPOINT_QUEUE + '/reports';
