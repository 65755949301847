import { Box, Button } from 'components/controls';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserAction } from 'state/user';
import { AppAction } from 'state/app';
import { VERSION } from 'version';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { loginMfaContext } from 'shared/context/mfa';
import useStyles from '../style';
import { userVerify, userSendcode } from '../service';
import { removeMaskedEmail } from 'shared/context/mfa/localstoragedata';

const ThirdComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    otp,
    setOtp,
    setIsSuccess,
    setOpenNotification,
    setNotificationMsg,
    loading,
    setLoading,
    senddisabled,
    setSendDisabled,
    maskedEmail,
  } = useContext(loginMfaContext);

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  useEffect(() => {
    if (otp.length === 6) {
      readOtp(otp);
    }
  }, [otp]);

  const readOtp = async (otp) => {
    if (otp.length === 6) {
      try {
        setLoading(true);
        const { success, message, user } = await userVerify(otp);
        setLoading(false);
        if (success === false) {
          if (
            message === 'User not found' ||
            message === 'User already has MFA enabled for email'
          ) {
            removeMaskedEmail();
            history.replace('/login');
          }
          setNotificationMsg(message);
          setIsSuccess(false);
          setOpenNotification(true);
          if (
            message === 'Session expired' ||
            message === 'Your account is disabled, please contact administrator'
          ) {
            setTimeout(() => {
              removeMaskedEmail();
              history.replace('/login');
            }, 3000);
          }
        } else {
          dispatch(
            AppAction.AppAll({
              version: VERSION.version,
            })
          );
          dispatch(UserAction.UserAll(user));
          removeMaskedEmail();
          history.replace('/');
        }
      } catch (e) {
        dispatch(UserAction.UserAll({}));
      }
    }
  };

  const Sendotp = async () => {
    setLoading(true);
    setSendDisabled(true);
    const { success, message } = await userSendcode();
    setLoading(false);
    if (success) {
      setNotificationMsg(message);
      setIsSuccess(true);
      setOpenNotification(true);
    } else {
      if (
        message === 'User not found' ||
        message === 'User already has MFA enabled for email'
      ) {
        removeMaskedEmail();
        history.replace('/login');
      }
      setNotificationMsg(message);
      setIsSuccess(false);
      setOpenNotification(true);
      if (message === 'Session expired') {
        setTimeout(() => {
          removeMaskedEmail();
          history.replace('/login');
        }, 3000);
      }
    }
    setTimeout(() => {
      setSendDisabled(false);
    }, 120000);
  };

  return (
    <Box className={classes.stagethreediv}>
      <Box className={classes.textdiv}>
        A verification code has been sent to{' '}
        <Box className={classes.email}>{maskedEmail}</Box>. Enter the code to
        continue and be redirected.
      </Box>
      <TextField
        className={classes.textfield}
        value={otp}
        label="Enter Code"
        onChange={handleChange}
        disabled={loading}
      />

      <Button
        className={classes.resendbtn}
        label="Send Code again"
        onClick={Sendotp}
        disabled={loading || senddisabled}
      />
    </Box>
  );
};

export default ThirdComponent;
