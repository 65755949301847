import { useEffect, useContext, useState } from 'react';

import { viewMemberContext, memberContext } from 'shared/context/member';

import WithLoadingHOC from 'components/loadinghoc';
import { RadioButtonGroup } from 'components/controls';

//import { getYearList } from './service';

const YearFilter = ({ setLoading }) => {
  const { controlVal, setControlVal, onFieldValueChange } =
    useContext(memberContext);

  const { selectedMember, setSelectedYear } = useContext(viewMemberContext);
  const [yearList, setYearList] = useState([]);

  // Load Year list
  useEffect(() => {
    //const yearList = await getYearList({});
    const years = selectedMember.DOS_Year_List || [];
    setYearList(years.map((item) => ({ value: item.toString(), label: item })));

    const year = years.length > 0 ? years[0].toString() : null;
    setControlVal({ year: year });
    setSelectedYear(year);
    setLoading(false);
  }, [selectedMember.Subscriber_ID]);

  const onChange = (e) => {
    onFieldValueChange(e);
    setSelectedYear(e.target.value);
  };

  return (
    <RadioButtonGroup
      name="year"
      label=""
      value={controlVal?.year}
      onChange={onChange}
      row
      items={yearList}
    />
  );
};

export default WithLoadingHOC(YearFilter);
