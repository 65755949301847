import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import ListItemButton from '@mui/material/ListItemButton';

import { Label } from 'components/controls';

const NestedTreeView = ({ sectionTree, setSelectedSectionId }) => {
  const TreeItemLabel = (nodeName, error) => {
    return (
      <>
        <ListItemButton component="a" href="#">
          <Label label={nodeName} variant="caption" display="inline" />
          {error ? <Label label="*" color="red" display="inline" /> : null}
        </ListItemButton>
      </>
    );
  };
  const renderTree = (nodes) =>
    Array.isArray(nodes) ? (
      nodes.map((node) => renderTree(node))
    ) : (
      <TreeItem
        key={nodes.sectionId}
        nodeId={nodes.sectionId}
        label={TreeItemLabel(nodes.title, nodes.error)}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );

  const onSelect = (event, nodeIds) => {
    setSelectedSectionId(nodeIds);
  };

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 200, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      onNodeSelect={onSelect}
    >
      {sectionTree && renderTree(sectionTree)}
    </TreeView>
  );
};

export default NestedTreeView;
