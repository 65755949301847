import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: '#dee0e3',
    //backgroundColor: theme.palette.background.paper,
    height: '91%',
    width: '70%',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  closeIcon: {
    display: 'flex',
    height: theme.spacing(2),
    marginRight: '0px',
    paddingRight: '0px',
    justifyContent: 'flex-end',
  },
  modalContent: {
    //paddingTop: '0px',
    //paddingleft: theme.spacing(1),
    //paddingright: theme.spacing(1),
    //paddingBottom: theme.spacing(1),
    padding: theme.spacing(0, 1, 1),
  },
}));
export default useStyles;
