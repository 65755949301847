import { authAPI } from 'config/api-routlink';
import request from 'shared/service/request';
import { encryptPasswordWithPublicKey } from 'utils/encryptPassw';

export const login = async ({ username, password }) => {
  password = encryptPasswordWithPublicKey(password);
  const rdata = await request.post(authAPI.LOGIN, {
    username,
    password,
  });
  return {
    setchangepassword: rdata.data.setchangepassword,
    setmfa: rdata.data.setmfa,
    setpasswordexpired: rdata?.data?.setpasswordexpired,
    data: rdata.data.data,
  };
};

export const forgetpassw = async ({ username }) => {
  const rdata = await request.post(authAPI.FORGOT_PASSW, {
    username,
  });

  return {
    data: rdata.data.data,
  };
};

export const confirmpassw = async ({
  username,
  type,
  verificationcode,
  newpassword,
}) => {
  newpassword = encryptPasswordWithPublicKey(newpassword);
  const rdata = await request.post(authAPI.CONFIRM_PASSW, {
    username,
    type,
    verificationcode,
    newpassword,
  });

  return {
    data: rdata.data.data,
  };
};

export const changepassw = async ({ username, password, newpassword }) => {
  password = encryptPasswordWithPublicKey(password);
  newpassword = encryptPasswordWithPublicKey(newpassword);
  const rdata = await request.post(authAPI.CHANGE_PASSW, {
    username,
    password,
    newpassword,
  });
  return {
    data: rdata.data.data,
  };
};

export const updatepassw = async ({ oldpassword, newpassword }) => {
  const oldPassword = encryptPasswordWithPublicKey(oldpassword);
  const newPassword = encryptPasswordWithPublicKey(newpassword);
  const rdata = await request.post(authAPI.UPDATE_PASSW, {
    oldPassword,
    newPassword,
  });
  return { data: rdata };
};
