import { useState } from 'react';
import useStyles from './style';

const { Box, Typography, Button } = require('@mui/material');

const ProjectListItem = ({
  projectId,
  title,
  progress,
  dueDate,
  favourite,
  onClick,
  isSelected = false,
  onEditClick,
  setId,
  role,
}) => {
  const [isFavourite, setIsFavourite] = useState(Boolean(favourite));
  const [inEditMode, setInEditMode] = useState(false);
  const classes = useStyles();

  const starIconClick = () => {
    setIsFavourite(() => !isFavourite);
  };

  const StarIcon = ({ style, pathStyle }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      style={style}
    >
      <path
        d="M7.71441 2.24712C7.98425 1.51789 9.01565 1.51789 9.28549 2.24712L10.6286 5.87671C10.7134 6.10598 10.8942 6.28674 11.1234 6.37158L14.753 7.71465C15.4823 7.98449 15.4823 9.0159 14.753 9.28574L11.1234 10.6288C10.8942 10.7136 10.7134 10.8944 10.6286 11.1237L9.28549 14.7533C9.01566 15.4825 7.98425 15.4825 7.71441 14.7533L6.37134 11.1237C6.2865 10.8944 6.10574 10.7136 5.87647 10.6288L2.24687 9.28574C1.51764 9.0159 1.51764 7.98449 2.24687 7.71465L5.87647 6.37158C6.10574 6.28674 6.2865 6.10598 6.37134 5.87671L7.71441 2.24712Z"
        stroke="#073746"
        strokeWidth="2"
        strokeLinejoin="round"
        style={pathStyle}
      />
    </svg>
  );

  const OptionsIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      style={style}
    >
      <path
        d="M1.69995 1.40039L15.3 1.40039M6.37495 6.50039L15.2999 6.50039M1.69995 11.6004L15.2999 11.6004"
        stroke="#073746"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );

  const PenIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      style={style}
    >
      <path
        d="M2.80039 9.10039L5.25039 11.2004M2.45039 9.10039L9.35202 1.95772C10.0951 1.21461 11.2999 1.21461 12.0431 1.95772C12.7862 2.70083 12.7862 3.90565 12.0431 4.64876L4.90039 11.5504L1.40039 12.6004L2.45039 9.10039Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleEditClick = () => {
    setId(projectId);
    onEditClick(true);
  };

  return (
    <Box
      className={
        isSelected
          ? `${classes.container} ${classes.selected}`
          : `${classes.container}`
      }
      onClick={onClick}
    >
      <Box className={classes.projectNameAndStatusContainer}>
        {title && (
          <Typography className={classes.title}>
            {title.replace(/\b\w/g, (char) => char.toUpperCase())}
          </Typography>
        )}
        {progress && (
          <Typography
            className={classes.detail}
          >{`${progress}% Done`}</Typography>
        )}
      </Box>
      <Box className={classes.projectIconsAndDateContainer}>
        <Box className={classes.iconContainer}>
          {/* <Button
            type="button"
            className={classes.iconButton}
            onClick={starIconClick}
          >
            <StarIcon
              style={isFavourite ? { fill: 'gold' } : {}}
              pathStyle={isFavourite ? { stroke: 'gold' } : {}}
            />
          </Button> */}
          {/* <Button type="button" className={classes.iconButton}>
            <OptionsIcon />
          </Button> */}
          {(role === 'SuperAdmin' || role === 'ClientAdmin') && (
            <Button
              type="button"
              className={classes.iconButton}
              onClick={handleEditClick}
            >
              <PenIcon />
            </Button>
          )}
        </Box>
        {dueDate && (
          <Typography className={classes.detail}>
            {`Due Date: ${new Date(dueDate).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProjectListItem;
