import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import * as d3 from 'd3';

const MyVerticalBarChart = ({
  data1,
  data2,
  label1 = 'Metric 1',
  label2 = 'Metric 2',
  colors = ['#2D67E3', '#5696EA'],
  title,
}) => {
  const svgRef = useRef();
  const CHART_WIDTH = 500;
  const CHART_HEIGHT = 280;
  const MARGIN = { top: 20, right: 10, bottom: 80, left: 50 };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const width = CHART_WIDTH - MARGIN.left - MARGIN.right;
    const height = CHART_HEIGHT - MARGIN.top - MARGIN.bottom;

    const x = d3
      .scaleBand()
      .domain(data1.map((d) => d.name))
      .range([0, width])
      .padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max([...data1, ...data2], (d) => d.value)])
      .nice()
      .range([height, 0]);

    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${MARGIN.left},${MARGIN.top})`);

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'rgba(0, 0, 0, 0.7)')
      .style('color', 'white')
      .style('padding', '5px')
      .style('border-radius', '5px')
      .style('font-size', '12px');

    const uniqueClass1 = `bar-${label1.replace(/\s+/g, '-').toLowerCase()}`;
    const uniqueClass2 = `bar-${label2.replace(/\s+/g, '-').toLowerCase()}`;

    const drawBars = (data, barClass, xOffset, color, label) => {
      chartGroup
        .selectAll(`.${barClass}`)
        .data(data)
        .enter()
        .append('rect')
        .attr('class', barClass)
        .attr('x', (d) => x(d.name) + xOffset)
        .attr('y', (d) => y(d.value))
        .attr('width', x.bandwidth() / 2)
        .attr('height', (d) => height - y(d.value))
        .style('fill', color)
        .on('mouseover', function (event, d) {
          d3.select(this).style('opacity', 0.8);
          tooltip
            .style('visibility', 'visible')
            .html(`Category: ${d.name}<br/>${label}: ${d.value}`)
            .style('left', `${event.pageX + 10}px`)
            .style('top', `${event.pageY - 28}px`);
        })
        .on('mouseout', function () {
          d3.select(this).style('opacity', 1);
          tooltip.style('visibility', 'hidden');
        });
    };

    drawBars(data1, uniqueClass1, 0, colors[0], label1);
    drawBars(data2, uniqueClass2, x.bandwidth() / 2, colors[1], label2);

    chartGroup
      .append('g')
      .call(d3.axisLeft(y))
      .selectAll('text')
      .style('font-size', '12px')
      .style('font-weight', '500');
    chartGroup
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll('text')
      .style('font-size', '12px')
      .style('font-weight', '500');

    // Add legend
    const legendData = [
      {
        label: label1,
        color: colors[0],
        barClass: `.${uniqueClass1}`,
        data: data1,
      },
      {
        label: label2,
        color: colors[1],
        barClass: `.${uniqueClass2}`,
        data: data2,
      },
    ];

    const legend = chartGroup
      .append('g')
      .attr('transform', `translate(${width / 2 - 130}, ${height + 40})`);

    const legendItems = legend
      .selectAll('.legend-item')
      .data(legendData)
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(${i * 160}, 0)`);

    legendItems
      .append('rect')
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', (d) => d.color);

    legendItems
      .append('text')
      .attr('x', 20)
      .attr('y', 12)
      .text((d) => d.label)
      .style('font-size', '12px')
      .style('alignment-baseline', 'middle');

    // Show value above bars on legend hover
    legendItems.on('mouseover', function (event, d) {
      d3.selectAll(d.barClass)
        .style('stroke', 'black')
        .style('stroke-width', '2px');
      // .style("fill", "red");

      // Append text elements for bar values
      chartGroup.selectAll('.bar-value').remove();
      chartGroup
        .selectAll('.bar-value')
        .data(d.data)
        .enter()
        .append('text')
        .attr('class', 'bar-value')
        .attr(
          'x',
          (barData) =>
            x(barData.name) +
            (d.barClass === `.${uniqueClass1}`
              ? x.bandwidth() / 4
              : (3 * x.bandwidth()) / 4)
        )
        .attr('y', (barData) => y(barData.value) - 10) // Positioned slightly above the bar
        .attr('text-anchor', 'middle')
        .style('fill', 'black')
        .style('font-size', '12px')
        .text((barData) => barData.value);
    });

    legendItems.on('mouseout', function (event, d) {
      //   d3.selectAll(d.barClass).style("stroke", "none").style("fill", d.color);
      d3.selectAll(d.barClass).style('stroke', 'none');
      chartGroup.selectAll('.bar-value').remove();
    });
  }, [data1, data2, label1, label2, colors]);

  return (
    <Box
      sx={{
        textAlign: 'start',
      }}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <svg ref={svgRef} width={CHART_WIDTH} height={CHART_HEIGHT}></svg>
    </Box>
  );
};

export default MyVerticalBarChart;
