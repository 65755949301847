import './App.css';
import GlobalThemeprovider from './theme/themeprovider';
import Router from './route';
import { Provider } from 'react-redux';
import configureStore from './state/configurestore';
//import { SnackbarProvider, useSnackbar } from 'notistack';
/* <SnackbarProvider maxSnack={3}>
<Router />
</SnackbarProvider> */

function App() {
  const store = configureStore();
  return (
    <Provider store={store}>
      <GlobalThemeprovider>
        <Router />
      </GlobalThemeprovider>
    </Provider>
  );
}

export default App;
