export const setMaskedEmail = (email) => {
  const [username, domain] = email.split('@');
  const index = domain.lastIndexOf('.');
  const domainWithoutExtension = domain.slice(0, index);
  const extension = domain.slice(index);
  let maskedUsername;
  if (username.length === 1) {
    maskedUsername = username + '*';
  }
  maskedUsername =
    username.slice(0, 2) + '*'.repeat(Math.max(0, username.length - 2));
  const maskedDomain =
    domain[0] +
    '*'.repeat(Math.max(0, domainWithoutExtension.length - 1)) +
    extension;
  const maskedEmail = maskedUsername + '@' + maskedDomain;
  sessionStorage.setItem('userEmail', maskedEmail);
};

export const getMaskedEmail = () => {
  const maskedEmail = sessionStorage.getItem('userEmail');
  return maskedEmail === null || maskedEmail === undefined ? null : maskedEmail;
};

export const removeMaskedEmail = () => {
  sessionStorage.removeItem('userEmail');
};
