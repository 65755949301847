import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import useStyles from './style';

const LabelBtn = ({
  label,
  showFilter,
  toggleFilter,
  iconUp: IconUp,
  iconDown: IconDown,
  singleIcon: SingleIcon,
  singleAction,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.selectFilter}>
      <Typography>{label}</Typography>
      <Box className={classes.selectFilterLogo}>
        {toggleFilter && IconUp && IconDown ? (
          <IconButton onClick={toggleFilter}>
            {showFilter ? <IconUp /> : <IconDown />}
          </IconButton>
        ) : (
          SingleIcon && (
            <IconButton onClick={singleAction}>
              <SingleIcon />
            </IconButton>
          )
        )}
      </Box>
    </Box>
  );
};

export default LabelBtn;
