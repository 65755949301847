import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import useStyles from '../style';
import { getMembersReport, getExportMembersReport } from 'api/report';
import Notification from 'components/notification';
import FileSaver from 'file-saver';
import CustomTable from 'components/customTable';
const MemberTab = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({});
  const [notificationType, setNotificationType] = useState('');
  const [notificationMssg, setNotificationMssg] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState([
    {
      label: 'Health PlanId',
      value: 'healthPlanId',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'ID',
      value: 'memberID',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'First Name',
      value: 'memberFirstName',
      display: true,
      type: 'string',
      align: 'left',
    },
    {
      label: 'Last Name',
      value: 'memberLastName',
      display: true,
      type: 'string',
      align: 'left',
    },
    {
      label: 'Gender',
      value: 'memberGender',
      display: true,
      type: 'string',
      align: 'center',
    },
    {
      label: 'DOB',
      value: 'memberDOB',
      display: true,
      type: 'date',
      align: 'center',
    },
    {
      label: 'Total DX Suggested',
      value: 'totalDxSuggested',
      display: true,
      type: 'number',
      align: 'center',
    },
    {
      label: 'Total Dx Accepted',
      value: 'totalDxAccepted',
      display: true,
      type: 'number',
      align: 'center',
    },
    {
      label: 'User Added Dx',
      value: 'userAddedDx',
      display: true,
      type: 'number',
      align: 'center',
    },
    {
      label: 'Total Dx Rejected',
      value: 'totalDxRejected',
      display: true,
      type: 'number',
      align: 'center',
    },
  ]);

  const handleDownload = async () => {
    try {
      if (
        Object.keys(filters).length === 0 ||
        Object.values(filters).every(
          (filter) => Object.keys(filter).length === 0
        )
      ) {
        setNotificationType('error');
        setNotificationMssg('Please select at least one filter');
        setNotificationOpen(true);
        return;
      }
      setOpen(true);
      const response = await getExportMembersReport({ filters: filters });
      FileSaver.saveAs(response.data, 'report.xlsx');
      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error('Error downloading the file:', error);
    }
  };

  useEffect(() => {
    const tabChanged = async () => {
      setLoading(true);

      try {
        const result = await getMembersReport({
          pageno: page + 1,
          rowsPerPage: rowsPerPage,
          filters: filters,
          sort: sort,
        });
        if (result?.data === undefined) {
          setLoading(false);
          return;
        }

        const tableData = result?.data?.data.map((item) => {
          return {
            id: String(item?._id),
            fields: [
              { field: 'Health PlanId', value: item?.healthPlanId },
              { field: 'ID', value: item?.memberID },
              { field: 'First Name', value: item?.memberFirstName },
              { field: 'Last Name', value: item?.memberLastName },
              { field: 'Gender', value: item?.memberGender },
              { field: 'DOB', value: item?.memberDOB },
              {
                field: 'Total DX Suggested',
                value: item?.totalDxSuggested,
              },
              { field: 'Total Dx Accepted', value: item?.totalDxAccepted },
              { field: 'User Added Dx', value: item?.userAddedDx },
              { field: 'Total Dx Rejected', value: item?.totalDxRejected },
            ],
          };
        });
        setData([...tableData]);
        setTotalRows(result?.data?.count);
      } catch (e) {
        setData([]);
        setLoading(false);
      }
      setLoading(false);
    };
    tabChanged();
  }, [page, rowsPerPage, filters, sort]);

  return (
    <Box className={classes.mainContainer}>
      <Box
        sx={{
          maxWidth: '95%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <Box className={classes.tableContainer}>
          <CustomTable
            totalRows={totalRows}
            loading={loading}
            data={data}
            headersList={headers}
            setHeadersList={setHeaders}
            exportFunction={handleDownload}
            tableName="Total Member Report"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            sort={sort}
            setSort={setSort}
            filters={filters}
            setFilters={setFilters}
            open={open}
          />
        </Box>
      </Box>
      <Notification
        type={notificationType}
        message={notificationMssg}
        openNotification={notificationOpen}
        setOpenNotification={setNotificationOpen}
      />
    </Box>
  );
};

export default MemberTab;
