import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowX: 'auto',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      height: '5px',
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: theme.palette.secondary.main,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.hover,
    },
  },
  description: {
    '& h6': { fontWeight: 'bold', fontSize: '16px' },
    '& p': { margin: 0, fontSize: '14px' },
  },
}));

export default useStyles;
