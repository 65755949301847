import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import * as d3 from 'd3';

const SingleBarChart = ({ data, title }) => {
  const svgRef = useRef();
  const tooltipRef = useRef();

  const BAR_HEIGHT = 25;
  const CHART_WIDTH = 360;
  const MARGIN = { top: 20, right: 20, bottom: 60, left: 5 };

  const getChartHeight = () => {
    return data.length * BAR_HEIGHT + MARGIN.top + MARGIN.bottom;
  };

  useEffect(() => {
    if (!data || data.length === 0) return;

    const fields = data.map((d) => Object.keys(d)[0]);
    const values = data.map((d) => Object.values(d)[0]);

    const width = CHART_WIDTH;
    const height = getChartHeight() - MARGIN.top - MARGIN.bottom;
    const maxValue = d3.max(values);

    const xScale = d3
      .scaleLinear()
      .domain([0, maxValue])
      .range([0, width - MARGIN.left - MARGIN.right]);

    const color = d3
      .scaleOrdinal()
      .domain(fields)
      .range(['#5696EA', '#2D67E3', '#1C317C']);

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height + MARGIN.top + MARGIN.bottom);

    svg.selectAll('*').remove();

    const tooltip = d3.select(tooltipRef.current);

    const barGroup = svg
      .append('g')
      .attr('transform', `translate(${MARGIN.left}, ${MARGIN.top})`);

    const barSegments = {};

    fields.forEach((field, i) => {
      const barWidth = xScale(values[i]);

      const segmentGroup = barGroup.append('g');

      const bar = segmentGroup
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', barWidth)
        .attr('height', BAR_HEIGHT)
        .attr('fill', color(field))
        .on('mouseover', function (event) {
          const mousePos = d3.pointer(event);
          tooltip
            .style('visibility', 'visible')
            .html(`<strong>${field}</strong>: ${values[i]}`)
            .style('left', `${mousePos[0] + 10}px`)
            .style('top', `${mousePos[1] - 30}px`);
        })
        .on('mousemove', function (event) {
          const mousePos = d3.pointer(event);
          tooltip
            .style('left', `${mousePos[0] + 10}px`)
            .style('top', `${mousePos[1] - 30}px`);
        })
        .on('mouseout', function () {
          tooltip.style('visibility', 'hidden');
        });

      barSegments[field] = { bar, barWidth, value: values[i] };
    });

    const xAxis = d3.axisBottom(xScale).ticks(5);

    svg
      .append('g')
      .attr(
        'transform',
        `translate(${MARGIN.left}, ${BAR_HEIGHT + MARGIN.top + 10})`
      )
      .call(xAxis)
      .selectAll('text')
      .style('font-size', '12px')
      .attr('fill', '#333');

    const legend = svg
      .append('g')
      .attr(
        'transform',
        `translate(${MARGIN.left}, ${BAR_HEIGHT + MARGIN.top + 40})`
      );

    fields.forEach((field, i) => {
      const legendItem = legend
        .append('g')
        .attr('transform', `translate(0, ${i * 25})`)
        .style('cursor', 'pointer')
        .on('mouseover', function () {
          const segment = barSegments[field];

          segment.bar
            .transition()
            .duration(200)
            .attr('stroke', 'black')
            .attr('stroke-width', 2);

          const labelX = segment.barWidth - 10;
          const labelY = -5;

          barGroup
            .append('text')
            .attr('id', `value-label-${i}`)
            .attr('x', labelX)
            .attr('y', labelY)
            .attr('text-anchor', 'end')
            .attr('fill', 'black')
            .style('font-size', '12px')
            .style('font-weight', '500')
            .text(segment.value);
        })
        .on('mouseout', function () {
          d3.selectAll('rect')
            .transition()
            .duration(200)
            .attr('stroke', 'none')
            .style('opacity', 1);
          d3.select(`#value-label-${i}`).remove();
        });

      legendItem
        .append('rect')
        .attr('width', 15)
        .attr('height', 15)
        .attr('fill', color(field));

      legendItem
        .append('text')
        .attr('x', 20)
        .attr('y', 12)
        .attr('fill', '#333')
        .style('font-size', '12px')
        .style('font-weight', '500')
        // .text(`${field} - ${values[i]}`);
        .text(field);
    });
  }, [data]);

  return (
    <Box
      style={{
        position: 'relative',
        width: '240px',
        textAlign: 'start',
        height: '0px',
      }}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      <svg ref={svgRef}></svg>
      <Box
        ref={tooltipRef}
        style={{
          position: 'absolute',
          background: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '4px',
          fontSize: '14px',
          pointerEvents: 'none',
          visibility: 'hidden',
        }}
      ></Box>
    </Box>
  );
};

export default SingleBarChart;
