import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import useStyles from './style';
import { useTheme } from '@mui/styles';
import { queueAPI } from 'api';
import Notification from 'components/notification';

const SelectProject = ({ open, onClose, setCurrentScreen, onProjectData }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [queueName, setQueueName] = useState('');
  const [chartProjects, setChartProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('error');

  useEffect(() => {
    if (open) {
      const fetchProjects = async () => {
        try {
          setIsLoading(true);
          const projectList = await queueAPI.getChartsProjects({
            pageno: 1,
            rowsPerPage: 30,
          });
          setChartProjects(projectList?.data || []);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching project list:', error);
          setIsLoading(false);
        }
      };

      fetchProjects();
    }
  }, [open]);

  const handleProjectClick = (projectID) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(projectID)
        ? prevSelected.filter((id) => id !== projectID)
        : [...prevSelected, projectID]
    );
  };

  const calculateTotalCharts = () => {
    return selectedProjects.reduce((total, projectId) => {
      const project = chartProjects.find((p) => p.projectID === projectId);
      return total + (project?.chartCount || 0);
    }, 0);
  };

  const handleClose = () => {
    setSelectedProjects([]);
    setQueueName('');
    onClose();
  };

  const handleViewChart = async () => {
    if (!queueName.trim()) {
      setSnackbarType('error');
      setSnackbarMessage('QueueName should not be empty!!');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await queueAPI.queueNameValidation({ queueName });

      if (response?.data?.success) {
        onProjectData(queueName, selectedProjects, chartProjects);
        setCurrentScreen({
          screen: 'createQueue',
        });
      } else {
        setSnackbarType('error');
        setSnackbarMessage('Queue name should be unique!');
        setSnackbarOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setSnackbarType('error');
        setSnackbarMessage('Queue name should be unique!');
        setSnackbarOpen(true);
      } else {
        console.error('Error validating queue name:', error);
        setSnackbarType('error');
        setSnackbarMessage(
          'Failed to validate the queue name. Please try again.'
        );
        setSnackbarOpen(true);
      }
    }
  };

  const handleQueueNameChange = (event) => {
    setQueueName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.textCenter}>
          <Typography variant="body1">
            Please give the Queue name & select one or more projects to filter
            the charts accordingly.
          </Typography>
        </Box>
        <Box className={classes.textFieldContainer}>
          <TextField
            id="queue-name"
            label="Queue Name *"
            variant="standard"
            value={queueName}
            onChange={handleQueueNameChange}
            style={{ width: '70%' }}
            InputLabelProps={{
              style: { color: theme.palette.primary.main },
            }}
            InputProps={{
              style: {
                borderBottom: `1px solid ${theme.palette.primary.main}`,
              },
            }}
          />
        </Box>
        {isLoading ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : chartProjects.length > 0 ? (
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    Project Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Description
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Charts Available
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chartProjects.map((project) => (
                  <TableRow
                    key={project.projectID}
                    className={classes.tableRow}
                  >
                    <TableCell
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className={classes.tableCell}
                    >
                      <Checkbox
                        checked={selectedProjects.includes(project.projectID)}
                        onChange={() => handleProjectClick(project.projectID)}
                        value={project.projectID}
                        color="primary"
                      />
                      <Tooltip
                        title={project.projectName}
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Typography
                          noWrap
                          style={{
                            maxWidth: '160px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {project.projectName}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Tooltip
                        title={
                          project.projectDescription ||
                          'No description available.'
                        }
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Typography
                          noWrap
                          style={{
                            maxWidth: '300px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {project.projectDescription ||
                            'No description available.'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <Typography
                        noWrap
                        style={{
                          maxWidth: '80px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {project.chartCount || 0}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              height: '350px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>No projects available.</Typography>
          </Box>
        )}
      </DialogContent>
      <Box className={classes.filterContainer}>
        <Typography variant="body1" className={classes.filterText}>
          Filter Chart: {calculateTotalCharts()}
        </Typography>
      </Box>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.actionbtn}
          onClick={handleViewChart}
          color="primary"
        >
          View Charts
        </Button>
        <Button
          className={classes.actionbtn}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
      <Notification
        type={snackbarType}
        message={snackbarMessage}
        openNotification={snackbarOpen}
        setOpenNotification={setSnackbarOpen}
      />
    </Dialog>
  );
};

export default SelectProject;
