import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    boxSizing: 'border-box',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  bgShape: {
    height: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.up('md')]: {
      backgroundImage:
        'url(./assets/shapes/shape1.png), url(./assets/shapes/shape2.png)',
      backgroundPosition: 'left, right',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: 'contain',
    },
  },
  content: {
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '100%',
      paddingLeft: theme.spacing(16),
    },
  },
  paper: {
    position: 'relative',
    padding: '45px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '35px 0 0 35px',
    '-webkit-box-shadow': 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    '-moz-box-shadow': 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    boxShadow: 'inset 0px 0px 14px 10px rgba(255,255,255,0.8)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(5px)',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    '& > div': { flexGrow: 1, flexBasis: 0 },
    '& > div:first-child': {
      padding: theme.spacing(2),
      '& img': { opacity: 0.8 },
    },
  },
  hr: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '5px',
    height: '100%',
    filter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2E0A70',
      borderRadius: '5px',
      borderWidth: '2px',
    },

    '& .MuiFormControl-root': {
      margin: 0,
      marginBottom: theme.spacing(4),
    },

    '& .MuiFormControl-fullWidth': {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '75%',
      },
    },

    '& .MuiFormLabel-root': { color: '#000' },

    '& .MuiFormLabel-asterisk': { color: 'transparent' },
  },
  logo: {
    maxWidth: '90px',
    height: 'auto',
    marginTop: '0px',
  },
  submit: {
    backgroundColor: '#8971B5',
    width: '75%',
    color: '#000',
    marginBottom: theme.spacing(4),
    fontSize: '20px',
    lineHeight: 'normal',
    fontFamily: 'Merriweather',
    fontWeight: 400,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: 'white',
      backgroundColor: '#8971B5',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
  },
  link: {
    color: 'rgba(0, 0, 0, 0.80)',
    fontFamily: 'Merriweather',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    textDecoration: 'none',
    marginBottom: theme.spacing(3),
  },
  mainLogo: {
    height: '90px',
    width: 'auto',
    color: '#175F88',
  },
  heading: {
    color: 'rgba(0;0;0;0.80)',
    fontSize: '32.31',
    fontFamily: 'Inter',
    fontWeight: '500',
    wordWrap: 'break-word',
  },
  btn: {
    background: '#8971B5',
    color: 'black',
    fontSize: '24.80',
    fontFamily: 'Merriweather',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  email: {
    display: 'inline',
    fontWeight: 'bold',
  },
  topdiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // gap : '10px',
  },
  CopyRights: {
    position: 'absolute',
    bottom: '0',
  },
  maindiv: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '350px',
    alignItems: 'center',
  },
  formdiv: {
    marginTop: '10px',
    display: 'flex',
    width: '80%',
    color: '#EDF4F7',
  },
  stagetwobtn: {
    marginTop: '10%',

    '&:hover': {
      color: 'white',
      boxShadow: '0px 5px 4px 1px #000000aa',
    },
  },
  stagethreediv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  textdiv: {
    textAlign: 'center',
  },
  resendbtn: {
    marginLeft: '53%',
    color: 'black',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:disabled': {
      color: 'grey',
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      color: '#303030',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  mainheading: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '25px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
  },
  subheading: {
    display: 'flex',
    width: '530px',
    height: '34px',
    flexDirection: 'column',
    justifyContent: 'center',
    flexShrink: '0',
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
  },
  stagetwodiv: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textfield: {
    width: '400px',
    height: '50px',
    marginTop: '15px',
  },
}));
export default useStyles;
