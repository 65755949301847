import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import useStyles from './style';
import { useEffect, useState } from 'react';
import { adminAPI, chartsAPI } from 'api';
import ChartRow from './chartRow';
import { useSelector } from 'react-redux';
import { UserSelector } from 'state/user';
import Notification from 'components/notification';

const ProjectTable = ({
  selectedProject,
  setCurrentScreen,
  projectData,
  bulkAssignmentMode,
  setBulkAssignmentMode,
  openAssignmentDialog,
  setOpenAssignmentDialog,
}) => {
  const userRole = useSelector(UserSelector.getUserRoles);
  const userDetails = useSelector(UserSelector.getUserDetail);

  const [charts, setCharts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([
    { _id: 'unassigned', name: 'Assign', role: 'N/A' },
  ]);
  const [noProjects, setNoprojects] = useState(false);
  const [canAssign, setCanAssign] = useState(false);
  const [bulkCheckList, setBulkCheckList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [disableSelectAll, setDisableSelectAll] = useState(false);

  const [selectedCoder, setSelectedCoder] = useState('');
  const [coaderAssignLoading, setCoderAssignLoading] = useState(false);

  /* STATES FOR REVIEWER MANAGEMENT */
  // const [selectedReviewer, setSelectedReviewer] = useState('');
  // const [reviewerAssignLoading, setReviewerAssignLoading] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  const classes = useStyles();
  useEffect(() => {
    if (projectData?.length) {
      (async () => {
        try {
          setIsLoading(true);
          const chartList = await chartsAPI.find({
            projectId: projectData[selectedProject]._id,
            userType: projectData[selectedProject].userType,
          });

          setCharts(chartList?.records);
          setIsLoading(false);
        } catch (e) {
          setCharts([]);
          setIsLoading(false);
        }
      })();

      (async () => {
        if (projectData?.length) {
          try {
            const res =
              await adminAPI.adminProjectAPI.getAssignedUsersByProjectId({
                projectId: projectData[selectedProject]._id,
              });
            setUsers([
              { _id: 'unassigned', name: 'Assign', role: 'N/A' },
              ...res?.data,
            ]);
          } catch (e) {
            setUsers([]);
          }
        }
      })();

      setCanAssign(false);
      if (userRole === 'ClientAdmin' || userRole === 'SuperAdmin')
        setCanAssign(true);
      else {
        projectData[selectedProject]?.projectUsers.forEach((user) => {
          if (
            user.userid === userDetails.userid &&
            user.role === 'supervisor'
          ) {
            setCanAssign(true);
          }
        });
      }
    } else setNoprojects(true);
  }, [selectedProject]);

  useEffect(() => {
    setBulkCheckList(
      charts.map((rec) => ({
        id: rec?.ID,
        phase: rec?.Phase,
        checked: false,
      }))
    );
  }, [charts]);

  useEffect(() => {
    if (bulkCheckList.some((val) => val.checked)) setBulkAssignmentMode(true);
    else setBulkAssignmentMode(false);

    if (
      bulkCheckList.filter(
        (val) => val.phase.toLowerCase().trim() === 'processed'
        // || val.phase.toLowerCase().trim() === 'review'
      ).length
    ) {
      setDisableSelectAll(false);
      if (
        bulkCheckList
          .filter(
            (val) => val.phase.toLowerCase().trim() === 'processed'
            // || val.phase.toLowerCase().trim() === 'review'
          )
          .every((val) => val.checked)
      )
        setSelectAll(true);
      else setSelectAll(false);
    } else {
      setSelectAll(false);
      setDisableSelectAll(true);
    }
  }, [bulkCheckList]);

  const handleCheckChange = (id) => {
    setBulkCheckList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  const handleSelectAll = () => {
    setBulkCheckList((prev) =>
      prev.map((val) =>
        val?.phase === 'processed'
          ? // || val?.phase === 'review'
            { ...val, checked: !selectAll }
          : val
      )
    );
    setSelectAll((prev) => !prev);
  };

  const handleBulkCoderAssign = async () => {
    try {
      setCoderAssignLoading(true);
      const chartsList = bulkCheckList
        .filter((item) => item?.phase === 'processed' && item?.checked)
        .map((item) => item?.id);

      await chartsAPI.assignChartsInBulk({
        projectId: projectData[selectedProject]?._id,
        userId: selectedCoder,
        role: 'coder',
        chartsList,
      });

      setCharts((prev) =>
        prev.map((chart) =>
          chartsList.indexOf(chart?.ID) > -1
            ? {
                ...chart,
                coderAssignee: selectedCoder,
                Phase: 'code',
              }
            : chart
        )
      );

      setSelectedCoder('');
      setOpenNotification(true);
      setNotificationType('success');
      setNotificationMsg('Coder assignment successful.');
      setOpenAssignmentDialog(false);
      setCoderAssignLoading(false);
    } catch (e) {
      setOpenNotification(true);
      setNotificationType('error');
      setNotificationMsg(
        `Coder assignment failed. ${error.response.data.message}`
      );
    }
  };

  /* COMMENT FOR REVIEWER ASSIGN BUTTON CLICK IMPLEMENTATION */
  // const handleBulkReviewerAssign = () => {};

  return noProjects ? (
    <Box>
      <Typography variant="h4" sx={{ textAlign: 'center', margin: '2rem 0' }}>
        No projects to work on yet
      </Typography>
    </Box>
  ) : isLoading ? (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  ) : charts?.length ? (
    <Box className={classes.container}>
      <ChartRow
        setCurrentScreen={setCurrentScreen}
        memberId={'ID'}
        firstName={'First Name'}
        lastName={'Last Name'}
        gender={'Gender'}
        DOB={'DOB'}
        pages={'Pages'}
        totalDxSuggested={'Total Dx Suggested'}
        userAddedDx={'User Added Dx'}
        totalDxAccepted={'Total Dx Accepted'}
        phase={'Phase'}
        coder={'Assignee'}
        lastReviewedTime={'Last reviewed Time'}
        checked={selectAll}
        disableSelectAll={disableSelectAll}
        onCheckChange={handleCheckChange}
        setChecked={setBulkCheckList}
        assignmentMode={bulkAssignmentMode}
        chartId="selectAll"
        onSelectAll={handleSelectAll}
        enableAssignment={canAssign}
        header
      />
      {charts?.map((chart, idx) => (
        <ChartRow
          key={chart?.ID}
          setCharts={setCharts}
          setCurrentScreen={setCurrentScreen}
          memberId={chart?.memberId}
          firstName={chart?.firstName}
          lastName={chart?.lastName}
          gender={chart?.Gender[0]?.toUpperCase()}
          DOB={chart?.DOB}
          pages={chart?.Pages}
          totalDxSuggested={chart?.totalDxSuggested}
          userAddedDx={chart?.userAddedDx}
          totalDxAccepted={chart?.totalDxAccepted}
          totalDxRejected={chart?.totalDxRejected}
          phase={chart?.Phase}
          coder={chart?.coderAssignee}
          reviewer={chart?.reviewerAssignee}
          chartId={chart?.ID}
          users={users}
          projectId={projectData[selectedProject]?._id}
          lockStatus={chart?.lockStatus}
          enableAssignment={canAssign}
          checked={bulkCheckList[idx]?.checked}
          onCheckChange={handleCheckChange}
          assignmentMode={bulkAssignmentMode}
          lastReviewedTime={
            typeof chart?.lastReviewedTime === 'string'
              ? chart?.lastReviewedTime
              : chart?.lastReviewedTime?.$date
          }
          submitted={chart?.isSubmitted?.codedStatus}
          reviewed={chart?.isReviewed?.reviewStatus}
        />
      ))}
      <Dialog open={openAssignmentDialog}>
        <DialogTitle>Pick a user</DialogTitle>
        <DialogContent>
          {bulkCheckList
            ?.filter((item) => item?.phase === 'processed')
            .some((val) => val?.checked) && (
            <Box className={classes.usersSelectionContainer}>
              {users.filter(
                (user) => user?._id !== 'unassigned' && user?.role === 'analyst'
              ).length ? (
                <>
                  <Box className={classes.bulkAssignContainer}>
                    <Typography variant="h6">Coders</Typography>
                    <Button
                      className={classes.bulkActionButton}
                      disabled={
                        coaderAssignLoading || selectedCoder?.trim() === ''
                      }
                      onClick={handleBulkCoderAssign}
                    >
                      {coaderAssignLoading && (
                        <CircularProgress
                          className={classes.bulkAssignCoderProgress}
                        />
                      )}
                      Assign
                    </Button>
                  </Box>
                  <ToggleButtonGroup
                    value={selectedCoder}
                    exclusive
                    onChange={(e) => {
                      setSelectedCoder(e.target.value);
                    }}
                    className={classes.toggleGroup}
                    disabled={coaderAssignLoading}
                  >
                    {users
                      .filter(
                        (user) =>
                          user?._id !== 'unassigned' && user?.role === 'analyst'
                      )
                      .map((user) => (
                        <ToggleButton
                          value={user?._id}
                          key={user?._id}
                          className={classes.toggleGroupButton}
                        >
                          {user?.name}
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </>
              ) : (
                <Typography variant="h6">No coders assigned</Typography>
              )}
            </Box>
          )}
          {/* CODE BLOCK TO DISPLAY REVIEWERS LIST ON THE BULK ASSIGNMENT DIAGLOG (COMMENT FOR FUTURE IMPLEMENTATION) */}
          {/* {bulkCheckList
            ?.filter((item) => item?.phase === 'review')
            .some((val) => val?.checked) && (
            <Box className={classes.usersSelectionContainer}>
              {users.filter(
                (user) =>
                  user?._id !== 'unassigned' && user?.role === 'reviewer'
              ).length ? (
                <>
                  <Box className={classes.bulkAssignContainer}>
                    <Typography variant="h6">Reviewers</Typography>
                    <Button
                      className={classes.bulkActionButton}
                      disabled={
                        reviewerAssignLoading || selectedReviewer?.trim() === ''
                      }
                      onClick={handleBulkReviewerAssign}
                    >
                      Assign
                    </Button>
                  </Box>
                  <ToggleButtonGroup
                    value={selectedReviewer}
                    exclusive
                    onChange={(e) => {
                      setSelectedReviewer(e.target.value);
                    }}
                    className={classes.toggleGroup}
                  >
                    {users
                      .filter(
                        (user) =>
                          user?._id !== 'unassigned' &&
                          user?.role === 'reviewer'
                      )
                      .map((user) => (
                        <ToggleButton
                          value={user?._id}
                          key={user?._id}
                          className={classes.toggleGroupButton}
                        >
                          {user?.name}
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                </>
              ) : (
                <Typography variant="h6">No reviewers assigned</Typography>
              )}
            </Box>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAssignmentDialog(false);
              setSelectedCoder('');
            }}
            disabled={coaderAssignLoading}
            className={classes.bulkActionButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Notification
        type={notificationType}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
    </Box>
  ) : (
    <Box className={classes.noDataContainer}>
      <img src="/assets/images/empty.svg" alt="no charts yet" />
      <Typography paragraph>
        No charts in {projectData[selectedProject]?.projectName} yet.
      </Typography>
    </Box>
  );
};

export default ProjectTable;
