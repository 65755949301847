import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  accordion: {
    border: 'none',
    marginTop: '10px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      padding: 0,
      minHeight: 'auto',
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    minHeight: 'auto',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '& p': {
        fontSize: '14px',
        fontWeight: 700,
      },
    },
  },
  accordionDetails: {
    padding: '0 10px',
    color: theme.palette.primary.main,
  },
  checklistItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& p': {
      fontSize: '10px',
      margin: 0,
    },
  },
  check: {
    color: theme.palette.primary.main,
    '& svg': {
      maxWidth: '14px',
      maxHeight: '14px',
    },
  },
  attributeCheck: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
