import MemberChartsTab from './memberChartsTab';
import MemberTab from './memberTab';
import EncounterTab from './encounterTab';
import ClaimTab from './claimTab';
const ReportTabs = ({ tableName }) => {
  switch (tableName) {
    case 'Total Members':
      return <MemberTab />;
    case 'Total Claims':
      return <ClaimTab />;

    case 'Total Encounters':
      return <EncounterTab />;
    case 'Total Member Charts':
      return <MemberChartsTab />;
  }
};

export default ReportTabs;
