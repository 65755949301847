import { Box, Dialog, Typography } from '@mui/material';
import useStyles from './styles';
import ZipFileUpload from '../zipFileUploaddialog';
import { useState } from 'react';

const SuccessDialog = ({
  open,
  onClose,
  type,
  reopenUploadDialog,
  lastStep,
  projectID,
  projectName,
  handleFileUpload,
}) => {
  const classes = useStyles();
  const [zipFileDialogOpen, setZipFileDialogOpen] = useState(false);

  const handleTryAgain = () => {
    onClose();
    setTimeout(() => {
      reopenUploadDialog();
    }, 300);
  };

  const handleStep2Click = () => {
    onClose();
    setZipFileDialogOpen(true);
  };

  const handleZipFileDialogClose = () => {
    setZipFileDialogOpen(false);
  };

  const handleDone = () => {
    if (lastStep) {
      handleFileUpload();
    }
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleDone} className={classes.dialog}>
        <Box className={classes.successDialogContainer}>
          {type === 'success' ? <SuccessIcon /> : <FailureIcon />}

          <Typography className={classes.messageText}>
            {lastStep
              ? type === 'success'
                ? `Voila!! You have uploaded the Zip file of charts to ${projectName} .`
                : `The Charts have not been uploaded to ${projectName} !!`
              : type === 'success'
              ? `Voila!! You have uploaded the chase list to ${projectName}.`
              : `The chase List has not been uploaded to ${projectName} !!`}
          </Typography>

          <Box className={classes.buttonContainer}>
            {lastStep ? (
              type === 'success' ? (
                <button
                  className={classes.buttonStyle}
                  type="submit"
                  onClick={handleDone}
                >
                  Done
                </button>
              ) : (
                <button
                  className={classes.buttonStyle}
                  type="submit"
                  onClick={handleTryAgain}
                >
                  Try Again
                </button>
              )
            ) : (
              <>
                {type === 'success' && (
                  <>
                    <button
                      className={classes.buttonStyle}
                      type="button"
                      onClick={onClose}
                    >
                      Exit
                    </button>
                    <button
                      style={{ color: '#127D1C' }}
                      className={classes.buttonStyle}
                      type="button"
                      onClick={handleStep2Click}
                    >
                      Step 2
                    </button>
                  </>
                )}
                {type === 'failure' && (
                  <>
                    <button
                      className={classes.buttonStyle}
                      type="submit"
                      onClick={handleTryAgain}
                    >
                      Try Again
                    </button>
                    <button
                      style={{ color: '#127D1C' }}
                      className={classes.buttonStyle}
                      type="button"
                      onClick={onClose}
                    >
                      Got It
                    </button>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Dialog>
      <ZipFileUpload
        open={zipFileDialogOpen}
        onClose={handleZipFileDialogClose}
        projectID={projectID}
        projectName={projectName}
        handleFileUpload={handleFileUpload}
      />
    </>
  );
};

export default SuccessDialog;

const SuccessIcon = () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 130 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="65" cy="65" r="65" fill="#037C35" />
    <g filter="url(#filter0_d_1594_5585)">
      <path
        d="M104.447 23.6993C106.159 30.5992 103.763 35.3241 99.1262 39.9865C95.3859 43.7365 90.6215 47.8114 85.573 54.7675C80.6206 61.5862 75.7922 69.8048 71.4999 77.8921C67.8275 84.8351 64.3311 92.001 61.019 99.373C59.3629 103.067 57.0467 108.579 57.0467 108.579C56.2155 110.585 55.0708 112.227 53.7206 113.35C52.3705 114.473 50.8594 115.04 49.33 114.998C47.8015 114.984 46.3007 114.359 44.9704 113.183C43.64 112.007 42.5243 110.319 41.7294 108.279C37.7331 97.7981 34.6528 93.6544 33.2367 92.1731C29.4484 88.1857 25 87.6107 25 78.3359C25 70.9673 28.9803 64.9986 33.8887 64.9986C37.357 65.1986 40.5773 67.3298 43.4335 70.3298C45.2577 72.2422 47.1898 74.7797 49.198 78.1109C51.8893 72.3731 54.6881 66.7604 57.5907 61.2799C62.2191 52.5613 67.6836 43.2052 73.544 35.1304C79.3045 27.193 85.9651 19.7619 93.0257 15.837C97.626 13.2745 102.738 16.7932 104.447 23.6993Z"
        fill="#D0EFA0"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1594_5585"
        x="21"
        y="15"
        width="88"
        height="114"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1594_5585"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1594_5585"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const FailureIcon = () => (
  <svg
    width="130"
    height="130"
    viewBox="0 0 130 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="65" cy="65" r="65" fill="#B01C25" />
    <g filter="url(#filter0_d_2094_8273)">
      <path
        d="M102.84 103.151C100.856 104.945 98.1648 105.952 95.3589 105.952C92.553 105.952 89.862 104.945 87.8778 103.151L64.5044 78.9991L41.131 103.143C40.1515 104.043 38.9846 104.759 37.6975 105.249C36.4104 105.74 35.0287 105.995 33.6321 106C32.2354 106.005 30.8515 105.76 29.5601 105.279C28.2686 104.799 27.0953 104.091 26.1077 103.198C25.1201 102.306 24.3378 101.245 23.806 100.077C23.2741 98.9098 23.0032 97.6586 23.0089 96.3959C23.0146 95.1333 23.2967 93.8841 23.8391 92.7206C24.3814 91.557 25.1733 90.502 26.1689 89.6164L50.4857 64.508L26.16 39.3836C25.1644 38.498 24.3726 37.443 23.8303 36.2795C23.2879 35.1159 23.0058 33.8667 23.0001 32.6041C22.9944 31.3414 23.2653 30.0902 23.7971 28.9227C24.329 27.7552 25.1113 26.6944 26.0989 25.8016C27.0865 24.9087 28.2598 24.2015 29.5512 23.7207C30.8427 23.2398 32.2266 22.9949 33.6233 23.0001C35.0199 23.0052 36.4016 23.2603 37.6887 23.7506C38.9757 24.2409 40.1427 24.9568 41.1222 25.8569L64.5044 50.0168L87.8778 25.8569C88.8573 24.9568 90.0243 24.2409 91.3113 23.7506C92.5984 23.2603 93.9801 23.0052 95.3767 23.0001C96.7734 22.9949 98.1573 23.2398 99.4488 23.7207C100.74 24.2015 101.914 24.9087 102.901 25.8016C103.889 26.6944 104.671 27.7552 105.203 28.9227C105.735 30.0902 106.006 31.3414 106 32.6041C105.994 33.8667 105.712 35.1159 105.17 36.2795C104.627 37.443 103.836 38.498 102.84 39.3836L78.5232 64.508L102.84 89.6164C103.824 90.5048 104.604 91.5597 105.136 92.7209C105.669 93.8821 105.943 95.1268 105.943 96.3838C105.943 97.6408 105.669 98.8855 105.136 100.047C104.604 101.208 103.824 102.263 102.84 103.151Z"
        fill="#DDB8B8"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2094_8273"
        x="19"
        y="23"
        width="91"
        height="97"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2094_8273"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2094_8273"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
