import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { en } from 'config/metadata/en';
import useControlEvent from 'utils/controlevent';
import { TextBox, Button } from 'components/controls';
import CopyRights from 'components/copyrights';
import { confirmpassw } from './service';
import { AppAction } from 'state/app';
import { VERSION } from 'version';
import { IconList } from 'icons';
import Notification from 'components/notification';
import { UserAction } from 'state/user';

import useStyles from './style';
import { IconButton, InputAdornment } from '@mui/material';

const ConfirmPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { controlVal, controlErrorVal, onFieldValueChange } = useControlEvent(
    {}
  );

  const [showPassword, setShowPassword] = useState({
    temporary: false,
    password: false,
    confirmPassword: false,
  });
  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [notificationMsg, setNotificationMsg] = useState(true);
  const [loading, setLoading] = useState(false);

  const passwRequirements = (passw) => {
    const checkArray = [
      passw.length >= 8,
      /[a-z]/.test(passw),
      /[A-Z]/.test(passw),
      /[0-9]/.test(passw),
      /[!@#$%^&*(),.?":{}|<>]/.test(passw),
    ];
    return checkArray.every(Boolean);
  };

  const confirmPassword = async (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(search);

    if (!passwRequirements(controlVal.confirmnewpassword)) {
      setNotificationMsg(
        'Password must be at least 8 characters, with one lowercase, one uppercase, one number, and one special character'
      );
      setIsSuccess(false);
      setOpenNotification(true);
      return;
    }

    if (controlVal.newpassword !== controlVal.confirmnewpassword) {
      setNotificationMsg('Password and confirm password mismatch');
      setIsSuccess(false);
      setOpenNotification(true);
      return;
    }

    const rawQuery = queryParams.toString();
    const qusernameMatch = rawQuery.match(/qusername=([^&]*)/);
    const qusername = qusernameMatch
      ? decodeURIComponent(qusernameMatch[1])
      : '';

    setLoading(true);
    try {
      await confirmpassw({
        // username: props.username || queryParams.get('qusername'),
        username: props.username || qusername,
        type: queryParams.get('qtype'),
        verificationcode: controlVal.verificationcode,
        newpassword: controlVal.newpassword,
      });
      setNotificationMsg('Password reset successfully');
      setIsSuccess(true);
      setOpenNotification(true);

      dispatch(
        AppAction.AppAll({
          version: VERSION.version,
        })
      );
      dispatch(UserAction.UserAll({}));
      setTimeout(() => {
        history.push('/login');
      }, 2999);
    } catch (e) {
      setNotificationMsg('Password reset failed');
      setIsSuccess(false);
      setOpenNotification(true);
    }
    setLoading(false);
  };

  return (
    <Box className={classes.container} component="main">
      <Box className={classes.bgShape}>
        {/* <img src="/assets/shapes/shape1.png" alt="" />
        <img src="/assets/shapes/shape2.png" alt="" /> */}
      </Box>
      <Box className={classes.content}>
        <Box className={classes.paper}>
          <Box>{/* <img src="/assets/images/login-image.svg" /> */}</Box>
          <span className={classes.hr} />
          <Box className={classes.form}>
            <Avatar className={classes.logo}>{IconList.User}</Avatar>
            {/* <img src="/logo2.png" alt="logo" className={classes.logo} /> */}
            <Typography component="h1" variant="h5">
              {en.confirmpassw.title}
            </Typography>
            <form
              className={classes.form}
              autoComplete="off"
              noValidate
              onSubmit={confirmPassword}
            >
              <TextBox
                name="verificationcode"
                label={en.confirmpassw.verificationCode}
                value={controlVal.verificationcode}
                onChange={onFieldValueChange}
                error={controlErrorVal.verificationcode}
                autoFocus
                required
                fullWidth
                type={`${showPassword.temporary ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => {
                            return { ...state, temporary: !state.temporary };
                          });
                        }}
                      >
                        {showPassword.temporary ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBox
                name="newpassword"
                label={en.confirmpassw.newpassword}
                value={controlVal.newpassword}
                onChange={onFieldValueChange}
                error={controlErrorVal.newpassword}
                autoFocus
                required
                fullWidth
                type={`${showPassword.password ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => {
                            return { ...state, password: !state.password };
                          });
                        }}
                      >
                        {showPassword.password ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBox
                name="confirmnewpassword"
                label={en.confirmpassw.confirmnewpassword}
                value={controlVal.confirmnewpassword}
                onChange={onFieldValueChange}
                error={controlErrorVal.confirmnewpassword}
                autoFocus
                required
                fullWidth
                type={`${showPassword.confirmPassword ? 'text' : 'password'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          color: '#000',
                          padding: '0 15px',
                        }}
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          setShowPassword((state) => {
                            return {
                              ...state,
                              confirmPassword: !state.confirmPassword,
                            };
                          });
                        }}
                      >
                        {showPassword.confirmPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                label={en.confirmpassw.submit}
                // fullWidth
                // startIcon={IconList.Email}
                className={classes.submit}
                disabled={loading}
              />

              <Notification
                type={isSuccess ? 'success' : 'error'}
                message={notificationMsg}
                openNotification={openNotification}
                setOpenNotification={setOpenNotification}
              />
            </form>
            <Box>
              <CopyRights />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ConfirmPassword.propTypes = {};
export default ConfirmPassword;
