import { CaretIcon, DoubleArrowDown, ReturnArrowIcon } from 'icons/customIcons';
import useStyles from './style';
import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

const { Box, Typography, IconButton, Tooltip } = require('@mui/material');

const DetailSection = ({
  queueName = '',
  projectName = '',
  memberDetails = [],
  retrospectiveReview = [],
  jsonData = [],
  submittedCodes = [],
  riskScores = {},
  setCurrentScreen = () => {},
  currentView,
  submissionStatus,
  loadingSubmitCodes,
}) => {
  const [expanded, setExpanded] = useState(false);
  const restructuredMemberDetails = memberDetails?.reduce((acc, item) => {
    acc[item?.key] = item?.text;
    return acc;
  }, {});

  const restructuredRetrospectiveReview = retrospectiveReview?.reduce(
    (acc, item) => {
      acc[item?.key] = item?.text;
      return acc;
    },
    {}
  );

  const classes = useStyles();

  const handleDetailsToggle = () => {
    setExpanded((prev) => !prev);
  };

  const handleBackButtonClick = () => {
    if (currentView == 'queue') {
      setCurrentScreen((prev) => ({ ...prev, screen: 'queue', props: '' }));
    } else if (currentView == 'project') {
      setCurrentScreen((prev) => ({
        ...prev,
        screen: 'viewProject',
        props: '',
      }));
    } else if (currentView == 'createQueueView') {
      setCurrentScreen((prev) => ({
        ...prev,
        screen: 'createQueue',
        props: '',
      }));
    } else {
      setCurrentScreen((prev) => ({ ...prev, screen: 'landing', props: '' }));
    }
  };

  const groupingFunction = (data) => {
    return data.reduce((acc, item) => {
      const key = `${item?.hccMapCaptured?.code ?? 'N/A'},${
        item?.hccMapCaptured?.description ?? 'N/A'
      }`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  const groupingPreviousCodes = (data) => {
    return data.reduce((acc, item) => {
      const key = `${item?.hccCode ?? 'N/A'},${item?.hccDescription ?? 'N/A'}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.headContainer}>
        <Box className={classes.titlesContainer}>
          <IconButton onClick={handleBackButtonClick}>
            <ReturnArrowIcon />
          </IconButton>
          <Box>
            {queueName?.trim().length === 0 ? (
              <></>
            ) : (
              <Typography variant="h6">Queue: {queueName}</Typography>
            )}
            {projectName?.trim().length === 0 ? (
              <></>
            ) : (
              <Typography variant="h6">Project: {projectName}</Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.detailsTogglerContainer}>
          <Typography variant="h6">details</Typography>
          <IconButton
            onClick={handleDetailsToggle}
            className={expanded ? 'expanded' : ''}
          >
            <DoubleArrowDown />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={`${classes.detailsContentWrapper} ${
          expanded ? 'expanded' : ''
        }`}
      >
        <Box className={classes.detailsContent}>
          <Box className={classes.sectionLeft}>
            <Box className={classes.retrospectiveReview}>
              <Typography variant="h6">
                <CaretIcon
                  style={{
                    filter: 'invert(1)',
                    maxWidth: '18px',
                    maxHeight: '18px',
                  }}
                />{' '}
                Retrospective review
              </Typography>
              {retrospectiveReview?.length === 0 ? (
                <Typography paragraph>N/A</Typography>
              ) : (
                <Typography paragraph>
                  {`${restructuredRetrospectiveReview?.Line_Of_Business} - ${restructuredRetrospectiveReview?.Review_Type} - Due by ${restructuredRetrospectiveReview?.Project_Due_Date} - CMS Submission Date ${restructuredRetrospectiveReview?.CMS_Submission_Date}`}
                </Typography>
              )}
            </Box>

            <Box className={classes.memberDetails}>
              <Typography variant="h6">
                <CaretIcon
                  style={{
                    filter: 'invert(1)',
                    maxWidth: '18px',
                    maxHeight: '18px',
                  }}
                />{' '}
                Member Details
              </Typography>{' '}
              {memberDetails?.length === 0 ? (
                <></>
              ) : (
                <Typography
                  paragraph
                >{`${restructuredMemberDetails?.MemberId} - ${restructuredMemberDetails?.Full_Name}, ${restructuredMemberDetails?.Gender}, ${restructuredMemberDetails?.DOB}`}</Typography>
              )}
            </Box>

            <Box className={classes.totalRiskScore}>
              <Typography variant="h6">
                <CaretIcon
                  style={{
                    filter: 'invert(1)',
                    maxWidth: '18px',
                    maxHeight: '18px',
                  }}
                />{' '}
                Accepted Risk Score
              </Typography>
              <Typography paragraph>{riskScores?.acceptedScore}</Typography>
            </Box>
            <Box className={classes.outstandingRiskScore}>
              <Typography variant="h6">
                <CaretIcon
                  style={{
                    filter: 'invert(1)',
                    maxWidth: '18px',
                    maxHeight: '18px',
                  }}
                />{' '}
                Outstanding Risk Score
              </Typography>
              <Typography paragraph>{riskScores?.outstandingScore}</Typography>
            </Box>
          </Box>

          <Box className={classes.sectionRight}>
            <Typography variant="h6">Diagnosis Details</Typography>
            <Box className={classes.codesTable}>
              <Box className={classes.codesTableColumn}>
                <Typography variant="h6" className={classes.codesTableHead}>
                  Previously Submitted Codes
                </Typography>
                {loadingSubmitCodes ? (
                  <Box className={classes.codesTableCellContainer}>
                    {' '}
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box className={classes.codesTableCellContainer}>
                    {Object.entries(
                      groupingPreviousCodes(
                        submittedCodes?.diagnosisPreviouslySubmitted ?? []
                      )
                    ).map(([key, value]) => (
                      <Accordion
                        key={key}
                        disableGutters
                        sx={{
                          margin: 0,
                          padding: 0,
                          border: 'none',
                          boxShadow: 'none',
                          '&::before': {
                            content: 'none',
                          },
                        }}
                        className={`${classes}.defaultHcc ${
                          value.some(
                            (prevCode) =>
                              key.split(',')[0] &&
                              key.split(',')[0] !== '' &&
                              prevCode?.status === 'CLAIM_CONFIRMED'
                          )
                            ? `${classes.acceptedHcc}`
                            : !key.split(',')[0]
                            ? `${classes.defaultHcc}`
                            : `${
                                submissionStatus === 'coder'
                                  ? classes.defaultHcc
                                  : classes.submittedHcc
                              }`
                        }`}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classes.codesTableSummary}
                          sx={{
                            margin: 0,
                            padding: 0,
                            borderBottom: 'none',
                            boxShadow: 'none',
                            '&::before': { content: 'none' },
                          }}
                        >
                          HCC {key.split(',')[0] ? key.split(',')[0] : 'N/A'} :{' '}
                          {key.split(',')[1] ? key.split(',')[1] : 'N/A'}
                        </AccordionSummary>
                        {value.map((item) => (
                          <AccordionDetails
                            sx={{
                              margin: 0,
                              padding: 0,
                              borderBottom: 'none',
                              borderBottom: 'none',
                              '&::before': { content: 'none' },
                            }}
                            key={item.dxCode}
                            className={`${classes.codesTableCell}`}
                          >
                            <Typography className={classes.detailsText}>
                              {item?.dxCode ? item?.dxCode : 'N/A'} -{' '}
                              {item?.dxDescription
                                ? item?.dxDescription
                                : 'N/A'}
                            </Typography>
                          </AccordionDetails>
                        ))}
                      </Accordion>
                    ))}
                  </Box>
                )}
              </Box>
              <Box className={classes.codesTableColumn}>
                <Typography variant="h6" className={classes.codesTableHead}>
                  Captured Diagnosis
                </Typography>
                <Box className={classes.codesTableCellContainer}>
                  {Object.entries(
                    groupingFunction(
                      jsonData?.filter(
                        (diag) => diag?.Status?.toLowerCase() === 'accepted'
                      )
                    )
                  ).map(([key, value]) => (
                    <Accordion
                      disableGutters
                      sx={{ marginBottom: 0, paddingBottom: 0 }}
                      paragraph
                      className={classes.codesTableCellSubmittedCode}
                      key={key}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.codesTableSummary}
                        sx={{ marginBottom: 0, paddingBottom: 0 }}
                      >
                        HCC {key.split(',')[0]} : {key.split(',')[1]}
                      </AccordionSummary>
                      {value.map((item) => (
                        <AccordionDetails
                          sx={{ marginBottom: 0, paddingBottom: 0 }}
                          key={item.Code}
                          className={`${classes.codesTableCell} ${classes.accordionDetails} ${classes.accordionDetailsExpanded}`}
                        >
                          <Typography className={classes.detailsText}>
                            {item.Code} - {item.Description}
                          </Typography>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailSection;
