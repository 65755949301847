import { useState } from 'react';
import Loading from 'components/loading';
const WithLoadingHOC = (WrapperComponent) => {
  const HOC = (props) => {
    const [isLoading, setLoading] = useState(true);
    return (
      <>
        {isLoading && <Loading />}
        <WrapperComponent {...props} setLoading={setLoading} />
      </>
    );
  };

  return HOC;
};

export default WithLoadingHOC;
