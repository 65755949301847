import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, value, theme) => {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const MultipleSelectDropDown = ({
  name,
  label,
  value = '',
  onChange,
  options = [],
  className,
  valueCellClassName,
  minWidth = 120,
  margin = 15,
  disabled = false,
  error,
}) => {
  const theme = useTheme();

  const converTargetEvent = (name, value) => ({
    target: {
      id: name,
      name: name,
      value: typeof value === 'string' ? value.split(',') : value,
    },
  });

  const getLabel = (value) => {
    const item = options.find((i) => i.value === value);
    return item?.label;
  };

  return (
    <FormControl
      id={'MultiSelectDropDown-' + name}
      {...(!className && {
        style: {
          minWidth: minWidth,
          margin: margin,
          '&:disabled': {
            backgroundColor: 'yellow',
          },
        },
      })}
      {...(className && { className: className })}
    >
      <InputLabel id={'dropdownlabel-' + name}>{label}</InputLabel>
      <Select
        labelId={name}
        id={name}
        multiple
        value={value}
        onChange={(e) => onChange(converTargetEvent(name, e.target.value))}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map((value) => (
              <Chip key={value} label={getLabel(value)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        className={valueCellClassName}
        disabled={disabled}
      >
        {options.map((oitem) => (
          <MenuItem
            key={oitem.value}
            value={oitem.value}
            style={getStyles(oitem.value, value, theme)}
          >
            <Checkbox checked={value.indexOf(oitem.value) > -1} />
            <ListItemText primary={oitem.label} />
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default MultipleSelectDropDown;
/************************************************************************************************
 * Example import for DropDown
 * <DropDown
            name= 'country'
            label= 'Country' 
            value= {controlVal.country} // Array
            onChange= {onChange}
            options= {[{label:'India', value:'india'},{label:'Singapore', value:'singapore'}]}
         />
 */
