import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardHeader from '@mui/material/CardHeader';

const Card = ({ children, ...rest }) => {
  return <MuiCard {...rest}>{children}</MuiCard>;
};
export default Card;

export const CardHeader = ({ children, ...rest }) => {
  return <MuiCardHeader {...rest}>{children}</MuiCardHeader>;
};

export const CardContent = ({ children, ...rest }) => {
  return <MuiCardContent {...rest}>{children}</MuiCardContent>;
};

/*******************************
 * Example for import
     <Card className={classes.card}>
        <CardContent>
        </CardContent>
      </Card> 
 */
