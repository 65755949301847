import VerticalTabs from 'components/verticaltabs';
import Mmr from './mmr';
import Claims from './claims';
import Charts from './charts';

const initialTabList = [
  // {
  //   label: 'Dashboard',
  //   content: <MemberDashboard />,
  // },
  {
    label: 'MMR',
    content: <Mmr />,
  },
  {
    label: 'Claims',
    content: <Claims />,
  },
  {
    label: 'Medical Charts',
    content: <Charts />,
  },
  // {
  //   label: 'Comments',
  //   content: <div>Under Constructtion</div>,
  // },
  // {
  //   label: 'Utility',
  //   content: <div>Under Constructtion</div>,
  // },
];

const Tabs = ({ className }) => {
  // const [tabList, setTabList] = useState(initialTabList);
  return <VerticalTabs tabList={initialTabList} className={className} />;
};

export default Tabs;
