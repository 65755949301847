import { useEffect, useState } from 'react';
import useStyles from './style';
import { chartsAPI } from 'api';
import Notification from 'components/notification';

const {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  IconButton,
  CircularProgress,
  Checkbox,
} = require('@mui/material');

const ChartRow = ({
  setCurrentScreen,
  memberId,
  firstName,
  lastName,
  gender,
  DOB,
  phase,
  lastReviewedTime,
  onCheckChange,
  onSelectAll,
  setCharts,
  projectId = '',
  pages = 0,
  totalDxSuggested = 0,
  userAddedDx = 0,
  totalDxAccepted = 0,
  totalDxRejected = 0,
  header = false,
  chartId = '',
  users = [],
  coder = '',
  reviewer = '',
  lockStatus = {},
  enableAssignment = false,
  checked = false,
  assignmentMode = false,
  disableSelectAll = false,
  submitted = false,
  reviewed = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [expand, setExpand] = useState(false);
  const [user, setUser] = useState(
    ['processed', 'code'].includes(phase.toLowerCase().trim())
      ? coder?.trim()?.length
        ? coder
        : users[0]?._id
      : reviewer?.trim()?.length
      ? reviewer
      : users[0]?._id
  );
  const [savedUser, setSavedUser] = useState(
    ['processed', 'code'].includes(phase.toLowerCase().trim())
      ? coder?.trim()?.length
        ? coder
        : users[0]?._id
      : reviewer?.trim()?.length
      ? reviewer
      : users[0]?._id
  );
  const [phaseFilter, setPhaseFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationType, setNotificationType] = useState('success');

  useEffect(() => {
    if (
      phase?.toLowerCase().trim() === 'processed' ||
      phase?.toLowerCase().trim() === 'code'
    )
      setPhaseFilter('analyst');
    else setPhaseFilter('reviewer');

    setUser(
      ['processed', 'code'].includes(phase.toLowerCase().trim())
        ? coder?.trim()?.length
          ? coder
          : users[0]?._id
        : reviewer?.trim()?.length
        ? reviewer
        : users[0]?._id
    );
    setSavedUser(
      ['processed', 'code'].includes(phase.toLowerCase().trim())
        ? coder?.trim()?.length
          ? coder
          : users[0]?._id
        : reviewer?.trim()?.length
        ? reviewer
        : users[0]?._id
    );

    if (!users?.filter((curUser) => curUser?._id === user && curUser).length) {
      setUser('unassigned');
      setSavedUser('unassigned');
    }
  }, [coder, reviewer, phase]);

  const open = Boolean(anchorEl);
  const nullIndicator = '-';

  const classes = useStyles();

  const ExpandIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={style}
    >
      <path
        d="M19.2178 15L11.7178 22.5L9.96777 20.75L15.7178 15L9.96777 9.25L11.7178 7.5L19.2178 15Z"
        fill="#020202"
      />
    </svg>
  );

  const FolderIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      style={style}
    >
      <path
        d="M4.94982 4.88477V3.23477C4.94982 2.62725 5.44231 2.13477 6.04982 2.13477H16.4998C17.1073 2.13477 17.5998 2.62725 17.5998 3.23477V7.63477M2.20069 17.664L2.20077 9.30006C2.20077 8.4623 2.20046 7.26904 2.2002 6.40504C2.20001 5.79737 2.69256 5.30532 3.30024 5.30532H8.54194L11.0765 8.01282H18.6998C19.3073 8.01282 19.7998 8.50533 19.7998 9.11286L19.7995 17.6641C19.7995 18.8791 18.8145 19.8641 17.5995 19.8641L4.40068 19.864C3.18565 19.864 2.20067 18.879 2.20069 17.664Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const DotsIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
        fill="#020202"
      />
    </svg>
  );

  const TickIcon = ({ style }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 48 48"
      style={style}
    >
      <path
        fill="#c8e6c9"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      ></path>
      <path
        fill="#4caf50"
        d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
      ></path>
    </svg>
  );

  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleConfirmClick = async () => {
    try {
      setIsLoading(true);
      const res = await chartsAPI.assignUserByChartId({
        chartId,
        userId: user,
        projectId,
        role: phaseFilter === 'analyst' ? 'coder' : 'reviewer',
      });

      setCharts((prev) =>
        prev.map((chart) =>
          chart?.ID === chartId
            ? chart?.Phase === 'processed'
              ? { ...chart, Phase: 'code', coderAssignee: user }
              : { ...chart, Phase: 'review', reviewerAssignee: user }
            : chart
        )
      );

      setSavedUser(user);
      setOpenNotification(true);
      setNotificationType('success');
      setNotificationMsg(res?.data?.message);
      setIsLoading(false);
    } catch (e) {
      setOpenNotification(true);
      setNotificationMsg('Some unknown error occured');
      setNotificationType('error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        className={classes.container}
        sx={
          header && {
            backgroundColor: '#f6f6f6',
            position: 'sticky',
            top: 0,
            zIndex: 3,
            '& p': { fontWeight: 600, fontSize: '14px' },
          }
        }
      >
        {enableAssignment ? (
          <Checkbox
            className={classes.assignCheck}
            sx={assignmentMode ? { visibility: 'visible !important' } : {}}
            value={header ? 'selectAll' : chartId}
            checked={checked}
            onChange={(e) => {
              if (header) onSelectAll();
              else onCheckChange(e.target.value);
            }}
            disabled={
              (!header &&
                (!enableAssignment ||
                  phase?.toLowerCase()?.trim() !== 'processed')) ||
              // && phase?.toLowerCase()?.trim() !== 'review'
              (header && disableSelectAll)
            }
          />
        ) : (
          <></>
        )}
        <Accordion className={classes.chartItemAccordion} expanded={expand}>
          <AccordionSummary
            expandIcon={
              header ? (
                <Button className={classes.buttons} disabled>
                  <ExpandIcon style={{ visibility: 'hidden' }} />
                </Button>
              ) : (
                <Button
                  className={`${classes.buttons} ${classes.expandIcon}`}
                  onClick={handleExpand}
                >
                  <ExpandIcon />
                </Button>
              )
            }
            className={classes.chartItemAccordionSummary}
            sx={{ flexDirection: 'row-reverse' }}
          >
            <Button
              type="button"
              className={`${classes.folderIcon} ${classes.buttons}`}
              onClick={() => {
                if (chartId !== '')
                  setCurrentScreen({
                    screen: 'chart',
                    id: chartId,
                    lockStatus,
                    submitted,
                    reviewed,
                  });
              }}
              disabled={header || phase?.toLowerCase()?.trim() === 'in_process'}
            >
              {<FolderIcon style={header ? { visibility: 'hidden' } : {}} />}
            </Button>
            <Typography className={`${classes.id} ${classes.text}`}>
              {memberId ? memberId : nullIndicator}
            </Typography>
            <Typography className={`${classes.firstName} ${classes.text}`}>
              {firstName ? firstName : nullIndicator}
            </Typography>
            <Typography className={`${classes.lastName} ${classes.text}`}>
              {lastName ? lastName : nullIndicator}
            </Typography>
            <Typography className={`${classes.gender} ${classes.text}`}>
              {gender ? gender : nullIndicator}
            </Typography>
            <Typography className={`${classes.DOB} ${classes.text}`}>
              {DOB
                ? !isNaN(Date.parse(DOB))
                  ? new Date(DOB)
                      .toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })
                      .replace(/\//g, '-')
                  : DOB
                : nullIndicator}
            </Typography>
            <Typography className={`${classes.pages} ${classes.text}`}>
              {pages}
            </Typography>
            <Typography
              className={`${classes.totalDxSuggested} ${classes.text}`}
            >
              {totalDxSuggested}
            </Typography>
            <Typography className={`${classes.userAddedDx} ${classes.text}`}>
              {userAddedDx}
            </Typography>
            <Typography
              className={`${classes.totalDxAccepted} ${classes.text}`}
            >
              {totalDxAccepted}
            </Typography>
            <Typography className={`${classes.phase} ${classes.text}`}>
              <Box className={classes.phaseContainer}>
                <Typography>{phase ? phase : nullIndicator}</Typography>
                {!header && (
                  <Box
                    className={classes.chartProgressContainer}
                    sx={{
                      '&::before': {
                        content: `"${Math.trunc(
                          ((Number(totalDxAccepted) + Number(totalDxRejected)) *
                            100) /
                            (Number(totalDxSuggested) + Number(userAddedDx)) ||
                            0
                        )}%"`,
                      },
                    }}
                  >
                    <Box
                      className={classes.chartProgressPercent}
                      sx={{
                        width: `${
                          ((Number(totalDxAccepted) + Number(totalDxRejected)) *
                            100) /
                            (Number(totalDxSuggested) + Number(userAddedDx)) ||
                          0
                        }%`,
                      }}
                    >
                      <Box
                        className={classes.chartAccepted}
                        sx={{
                          width: `${
                            (Number(totalDxAccepted) * 100) /
                              (Number(totalDxAccepted) +
                                Number(totalDxRejected)) || 0
                          }%`,
                        }}
                      />
                      <Box
                        className={classes.chartRejected}
                        sx={{
                          width: `${
                            (Number(totalDxRejected) * 100) /
                              (Number(totalDxAccepted) +
                                Number(totalDxRejected)) || 0
                          }%`,
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Typography>
            <Typography className={`${classes.assignee} ${classes.text}`}>
              {header ? (
                coder ? (
                  coder
                ) : (
                  nullIndicator
                )
              ) : (
                <Box className={classes.userSelectContainer}>
                  <Select
                    value={user}
                    onChange={handleUserChange}
                    className={classes.usersDropDown}
                    sx={
                      user?.toLowerCase() === 'unassigned' && {
                        color: 'rgba(0, 0, 0, 0.40)',
                      }
                    }
                    disabled={
                      isLoading ||
                      !enableAssignment ||
                      (phase?.toLowerCase()?.trim() !== 'processed' &&
                        phase?.toLowerCase()?.trim() !== 'coded')
                    }
                  >
                    {users
                      .filter(
                        (user) =>
                          user?.role === phaseFilter || user?.role === 'N/A'
                      )
                      .map((user) => (
                        <MenuItem
                          key={user?._id}
                          value={user?._id}
                          className={classes.assigneeDD}
                          disabled={user?._id === 'unassigned'}
                        >
                          <Typography className={classes.username}>
                            {user?.name}
                          </Typography>
                          <Typography className={classes.role}>
                            {user?.role}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                  {user !== savedUser && (
                    <IconButton
                      onClick={handleConfirmClick}
                      disabled={isLoading}
                    >
                      <TickIcon />
                      {isLoading && <CircularProgress />}
                    </IconButton>
                  )}
                </Box>
              )}
            </Typography>
            <Typography
              className={`${classes.lastReviewedTime} ${classes.text}`}
            >
              {lastReviewedTime
                ? !isNaN(Date.parse(lastReviewedTime))
                  ? new Date(lastReviewedTime)
                      .toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                      .replace(',', '')
                      .replace(/\//g, '-')
                  : lastReviewedTime
                : nullIndicator}
            </Typography>
            <Button
              id="threeDotsButton"
              type="button"
              onClick={handleDotsClick}
              className={`${classes.dotsIcon} ${classes.buttons}`}
              disabled={header}
              sx={header && { visibility: 'hidden' }}
            >
              {<DotsIcon />}
            </Button>

            <Menu
              id="threeDotsMenu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'threeDotsButton',
              }}
              className={classes.dotsMenu}
            >
              <MenuItem onClick={handleClose}>Details</MenuItem>
            </Menu>
          </AccordionSummary>
          <AccordionDetails className={classes.chartItemAccordionDetails}>
            <Box className={classes.expansionContainer}>
              <Typography variant="h6">Extra details goes here...</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Notification
        type={notificationType}
        message={notificationMsg}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
    </>
  );
};

export default ChartRow;
