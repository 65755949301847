// English : Store all pages label info into singe place

export const en = {
  login: {
    username: 'Email',
    password: 'Password',
    signin: 'Sign In',
    failure: {
      dialogTitle: 'Login failure',
      dialogMessage: 'Invalid username or password',
    },
  },
  forgotpassw: {
    title: 'Password Reset',
    username: 'User Name',
    submit: 'Reset',
    failure: {
      dialogTitle: 'Forgot password request failure',
      dialogMessage: 'Invalid username',
    },
  },
  confirmpassw: {
    title: 'Password reset',
    verificationCode: 'Verification Code',
    newpassword: 'New Password',
    confirmnewpassword: 'Confirm new Password',
    submit: 'Submit',
    failure: {
      dialogTitle: 'Confirm password request failure',
      dialogMessage: 'Request failed',
    },
  },
  copyRights: 'Health Data Max',
  mainTitle: 'Health Data Max',
};
