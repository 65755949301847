import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  navGuideContainer: {
    backgroundColor: theme.palette.primary.grey,
    padding: '10px',
    borderRadius: '8px',
    width: '100%',
    flex: '22%',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  navTitle: {
    color: theme.palette.primary.black,
    fontWeight: '600',
    fontSize: '16px',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px',
  },
  searchBar: {
    borderRadius: '999px',
    padding: 0,
    marginBottom: '12px',
    '& input': { padding: '6px 0' },
    '& button': {
      padding: '7px',
      marginRight: '-4px',
      backgroundColor: theme.palette.secondary.grey,
      border: `1px solid ${theme.palette.primary.black}`,
      zIndex: 1,
      '& svg': {
        maxWidth: '20px',
        maxHeight: '20px',
      },
    },
    '& fieldset': {
      padding: 0,
    },
    '& fieldset': {
      border: `2px solid ${theme.palette.primary.black}`,
    },
    '&:hover fieldset': {
      border: `2px solid ${theme.palette.primary.black} !important`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${theme.palette.primary.black} !important`,
    },
    // marginBottom: '12px',
    // paddingRight: '20px',
    // '& .MuiInputBase-input': {
    //   padding: '6px 0 6px 16px',
    // },
    // '& button': {
    //   maxWidth: '40px',
    //   maxHeight: '40px',
    // },
    // '& input': {
    //   color: theme.palette.primary.black,
    //   '&::placeholder': {
    //     color: theme.palette.primary.black,
    //   },
    // },
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '& .Mui-expanded': {
      margin: '0',
    },
  },
  submittedCodesButton: {
    boxShadow: 'none',
    borderRadius: '10px',
    backgroundColor: 'rgba(193, 209, 214, 0.30)',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    '&:hover': {
      backgroundColor: 'rgba(193, 209, 214, 0.40)',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: 'rgba(193, 209, 214, 0.40)',
      boxShadow: 'none',
    },
  },
  skeletonAccordion: {
    borderRadius: '10px',
    width: '100%',
    height: '37px',
    marginBottom: '2px',
    paddingLeft: '16px',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3))',
    },
  },
}));

export default useStyles;
