import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px',
    width: '100%',
    height: 'calc(100vh - 65px)',
  },
}));

export default useStyles;
