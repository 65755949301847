import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import useStyles from './style';

const CollapseTableRow = ({
  row,
  rowIndex,
  openCollapse,
  metadata,
  classes,
}) => {
  const defaultClasses = useStyles();

  return (
    <TableRow
      key={`collapase-Row-${rowIndex}`}
      className={classes.collapseTableRow}
    >
      <TableCell
        key={`collapase-cell-${rowIndex}`}
        style={{ paddingBottom: 0, paddingTop: 0 }}
        //colSpan={0}
        colSpan={6}
      >
        <Collapse
          key={`collapase-component-${rowIndex}`}
          in={rowIndex === openCollapse}
          timeout="auto"
          unmountOnExit
        >
          <Box key={`collapase-box-${rowIndex}`} margin={1}>
            <Table
              key={`collapase-inner-table-${rowIndex}`}
              size="small"
              aria-label="collapse-table"
            >
              <TableHead
                key={`collapase-head-${rowIndex}`}
                className={defaultClasses.tableHeader}
                sx={{
                  backgroundColor: '#F6F6F6 !important',
                  borderRadius: '10px !important',
                }}
              >
                <TableRow key="Collapse-tableHead">
                  {metadata.fields
                    .filter((x) => x.collapse)
                    .map((headCell) => (
                      <TableCell
                        key={`collapase-head-${headCell.label}`}
                        style={{ fontSize: 11, fontWeight: 'bold' }}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody key={`collapase-inner-body-${rowIndex}`}>
                <TableRow
                  key={`collapase-inner-row-${rowIndex}`}
                  sx={{
                    borderRadius: '10px',
                    background: '#FFF',
                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
                  }}
                >
                  {metadata.fields
                    .filter((x) => x.collapse)
                    .map((col) => (
                      <TableCell
                        align={col.alignRight ? 'right' : 'left'}
                        key={col.id}
                        style={{ fontSize: 10 }}
                      >
                        {row[col.id]}
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default CollapseTableRow;
